import { EntityFields } from '../../../utils/index';
import { Typography } from '@mui/material';
import { formatCurrency } from 'core/utils';

export const PAYMENT_ENTITY_NAME = 'Payment';

export type PaymentInput = {
  amount: number;
  payment_method: string;
};

export const paymentInitialValues: PaymentInput = {
  amount: 0,
  payment_method: 'cash',
};

export const paymentFields: EntityFields[] = [
  {
    fieldName: 'payment_date',
    displayName: 'Payment Date',
    type: 'date',
  },
  {
    fieldName: 'amount',
    displayName: 'Amount',
    type: 'currency',
  },
  {
    fieldName: 'payment_method',
    displayName: 'Payment Method',
    type: 'select',
    options: [
      { key: 'Cash', value: 'cash' },
      { key: 'Bank Transfer', value: 'bank' },
      { key: 'Gcash', value: 'gcash' },
      { key: 'Maya', value: 'maya' },
      { key: 'Debit/Credit', value: 'debitcredit' },
    ],
  },
  {
    fieldName: 'payment_difference',
    type: 'component',
    hiddenBasedOnOtherField: (data) => parseFloat(data.amount) >= parseFloat(data.remaining),
    displayComponentBasedOnFormFields: (data) => (
      <Typography>
        Payment Difference: {formatCurrency(parseFloat(data.remaining) - parseFloat(data.amount))}
      </Typography>
    ),
  },
  {
    fieldName: 'invoice_number',
    displayName: 'Invoice #',
  },
  {
    fieldName: 'full_name',
    displayName: 'Patient',
    generateLink: (data) => `/company/patient-management?patient_id=${data.patient_id}`,
  },
];

export const paymentTableColumns: string[] = ['payment_date', 'amount', 'payment_method'];

export const paymentFormFields: string[] = ['amount', 'payment_method', 'payment_difference'];
