/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, useMediaQuery } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomModal,
  CustomSelectField,
  RegularButton,
} from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { createTemplate, deleteTemplate, getTemplates, updateTemplate } from 'company/api/note-templates';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import DocumentEditor from '../DocumentEditor';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { UserContext } from 'core/context/user.context';
import { WaitForFacility } from 'company/components';
import { createNote } from 'company/api/patient-notes';
import { create_note_template_schema } from 'carego-admin/model/schema';
import { formatDateTime } from 'core/utils';
import { getCollections } from 'lgu/api/setup';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type TemplateInput = {
  template_name: string;
  collection_id: string | number;
};

interface TemplateFieldInput extends FieldInput {
  field_name: keyof TemplateInput;
}

type CompanyTemplatesProps = {
  refreshFromCopy?: number;
  isFromPatientManagement?: boolean;
  patient_id?: number;
  setRefreshForNotes?: (refreshForNotes: any) => void;
  appointment?: any;
  onCreate?: (data: any) => void;
};

const CompanyTemplates: React.FC<CompanyTemplatesProps> = ({
  refreshFromCopy,
  setRefreshForNotes,
  isFromPatientManagement,
  patient_id,
  appointment,
  onCreate,
}) => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const [forceRefresh, setForceRefresh] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openAddConfirmModal, setOpenAddConfirmModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [collections, setCollections] = useState<any>();
  const [collectionOptions, setCollectionOptions] = useState<any>();
  const [selectedCollectionId, setSelectedCollectionId] = useState<any>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const columns: GridColDef[] = [
    {
      field: 'header',
      headerName: 'Header',
      flex: 2,
    },
    {
      field: 'collection_name',
      headerName: 'Collection',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>{params.row.collection_name ? params.row.collection_name : '-'}</CustomGridCell>
      ),
    },
    {
      field: 'updated_at',
      headerName: 'Date Updated',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.updated_at)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <RegularButton
            label="Use"
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              setSelectedTemplate(params.row);
              setOpenAddConfirmModal(true);
            }}
          />
          <RegularButton
            label="Preview"
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              setSelectedTemplate(params.row);
              setOpenPreviewModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const fetchCollections = () => {
    getCollections().then((collections) => {
      setCollections(collections.data);
      setCollectionOptions(
        collections.data.map((u: any) => {
          return { key: u.name, value: u.id };
        })
      );
    });
  };

  const getData = async (query: ApiQuery) => {
    fetchCollections();
    if (selectedCollectionId) {
      query = {
        collection_id: selectedCollectionId,
      };
    }
    return getTemplates({ page: 1, length: 10, ...query });
  };

  const handleDelete = async (data: any) => {
    return facility && deleteTemplate(data);
  };

  const handleCreate = async () => {
    const data = {
      template_id: selectedTemplate.id,
      source: 'clinic',
      appointment_id: appointment?.id,
    };

    return (
      facility &&
      patient_id &&
      createNote(facility.id, patient_id, data)
        .then((res) => {
          enqueueSnackbar(`Note successfully added!`, { variant: 'success' });
          setRefreshForNotes && setRefreshForNotes((prev: number) => prev + 1);
          // navigate(`/company/notes/View/${res.data.note.id}`);
          navigate(`/company/patient-notes/Edit/${res.data.note.id}`);

          if (onCreate) {
            onCreate(res.data.note);
          }

          // navigate(`/company/patient-notes/view/${res.data.note.id}`);
        })
        .catch((error) => {
          // console.error(error);
          // enqueueSnackbar(`Error occurred! Unable to add note.`, { variant: 'error' });
        })
    );
  };

  const handleSubmit = async (data: any) => {
    if (data.id) {
      return (
        facility &&
        updateTemplate(data, facility.id).then(() => {
          enqueueSnackbar('Note Template successfully updated!', { variant: 'success' });
          refresh();
        })
      );
    } else {
      return (
        facility &&
        createTemplate(data).then((res) => {
          enqueueSnackbar('Note Template successfully created!', { variant: 'success' });
          navigate(`/company/notes/Modify/${res.data.note.id}`);
          refresh();
        })
      );
    }
  };

  const handleFilterChange = (event: any) => {
    setSelectedCollectionId(event.target.value);
    refresh();
  };

  const refresh = () => {
    setForceRefresh && setForceRefresh((prev: number) => prev + 1);
  };

  const formFields: TemplateFieldInput[] = [
    { field_name: 'template_name', display_name: 'Template Name', type: 'text', span: 4 },
    {
      field_name: 'collection_id',
      display_name: 'Collection',
      type: 'select',
      span: 4,
      options: collections?.map((u: any) => {
        return { key: u.name, value: u.id };
      }),
    },
  ];

  const initialValues: TemplateInput = {
    template_name: '',
    collection_id: '',
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Templates' }]);
    fetchCollections();
  }, []);

  useEffect(() => {}, [selectedCollectionId]);

  useEffect(() => {
    refresh();
  }, [refreshFromCopy]);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.TABLE,
      tableName: 'Company Templates',
      entityName: 'Template',
      useColoredTable: true,
      tableComponent: {
        hideSearch: isMobilePhone,
        columns: isFromPatientManagement ? columns : columns.filter((col) => col.field !== 'action'),
        getData: getData,
        mobileViewConfig: () => ({
          title_fields: ['template_name'],
          subtitle_fields: ['updated_at', 'collection_name'],
        }),
        filterComponent: (
          <Box width={!isMobilePhone ? '250px' : '100%'}>
            <CustomSelectField
              label="Collection Filter"
              fieldName="collection_id"
              options={collectionOptions}
              size="small"
              handleChange={handleFilterChange}
            />
          </Box>
        ),
        forceRefresh: forceRefresh,
        rowHeight: 45,
      },
      tableForm: {
        formFields: formFields,
        formInitialValues: initialValues,
        formSchema: create_note_template_schema,
        formSubmitApiFunction: handleSubmit,
      },
      tableAction:
        isMobilePhone || isFromPatientManagement
          ? {
              includeAddButton: false,
              includeDeleteAction: false,
              includeUpdateAction: false,
              deleteApiFunction: handleDelete,
              handleRowActionsClick(selectedRow, action) {
                if (action === 'update') {
                  navigate(`/company/notes/Modify/${selectedRow.id}`);
                }
              },
              rowActions: isMobilePhone && [
                {
                  label: 'View',
                  icon: <RemoveRedEyeOutlinedIcon />,
                  action: (row) => {
                    navigate(`/company/notes/View/${row.id}`);
                  },
                },
                {
                  label: 'Use',
                  action: (row) => {
                    setSelectedTemplate(row);
                    setOpenAddConfirmModal(true);
                  },
                },
              ],
              rowActionRequiredAuth: ['MANAGE_NOTE_TEMPLATES'],
              addActionRequiredAuth: ['MANAGE_NOTE_TEMPLATES'],
            }
          : {
              includeAddButton: false,
              includeDeleteAction: true,
              includeUpdateAction: true,
              deleteApiFunction: handleDelete,
              handleRowActionsClick(selectedRow, action) {
                if (action === 'update') {
                  navigate(`/company/notes/Modify/${selectedRow.id}`);
                }
              },

              rowActionRequiredAuth: ['MANAGE_NOTE_TEMPLATES'],
              addActionRequiredAuth: ['MANAGE_NOTE_TEMPLATES'],
            },
    } as PageBuilderTableType,
  } as PageBuilderPageType;

  return (
    <>
      <ConfirmationDialog
        content="Are you sure you want to delete this template?"
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDelete}
      />
      <ConfirmationDialog
        content="Are you sure you want to use this template?"
        open={openAddConfirmModal}
        setOpen={setOpenAddConfirmModal}
        onConfirm={handleCreate}
        confirmButtonColor
        bottomDrawerDialog={isMobilePhone ? true : false}
      />

      {selectedTemplate && (
        <CustomModal header="Preview Note Template" width={1000} open={openPreviewModal} setOpen={setOpenPreviewModal}>
          <DocumentEditor source={'company'} isPreview template_id={selectedTemplate.id} patient_id={patient_id} />
        </CustomModal>
      )}
      <WaitForFacility facility={facility}>
        <CustomContainer>
          <Box width="100%">
            <PageBuilder content={content} />
          </Box>
        </CustomContainer>
      </WaitForFacility>
    </>
  );
};

export default CompanyTemplates;
