import * as yup from 'yup';

import { Box, Typography } from '@mui/material';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';
import { generateInstruction } from 'core/utils';

export const PRESCRIPTION_LINE_ITEM_ENTITY_NAME = 'Medicine';

export interface PrescriptionLineItemModel {
  id: number;
  brand_name: string;
  company_id: number;
  dosage: string;
  duration: number;
  facility_id: number;
  generic_name: string;
  instruction: string;
  is_active: number;
  medicine_id: number;
  medicine_instruction_id: number;
  prescription_id: number;
  with_reminders: boolean;
  quantity: number;
  created_at: Date;
  updated_at: Date;
  start_date: string;
  time_to_take: string;
}

export const prescription_line_schema = yup.object().shape({
  medicine_id: yup.string().required('Medicine is required.'),
  quantity: yup
    .number()
    .required('Quantity is required')
    .typeError('Must be a number')
    .min(1, 'Must be greater than 0')
    .integer('Must be a whole number'),
});

export const PrescriptionLineItemFields: EntityFields[] = [
  // { fieldName: 'generic_name', displayName: 'Generic Name' },
  // { fieldName: 'brand_name', displayName: 'Brand Name' },
  // { fieldName: 'dosage', displayName: 'Dosage' },
  {
    fieldName: 'medicine_id',
    type: DROPDOWN_FIELD.MEDICINE,
    optional: false,
    span: 2,
  },
  { fieldName: 'quantity', displayName: 'Quantity', span: 2 },
  {
    fieldName: 'instruction',
    displayName: 'Instruction',
    optional: true,
    renderCell: (params) => <Typography>{generateInstruction(params.row)}</Typography>,
    span: 4,
    flex: 3,
    multiline: true,
    rows: 3,
  },
  { fieldName: 'divider', type: 'divider' },
  {
    fieldName: 'component_1',
    type: 'component',
    component: (
      <Typography variant="h4" fontWeight="500" color="primary">
        If you want to send reminders, please fill in the fields here: (optional)
      </Typography>
    ),
  },
  { fieldName: 'start_date', displayName: 'Medication Start Date', type: 'date', span: 2 },
  { fieldName: 'time_to_take', displayName: 'Start Time', type: 'time', optional: true },
  { fieldName: 'duration', displayName: 'Duration of the treatment (in days)', type: 'number', optional: true },
  {
    fieldName: 'frequency',
    displayName: 'Frequency',
    type: 'select',
    options: [
      { key: 'Daily', value: 'daily' },
      // { key: 'Weekly', value: 'weekly' },
      // { key: 'Custom', value: 'custom' },
    ],
    optional: true,
  },
  {
    fieldName: 'frequency_per_day',
    displayName: 'Frequency per day (Dosage)',
    type: 'number',
    optional: true,
  },
  {
    fieldName: 'hours_interval',
    displayName: 'Dosage Interval (in hours)',
    type: 'number',
    hiddenBasedOnOtherField: (data) => {
      return !data.frequency_per_day || data.frequency_per_day <= 1;
    },
    optional: true,
  },
  {
    fieldName: 'additional',
    type: 'component',
    span: 4,
    hiddenBasedOnOtherField: (data) => !data.frequency,
    displayComponentBasedOnFormFields: (data) => {
      return (
        <Box sx={{ border: '1px solid #239BD7', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ color: '#239BD7' }}>
            {generateInstruction(data)}
          </Typography>
        </Box>
      );
    },
  },
];

export const PrescriptionLineItemsVisibleColumns = ['generic_name', 'brand_name', 'dosage', 'quantity', 'instruction'];

export const PrescriptionLineItemsForm = [
  'medicine_id',
  'quantity',
  'instruction',
  'divider',
  'component_1',
  'frequency',
  'start_date',
  'time_to_take',
  'duration',
  'frequency_per_day',
  'hours_interval',
  'additional',
];

export const prescriptionLineInitialValues = {
  quantity: '',
  instruction: '',
};
