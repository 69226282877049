import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getDoctor, getDoctors } from 'company/api/doctors';

const ServiceProviderSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Service Provider'}
      getData={getDoctors}
      getById={getDoctor}
      processOption={(doctor) => ({ key: `${doctor.first_name} ${doctor.last_name}`, value: doctor.doctor_id })}
      processNewRecord={(record) => record.company_doctor}
      hideAddOption
    />
  );
};

export default ServiceProviderSearchDropdown;
