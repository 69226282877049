import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { calculateAge, formatArray, formatDate } from 'core/utils';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { PatientEmergencyContactModel } from 'company/entities/modules/ClinicManagement/PatientEmergencyContact/PatientEmergencyContactModel';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import PatientHeader from './PatientHeader';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { getBasicHealthInformationByPatient } from 'company/api/patient-basic-health-info';
import { getBasicHealthInformationFields } from 'company/api/patient-basic-health-info-field';
import { getContactByPatient } from 'company/api/patient-emergency-contact';
import { tokens } from 'theme/theme';

type Props = {
  patient: PatientModel;
};

const PrintablePatientProfile = forwardRef(({ patient }: Props, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [patientData, setPatientData] = useState<PatientEmergencyContactModel[]>();

  // const [patient, setPatient] = useState<PatientModel>();
  // const [items, setItems] = useState<OrderProps[]>([]);

  const [fields, setFields] = useState<FieldInput[]>([]);
  const [initialValues, setInitialValues] = useState<any[]>([]);

  const processFields = (fields: any[]) => {
    return fields.map(
      (field: {
        field_type: any;
        id: any;
        field_name: string;
        display_name: string;
        field_options?: string[];
        field_unit?: string;
      }) =>
        ({
          field_name: field.id.toString(),
          display_name: (field.display_name ?? field.field_name) + (field.field_unit ? ` (${field.field_unit})` : ''),
          type: field.field_type === 'yes_no' ? 'radiogroup' : field.field_type,
          span: ['long_text', 'group_header'].includes(field.field_type) ? 4 : 2,
          multiline: field.field_type === 'long_text',
          rows: field.field_type === 'long_text' ? 3 : undefined,
          options:
            field.field_type === 'yes_no' || (field.field_options && field.field_options.length)
              ? field.field_type === 'yes_no'
                ? [
                    { key: 'Yes', value: 'yes' },
                    { key: 'No', value: 'no' },
                  ]
                : (Array.isArray(field!.field_options)
                    ? field!.field_options
                    : JSON.parse(field!.field_options + '')
                  )?.map((option: string) => ({ key: option, value: option }))
              : undefined,
        } as FieldInput)
    );
  };

  const fetchData = async () => {
    try {
      if (patient) {
        const response = await getContactByPatient(facility.id, patient.patient_id, { length: 100 });
        setPatientData(response.data.data); // Update patientData state with fetched data
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  useEffect(() => {
    if (patient && facility) {
      getBasicHealthInformationByPatient(facility.id, patient.patient_id).then((fieldData) => {
        const initialData = {} as any;
        fieldData.data.data.forEach((data: any) => {
          initialData[data.field_id as string] = data.value;
        });

        setInitialValues(initialData);
      });
      getBasicHealthInformationFields({ order_by: 'order' }).then((result) => {
        setFields(processFields(result.data.data.filter((item: any) => !item.hidden)));
      });
    }
  }, [patient, facility]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  return (
    <Box ref={ref}>
      <style>{styles}</style>

      <Box display={'flex'} justifyContent={'space-between'}>
        <PatientHeader patient={patient} />
        <Box display={'flex'} flexDirection={'column'} rowGap={'5px'} alignItems={'end'}>
          {user.company?.company_logo && (
            <img alt="clinic-logo" width="90px" height="90px" id="logo" src={user.company?.company_logo} />
          )}
          <Typography variant="h3" textAlign="right" color={colors.primary} fontWeight={'bold'}>
            {facility.facility_name}
          </Typography>
          <Typography variant="h5" textAlign="right">
            {formatArray([facility.address, facility.municipality_name, facility.province_name])}
          </Typography>
          <Typography variant="h5" textAlign="right">
            {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')};
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={SIZES.paddingL}>
        {/* Personal Information */}
        <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <PersonPinOutlinedIcon color="primary" />
            <Typography variant="h6" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
              Patient Information
            </Typography>
          </Box>
          <Box sx={{ '& p': { fontSize: '11px !important' } }}>
            {patient && (
              <Box width="100%">
                <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="10px">
                  <PatientField label="Patient ID" value={patient.id} />
                  <PatientField label="First Name" value={patient.first_name} />
                  <PatientField label="Middle Name" value={patient.middle_name} />
                  <PatientField label="Last Name" value={patient.last_name} />
                  <PatientField label="Date Registered" value={formatDate(patient.created_at)} />
                  <PatientField label="Sex Assigned at Birth" value={patient.sex} />
                  <PatientField label="Birthday" value={formatDate(patient.birthday)} />
                  <PatientField label="Age" value={calculateAge(patient.birthday)} />
                  <PatientField label="Occupation" value={patient.occupation} />
                  {/* <PatientField label="PWD ID" value={patient.pwd_id} /> */}
                  {/* <PatientField label="Senior Citizen ID" value={patient.senior_citizen_id} /> */}

                  <PatientField label="Mobile # (Primary)" value={patient.mobile_number} />
                  <PatientField label="Mobile # (Secondary)" value={patient.mobile_number_2} />
                  <PatientField label="Landline Number" value={patient.landline_number} />
                  <PatientField label="Email Address" value={patient.email} />
                  <PatientField
                    label="Address"
                    value={formatArray([patient.address, patient.province_city], ', ')}
                    sx={{ gridColumn: 'span 3' }}
                  />
                  <Divider sx={{ gridColumn: 'span 4' }} />
                  {patient.company_name && (
                    <>
                      <PatientField label="Company" value={patient.company_name} />
                      {/* <PatientField label="Department" value={patient.department_name} /> */}
                      <PatientField label="Employee ID" value={patient.employee_id} />
                    </>
                  )}

                  {patient.hmo_name && (
                    <>
                      <PatientField label="HMO" value={patient.hmo_name} />
                      <PatientField label="HMO Number" value={patient.hmo_account_number} />
                      <PatientField label="HMO Account Name" value={patient.hmo_account_name} />
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* Health Information */}
        <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <AssignmentIndIcon color="primary" />
            <Typography variant="h6" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
              Health Information
            </Typography>
          </Box>
          <Box sx={{ '& p': { fontSize: '11px !important' } }}>
            <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
              {fields.map((field, index) =>
                field.type === 'group_header' ? (
                  <Typography key={index} variant="h5" fontWeight={'600'} style={{ gridColumn: 'span 4' }}>
                    {field.display_name}
                  </Typography>
                ) : (
                  <PatientField
                    key={index}
                    label={field.display_name!}
                    value={initialValues[field.field_name] ?? '-'}
                    sx={{ gridColumn: field.type === 'long_text' ? 'span 2' : undefined }}
                  />
                )
              )}
            </Box>
          </Box>
        </Box>

        {/* Emergency Contacts */}
        <Box display={'flex'} flexDirection={'column'} gap={SIZES.padding}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <LocalPhoneIcon color="primary" />
            <Typography variant="h6" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
              Emergency Contacts
            </Typography>
          </Box>
          <Box sx={{ '& p': { padding: '0px !important' } }}>
            <Grid container spacing={1}>
              {patientData &&
                patientData.map((data, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(4, 1fr)"
                      gap="5px"
                      paddingBottom={SIZES.paddingS}
                      sx={{ '& p': { fontSize: '11px !important' } }}
                    >
                      <PatientField label="Full Name" value={`${data.first_name} ${data.last_name}`} />
                      <PatientField label="Relationship" value={data.relationship} />
                      <PatientField label="Contact #" value={data.mobile_number} />
                      <PatientField label="Email" value={data.email} />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>

        {/* <Box width="100%" position="fixed" bottom="0">
          <Box width="100%">
            <hr
              style={{
                border: 'none',
                borderTop: '0.5px solid #0192D5',
                width: '100%',
                transform: 'scaleY(0.5)',
              }}
            />

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <img
                  alt="cphi-logo"
                  width="35px"
                  height="35px"
                  id="logo"
                  src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                />

                <Box sx={{ textAlign: 'left', gap: '4px' }}>
                  <Typography sx={{ fontSize: '8px', color: colors.accent }}>
                    Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
});

const styles = `
  @page {
    size: A4;
    margin: 1cm 2cm 2cm;
  }

  table {
    border-collapse: collapse;
    padding: 10px;
    width: 100%;
  }

  thead tr td {
    padding-top: 220px;
  }

  thead td, tbody td {
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
  }
`;

export default PrintablePatientProfile;
