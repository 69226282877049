import { Box, Typography, useTheme } from '@mui/material';
import { CalendarEvent, ViewProps } from '.';
import { NoServiceProviderDisplay, calculateEventPosition } from './WeekView';
import React, { useContext, useEffect, useState } from 'react';
import { calculatePositionedEvents, formatCalenderTime } from './utils';

import { FacilityContext } from 'core/context/facility.context';
import { tokens } from 'theme/theme';

const DayView: React.FC<ViewProps> = ({
  date,
  staff,
  events,
  hours,
  startHour,
  endHour,
  facilitySchedule,
  setOpenServiceProviders,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);

  const dayOfWeek = date.getDay();
  const todaySchedule = facilitySchedule[dayOfWeek];
  const hasScheduleToday = todaySchedule && todaySchedule.hours && todaySchedule.hours.length > 0;

  let earliestStartTime = 23;
  let latestEndTime = 0;

  facilitySchedule.forEach((day, index) => {
    if (day && day.hours && day.hours.length > 0) {
      day.hours.forEach((hour) => {
        const startHourString = hour.start.split(':')[0];
        const endHourString = hour.end.split(':')[0];
        const startPeriod = hour.start.split(' ')[1];
        const endPeriod = hour.end.split(' ')[1];

        let startHour = parseInt(startHourString);
        let endHour = parseInt(endHourString);

        if (startPeriod === 'PM' && startHour !== 12) {
          startHour += 12;
        } else if (startPeriod === 'AM' && startHour === 12) {
          startHour += 1;
        }

        if (endPeriod === 'PM' && endHour !== 12) {
          endHour += 13;
        } else if (endPeriod === 'AM' && endHour === 12) {
          endHour += 1;
        } else if (endPeriod === 'AM' && endHour <= 12) {
          endHour += 1;
        }
        earliestStartTime = Math.min(earliestStartTime, startHour);
        latestEndTime = Math.max(latestEndTime, endHour);
      });
    }
  });

  const hoursRange = Array.from({ length: latestEndTime - earliestStartTime }, (_, i) => (i + earliestStartTime) % 24);

  const getEventsForStaffAndHour = (staffId: number, facilityId: number, hour: number) => {
    return calculatePositionedEvents(
      events.filter((event) => {
        const scheduleStart = new Date(event.schedule);
        return (
          ((event.provider_id === staffId && event.facility_id === facilityId) || (!staffId && !event.provider_id)) &&
          scheduleStart.getFullYear() === date.getFullYear() &&
          scheduleStart.getMonth() === date.getMonth() &&
          scheduleStart.getDate() === date.getDate() &&
          scheduleStart.getHours() === hour
        );
      })
    );
  };

  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate() &&
        date.getHours() <= endHour
      ) {
        const minutesSinceStartOfDay = now.getHours() * 60 + now.getMinutes() - startHour * 60;
        setCurrentTimePosition(minutesSinceStartOfDay);
      } else {
        setCurrentTimePosition(null);
      }
    };

    updateCurrentTimePosition();
    const intervalId = setInterval(updateCurrentTimePosition, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [date, endHour, startHour]);

  return (
    <Box display="flex" width="100%">
      <Box>
        <Box
          height={'70px'}
          position="sticky"
          top={0}
          sx={{ backgroundColor: colors.secondary_background, borderBottom: '3px solid #ccc' }}
        />
        {!hasScheduleToday
          ? hoursRange.map((hour) => (
              <Box key={hour} className="time-slot" sx={{ height: '60px' }}>
                <Box className="time-label" textAlign="left" width="60px" paddingRight="10px">
                  <Typography fontWeight={500} fontSize={'11px'}>
                    No Schedule Today
                  </Typography>
                </Box>
              </Box>
            ))
          : hoursRange.map((hour) => (
              <Box key={hour} className="time-slot" sx={{ height: '60px' }}>
                <Box className="time-label" textAlign="right" width="60px" paddingRight="10px">
                  <Typography>{formatCalenderTime(hour)}</Typography>
                </Box>
              </Box>
            ))}
      </Box>

      <Box width="100%">
        <Box display="flex" position="sticky" top={0} zIndex={11} sx={{ backgroundColor: colors.secondary_background }}>
          {staff.map((staffMember, staffIndex) => (
            <Box
              flex={1}
              width="100%"
              key={staffMember.assignment_id}
              className="staff-column"
              sx={{ borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined }}
            >
              <Box
                className="staff-name"
                sx={{ borderBottom: '3px solid ' + colors.primary, borderColor: staffMember.color }}
              >
                <Box sx={{ wordBreak: 'break-word' }}>
                  <Typography fontWeight={500}>
                    {staffMember.first_name} {staffMember.last_name ? staffMember.last_name?.charAt(0) + '.' : ''}
                  </Typography>
                  {facility?.is_admin && <Typography fontSize="11px">{staffMember.facility_name}</Typography>}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box display="flex" width="100%">
          {!staff.length && <NoServiceProviderDisplay setOpenServiceProviders={setOpenServiceProviders} />}
          {staff.map((staffMember, index) => (
            <Box width="100%" key={index}>
              <Box
                key={staffMember.id}
                className="staff-column"
                sx={{
                  position: 'relative',
                  borderLeft: index === 0 ? '1px solid #ccc' : undefined,
                }}
              >
                <div className="events">
                  {hoursRange.map((hour) => (
                    <div key={hour} className="time-slot" style={{ height: '60px' }}>
                      {getEventsForStaffAndHour(staffMember.doctor_id, staffMember.facility_id, hour).map((event) => {
                        const { top, height } = calculateEventPosition(event, earliestStartTime);
                        return (
                          <Box key={event.id} sx={{ position: 'absolute', top: `${top}px`, width: '100%' }}>
                            <CalendarEvent
                              event={{ ...event, height }}
                              staffColor={staffMember.color || null}
                              staffCount={staff.length}
                              dayView
                            />
                          </Box>
                        );
                      })}
                    </div>
                  ))}
                </div>
                {currentTimePosition !== null && (
                  <Box
                    sx={{
                      top: `${currentTimePosition}px`,
                      position: 'absolute',
                      height: '2px',
                      width: '100%',
                      backgroundColor: colors.redAccent,
                      zIndex: 2,
                    }}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DayView;
