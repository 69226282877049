// src/utils.ts
import { CompanyAppointmentModel } from 'company/model/Entities';
import { Event } from './sampleEvents';

export interface PositionedEvent extends CompanyAppointmentModel {
  top: number;
  height: number;
  left: number;
  width: number;
  start?: string;
  end?: string;
}

export const calculatePositionedEvents = (events: CompanyAppointmentModel[]): PositionedEvent[] => {
  if (events.length === 0) return [];

  // Sort events by start time
  events.sort((a, b) => new Date(a.schedule).getTime() - new Date(b.schedule).getTime());

  // Create an array to hold positioned events
  const positionedEvents: PositionedEvent[] = [];

  // Track columns and their end times
  const columns: { [key: number]: { endTime: number; width: number; events: PositionedEvent[] } } = {};

  events.forEach((event, index) => {
    const scheduleStart = new Date(event.schedule);
    const scheduleEnd = new Date(event.schedule);
    scheduleEnd.setMinutes(scheduleEnd.getMinutes() + (event.duration ? event.duration : 30));
    const start = scheduleStart.getHours() + scheduleStart.getMinutes() / 60;
    const end = scheduleEnd.getHours() + scheduleEnd.getMinutes() / 60;

    const top = scheduleStart.getMinutes(); // each hour is 60px
    const height = (end - start) * 60;

    let placed = false;

    for (const key in columns) {
      const column = columns[key];
      if (column.endTime <= start) {
        column.endTime = end;
        column.events.push({ ...event, top, height, left: Number(key), width: 1 });
        placed = true;
        break;
      }
    }

    if (!placed) {
      const columnCount = Object.keys(columns).length;
      columns[columnCount] = {
        endTime: end,
        width: 1,
        events: [{ ...event, top: top, height, left: columnCount, width: 1 }],
      };
    }
  });

  // Adjust widths of events
  const maxColumns = Object.keys(columns).length;

  for (const key in columns) {
    const column = columns[key];
    column.events.forEach((event) => {
      event.width = maxColumns;
    });
    positionedEvents.push(...column.events);
  }

  return positionedEvents;
};

export const getEventStyle = (event: Event) => {
  const start = event.start.getHours() + event.start.getMinutes() / 60;
  const end = event.end.getHours() + event.end.getMinutes() / 60;
  const height = (end - start) * 60;
  return {
    height: `${height}px`,
  };
};

export const formatCalenderTime = (hour: number) => {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(0);
  return date.toLocaleTimeString([], { hour: 'numeric', hour12: true });
};

export function simpleStringify(object: any): string {
  const simpleObject: { [key: string]: any } = {};
  for (const prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) {
      if (typeof object[prop] !== 'object' && typeof object[prop] !== 'function') {
        simpleObject[prop] = object[prop];
      }
    }
  }
  return JSON.stringify(simpleObject);
}
