import * as yup from 'yup';

import { DROPDOWN_FIELD, FieldInput, SelectOption } from 'core/model/interface';

import { EntityFields } from '../../../utils';
import { Typography } from '@mui/material';
import { formatDateTime } from 'core/utils';
import moment from 'moment';

const today = new Date();

export const BOOKING_ENTITY_NAME = 'Booking';

export const createBookingSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required('Last Name is required.'),
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  mobile_number: yup
    .string()
    .matches(/^(09|639|\+639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Please enter a valid email address'
    )
    .nullable(),
  service_id: yup.number().required('Service is required.'),
});

export interface BookingModel {
  id: number;
  service_id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  birthday: string;
  email: string;
  mobile_number: string;
  schedule: string;
  sex: string;
  status: string;
  service_name: string;
  is_active: boolean;
}

export interface BookingInput {
  first_name: string;
  middle_name: string;
  last_name: string;
  schedule: string;
  mobile_number: string;
  birthday: string;
  sex: string;
  email: string;
  service_id: SelectOption[];
}

export interface BookingFieldInput extends FieldInput {
  field_name?: keyof BookingInput;
}

export const bookingInitialValues: BookingInput = {
  service_id: [],
  first_name: '',
  middle_name: '',
  last_name: '',
  birthday: '',
  email: '',
  mobile_number: '',
  schedule: '',
  sex: '',
};

// fieldName should be unique
export const bookingFields: EntityFields[] = [
  { fieldName: 'first_name', displayName: 'First Name' },
  { fieldName: 'middle_name', displayName: 'Middle Name' },
  { fieldName: 'last_name', displayName: 'Last Name' },
  {
    fieldName: 'full_name',
    displayName: 'Name',
    // link: '/company/booking/:id',
    // rowId: 'id',
    flex: 1.5,
  },
  { fieldName: 'service_name', displayName: 'Service Name' },
  { fieldName: 'service_id', displayName: 'Service Name', type: DROPDOWN_FIELD.SERVICE, options: [], optional: false },
  { fieldName: 'status', displayName: 'Status' },
  { fieldName: 'schedule', displayName: 'Schedule', type: 'datetime', editable: true },
  { fieldName: 'mobile_number', displayName: 'Mobile Number' },
  { fieldName: 'email', displayName: 'Email', type: 'email' },
  {
    fieldName: 'sex',
    displayName: 'Sex Assigned At Birth',
    type: 'select',
    options: [
      { key: 'Male', value: 'Male' },
      { key: 'Female', value: 'Female' },
    ],
  },
  { fieldName: 'birthday', displayName: 'Birthday', type: 'date', editable: true, optional: true },
  {
    fieldName: 'created_at',
    displayName: 'Date Registered',
    flex: 1,
    type: 'dateTime',
    valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY hh:mm A'),
    renderCell: (params) => <Typography>{formatDateTime(params.row.created_at)}</Typography>,
  },
  { fieldName: 'divider_1', type: 'divider' },
];

// Table
export const bookingTableColumns: string[] = ['full_name', 'mobile_number', 'schedule', 'service_name', 'status'];

// Forms
export const bookingFormFields: string[] = [
  'first_name',
  'middle_name',
  'last_name',
  'schedule',
  'mobile_number',
  'birthday',
  'sex',
  'email',
  'service_id',
];
