import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'inventories';

export const getInventory = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getInventories = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/many/${facility_id}`, { params: query });
};

export const getInventorySummary = (facility_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/summary/${facility_id}`, { params: query });
};

export const getVariantsOfProduct = (product_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/variants/${product_id}`, { params: query });
};

export const getClinicsOfVariant = (variant_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/clinics/${variant_id}`, { params: query });
};

export const updateInventoryPrice = (variant_id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/price/${variant_id}`, data);
};

export const updateInventoryQuantity = (variant_id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/quantity/${variant_id}`, data);
};

export const createVariant = (facility_id: number, product_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${product_id}`, data);
};

export const createOrUpdateVariantInventory = (facilityId: number, variantId: number, data: any) => {
  return axios.post(`${baseURL}/${path}/quantity/${facilityId}/${variantId}`, data);
};

export const transferProduct = (facilityId: number, variantId: number, data: any) => {
  return axios.post(`${baseURL}/${path}/transfer/${facilityId}/${variantId}`, data);
};

export const updateVariant = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteVariant = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const exportInventories = (facility_id: number, clinic_name: string, query?: ApiQuery) => {
  return axios 
    .post(
      `${baseURL}/${path}/export/${facility_id}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', clinic_name + ' - Inventories.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const getLowStockInventory = (facilityId: number) => {
  return axios.get(`${baseURL}/${path}/lowStock/${facilityId}`);
};

