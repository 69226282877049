/* eslint-disable react-hooks/exhaustive-deps */

import {
  CC_ENTITY_NAME,
  corporateClientFields,
  corporateClientFormFields,
  corporateClientInitialValues,
  createCorporateClientSchema,
} from './CorporateClientModel';
import { createClient, getClinicsNotAdded, updateClient } from 'company/api/corporate-clients';
import { useContext, useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';
import { changeNullToBlank } from 'core/utils';
import { getCompanyFacilities } from 'company/api/facility';

type Props = {
  corporateClient?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const CorporateClientForm = (props: Props) => {
  const { corporateClient, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);

  const handleSubmit = async (data: any) => {
    if (!!data.facilities) {
      data.facilities = data.facilities.map((facility: any) => facility.value);
    }
    return corporateClient ? updateClient(corporateClient.id, data) : createClient(data);
  };

  useEffect(() => {
    if (corporateClient) {
      const facilitiesArray = corporateClient.assigned_facilities?.split(', ') ?? [];
      const idsArray = corporateClient.assigned_facilities_ids?.split(',').map(Number) ?? [];

      const selectedClinics = facilitiesArray.map((key: any, index: string | number) => ({
        key,
        value: idsArray[index],
      }));
      corporateClient.facilities = corporateClient.assigned_facilities?.length > 0 ? selectedClinics : [];
    }
  }, [corporateClient]);

  useEffect(() => {
    (corporateClient ? getClinicsNotAdded(corporateClient.id) : getCompanyFacilities({})).then((res) => {
      setDropdownOptions({
        facilities: res.data.data?.map((facility: any) => ({
          key: facility.facility_name,
          value: facility.id,
        })),
      });
    });
  }, [user]);

  return (
    <TemplateForm
      entityName={CC_ENTITY_NAME}
      fields={corporateClientFields}
      visibleFields={corporateClientFormFields}
      entity={corporateClient}
      initialValues={corporateClient ? changeNullToBlank(corporateClient) : corporateClientInitialValues}
      schema={createCorporateClientSchema}
      dropdownOptions={dropdownOptions}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default CorporateClientForm;
