/* eslint-disable react-hooks/exhaustive-deps */

import { ConfirmationDialog, CustomContainer, CustomGridCell, RegularButton } from 'core/components';
import { Link, useNavigate } from 'react-router-dom';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { createTemplate, getCareGoTemplates } from 'company/api/note-templates';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { FacilityContext } from 'core/context/facility.context';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { GridColDef } from '@mui/x-data-grid';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { WaitForFacility } from 'company/components';
import { createNote } from 'company/api/patient-notes';
import { formatDateTime } from 'core/utils';
import { useSnackbar } from 'notistack';

type CareGoTemplatesProps = {
  setForceRefresh?: (forceRefresh: any) => void;
  setRefreshForNotes?: (setRefreshForNotes: any) => void;
  isFromPatientManagement?: boolean;
  patient_id?: number;
  appointment?: any;
  onCreate?: (data: any) => void;
};

const CareGoTemplates: React.FC<CareGoTemplatesProps> = ({
  setForceRefresh,
  isFromPatientManagement = false,
  patient_id,
  setRefreshForNotes,
  appointment,
  onCreate,
}) => {
  const { facility } = useContext(FacilityContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();

  const [openAddConfirmModal, setOpenAddConfirmModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  const navigate = useNavigate();

  // Functions
  const getData = async (query: ApiQuery) => {
    return facility && getCareGoTemplates(query);
  };

  const handleCopy = async (data: any) => {
    const note = {
      template_name: data.template_name,
      carego_template_id: data.id,
      header: data.header,
      body: data.body,
      footer: data.footer,
      appointment_id: appointment?.id,
    };

    return createTemplate(note)
      .then((res) => {
        enqueueSnackbar('Template copied to Company Templates successfully!', { variant: 'success' });
        setForceRefresh && setForceRefresh((prev: number) => prev + 1);
      })
      .catch((error) => console.error(error));
  };

  const handleCreate = async () => {
    const data = {
      template_id: selectedTemplate.id,
      source: 'carego',
      appointment_id: appointment?.id,
    };

    return (
      facility &&
      patient_id &&
      createNote(facility.id, patient_id, data)
        .then((res) => {
          enqueueSnackbar(`Note successfully added!`, { variant: 'success' });
          setRefreshForNotes && setRefreshForNotes((prev: number) => prev + 1);
          if (onCreate) {
            onCreate(res.data.note);
          } else {
            navigate(`/company/patient-notes/respond/${res.data.note.id}`);
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`Error occured! Unable to add note.`, { variant: 'error' });
        })
    );
  };

  // Table Columns
  const columns: GridColDef[] = isFromPatientManagement
    ? [
        { field: 'template_name', headerName: 'Template Name', flex: 3 },
        {
          field: 'updated_at',
          headerName: 'Date Updated',
          flex: 2,
          renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.updated_at)}</CustomGridCell>,
        },
        {
          field: 'action',
          sortable: false,
          headerName: 'Action',
          headerAlign: 'center',
          align: 'center',
          flex: 0,
          renderCell: (params) => (
            <div>
              <RegularButton
                label="Use"
                variant="outlined"
                size="small"
                color="success"
                onClick={() => {
                  setSelectedTemplate(params.row);
                  setOpenAddConfirmModal(true);
                }}
              />
            </div>
          ),
        },
      ]
    : [
        {
          field: 'template_name',
          headerName: 'Template Name',
          flex: 3,
          renderCell: (params) => (
            <CustomGridCell>
              <Link to={`/company/carego-notes/view/${params.row.id}`} className="emphasis">
                {params.row.header}
              </Link>
            </CustomGridCell>
          ),
        },
        {
          field: 'updated_at',
          headerName: 'Date Updated',
          flex: 2,
          renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.updated_at)}</CustomGridCell>,
        },
      ];

  useEffect(() => {
    setBreadcrumb([{ label: 'Templates' }]);
  }, []);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.TABLE,
      entityName: 'CareGo Template',
      tableName: 'CareGo Templates',
      doNotWaitForFacility: true,
      useColoredTable: true,
      tableComponent: {
        columns: columns,
        getData: getData,
        rowHeight: 45,
      },
      tableAction: !isFromPatientManagement && {
        rowActions: [
          {
            label: 'Copy to Company Templates',
            icon: <FileCopyOutlinedIcon />,
            action: handleCopy,
          },
          {
            label: 'View',
            icon: <RemoveRedEyeOutlinedIcon />,
            action: (row) => {
              navigate(`/company/carego-notes/view/${row.id}`);
            },
          },
        ],
      },
    } as PageBuilderTableType,
  } as PageBuilderPageType;

  return (
    <WaitForFacility facility={facility}>
      <CustomContainer>
        <Box width="100%">
          <PageBuilder content={content} />
        </Box>
      </CustomContainer>
      <ConfirmationDialog
        content="Are you sure you want to use this template?"
        open={openAddConfirmModal}
        setOpen={setOpenAddConfirmModal}
        onConfirm={handleCreate}
      />
    </WaitForFacility>
  );
};

export default CareGoTemplates;
