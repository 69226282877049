import { ApiQuery } from 'core/model/interface';
import { Patients } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-assessments';


export const getHealthAssessments = (query?: ApiQuery) => {
    return axios.get(`${baseURL}/${path}`, {params: query});
  };
