import { Box, Button, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { AddOutlined } from '@mui/icons-material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import CustomIconButton from './buttons/IconButton';
import { DropdownOptionProps } from './Dropdown';
import RegularButton from './buttons/RegularButton';
import { tokens } from 'theme/theme';

type PageHeaderProps = {
  addButtonId?: string;
  title?: string;
  mb?: string;
  tabs?: ReactNode;
  actionButton?: ReactNode;
  menuOptions?: DropdownOptionProps[];
  dropdownTextColor?: string;
  actionButtonLabel?: string;
  actionButtonIcon?: JSX.Element;
  showAddButton?: boolean;
  addButtonLabel?: string;
  handleAddButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  regularButtonId?: string;
  showRegularButton?: boolean;
  regularButtonLabel?: string;
  regularButtonIcon?: JSX.Element;
  handleRegularButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  regularButtonStyle?: React.CSSProperties;
  showIconButton?: boolean;
  handleIconButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconButtonStyle?: React.CSSProperties;
  iconForCustomButtonIcon?: JSX.Element;
  rightContent?: JSX.Element;
  iconSize?: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  addButtonId,
  title,
  mb,
  tabs,
  actionButton,
  menuOptions,
  dropdownTextColor,
  actionButtonLabel,
  actionButtonIcon,
  showAddButton,
  addButtonLabel,
  handleAddButtonClick,
  regularButtonId,
  showRegularButton,
  regularButtonLabel,
  regularButtonIcon,
  handleRegularButtonClick,
  regularButtonStyle,
  showIconButton,
  handleIconButtonClick,
  iconButtonStyle,
  iconForCustomButtonIcon,
  rightContent,
  iconSize
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems={isMobile ? "center" : "flex-start"}
        justifyContent="space-between"
        sx={{
          paddingBottom: isMobile ? '10px' : mb || '20px',
          maxWidth: '100%',
          borderRadius: '10px',
          position: 'sticky',
          top: 0,
          zIndex: 10,
          backgroundColor: colors.background,
        }}
      >
        <Typography
          variant={isMobile ? "body1" : "h2"}
          style={{
            color: colors.primary,
            fontWeight: '700',
            fontSize: isMobile ? '20px' : '24px',
            letterSpacing: 0,
            textAlign: 'left',
          }}
        >
          {title}
        </Typography>
        
        <Box>
          {isMobile && showIconButton && handleIconButtonClick && (
            <CustomIconButton
              icon={iconForCustomButtonIcon ? iconForCustomButtonIcon : <AddOutlined />}
              onClick={handleIconButtonClick}
              sx={iconButtonStyle}
            />
          )}
          {showRegularButton && handleRegularButtonClick && (
            <RegularButton
              id={regularButtonId}
              label={regularButtonLabel || (isMobile ? '' : 'Label')}
              startIcon={regularButtonIcon}
              onClick={handleRegularButtonClick}
              styles={{
                ...regularButtonStyle,
                color: 'white',
                borderColor: 'white',
              }}
            />
          )}
          {actionButton && !showAddButton && (
            <>
              <RegularButton
                label={actionButtonLabel || 'Action'}
                startIcon={actionButtonIcon || <ArrowDropDownCircleOutlinedIcon />}
                onClick={handleMenuClick}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  '& .MuiSvgIcon-root': { color: colors.primary },
                  '& .MuiPaper-root': { borderRadius: '15px' },
                  marginTop: '5px',
                }}
              >
                {menuOptions?.map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      option?.action?.();
                      handleMenuClose();
                    }}
                    sx={{ width: '100%', borderRadius: '8px', ...option.labelStyle }}
                  >
                    <Box display="flex" gap="15px" alignItems="center">
                      {option.icon && (
                        <Typography
                          sx={{
                            lineHeight: 1,
                            '& .MuiSvgIcon-root': {
                              fontSize: '15px',
                              marginTop: '3px',
                            },
                          }}
                        >
                          {option.icon}
                        </Typography>
                      )}
                      <Typography sx={{ ...(option.labelStyle || {}) }}>{option.label}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {showAddButton && handleAddButtonClick && (
            <RegularButton
              id={addButtonId}
              label={addButtonLabel || 'Add'}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddButtonClick}
              styles={{ color: 'white', borderColor: 'white' }}
            />
          )}
          {isMobile && rightContent}
        </Box>
      </Box>
      {tabs}
    </Box>
  );
};

export default PageHeader;