import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material';
import { FacilityContext } from 'core/context/facility.context';
import { UserContext } from 'core/context/user.context';
import { AccountType } from 'core/model/Entities';

export default function MobileNavBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const [currentScreen, setCurrentScreen] = useState(location.pathname);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentScreen(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    setCurrentScreen(location.pathname);
  }, [location.pathname]);

  const getNavigationItems = () => {
    const isCompanyAdmin = user?.user_group.group_name === 'COMPANY_ADMIN' && facility?.is_admin;
    const baseUrl = isCompanyAdmin ? '/company/admin' : '/company';

    const commonItems = [
      { label: 'Home', value: `${baseUrl}`, icon: <HomeOutlinedIcon /> },
      { label: 'Appointments', value: `${baseUrl}/appointments`, icon: <CalendarMonthOutlinedIcon /> },
    ];

    if (isCompanyAdmin) {
      return [
        ...commonItems,
        { label: 'Transactions', value: `${baseUrl}/transactions`, icon: <ReceiptOutlinedIcon /> },
        { label: 'Settings', value: `${baseUrl}/clinic-management`, icon: <SettingsOutlinedIcon /> },
      ];
    } else {
      return [
        ...commonItems,
        { label: 'Patients', value: '/company/patient-management', icon: <ManageAccountsOutlinedIcon /> },
        { label: 'Transactions', value: `${baseUrl}/transactions`, icon: <ReceiptOutlinedIcon /> },
        { label: 'Settings', value: '/company/clinic-management', icon: <SettingsOutlinedIcon /> },
      ];
    }
  };

  return (
    <BottomNavigation
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: colors.light_blue_background,
      }}
      value={currentScreen}
      onChange={handleChange}
    >
      {getNavigationItems().map((item) => (
        <BottomNavigationAction
          key={item.value}
          label={item.label}
          value={item.value}
          icon={item.icon}
          onClick={() => navigate(item.value)}
        />
      ))}
    </BottomNavigation>
  );
}
