import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomModal,
  CustomTabs,
  Dropdown,
  ProtectedComponent,
  RegularButton,
} from 'core/components';
import HideOrShowComponent, { isDevMode } from 'core/components/HideOrShowComponent';
import { PROGRAM_CODES, getFormFields, getFormFieldsValues } from 'company/screens/Utils/Customization';
import PatientProfile, { PatientProfileMobile } from './components/PatientProfile';
import { Description as RecordsIcon, MonetizationOn as TransactionsIcon } from '@mui/icons-material';
import { createRunningInvoice, getPatientActiveRunningInvoices } from 'company/api/running-invoices';
import { getPatient, getPatients } from 'company/api/patient';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { CareGoPage } from 'core/PageBuilder';
import { ContainerColumn } from 'core/components/containers';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CreatePatientNoteModal from '../RegistrationBoard/components/CreatePatientNoteModal';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmergencyContact from './components/EmergencyContact';
import { FacilityContext } from 'core/context/facility.context';
import FullScreenMobileModal from 'core/components/modals/FullScreenMobileModal';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HealthPackagesEnrolled from './components/BasicHealthInfo/Sections/HealthPackagesEnrolled';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import LabRequestForm from 'company/entities/modules/ClinicManagement/LabRequest/LabRequestForm';
import { Link } from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import MedicationIcon from '@mui/icons-material/Medication';
import PatientBasicHealthInformation from './components/BasicHealthInfo/PatientBasicHealthInformation';
import PatientClinicAccess from './PatientClinicAccess';
import PatientFilesTable from 'company/entities/modules/ClinicManagement/PatientFiles/PatientFilesTable';
import { PatientForm } from 'company/entities/forms';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PatientNotesContainer from './components/BasicHealthInfo/Sections/PatientNotesContainer';
import PatientRecords from '../PatientRecords';
import PatientTransactions from './components/BasicHealthInfo/Sections/PatientTransactions';
import PersonIcon from '@mui/icons-material/Person';
import PrescriptionForm from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionForm';
import PrintablePatientProfile from './components/PrintablePatientProfile';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SIZES } from 'theme/constants';
import ScienceIcon from '@mui/icons-material/Science';
import SectionDropdown from 'core/components/SectionDropdown';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { TabProps } from 'core/components/CustomTabs';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { UserContext } from 'core/context/user.context';
import { WaitForFacility } from 'company/components';
import { formatArray } from 'core/utils';
import { getAppointments } from 'company/api/appointments';
import { tokens } from 'theme/theme';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

export enum PATIENT_SECTION {
  // OVERVIEW = 'Patient Overview',
  TIMELINE = 'History',
  RECORDS = 'Patient Records',
  TRANSACTIONS = 'Patient Transactions',
  CAMPAIGNS = 'Campaigns Received',
  HEALTH_PACKAGE = 'Health Sessions Enrolled',
  FILES = 'Uploaded Files',
}

const PatientManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { facility } = useContext(FacilityContext);

  const { user } = useContext(UserContext);
  const [addPatientModal, setAddPatientModal] = useState(false);
  const [addedPatient, setAddedPatient] = useState<number>();
  const [updatedPatientData, setUpdatedPatientData] = useState<PatientModel>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedPatient, setSelectedPatient] = useState<number>(0);
  const [deleteID, setDeleteID] = useState<number>();
  const [selectedPatientDetails, setSelectedPatientDetails] = useState<PatientModel>();
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [selectedPatientNote, setSelectedPatientNote] = useState<any>();
  const [selectedPrescription, setSelectedPrescription] = useState<any>();
  const [selectedPatientRunningInvoice, setSelectedPatientRunningInvoice] = useState<any>();
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState<boolean>(false);
  const [confirmCreateRunningInvoiceOpen, setConfirmCreateRunningInvoiceOpen] = useState<boolean>(false);
  const [labRequestModalOpen, setLabRequestModalOpen] = useState<boolean>(false);
  const [selectedLabRequest, setSelectedLabRequest] = useState<any>();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [openEnrollModal, setOpenEnrollModal] = useState<boolean>(false);
  const [schema, setSchema] = useState<any>();
  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({});
  const [openShareAccessModal, setOpenShareAccessModal] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const noteRef: any = useRef();
  const patientProfileRef = useRef(null);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateNote = (data: any) => {
    setSelectedPrescription(undefined);
    setSelectedPatientNote(data); // Update the state with the newly created note
    setOpenNoteModal(false);
    noteRef.current.refreshTimeline();
  };

  const handleCreatePrescription = (data: any) => {
    setSelectedPatientNote(undefined);
    setSelectedPrescription(data.data[0]); // Update the state with the newly created prescription
    setOpenPrescriptionModal(false);
    noteRef.current.refreshTimeline();
  };

  const getPatientDetails = () => {
    if (facility && selectedPatient) {
      setIsLoading(true);
      getPatient(facility.id, selectedPatient)
        .then((res) => {
          setSelectedPatientDetails(res.data);
        })
        .catch()
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getPatientActiveRunningInvoice = () => {
    if (facility && selectedPatient) {
      getPatientActiveRunningInvoices(facility.id, selectedPatient).then((res) => {
        setSelectedPatientRunningInvoice(res.data);
      });
    }
  };

  const getAllAppointments = async (query: ApiQuery) => {
    try {
      const res = await getAppointments(facility.id, query);
      setAppointments(res.data.data);
    } catch (error) {
      console.error();
    }
  };

  const handleConfirmAddRunningInvoice = () => {
    if (facility && selectedPatient) {
      createRunningInvoice(facility.id, { patient_id: selectedPatient })
        .then((res) => {
          enqueueSnackbar(`Running invoice successfully created`, { variant: 'success' });
          setRefreshCount((count: number) => count + 1);
        })
        .catch((err) => {
          if (err.response.status === 400)
            enqueueSnackbar(`${selectedPatientDetails?.full_name} has an active running invoice`, { variant: 'error' });
        });
    }
  };

  const handleConfirmCreateLabRequest = (data: any) => {
    setSelectedLabRequest({
      ...data.patientLabRequest,
      body: JSON.stringify(data.patientLabRequest.body),
    });
    setRefreshCount((count: number) => count + 1);
    noteRef.current.refreshTimeline();
    setLabRequestModalOpen(false);
  };

  const determineOptionList = () => {
    const originalList: DropdownOptionProps[] = [
      {
        label: 'Create Appointment',
        action: (data: any) => {
          setOpenEnrollModal(true);
        },
        icon: <CalendarMonthOutlinedIcon />,
      },
      {
        label: 'Create Note',
        action: (data: any) => {
          setOpenNoteModal(true);
        },
        icon: <CreateOutlinedIcon />,
      },
      {
        label: 'Create Prescription',
        action: (data: any) => {
          setOpenPrescriptionModal(true);
        },
        icon: <MedicationIcon />,
      },
      {
        label: 'Create Running Invoice',
        hidden: !isDevMode(),
        action: (data: any) => {
          setConfirmCreateRunningInvoiceOpen(true);
        },
        icon: <ReceiptIcon />,
      },
      {
        label: 'Create Lab Request',
        action: () => {
          setLabRequestModalOpen(true);
        },
        icon: <ScienceIcon />,
      },
    ];
    if (selectedPatientRunningInvoice?.length > 0) {
      return originalList.filter((list) => list.label !== 'Create Running Invoice');
    }

    return originalList;
  };
  const tabProps = useMemo(
    () => ({
      patient: selectedPatientDetails!,
      onUpdate: (id: number, data: any) => {
        getPatientDetails();
        setUpdatedPatientData(data.patient);
      },
      onDelete: (id: number) => setDeleteID(id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPatientDetails]
  );

  const tabs: TabProps[] = useMemo(
    () => [
      {
        icon: <PersonIcon />,
        label: isMobilePhone ? 'Personal Info' : 'Personal Information',
        content: isMobilePhone ? <PatientProfileMobile {...tabProps} /> : <PatientProfile {...tabProps} />,
      },
      {
        icon: <HealthAndSafetyIcon />,
        label: isMobilePhone ? 'Health Info' : 'Health Information',
        content: <PatientBasicHealthInformation {...tabProps} />,
      },
      {
        icon: <LocalPhoneIcon />,
        label: isMobilePhone ? 'Emergency' : 'Emergency Contact',
        content: <EmergencyContact {...tabProps} />,
      },
    ],
    [isMobilePhone, tabProps]
  );

  const handlePrintPatientProfile = useReactToPrint({
    content: () => patientProfileRef.current,
    pageStyle: `
      @page {
        size: A5;
        margin: 20mm;
      }
      body {
        margin: 0;
      }
    `,
  });

  const actions = [
    // {
    //   label: 'Update',
    //   action: (data: any) => console.log(data),
    //   icon: <EditOutlinedIcon />,
    // },
    // {
    //   label: 'Delete',
    //   action: () => console.log('Delete'),
    //   icon: <DeleteOutlinedIcon />,
    // },
    {
      label: 'Share',
      action: () => setOpenShareAccessModal(true),
      icon: <ShareOutlinedIcon />,
    },
    {
      label: 'Print',
      action: () => {
        document.title = `${selectedPatientDetails?.full_name} Profile`;
        setTimeout(() => (document.title = `CareGo Health Suite`), 3000);
        handlePrintPatientProfile();
      },
      icon: <LocalPrintshopOutlinedIcon />,
    },
  ];
  const sections: TabProps[] = useMemo(() => {
    if (selectedPatientDetails) {
      const props = { patient: selectedPatientDetails };
      return [
        // {
        //   icon: <OverviewIcon />,
        //   label: PATIENT_SECTION.OVERVIEW,
        //   content: (
        //     <AppointmentTable
        //       patientId={selectedPatientDetails?.patient_id}
        //       visibleColumns={patientAppointmentTableColumns}
        //     />
        //   ),
        // },
        {
          icon: <TimelineOutlinedIcon />,
          label: PATIENT_SECTION.TIMELINE,
          content: (
            <PatientNotesContainer
              ref={noteRef}
              patientNote={selectedPatientNote}
              patientPrescription={selectedPrescription}
              runningInvoice={selectedPatientRunningInvoice}
              labRequest={selectedLabRequest}
              {...props}
            />
          ),
        },
        {
          icon: <RecordsIcon />,
          label: PATIENT_SECTION.RECORDS,
          content: <PatientRecords {...props} />,
        },
        {
          icon: <TransactionsIcon />,
          label: PATIENT_SECTION.TRANSACTIONS,
          content: <PatientTransactions {...props} />,
        },
        // {
        //   icon: <CampaignsIcon />,
        //   label: PATIENT_SECTION.CAMPAIGNS,
        //   content: <CampaignsReceived {...props} />,
        //   hidden: true,
        // },
        {
          icon: <MedicalInformationOutlinedIcon />,
          label: PATIENT_SECTION.HEALTH_PACKAGE,
          content: <HealthPackagesEnrolled {...props} />,
        },
        {
          icon: <AttachFileOutlinedIcon />,
          label: PATIENT_SECTION.FILES,
          content: <PatientFilesTable patientId={selectedPatient} {...props} />,
        },
      ];
    }
    return [];
  }, [
    selectedPatientDetails,
    selectedPatientNote,
    selectedPrescription,
    selectedPatientRunningInvoice,
    selectedLabRequest,
    selectedPatient,
  ]);

  // useEffect(() => {
  //   getPatientDetails();
  //   setSelectedPatientNote(undefined);
  //   getPatientActiveRunningInvoice();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getPatientDetails, refreshCount, selectedPatient]);

  useEffect(() => {
    // if (facility) setOpen(!facility.services.length); // TODO: check if faciliy has services
    if (facility) {
      getAllAppointments({});
    }
  }, [facility]);

  useEffect(() => {
    if (facility) {
      getFormFields(PROGRAM_CODES.APPOINTMENTS, setFormFields, setSchema, facility, undefined, appointments);
      getFormFieldsValues(PROGRAM_CODES.APPOINTMENTS, setInitialValues);
    }
  }, [facility, appointments, services]);

  useEffect(() => {
    getPatientDetails();
    setSelectedPatientNote(undefined);
    getPatientActiveRunningInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount, selectedPatient]);

  const displayItem = useCallback(
    (patient: PatientModel) => {
      const isSelectedPatient = selectedPatient === patient.patient_id;
      return (
        <>
          <Box display="flex" flexDirection="column" gap="5px" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              color={!isSelectedPatient && isMobilePhone ? colors.primary : colors.text}
              gap={SIZES.padding}
            >
              <Typography
                variant="h5"
                fontWeight="500"
                color={isMobilePhone ? colors.dark_grey_text : !isSelectedPatient ? colors.dark_grey_text : 'white'}
              >
                {patient.id} | {patient.full_name}
              </Typography>
              <Typography
                fontSize="11px"
                color={isMobilePhone ? colors.dark_grey_text : !isSelectedPatient ? colors.dark_grey_text : 'white'}
              >
                {patient.sex}
              </Typography>
            </Box>
            <Box display="flex" gap="10px" width="100%">
              <Typography
                fontSize="11px"
                color={isMobilePhone ? colors.dark_grey_text : !isSelectedPatient ? colors.dark_grey_text : 'white'}
              >
                {formatArray([patient.mobile_number ?? '', patient.email], ' | ')}
              </Typography>
            </Box>
          </Box>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPatient]
  );

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const patientIdFromUrl = searchParams.get('patient_id');
    if (patientIdFromUrl) {
      setSelectedPatient(Number(patientIdFromUrl));
    }
  }, [location]);

  return (
    <CareGoPage loading={!user} breadcrumb={[{ label: 'Patient Management' }]}>
      <WaitForFacility facility={facility}>
        <InfiniteScrollContainer
          actions={actions}
          title="List of Patients"
          getData={(query) => getPatients(facility.id, query)}
          getSingleData={(id) => getPatient(facility.id, id)}
          itemIdName="patient_id"
          renderItem={displayItem}
          selected={selectedPatient}
          setSelected={setSelectedPatient}
          addedNewItem={addedPatient}
          updatedItem={updatedPatientData}
          deleteId={deleteID}
          urlKeyId="patient_id"
          titleComponents={
            <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENTS']}>
              <RegularButton
                startIcon={<AddIcon />}
                label="Add"
                size="small"
                onClick={() => setAddPatientModal(true)}
                styles={{ paddingInline: SIZES.padding }}
              />
            </CompanyProtectedComponent>
          }
          headerComponents={
            <HideOrShowComponent hidden={user?.user_group.group_name !== 'COMPANY_ADMIN'}>
              <ProtectedComponent requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}>
                <Link to="groups" style={{ width: '100%' }}>
                  <RegularButton
                    // startIcon={<VisibilityOutlinedIcon />}
                    label="Patient Groups"
                    variant="outlined"
                    onClick={() => undefined}
                    styles={{ paddingInline: SIZES.paddingS, fontSize: '11px' }}
                    fullWidth
                  />
                </Link>

                <Link to="corporate-clients" style={{ width: '100%' }}>
                  <RegularButton
                    // startIcon={<VisibilityOutlinedIcon />}
                    label="Corporate Clients"
                    variant="outlined"
                    onClick={() => undefined}
                    styles={{ paddingInline: SIZES.paddingS, fontSize: '11px' }}
                    fullWidth
                  />
                </Link>

                <Link to="company-hmos" style={{ width: '100%' }}>
                  <RegularButton
                    // startIcon={<VisibilityOutlinedIcon />}
                    label="HMOs"
                    variant="outlined"
                    onClick={() => undefined}
                    styles={{ paddingInline: SIZES.paddingS, fontSize: '11px', height: '100%' }}
                    fullWidth
                  />
                </Link>
              </ProtectedComponent>
            </HideOrShowComponent>
          }
          displaySelectedTitle={() => (
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {selectedPatientDetails?.full_name}
            </Typography>
          )}
        >
          {selectedPatientDetails && (
            <ContainerColumn>
              <Box>
                <CustomTabs tabs={tabs} selected={selectedTab} setSelected={setSelectedTab} />
              </Box>
              {/* <ProtectedComponent requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}> */}
              <Box mt={SIZES.padding}>
                <SectionDropdown
                  optionList={sections}
                  popupContainerStyle={{ padding: '10px' }}
                  urlKeyId="section"
                  stickyTop={isMobilePhone ? 0 : 55}
                  hiddenActionsFor={[
                    'Patient Overview',
                    'Patient Transactions',
                    'Campaigns Received',
                    'Health Sessions Enrolled',
                    'Uploaded Files',
                  ]}
                  extraActions={
                    <Dropdown
                      notFullWidth
                      optionList={determineOptionList()}
                      buttonLabel="Create"
                      startIcon={<AddIcon />}
                    />
                  }
                />
              </Box>
              {/* </ProtectedComponent> */}
            </ContainerColumn>
          )}
        </InfiniteScrollContainer>
        {/* <PatientTable /> */}
      </WaitForFacility>

      {/* Modal for adding patient */}
      {isMobilePhone ? (
        <FullScreenMobileModal header="Add Patient" open={addPatientModal} setOpen={setAddPatientModal}>
          <PatientForm
            facility={facility}
            callbackAfterSubmit={(data: any) => {
              setAddedPatient(data.patient.patient_id);
              setAddPatientModal(false);
            }}
          />
        </FullScreenMobileModal>
      ) : (
        <CustomModal header="Add Patient" open={addPatientModal} setOpen={setAddPatientModal}>
          <PatientForm
            facility={facility}
            callbackAfterSubmit={(data: any) => {
              setAddedPatient(data.patient.patient_id);
              setAddPatientModal(false);
            }}
          />
        </CustomModal>
      )}

      <CustomModal header="Schedule Appointment" open={openEnrollModal} setOpen={setOpenEnrollModal}>
        <AppointmentForm
          facility={facility}
          patientId={selectedPatientDetails?.patient_id}
          callbackAfterSubmit={() => {
            setOpenEnrollModal(false);
            setRefreshCount((count) => count + 1);
            noteRef.current.refreshTimeline();
          }}
        />
      </CustomModal>

      {/* Modal for creating notes */}
      <CustomModal open={openNoteModal} setOpen={setOpenNoteModal} header={'Create Note'} width={1000}>
        <CreatePatientNoteModal
          patient_id={selectedPatient}
          patient={selectedPatientDetails}
          onCreate={(data) => {
            handleCreateNote(data);
          }}
        />
      </CustomModal>

      {/* Modal for creating prescription */}
      <CustomModal header="Create Prescription" open={openPrescriptionModal} setOpen={setOpenPrescriptionModal}>
        <PrescriptionForm
          patientID={selectedPatient}
          callbackAfterSubmit={(data: any) => {
            handleCreatePrescription(data);
          }}
        />
      </CustomModal>

      <CustomModal
        header="Share patient with other clinics"
        open={openShareAccessModal}
        setOpen={setOpenShareAccessModal}
      >
        <PatientClinicAccess selectedPatientId={selectedPatient} />
      </CustomModal>
      {/* Confirmation Dialog For Adding Running Invoice */}
      <ConfirmationDialog
        open={confirmCreateRunningInvoiceOpen}
        setOpen={setConfirmCreateRunningInvoiceOpen}
        onConfirm={handleConfirmAddRunningInvoice}
        title="Create Running Invoice"
        content={`Create Running Invoice for patient ${selectedPatientDetails?.full_name}`}
      />

      <CustomModal open={labRequestModalOpen} setOpen={setLabRequestModalOpen} header="Create Lab Request" width={500}>
        <LabRequestForm patientID={selectedPatient} callbackAfterSubmit={handleConfirmCreateLabRequest} />
      </CustomModal>

      {selectedPatientDetails && (
        <Box display="none">
          <PrintablePatientProfile ref={patientProfileRef} patient={selectedPatientDetails} />
        </Box>
      )}
    </CareGoPage>
  );
};

export default PatientManagement;
