import {
  PrescriptionLineItemFields,
  PrescriptionLineItemModel,
  PrescriptionLineItemsForm,
  prescriptionLineInitialValues,
  prescription_line_schema,
} from './PrescriptionLineItemModel';
import { addMedicinesToPrescription, updatePrescriptionMedicine } from 'company/api/prescription-medicines';

import { FacilityContext } from 'core/context/facility.context';
import { PRESCRIPTION_ENTITY_NAME } from '../PrescriptionModel';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { useContext } from 'react';

type Props = {
  prescriptionId: number;
  prescriptionLineItem?: PrescriptionLineItemModel;
  callbackAfterSubmit?: () => void;
};

const PrescriptionLineItemForm: React.FC<Props> = (props) => {
  const { prescriptionId, prescriptionLineItem, callbackAfterSubmit } = props;

  const { facility } = useContext(FacilityContext);

  // cannot apply update for now (another form will be used)
  const handleSubmit = (data: any) => {
    return prescriptionLineItem
      ? updatePrescriptionMedicine(facility.id, prescriptionLineItem.id, data)
      : addMedicinesToPrescription(facility.id, prescriptionId, data);
  };

  // return (
  //   <AddMedicines
  //     prescription_id={prescriptionId}
  //     selectedMedicine={selectedMedicine}
  //     // refreshMainTable={callbackAfterSubmit}
  //     refreshPrescriptionTable={callbackAfterSubmit}
  //   />
  // );

  return (
    <TemplateForm
      submitButtonId={'create_prescriptions_submit_button'}
      entity={{
        ...prescriptionLineItem,
        ...prescriptionLineInitialValues,
        start_date: prescriptionLineItem?.start_date === '0000-00-00' ? '' : prescriptionLineItem?.start_date,
        time_to_take: prescriptionLineItem?.time_to_take === '00:00:00' ? '' : prescriptionLineItem?.time_to_take,
      }}
      entityName={PRESCRIPTION_ENTITY_NAME}
      fields={PrescriptionLineItemFields}
      visibleFields={prescriptionLineItem ? PrescriptionLineItemsForm : PrescriptionLineItemsForm.slice(0, 3)}
      initialValues={prescriptionLineInitialValues}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      schema={prescription_line_schema}
    />
  );
};

export default PrescriptionLineItemForm;
