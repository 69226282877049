const userGroups = [
  {
    group_name: 'Company Admin',
    id: 4,
  },
  {
    group_name: 'Company Staff',
    id: 5,
  },
  {
    group_name: 'Company Doctor',
    id: 8,
  },
  {
    group_name: 'Facility Admin',
    id: 6,
  },
  {
    group_name: 'Facility Staff',
    id: 7,
  },
];

const specialTokens = [
  { token: '[Name]', description: "Patient's Full Name" },
  { token: '[FirstName]', description: "Patient's First Name" },
  { token: '[Facility_Name]', description: 'Facility Name' },
  { token: '[Facility_Type]', description: 'Facility Type (e.g. clinic or hospital)' },
  { token: '[Facility_Contact]', description: 'Facility Contact Details' },
  { token: '[Health_Program]', description: 'Program Name' },
  { token: '[Date]', description: 'Schedule Date' },
  { token: '[Time]', description: 'Schedule Time' },
];

export { userGroups, specialTokens };
