import { Box, Button, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ColoredTable,
  ConfirmationDialog,
  CustomForm,
  CustomIconButton,
  CustomModal,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { DISCOUNT_TYPE, DROPDOWN_FIELD } from 'core/model/interface';
import {
  PatientBillingModel,
  patientBillingSchema,
} from 'company/entities/modules/ClinicManagement/PatientBilling/PatientBillingModel';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  deleteInvoice,
  postInvoice,
  reverseInvoice,
  updateInvoice,
  updateInvoiceServiceProvider,
} from 'company/api/patient-billing';
import { formatCurrency, formatDate, formatDateTime } from 'core/utils';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddIcon from '@mui/icons-material/Add';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import HistoryIcon from '@mui/icons-material/History';
import InvoiceItemTable from 'company/entities/modules/ClinicManagement/PatientBilling/InvoiceItem/InvoiceItemTable';
import { InvoiceStatus } from 'company/entities/modules/ClinicManagement/PatientBilling/PatientBillingModel';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { POS } from 'company/screens/InventorySystem';
import PaymentForm from 'company/entities/modules/ClinicManagement/Payment/PaymentForm';
import PaymentTable from 'company/entities/modules/ClinicManagement/Payment/PaymentTable';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PrintableInvoice from 'company/screens/RegistrationBoard/components/PrintableInvoice';
import { SIZES } from 'theme/constants';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { getInvoiceItems } from 'company/api/invoice-items';
import { getPaymentsByInvoiceId } from 'company/api/billing-payment';
import { reverse_invoice_schema } from 'company/model/schema';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

// must have the following data
interface LocalOrders {
  id: number;
  company_id: number;
  facility_id: number;
  patient_id: number;
  service_id: number;
  appointment_id: number;
  package_patient_id: number | null;
  created_at: string;
  updated_at: string;
  created_by: number | null;
  quantity: number;
  unit_price: string;
  service_name: string;
  original_price: string;
  total_amount: number;
  discount: any;
}
type UserInput = {
  issue_date: Date;
  due_date: Date | null;
  patient_id: number | string;
  service_provider_id?: any;
  corporate_client_id?: number | string;
  hmo_id?: number | string;
  hmo_card_number?: string;
  loa_number?: string;
  providerIDs?: number[];
  status: string;
};
interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}
interface InvoiceTemplateProps {
  localOrders?: LocalOrders[];
  lowerButtons?: React.ReactNode;
  handleEditCallback?: (order: LocalOrders) => void;
  handleApplyDiscountCallback?: (order: LocalOrders) => void;
  handleRemoveDiscountCallback?: (order: LocalOrders) => void;
  invoiceDetails?: PatientBillingModel;
  onInvoiceUpdate?: () => void;
  showCancelButton?: boolean;
  fromPOS?: boolean;
  appointmentDetails?: any;
}

const InvoiceTemplate: React.FC<InvoiceTemplateProps> = ({
  localOrders,
  handleEditCallback,
  handleApplyDiscountCallback,
  handleRemoveDiscountCallback,
  invoiceDetails,
  lowerButtons,
  onInvoiceUpdate,
  showCancelButton,
  fromPOS = false,
  appointmentDetails,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const { facility } = useContext(FacilityContext);
  const [totalAmount, setTotalAmount] = useState(0);

  const [totalPayment, setTotalPayment] = useState<number>(0);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState<boolean>(false);
  const paymentTableRef: any = useRef();
  const invoiceItemTableRef: any = useRef();

  const [update, setUpdate] = useState<boolean>(false);
  // const [createInvoiceItems, setCreateInvoiceItems] = useState<LocalOrders[]>(localOrders ?? []);
  const [openReverseInvoiceDialog, setOpenReverseInvoiceDialog] = useState<boolean>(false);
  const [confirmInvoiceDialog, setInvoiceDialog] = useState<boolean>(false);
  const [openDeleteInvoiceDialog, setOpenDeleteInvoiceDialog] = useState<boolean>(false);
  const [invoiceItems, setInvoiceItems] = useState<any>();

  const [openInvoiceItemModal, setOpenInvoiceItemModal] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const printableInvoiceRef = useRef(null);

  const navigate = useNavigate();
  // useEffect(() => setCreateInvoiceItems(localOrders ?? []), [localOrders]);

  const initialValues: UserInput = useMemo(
    () => ({
      issue_date: invoiceDetails ? new Date(invoiceDetails.issue_date) : new Date(),
      due_date: invoiceDetails ? (invoiceDetails.due_date ? new Date(invoiceDetails.due_date) : null) : null,
      patient_id: invoiceDetails?.patient_id ?? '',
      status: invoiceDetails?.status ?? '',
      hmo_id: invoiceDetails?.hmo_id ?? '',
      hmo_card_number: '', //change to card number
      loa_number: '',
      corporate_client_id: invoiceDetails?.corporate_client_id ?? '',
      service_provider_id: invoiceDetails?.service_provider_id ?? '',
    }),
    [invoiceDetails]
  );

  const invoiceReversed = useMemo(() => !!invoiceDetails?.reversal_id, [invoiceDetails]);
  const invoicePosted = useMemo(() => !!invoiceDetails?.is_posted, [invoiceDetails]);

  useEffect(() => {
    async function fetchInvoiceItems() {
      if (!invoiceDetails) return;
      const res = await getInvoiceItems(facility.id, 'all', invoiceDetails.id, {});
      const includedPatientID = res.data.data.map((item: any) => {
        return { ...item, patientId: invoiceDetails.patient_id };
      });
      setInvoiceItems(includedPatientID);
    }
    fetchInvoiceItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails]);
  // const calculateTax = useCallback(() => {
  //   if (!invoiceDetails || !invoiceDetails.item_names || !invoiceDetails.product_taxes) return;

  //   const items = invoiceDetails.item_names.split(', ');
  //   const taxes = invoiceDetails.product_taxes.split(', ');
  //   const totalAmount = Number(invoiceDetails.total_amount);

  //   // Assume equal distribution of total amount among items if we don't have individual prices
  //   const itemPrice = totalAmount / items.length;

  //   let newTotalTax = 0;

  //   items.forEach((item, index) => {
  //     if (taxes[index] === 'vat') {
  //       const itemTax = itemPrice * 0.12; // 12% VAT
  //       newTotalTax += itemTax;
  //     }
  //   });

  //   setTotalTax(newTotalTax);
  // }, [invoiceDetails]);
  const calculateTotalPayment = () => {
    if (invoiceDetails) {
      getPaymentsByInvoiceId(facility.id, invoiceDetails.id, { length: 1000 }).then((res) => {
        const payments: any[] = res.data.data;
        setTotalPayment(payments.reduce((total, payment) => total + Number(payment.amount), 0));
      });
    }
  };

  useEffect(() => {
    calculateTotalPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails]);

  // useEffect(() => {
  //   if (invoiceDetails) {
  //     calculateTax();
  //   }
  // }, [invoiceDetails, calculateTax]);
  const handlePrintInvoice = useReactToPrint({
    content: () => printableInvoiceRef.current,
  });

  useEffect(() => {
    if (localOrders && !invoiceDetails) {
      const newTotalAmount = localOrders.reduce(
        (total, order) => total + (Number(order.unit_price) || 0) * (Number(order.quantity) || 1),
        0
      );
      setTotalAmount(newTotalAmount);
    }
  }, [localOrders, invoiceDetails]);

  const invoiceUpdated = () => {
    refreshTable();
    onInvoiceUpdate && onInvoiceUpdate();
    setLoading(false);
  };

  const handleSubmit = async (data: any) => {
    if (invoiceDetails) {
      try {
        setLoading(true);
        await updateInvoice(facility.id, invoiceDetails.id, data);
        await updateInvoiceServiceProvider(facility.id, invoiceDetails.id, {
          service_provider_id: data.service_provider_id ? data.service_provider_id : '',
        });
        enqueueSnackbar('Invoice successfully updated!', { variant: 'success' });
        setUpdate(false);
        invoiceUpdated();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleReverseInvoiceAction = async () => {
    setOpenReverseInvoiceDialog(true);
  };

  const handleConfirmInvoiceAction = async () => {
    setInvoiceDialog(true);
  };

  const handleConfirmDialog = async (payNow: boolean) => {
    setLoading(true);
    setOpenAddPaymentModal(false);
    if (invoiceDetails) {
      return postInvoice(facility.id, invoiceDetails.id)
        .then((res) => {
          // enqueueSnackbar(`Invoice successfully posted!`, { variant: 'success' });
          invoiceUpdated();
        })
        .finally(() => {
          setInvoiceDialog(false);
          if (payNow) {
            setOpenAddPaymentModal(true);
          }
        });
    }
  };

  const handleReverseInvoice = async (data: any) => {
    setLoading(true);
    if (invoiceDetails) {
      return reverseInvoice(facility.id, invoiceDetails.id, data).then((res) => {
        setOpenReverseInvoiceDialog(false);
        enqueueSnackbar(`Invoice successfully reversed!`, { variant: 'success' });
        invoiceUpdated();
      });
    }
  };

  const handleDeleteInvoiceAction = async () => {
    setOpenDeleteInvoiceDialog(true);
  };

  const handleDelete = (data: any) => {
    setLoading(true);
    if (invoiceDetails) {
      return deleteInvoice(facility!.id, invoiceDetails.id).then(() => {
        enqueueSnackbar(`Invoice successfully deleted!`, { variant: 'success' });
        invoiceUpdated();
        setOpenDeleteInvoiceDialog(false);
      });
    }
  };

  const refreshTable = async () => {
    invoiceItemTableRef?.current?.refreshTable();
    // invoiceUpdated();
  };

  const formFields: UserFieldInput[] = [
    { field_name: 'patient_id', type: DROPDOWN_FIELD.PATIENT, span: 2, id: 'invoice-patient-dropdown', disabled: true },
    {
      field_name: 'issue_date',
      display_name: 'Issue Date',
      type: 'datetime',
    },
    {
      field_name: 'due_date',
      display_name: 'Due Date',
      type: 'date',
    },
    {
      field_name: 'service_provider_id',
      type: DROPDOWN_FIELD.SERVICE_PROVIDER,
      // multiple: true,
    },
    {
      display_name: 'Corporate Client',
      field_name: 'corporate_client_id',
      type: DROPDOWN_FIELD.CORPORATE_CLIENT,
    },
    {
      display_name: 'HMO',
      field_name: 'hmo_id',
      type: DROPDOWN_FIELD.HMO,
      // disabled: true,
    },
    {
      field_name: 'hmo_card_number',
      display_name: 'HMO Card Number',
      hiddenBasedOnOtherField: (data) => !data.hmo_id,
      optional: true,
    },
    {
      field_name: 'loa_number',
      display_name: 'LOA Number',
      hiddenBasedOnOtherField: (data) => !data.hmo_id,
      optional: true,
    },
  ];

  return (
    <>
      <ContainerColumn>
        {/* <RegularButton onClick={() => setUpdate(true)} startIcon={<CreateOutlinedIcon />} /> */}
        {!update && invoiceDetails && (
          <Box
            sx={
              isMobile
                ? { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }
                : {}
            }
          >
            <Box
              alignItems="center"
              display={'flex'}
              justifyContent={'space-between'}
              mb={SIZES.paddingS}
              sx={isMobile ? { width: '100%' } : {}}
            >
              <Typography fontWeight="bold" variant="h3">
                {invoiceDetails.full_name}
              </Typography>
              <Box display={'flex'}>
                {!invoiceReversed && (
                  <CustomIconButton
                    color="primary"
                    size="medium"
                    icon={<CreateOutlinedIcon sx={{ fontSize: '24px' }} />}
                    onClick={() => setUpdate(true)}
                    tooltip="Edit"
                  />
                )}
                {!isMobile && (
                  <CustomIconButton
                    color="primary"
                    size="medium"
                    icon={<LocalPrintshopOutlinedIcon sx={{ fontSize: '24px' }} />}
                    onClick={() => {
                      handlePrintInvoice();
                    }}
                    tooltip="Print"
                  />
                )}
              </Box>
            </Box>
            <Box
              display={'flex'}
              gap="40px"
              padding="0 0 10px 0"
              sx={isMobile ? { width: '100%', justifyContent: 'space-between' } : {}}
            >
              <Box display={'flex'} gap="20px">
                <Typography color={colors.accent}>Issue Date</Typography>
                <Typography>{formatDate(invoiceDetails.issue_date)}</Typography>
              </Box>
              <Box display={'flex'} gap="20px">
                <Typography color={colors.accent}>Due Date</Typography>
                <Typography>{invoiceDetails.due_date ? formatDate(invoiceDetails.due_date) : '-'}</Typography>
              </Box>
            </Box>
            {invoiceDetails
              ? invoiceDetails.service_provider_names && (
                  <Box display={'flex'} gap="20px">
                    <Typography color={colors.accent}>Service Provider</Typography>
                    <Typography>{invoiceDetails.service_provider_names}</Typography>
                  </Box>
                )
              : ''}
            <Box padding="10px 0">
              <InvoiceStatus value={invoiceDetails.status} row={invoiceDetails} />
            </Box>
          </Box>
        )}

        {update && (
          <CustomForm
            onSubmit={handleSubmit}
            onCancel={() => {
              setUpdate(false);
            }}
            fields={formFields}
            initialValues={initialValues}
            loading={loading}
            submitButtonText={update ? 'Save' : 'Create'}
            schema={patientBillingSchema}
            showCancelButton={showCancelButton || loading}
          />
        )}

        {invoiceDetails && !isMobile && (
          <ContainerRow gap={SIZES.paddingS}>
            <Box flex="1">
              <CustomDashboardWidget
                count={formatCurrency(totalPayment)}
                label="Total Amount Paid"
                icon={PaymentsOutlinedIcon}
              />
            </Box>
            <Box flex="1">
              {!invoiceReversed && (
                <CustomDashboardWidget
                  count={formatCurrency(totalAmount - totalPayment < 0 ? 0 : totalAmount - totalPayment)}
                  label="Total Remaining Balance"
                  icon={AccountBalanceWalletOutlinedIcon}
                />
              )}
            </Box>
          </ContainerRow>
        )}

        {invoiceDetails && isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'stretch', alignItems: 'stretch', gap: '10px' }}>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'stretch' }}>
              <CustomDashboardWidget
                count={formatCurrency(totalPayment)}
                label="Total Amount Paid"
                icon={PaymentsOutlinedIcon}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'stretch' }}>
              {!invoiceReversed && (
                <CustomDashboardWidget
                  count={formatCurrency(totalAmount - totalPayment < 0 ? 0 : totalAmount - totalPayment)}
                  label="Total Remaining Balance"
                  icon={AccountBalanceWalletOutlinedIcon}
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                />
              )}
            </Box>
          </Box>
        )}

        <Box>
          {!isMobile && (
            <Box display={'flex'} alignItems={'center'} gap="10px">
              <ShoppingCartOutlinedIcon />
              <Typography fontWeight={'600'} variant="h4">
                Orders
              </Typography>
            </Box>
          )}
          {isMobile && !appointmentDetails && (
            <Box display={'flex'} alignItems={'center'} gap="10px">
              <ShoppingCartOutlinedIcon />
              <Typography fontWeight={'600'} variant="h4">
                Orders
              </Typography>
            </Box>
          )}
          {isMobile && appointmentDetails && (
            <Box display={'flex'} flexDirection={'column'} alignItems={'space-between'} marginTop={'20px'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap="10px"
                marginBottom={'20px'}
              >
                <Typography
                  fontWeight={'400'}
                  variant="h4"
                  color={colors.grey_text}
                  fontSize={'14px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  marginLeft={'6px'}
                >
                  <AccessTimeIcon sx={{ color: colors.primary, fontSize: '18px' }} />
                  Schedule:
                </Typography>

                <Typography
                  fontWeight={'400'}
                  variant="h4"
                  fontSize={'14px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  marginRight={'6px'}
                >
                  {formatDateTime(appointmentDetails?.schedule)}
                </Typography>
              </Box>

              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap="10px"
                marginBottom={'28px'}
              >
                <Typography
                  fontWeight={'400'}
                  variant="h4"
                  color={colors.grey_text}
                  fontSize={'14px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  marginLeft={'6px'}
                >
                  <PersonIcon sx={{ color: colors.primary, fontSize: '18px' }} />
                  Patient:
                </Typography>

                <Typography
                  fontWeight={'400'}
                  variant="h4"
                  fontSize={'14px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  marginRight={'6px'}
                >
                  {appointmentDetails?.full_name}
                </Typography>
              </Box>
            </Box>
          )}
          {invoiceDetails && (
            <ContainerColumn>
              <InvoiceItemTable
                invoiceId={invoiceDetails.id}
                onGetData={(data) => {
                  setTotalAmount(data.data.reduce((total: number, order: any) => total + Number(order.total_price), 0));
                }}
                hideActions
                tableRef={invoiceItemTableRef}
              />
              {!invoicePosted && invoiceDetails.status !== 'void' && (
                <RegularButton
                  startIcon={<EditOutlinedIcon />}
                  variant="outlined"
                  onClick={() => {
                    setOpenInvoiceItemModal(true);
                  }}
                  label="Update Items"
                />
              )}
              {!invoicePosted && (
                <CustomModal
                  open={openInvoiceItemModal}
                  setOpen={setOpenInvoiceItemModal}
                  header={'Update Invoice Items'}
                  width={'auto'}
                >
                  {/* <InvoiceItemForm
                    invoice={invoiceDetails}
                    refreshTable={() => {
                      refreshTable();
                      setOpenInvoiceItemModal(false);
                    }}
                  /> */}
                  <POS
                    initialOrders={invoiceItems}
                    fromUpdateInvoiceItems
                    setOpenInvoiceItemModal={setOpenInvoiceItemModal}
                    refreshInvoiceDetails={() => {
                      invoiceUpdated();
                    }}
                  />
                </CustomModal>
              )}
            </ContainerColumn>
          )}

          {/* {invoiceDetails &&
            isMobile &&
            invoiceItemsDetails &&
            invoiceItemsDetails.map((invoiceItem: any) => {
              return (
                <Box sx={{ backgroundColor: colors.light_blue_background_2, width: '100%' }}>{invoiceItem.id}</Box>
              );
            })} */}

          {localOrders && isMobile && (
            <ContainerColumn>
              {localOrders.map((order, index) => {
                const currentOrderTotalAmount = +order.original_price * order.quantity;
                let currentOrderTotalDiscount = 0;

                if (order.discount && order.discount.discount_type === 'percentage') {
                  currentOrderTotalDiscount = currentOrderTotalAmount * (order.discount?.percentage / 100);
                }
                if (order.discount && order.discount.discount_type === 'new_unit_price') {
                  currentOrderTotalDiscount = currentOrderTotalAmount - +order.unit_price * order.quantity;
                }
                const currentTotalAmountAfterDiscount = currentOrderTotalAmount - currentOrderTotalDiscount;
                return (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid',
                      borderColor: colors.light_blue_background,
                      borderRadius: '8px',
                      padding: '20px 24px',
                      marginBottom: SIZES.paddingS,
                      backgroundColor: colors.light_blue_background,
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} gap="10px" marginBottom={'20px'}>
                      <ShoppingCartOutlinedIcon sx={{ fontSize: '18px' }} />
                      <Typography fontWeight={'600'} variant="h5">
                        Order Details
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      gap="10px"
                      marginBottom={'20px'}
                    >
                      <Typography variant="subtitle1" fontWeight="400" fontSize={'14px'}>
                        {order.service_name}
                      </Typography>
                      <Box display={'flex'}>
                        <Typography variant="subtitle1" fontWeight="bold" color={colors.primary}>
                          {formatCurrency(order.total_amount ? order.total_amount : order.unit_price)}
                        </Typography>

                        <Tooltip title="Apply Discount">
                          <Button
                            onClick={() => handleApplyDiscountCallback && handleApplyDiscountCallback(order)}
                            sx={{ padding: '0px !important', minWidth: '30px' }}
                          >
                            <DiscountOutlinedIcon
                              sx={{ color: colors.secondary, fontSize: '16px', margin: '0px !important' }}
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" marginTop={'-12px'}>
                      <Box display="flex" alignItems="center" color={colors.primary}>
                        <Typography>
                          {formatCurrency(order.unit_price)} × {order.quantity}
                        </Typography>
                        <Tooltip title="Update Quantity">
                          <Button
                            onClick={() => handleEditCallback && handleEditCallback(order)}
                            sx={{ padding: '0px !important', minWidth: '30px' }}
                          >
                            <EditOutlinedIcon
                              sx={{ color: colors.secondary, fontSize: '16px', margin: '0px !important' }}
                            />
                          </Button>
                        </Tooltip>
                      </Box>

                      {order.discount && (
                        <Box display="flex" alignItems="center">
                          {/* <Typography color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                          {formatCurrency(order.original_price)}
                        </Typography> */}
                          {order.discount.discount_type === DISCOUNT_TYPE.PERCENTAGE && (
                            <>
                              <Typography
                                sx={{
                                  backgroundColor: colors.light_blue_background_2,
                                  color: 'black',
                                  padding: '4px 6px ',
                                  borderRadius: '6px',
                                  fontSize: '0.75rem',
                                }}
                              >
                                -{order.discount?.percentage}%
                              </Typography>

                              <Tooltip title="Remove Discount">
                                <Button
                                  onClick={() => handleRemoveDiscountCallback && handleRemoveDiscountCallback(order)}
                                  sx={{ padding: '0px !important', minWidth: '30px' }}
                                >
                                  <DeleteOutlineIcon sx={{ color: colors.redAccent, fontSize: '16px' }} />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>

                    <hr style={{ marginTop: '20px', marginBottom: '20px' }} />

                    <Box>
                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'500'}>Subtotal</Typography>
                        <Typography fontWeight={'500'} variant="h4" color={colors.primary}>
                          {order ? formatCurrency(Number(order.original_price) * order.quantity) : 0}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'500'}>VAT Amount (12%) </Typography>
                        <Typography fontWeight={'500'} variant="h4" color={colors.grey_text}>
                          + {invoiceDetails ? formatCurrency(invoiceDetails.total_tax) : 0}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'400'} sx={{ marginLeft: '12px' }}>
                          VATable Sales{' '}
                        </Typography>
                        <Typography fontWeight={'400'} variant="h4" color={colors.grey_text}>
                          {invoiceDetails ? formatCurrency(invoiceDetails.total_tax) : 0}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'400'} sx={{ marginLeft: '12px' }}>
                          Non-VATable Sales{' '}
                        </Typography>
                        <Typography fontWeight={'400'} variant="h4" color={colors.grey_text}>
                          {invoiceDetails ? formatCurrency(invoiceDetails.total_tax) : 0}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'500'}>Total Discount</Typography>
                        <Typography fontWeight={'500'} variant="h4" color={colors.grey_text}>
                          {invoiceDetails && `- ${formatCurrency(invoiceDetails.total_discount)}`}
                          {!invoiceDetails && localOrders && `- ${formatCurrency(currentOrderTotalDiscount)}`}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'400'} sx={{ marginLeft: '12px' }}>
                          Store Discount{' '}
                        </Typography>
                        <Typography fontWeight={'400'} variant="h4" color={colors.grey_text}>
                          - {invoiceDetails ? formatCurrency(invoiceDetails.total_tax) : 0}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'400'} sx={{ marginLeft: '12px' }}>
                          PWD/SC Discount{' '}
                        </Typography>
                        <Typography fontWeight={'400'} variant="h4" color={colors.grey_text}>
                          - {invoiceDetails ? formatCurrency(invoiceDetails.total_tax) : 0}
                        </Typography>
                      </ContainerRow>

                      <ContainerRow sx={{ marginBottom: '8px' }}>
                        <Typography fontWeight={'600'}>Total Amount</Typography>
                        <Typography fontWeight={'600'} variant="h4" color={colors.primary}>
                          {invoiceDetails && formatCurrency(invoiceDetails.total_amount)}
                          {!invoiceDetails && localOrders && formatCurrency(currentTotalAmountAfterDiscount)}
                        </Typography>
                      </ContainerRow>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}></Box>
                  </Box>
                );
              })}
            </ContainerColumn>
          )}
          {localOrders && !isMobile && (
            <ColoredTable
              dataRows={localOrders}
              columns={[
                {
                  field: 'item_name',
                  headerName: 'Products / Services',
                  flex: 1.5,
                  renderCell: (params) => (
                    <Typography>
                      {/* {params.row.item_name} */}
                      {params.row.service_name ?? params.row.item_name}
                    </Typography>
                  ),
                },
                {
                  field: 'unit_price',
                  headerName: 'Unit Price × Quantity',
                  flex: 1.5,
                  headerAlign: 'right',
                  align: 'right',
                  renderCell: (params) => (
                    <Box>
                      <Typography>
                        {formatCurrency(params.value)} × {params.row.quantity}
                      </Typography>
                      {params.row.discount && (
                        <Box display="flex" justifyContent="flex-end">
                          <Typography
                            color="gray"
                            fontWeight="500"
                            fontSize="13px"
                            sx={{ textDecoration: 'line-through' }}
                          >
                            {formatCurrency(params.row.original_price)}
                          </Typography>
                          {params.row.discount.discount_type === DISCOUNT_TYPE.PERCENTAGE && (
                            <Typography
                              fontWeight="500"
                              fontSize="13px"
                              sx={{
                                backgroundColor: 'grey',
                                color: 'white',
                                paddingInline: SIZES.paddingS,
                                marginLeft: SIZES.paddingS,
                                borderRadius: '10px',
                              }}
                            >
                              {params.row.discount.percentage}%
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  ),
                },
                {
                  field: 'total_amount',
                  headerName: 'Total Amount',
                  headerAlign: 'right',
                  align: 'right',
                  flex: 1,
                  renderCell: (params) => (
                    <Typography>{formatCurrency(params.row.unit_price * params.row.quantity)}</Typography>
                  ),
                },
                {
                  field: 'action',
                  headerName: 'Action',
                  flex: 0.5,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => (
                    <RowAction
                      data={params.row}
                      actions={[
                        {
                          label: 'Apply Discount',
                          icon: <DiscountOutlinedIcon />,
                          action: (data) => {
                            handleApplyDiscountCallback && handleApplyDiscountCallback(data);
                          },
                        },
                        {
                          label: 'Remove Discount',
                          icon: <DiscountOutlinedIcon />,
                          hideCallback: (data) => !data.discount,
                          action: (data) => {
                            handleRemoveDiscountCallback && handleRemoveDiscountCallback(data);
                          },
                        },
                        {
                          label: 'Update Quantity',
                          icon: <EditOutlinedIcon />,
                          action: (data) => {
                            handleEditCallback && handleEditCallback(data);
                          },
                        },
                      ]}
                    />
                  ),
                },
              ]}
            />
          )}
        </Box>

        {!isMobile && (
          <>
            <ContainerRow sx={{ paddingInline: SIZES.padding }}>
              <Typography fontWeight={'bold'}>Subtotal</Typography>
              <Typography fontWeight={'bold'} variant="h4">
                {invoiceDetails ? formatCurrency(Number(invoiceDetails.original_amount)) : 0}
              </Typography>
            </ContainerRow>

            <ContainerRow sx={{ paddingInline: SIZES.padding }}>
              <Box display="flex" gap="10px">
                <Typography fontWeight={'bold'}>VAT (12%)</Typography>
              </Box>
              <Typography fontWeight={'bold'} variant="h4">
                {invoiceDetails ? formatCurrency(invoiceDetails.total_tax) : 0}
              </Typography>
            </ContainerRow>

            <ContainerRow sx={{ paddingInline: SIZES.padding }}>
              <Box display="flex" gap="10px">
                <Typography fontWeight={'bold'}>VAT Exempt</Typography>
              </Box>
              <Typography fontWeight={'bold'} variant="h4">
                {invoiceDetails ? formatCurrency(invoiceDetails.total_vat_exempt) : 0}
              </Typography>
            </ContainerRow>

            <ContainerRow sx={{ paddingInline: SIZES.padding }}>
              <Typography fontWeight={'bold'}>Total Discount</Typography>
              <Typography fontWeight={'bold'} variant="h4">
                - {invoiceDetails ? formatCurrency(invoiceDetails.total_discount) : 0}
              </Typography>
            </ContainerRow>

            <ContainerRow sx={{ paddingInline: SIZES.padding }}>
              <Typography fontWeight={'bold'}>Total</Typography>
              <Typography fontWeight={'bold'} variant="h4">
                {invoiceDetails ? formatCurrency(invoiceDetails.total_amount) : 0}
              </Typography>
            </ContainerRow>
          </>
        )}

        {invoiceDetails && invoiceDetails.status !== 'unpaid' && (
          <Box padding="20px 0">
            <Box display={'flex'} alignItems={'center'} gap="10px">
              <LocalAtmIcon />
              <Typography fontWeight={'600'} variant="h4">
                Payment Details
              </Typography>
            </Box>
            <PaymentTable tableRef={paymentTableRef} invoice={invoiceDetails} />
          </Box>
        )}

        {lowerButtons && (
          <Box display="flex" gap={SIZES.padding} mt="5px" justifyContent={'center'} width="100%">
            {lowerButtons}
          </Box>
        )}

        {invoiceDetails && (
          <CustomModal
            open={openAddPaymentModal}
            setOpen={setOpenAddPaymentModal}
            header={'Add Payment'}
            subHeader={invoiceDetails.invoice_number}
          >
            <PaymentForm
              invoice={invoiceDetails}
              callbackAfterSubmit={() => {
                setOpenAddPaymentModal(false);
                calculateTotalPayment();
                paymentTableRef.current?.refreshTable();
                invoiceUpdated();
              }}
            />
            {/* <CustomForm
              onSubmit={handleSubmitPayment}
              fields={formFields}
              initialValues={initialValues}
              schema={billing_payment_schema}
              loading={loading}
            /> */}
          </CustomModal>
        )}
        {invoiceDetails && (
          <Box display="flex" width="100%" gap="20px">
            {totalPayment < totalAmount && invoicePosted && !invoiceReversed && (
              <RegularButton
                startIcon={<AddIcon />}
                label="Add Payment"
                variant="outlined"
                onClick={() => setOpenAddPaymentModal(true)}
                fullWidth
              />
            )}
            {invoicePosted && !invoiceReversed && (
              <ProtectedComponent requiredRole={['COMPANY_ADMIN']}>
                <RegularButton
                  startIcon={<HistoryIcon />}
                  label="Reverse"
                  variant="outlined"
                  onClick={handleReverseInvoiceAction}
                  fullWidth
                  color="warning"
                />
              </ProtectedComponent>
            )}
            {!invoicePosted && invoiceDetails.status !== 'void' && (
              <RegularButton
                startIcon={<CheckCircleOutlinedIcon />}
                label="Confirm"
                variant="outlined"
                onClick={handleConfirmInvoiceAction}
                fullWidth
              />
            )}
            {!invoicePosted && invoiceDetails.status !== 'void' && (
              <ProtectedComponent requiredRole={['COMPANY_ADMIN']}>
                <RegularButton
                  startIcon={<BlockOutlinedIcon />}
                  label="Void"
                  variant="outlined"
                  onClick={handleDeleteInvoiceAction}
                  fullWidth
                  color="warning"
                />
              </ProtectedComponent>
            )}
          </Box>
        )}
        {fromPOS && (
          <RegularButton
            label="View Patient Transactions"
            onClick={() => navigate(`/company/inventory/patient-transactions`)}
            fullWidth
          />
        )}
      </ContainerColumn>
      <CustomModal
        width={550}
        header={'Confirmation Message '}
        open={openReverseInvoiceDialog}
        setOpen={setOpenReverseInvoiceDialog}
      >
        <Typography textAlign={'center'} px={'3rem'} pb={'3rem'} fontWeight={500}>
          Would you like to reverse the action made in {invoiceDetails?.invoice_number}?
        </Typography>

        <Typography pb={'1rem'}>Please state your purpose here:</Typography>
        <CustomForm
          initialValues={{ reason: '', return: true }}
          onSubmit={handleReverseInvoice}
          fields={[
            {
              field_name: 'reason',
              display_name: 'Purpose',
              type: 'string',
              span: 4,
            },
            {
              field_name: 'return',
              display_name: 'Return products to inventory',
              type: 'checkbox',
              span: 4,
            },
          ]}
          schema={reverse_invoice_schema}
          loading={loading}
        />
      </CustomModal>
      <ConfirmationDialog
        title="Confirm Invoice"
        setOpen={setInvoiceDialog}
        open={confirmInvoiceDialog}
        cancelButtonLabel="Pay Later"
        confirmButtonLabel={'Pay Now'}
        content={`Would you like to confirm Invoice #${invoiceDetails?.invoice_number} and proceed to payment?`}
        onConfirm={() => {
          // Pay Now
          setInvoiceDialog(false);
          handleConfirmDialog(true);
        }}
        onCancel={() => {
          // Pay Later
          handleConfirmDialog(false);
          setInvoiceDialog(false);
        }}
        cancelButtonIcon={<WatchLaterOutlinedIcon />}
        confirmButtonIcon={<CheckOutlinedIcon />}
      />

      <ConfirmationDialog
        open={openDeleteInvoiceDialog}
        setOpen={setOpenDeleteInvoiceDialog}
        content={`Would you like to delete ${invoiceDetails?.invoice_number}?`}
        onConfirm={handleDelete}
      />
      {invoiceDetails && (
        <Box display="none">
          <PrintableInvoice ref={printableInvoiceRef} invoice={invoiceDetails} />
        </Box>
      )}
    </>
  );
};

export default InvoiceTemplate;
