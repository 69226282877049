import { ApiQuery } from 'core/model/interface';
import { Services } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'services';

export const getService = (facility_code: string, id: number) => {
  return axios.get(`${baseURL}/public/${path}/${facility_code}/${id}`);
};

export const getServiceById = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getClinicServiceById = (clinic_id: number,id: number) => {
  return axios.get(`${baseURL}/${path}/clinic-service/${clinic_id}/${id}`);
};

export const getServices = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, {
    params: query,
  });
};

export const getServicesClinics = (id: number) => {
  return axios.get(`${baseURL}/${path}/clinics/${id}`);
};

export const updatePriceWithClinicId = (clinic_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/clinics/${clinic_id}/${id}`, data);
};

export const createClinicService = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateClinicService = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteClinicService = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const updateClinicServicePrice = (clinic_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${clinic_id}/${id}`, data);
};

export const updateAllClinicServicePrice = (serviceId:number,data: any) => {
  return axios.patch(`${baseURL}/${path}/update/all-price/${serviceId}`, data);
};


export const updateClinicServiceAvailableSchedule = (id: number, availableSchedule: boolean) => {
  return axios.patch(`${baseURL}/${path}/available_schedule/${id}`, { availableSchedule });
};

export const removeServicesFromClinic = (clinic_id: number, services: Services) => {
  return axios.delete(`${baseURL}/${path}/clinic/${clinic_id}`, { data: services });
};

export const assignServiceToClinic = (clinic_id: number, services: any) => {
  return axios.post(`${baseURL}/${path}/clinic/${clinic_id}`, services);
};

export const getServicesAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/assigned/${clinic_id}`, { params: query });
};

export const getServicesNotAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-assigned/${clinic_id}`, { params: query });
};

export const updateServiceSchedules = (clinic_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/schedules/${clinic_id}/${id}`, data);
};

export const updateServiceVisibility = (clinic_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/clinic/visibility/${clinic_id}`, data);
};

export const getServiceTimeBlocks = (facility_code: string, id: number, formatted_data: any) => {
  return axios.get(`${baseURL}/public/${path}/timeblocks/${facility_code}/${id}/${formatted_data}`);
};

export const getInvoiceAvailableServices = (clinic_id: number, invoice_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/invoice/${clinic_id}/${invoice_id}`, {
    params: query,
  });
};
