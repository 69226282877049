import {
  AppointmentDetails,
  AppointmentsTabs,
  Bookings,
  Campaigns,
  ClinicDetails,
  ClinicFeedback,
  ClinicManagement,
  DocumentEditor,
  HealthProgramsTabs,
  PatientGroups,
  PatientManagement,
  PatientRecords,
  Promotions,
  TbReminder,
  Templates,
} from 'company/screens';

import { AccountType } from 'core/model/Entities';
import CompanyHMO from 'company/screens/CompanyHMOs';
import CompanyNotificationsPage from 'company/screens/CompanyNotifications';
import CorporateClients from 'company/screens/CorporateClients';
import Dashboard from 'company/screens/Dashboard';
import HealthAssessments from 'company/screens/HealthAssessments';
import Onboarding from 'company/screens/Settings/screens/Onboarding';
import { POS } from 'company/screens/InventorySystem';
import PatientBilling from 'company/screens/RegistrationBoard/components/PatientBilling';
import { ProtectedRoute } from 'core/components';
import { Route } from 'react-router-dom';
import { Typography } from '@mui/material';

const ClinicManagementRoutes = (
  <Route path="/company" element={<ProtectedRoute accountType={AccountType.COMPANY} />}>
    <Route
      path="clinic-management"
      element={<ProtectedRoute requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF']} />}
    >
      <Route index element={<ClinicManagement />} />
      <Route path=":id" element={<ClinicDetails />} />
    </Route>

    <Route path="patient-management" element={<ProtectedRoute requiredAuth={['MANAGE_PATIENTS', 'VIEW_PATIENTS']} />}>
      <Route index element={<PatientManagement />} />
      <Route path="groups" element={<PatientGroups />} />
      <Route
        path="corporate-clients"
        element={
          <ProtectedRoute
            requiredAuth={['MANAGE_CORPORATE_CLIENTS', 'VIEW_CORPORATE_CLIENTS']}
            element={<CorporateClients />}
          />
        }
      />
      <Route path="company-hmos" element={<CompanyHMO />} />
    </Route>

    <Route
      path="notifications"
      element={
        <ProtectedRoute
          requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'CLINIC_STAFF']}
          element={<CompanyNotificationsPage />}
        />
      }
    />

    <Route
      path="patient-records"
      element={
        <ProtectedRoute
          requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
          element={<PatientRecords />}
        />
      }
    />

    <Route
      path="dashboard"
      element={
        <ProtectedRoute requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF']} element={<Dashboard />} />
      }
    />

    <Route
      path="transactions"
      element={<ProtectedRoute requiredAuth={['VIEW_PATIENT_TRANSACTIONS']} element={<PatientBilling />} />}
    />

    <Route path="appointments">
      <Route index element={<AppointmentsTabs />} />
      <Route path=":id" element={<AppointmentDetails />} />
    </Route>

    <Route path="health-assessments">
      <Route
        index
        element={
          <ProtectedRoute
            requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
            element={<HealthAssessments />}
          ></ProtectedRoute>
        }
      />
    </Route>

    <Route
      path="campaigns"
      element={
        <ProtectedRoute
          requiredAuth={['VIEW_CAMPAIGNS', 'MANAGE_CAMPAIGNS', 'PUBLISH_CAMPAIGNS']}
          element={<Campaigns />}
        />
      }
    />

    <Route path="onboarding" element={<Onboarding />} />
    <Route
      path="templates"
      element={
        <ProtectedRoute requiredAuth={['MANAGE_NOTE_TEMPLATES', 'VIEW_NOTE_TEMPLATES']} element={<Templates />} />
      }
    />

    {/* BOOKINGS */}
    <Route path="bookings" element={<Bookings />} />
    <Route
      path="client-feedback"
      element={
        <ProtectedRoute
          requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF']}
          element={<ClinicFeedback />}
        />
      }
    />
    <Route
      path="promos"
      element={
        <ProtectedRoute requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF']} element={<Promotions />} />
      }
    />

    <Route path="carego-notes/:initialMode/:id" element={<DocumentEditor source="carego" />} />
    <Route path="notes/:initialMode/:id" element={<DocumentEditor source="company" />} />
    <Route path="patient-notes/:initialMode/:id" element={<DocumentEditor source="patient" />} />
    <Route path="program/tb" element={<TbReminder />} />
    <Route path="program/:program_code" element={<HealthProgramsTabs />} />
    <Route path="pos" element={<ProtectedRoute requiredAuth={['USE_POS']} element={<POS />} />} />
  </Route>
);

export default ClinicManagementRoutes;
