/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import { CustomAnimatedDrawer, CustomModal } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import InvoiceTemplate from 'company/screens/Transactions/components/InvoiceTemplate';
import { getInvoice, getInvoiceServiceProviders } from 'company/api/patient-billing';
import { useMediaQuery } from '@mui/material';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';

type CreateInvoiceClickProps = {
  invoiceId: number | null;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  onInvoiceUpdate?: () => void;
  fromPOS?: boolean;
  onClose?: () => void;
};

interface InvoiceDetails {
  due_amount: number;
  invoice_number: number;
  total_amount: number;
  status: string;
  full_name: string;
  issue_date: string;
  // Add other properties if there are more in the response
}
const ViewInvoiceClick: React.FC<CreateInvoiceClickProps> = ({
  openModal,
  setOpenModal,
  invoiceId,
  onInvoiceUpdate,
  fromPOS = false,
  onClose,
}) => {
  const { facility } = useContext(FacilityContext);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetails | any>();
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useMediaQuery('(max-width:768px)');

  // const getInvoiceDetails = () => {
  //   setInvoiceDetails(undefined);
  //   if (invoiceId) {
  //     getInvoice(facility.id, invoiceId).then((res) => {
  //       let serviceProviderID = null;
  //       getInvoiceServiceProviders(facility.id, invoiceId).then((res) => {
  //         serviceProviderID = res.data[0].value;
  //       });

  //       setInvoiceDetails({ ...res.data, service_provider_id: serviceProviderID ?? null });
  //     });
  //   }
  // };

  async function getInvoiceDetails() {
    setInvoiceDetails(undefined);
    try {
      if (invoiceId) {
        setLoading(true);
        const res = await getInvoice(facility.id, invoiceId);
        const result = await getInvoiceServiceProviders(facility.id, invoiceId);
        const invoiceDetails = { ...res.data, service_provider_id: result.data[0]?.value ?? '' };
        setInvoiceDetails(invoiceDetails);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }

  useEffect(() => {
    getInvoiceDetails();
    console.log(invoiceDetails);
  }, [invoiceId]);

  if (isMobile)
    return (
      <CustomModal
        header={invoiceDetails?.invoice_number ? 'Invoice ' + invoiceDetails.invoice_number : 'View Invoice'}
        open={openModal}
        setOpen={setOpenModal}
        id="editFieldModal"
        onClose={onClose}
      >
        {loading ? (
          <CustomLoadingIndicator />
        ) : (
          <InvoiceTemplate
            invoiceDetails={invoiceDetails}
            onInvoiceUpdate={() => {
              onInvoiceUpdate && onInvoiceUpdate();
              getInvoiceDetails();
            }}
            showCancelButton
            fromPOS={fromPOS}
          />
        )}
      </CustomModal>
    );
  return (
    <CustomAnimatedDrawer
      width={600}
      id="editFieldModal"
      header={invoiceDetails?.invoice_number ? 'Invoice ' + invoiceDetails.invoice_number : 'View Invoice'}
      open={openModal}
      setOpen={setOpenModal}
      onClose={onClose}
    >
      {loading ? (
        <CustomLoadingIndicator />
      ) : (
        <InvoiceTemplate
          invoiceDetails={invoiceDetails}
          onInvoiceUpdate={() => {
            onInvoiceUpdate && onInvoiceUpdate();
            getInvoiceDetails();
          }}
          showCancelButton
          fromPOS={fromPOS}
        />
      )}
    </CustomAnimatedDrawer>
  );
};

export default ViewInvoiceClick;
