import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'invoice-items';

export const getInvoiceItems = (
  facility_id: number,
  item: 'service' | 'product' | 'all',
  invoice_id: number,
  query: ApiQuery
) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/all/${invoice_id}/${item}`, { params: query });
};

export const getInvoicesByProduct = (
  facility_id: number,
  product_id: number,
  item: 'service' | 'product',
  query: ApiQuery
) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/variant/${product_id}/${item}`, { params: query });
};

export const getInvoiceItem = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const addInvoiceItems = (facility_id: number, invoice_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${invoice_id}`, data);
};

export const updateInvoiceItem = (facility_id: number, id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const updateInvoiceItems = (facility_id: number, invoice_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/update/${invoice_id}`, data);
};

export const updatePriceAndQty = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/price-and-qty/${id}`, data);
};

export const deleteInvoiceItem = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};
