import { GridColDef } from '@mui/x-data-grid';
import { block, getAll } from 'carego-admin/api/carego-flagged-ips';
import { PageBuilderPageType } from 'core/PageBuilder';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import ReplayIcon from '@mui/icons-material/Replay';

import { Box } from '@mui/material';
import { CustomGridCell, CustomModal, RegularButton } from 'core/components';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { extractFirewallRequestMetadata, formatDateTime } from 'core/utils';
import { useNavigate } from 'react-router-dom';
import DetailsViewer, { DetailsViewerDataFormat } from '../components/DetailsViewer';

const FlaggedRequests: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [forceRefresh, setForceRefresh] = useState(0);
  const [openShowDetails, setOpenShowDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<DetailsViewerDataFormat[]>([]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'ip', headerName: 'IP Address', flex: 2 },
    { field: 'method', headerName: 'Method', flex: 2 },
    { field: 'path', headerName: 'Path', flex: 2 },
    { field: 'type', headerName: 'Flag Type', flex: 2, headerAlign: 'center', align: 'center' },
    {
      field: 'created_at',
      headerName: 'Datetime',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.created_at)}</CustomGridCell>,
    },
  ];

  const content: PageBuilderPageType = {
    type: BLOCK_TYPE.PAGE,
    header: 'Flagged Requests',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Flagged Requests',
        doNotWaitForFacility: true,
        tableAction: {
          rowActions: [
            {
              label: 'Follow Session',
              icon: <TrendingFlatIcon />,
              action: (row: any) => {
                const middlewareMetadata = extractFirewallRequestMetadata(row.request);
                if (!middlewareMetadata.log_session_id)
                  return enqueueSnackbar('The selected request has no session', { variant: 'info' });
                navigate(`/admin/security/logs?session=${middlewareMetadata.log_session_id}`);
              },
            },
            {
              label: 'Show Details',
              icon: <OpenWithIcon />,
              action: (row: any) => {
                setOpenShowDetails(true);
                setSelectedDetails([
                  { summary: 'Request ID', values: row.id },
                  { summary: 'IP Address', values: row.ip },
                  { summary: 'Path', values: row.path },
                  { summary: 'Request Headers', values: JSON.parse(row.header), isMono: true, isJSON: true },
                  { summary: 'Request Body', values: JSON.parse(row.body ?? '{}'), isMono: true, isJSON: true },
                ]);
              },
            },
            {
              label: 'Block IP',
              icon: <GppMaybeIcon />,
              color: 'error',
              action: (row: any) => {
                block(row.id)
                  .then((response) => {
                    enqueueSnackbar('Request IP successfully blocked', { variant: 'success' });
                  })
                  .catch(() => {
                    enqueueSnackbar('Failed blocking IP (try again later)', { variant: 'error' });
                  });
              },
            },
          ],
        },
        tableComponent: {
          forceRefresh: forceRefresh,
          columns: columns,
          getData: getAll,
          headerComponent: (
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <RegularButton
                startIcon={<ReplayIcon />}
                label="Refresh Table"
                onClick={() => setForceRefresh(forceRefresh + 1)}
              />
            </Box>
          ),
        },
      },
    },
  };

  return (
    <>
      <CustomModal
        header={'Request Details'}
        subHeader="Click to expand request values"
        open={openShowDetails}
        setOpen={setOpenShowDetails}
      >
        <DetailsViewer data={selectedDetails} />
      </CustomModal>
      <PageBuilder content={content} />
    </>
  );
};

export default FlaggedRequests;
