/* eslint-disable react-hooks/exhaustive-deps */

import { CustomForm, CustomModal } from 'core/components';
import { getHealthPackage, getStages } from 'company/api/health-packages';
import { useContext, useState } from 'react';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { enrollHealthPackagePatients } from 'company/api/health-packages-patients';
import { health_package_patient_schema } from 'company/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type UserInput = {
  date_started: string;
  provider_id: number | string;
  package_id: number | string;
  starting_stage: number | string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const initialValues: UserInput = {
  date_started: '',
  package_id: '',
  starting_stage: '',
  provider_id: '',
};

type EnrollmentFormProps = {
  refreshEnrollmentTable?: () => void;
  refreshMainTable?: () => void;
  selectedRow: any;
  selectedRows?: any[];
  openEnrollFormModal: boolean;
  setOpenEnrollFormModal: (openEnrollFormModal: boolean) => void;
  callbackAfterSubmit?: (data: any) => void;
};

const EnrollmentForm: React.FC<EnrollmentFormProps> = ({
  refreshEnrollmentTable,
  refreshMainTable,
  selectedRow,
  selectedRows,
  openEnrollFormModal,
  setOpenEnrollFormModal,
  callbackAfterSubmit,
}) => {
  const { facility } = useContext(FacilityContext);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [stages, setStages] = useState<any>();
  const [selectedPackage, setSelectedPackage] = useState<string>('');

  const formFields: UserFieldInput[] = [
    {
      field_name: 'package_id',
      display_name: 'Session Plan Name',
      type: DROPDOWN_FIELD.HEALTH_PACKAGE,
      span: 4,
      onChange: async (value: any, setFieldValue: any) => {
        try {
          const response = await getHealthPackage(value.value);
          const selectedPackage = response.data;

          const res = await getStages(selectedPackage.id);
          const stage = res.data.data;

          setStages(stage);
          setFieldValue('starting_stage', stage.length > 0 ? stage[0].id : '');
          setSelectedPackage(selectedPackage.package_name);
        } catch (error) {
          console.error('Error fetching stages for the selected package:', error);
          setStages([]);
          setFieldValue('starting_stage', '');
        }
      },
    },

    { field_name: 'date_started', display_name: 'Starting Date', type: 'datetime', span: 2 },
    {
      field_name: 'starting_stage',
      display_name: 'Session',
      type: 'select',
      options: stages?.map((u: any) => ({
        key: u.stage_name,
        value: u.id,
      })),
      disabled: !stages || stages.length === 0,
      span: 2,
    },
    {
      field_name: 'provider_id',
      display_name: 'Doctor',
      type: DROPDOWN_FIELD.SERVICE_PROVIDER,
      span: 2,
      optional: true,
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setLoading(true);

    // const patientsArray = selectedRow ? [selectedRow.id] : [];

    // const patients = selectedRows ? JSON.stringify(selectedRows) : JSON.stringify(patientsArray);

    if (data) {
      data.patient_id = selectedRow.patient_id;
      data.stage_id = data.starting_stage;

      enrollHealthPackagePatients(facility.id, data)
        .then((res: any) => {
          // const successMessage =
          //   patientsArray.length === 1 ? 'Patient successfully enrolled!' : 'Patients successfully enrolled!';

          successFormSubmit(
            res,
            formikHelpers,
            selectedRow.full_name + ' has been successfully enrolled to ' + selectedPackage + '.'
          );
          console.log('Form data being sent:', data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>, successMessage: string) => {
    if (res.status === 200) {
      enqueueSnackbar(successMessage, { variant: 'success' });
      callbackAfterSubmit && callbackAfterSubmit((prev: any) => ({ ...prev, ...res.data.patient }));
      setOpenEnrollFormModal(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    // setLoading(false);
    refreshMainTable && refreshMainTable();
    refreshEnrollmentTable && refreshEnrollmentTable();
  };

  return (
    <>
      <CustomModal open={openEnrollFormModal} setOpen={setOpenEnrollFormModal} header={'Enroll to Health Session'}>
        <CustomForm
          onSubmit={handleSubmit}
          fields={formFields}
          initialValues={initialValues}
          schema={health_package_patient_schema}
          loading={loading}
          submitButtonId="enrollment-form-submit-button"
        />
      </CustomModal>
    </>
  );
};

export default EnrollmentForm;
