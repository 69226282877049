import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { formatNumber, shuffle } from 'core/utils';
import { useEffect, useMemo, useState } from 'react';

import { PieChart } from '@mui/x-charts/PieChart';
import { chart_colors } from 'company/screens/PatientRecords/components/ChartEditor';
import { tokens } from 'theme/theme';

export interface PieChartData {
  value: number;
  label: string;
}

interface Props {
  processData: (setPieChartData: (data: PieChartData[]) => void) => void;
  title: string;
}

const DashboardPieChart: React.FC<Props> = ({ title, processData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([]);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const chartColors = useMemo(() => shuffle(chart_colors), []);

  useEffect(() => {
    setTimeout(() => {
      processData(setPieChartData);
    }, 2000);
  }, [processData]);

  if (!pieChartData) {
    return (
      <Skeleton
        variant="rounded"
        width={'100%'}
        height={'100%'}
        sx={{ gridColumn: isMobilePhone ? 'span 12' : 'span 6', borderRadius: '8px' }}
        animation="wave"
      />
    );
  }

  return (
    <Box
      sx={{
        gridColumn: isMobilePhone ? 'span 12' : 'span 6',
        border: '1px solid ' + colors.border,
        borderRadius: '8px',
        minHeight: '400px',
        padding: '12px 24px',
        '& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
          transform: 'translateX(-25px) !important',
        },
        '& .MuiChartsAxis-directionX .MuiChartsAxis-label': {
          transform: 'translateY(15px) !important',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            border: 'none',
            borderBottom: '1px solid #CCCCCC',
            padding: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '30px',
          }}
        >
          <Box sx={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
            <Typography variant="h3" fontWeight="700">
              {title}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: isMobilePhone ? '1fr' : '1fr 35%', alignItems: 'center' }}>
          <PieChart
            sx={{ marginRight: isMobilePhone ? '-80px' : '-50px' }}
            colors={chartColors}
            series={[
              {
                data: pieChartData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -20, color: 'gray' },
                innerRadius: 30,
                outerRadius: isMobilePhone ? 100 : 130,
                // paddingAngle: 0,
                cornerRadius: 5,
              },
            ]}
            slotProps={{
              legend: { hidden: true },
            }}
            height={280}
          />

          <Box display="flex" flexDirection="column">
            {pieChartData.map((entry, index) => (
              <Box key={`item-${index}`} display="flex" alignItems="center" mb={1}>
                <Box width={15} height={15} bgcolor={chartColors[index]} mr={1} />
                <Typography fontSize="12px">{`${entry.label} - ${formatNumber(entry.value)}`}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPieChart;
