import { Box, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, PrimaryButton, RegularButton, RowAction } from 'core/components';
import { deletePrescriptionMedicine, getPrescriptionMedicines } from 'company/api/prescription-medicines';
import { useContext, useEffect, useRef, useState } from 'react';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { CompanyAppointmentModel } from 'company/model/Entities';
import { ContainerRow } from 'core/components/containers';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from '../Patient/PatientModel';
import PrescriptionLineItemForm from './PrescriptionLineItem/PrescriptionLineItemForm';
import { PrescriptionLineItemModel } from './PrescriptionLineItem/PrescriptionLineItemModel';
import { PrescriptionModel } from './PrescriptionModel';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PrintablePrescription from 'company/screens/RegistrationBoard/components/PrintablePrescription';
import { SIZES } from 'theme/constants';
import SendReminders from './PrescriptionLineItem/Components/SendReminder';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { SmsScheduleModal } from 'company/components';
import { cancelMedicationReminder } from 'company/api/medication-reminder';
import { generateInstruction } from 'core/utils';
import { getAppointment } from 'company/api/appointments';
import { getDoctor } from 'company/api/doctors';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

type Props = {
  prescription: PrescriptionModel;
  patient: PatientModel;
};

const PrescriptionContainer: React.FC<Props> = ({ prescription, patient }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const printablePrescriptionRef = useRef(null);

  const [lineItems, setLineItems] = useState<PrescriptionLineItemModel[]>();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedMedicine, setSelectedMedicine] = useState<PrescriptionLineItemModel>();
  const [openSendReminder, setOpenSendReminder] = useState(false);
  const [openConfirmCancelation, setOpenConfirmCancelation] = useState(false);
  const [openSmsSchedules, setOpenSmsSchedules] = useState(false);
  const [doctor, setDoctor] = useState<any>();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility.id, prescription.id]);

  const handlePrintPrescription = useReactToPrint({
    content: () => printablePrescriptionRef.current,
    pageStyle: `
      @page {
        size: A5;
        margin: 20mm;
      }
      body {
        margin: 0;
      }
    `,
  });
  

  const fetchData = async () => {
    try {
      if (facility && prescription) {
        const response = await getPrescriptionMedicines(facility.id, prescription.id, { length: 100 });
        setLineItems(response.data.data);

        const doctor_res = await getDoctor(prescription.doctor_id);
        setDoctor(doctor_res.data);
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const handleDeleteAction = (lineItem: PrescriptionLineItemModel) => {
    setDeleteModal(true);
    setSelectedMedicine(lineItem);
  };

  const handleUpdateAction = (lineItem: PrescriptionLineItemModel) => {
    try {
      setSelectedMedicine(lineItem);
      setOpenForm(true);
    } catch (error) {
      console.error('Error updating prescription:', error);
    }
  };

  const handleDeleteConfirm = async (lineItem: PrescriptionLineItemModel) => {
    try {
      await deletePrescriptionMedicine(facility.id, lineItem.id);
      enqueueSnackbar(`Medicine successfully deleted!`, { variant: 'success' });
      fetchData();
    } catch (error) {
      enqueueSnackbar(`Error occured! Failed to delete medicine!`, { variant: 'error' });
    }
  };

  return (
    <>
      <Box>
        <Box display="flex-column" gap={SIZES.padding}>
          <ContainerRow sx={{ mb: SIZES.padding }}>
            <ContainerRow sx={{ justifyContent: 'flex-start', width: undefined, gap: SIZES.paddingS }}>
              <PrimaryButton
                label="Add Medicine"
                onClick={() => {
                  setSelectedMedicine(undefined);
                  setOpenForm(true);
                }}
              />
              <RegularButton
                startIcon={<PrintOutlinedIcon />}
                onClick={() => {
                  document.title = `Prescription - ${patient.full_name}`;
                  setTimeout(() => (document.title = `CareGo Health Suite`), 3000);
                  handlePrintPrescription();
                }}
                label="Print Prescription"
              />
            </ContainerRow>
            <RegularButton
              startIcon={<SmsOutlinedIcon />}
              variant="outlined"
              onClick={() => setOpenSmsSchedules(true)}
              label="View SMS Schedules"
            />
          </ContainerRow>
          {lineItems &&
            lineItems.map((data, index) => (
              <Box
                key={index}
                display="flex"
                gap="10px"
                borderRadius={'10px'}
                border="2px solid"
                borderColor={colors.border}
                padding="15px"
                marginBottom={'20px'}
              >
                <AccessTimeFilledIcon sx={{ color: colors.accent }} />

                <Box width="100%">
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h5" fontWeight={'bold'}>
                      {data.generic_name} - Medication
                    </Typography>
                    <RowAction
                      data={data}
                      actions={[
                        {
                          label: 'Send Reminders',
                          action: () => {
                            setSelectedMedicine(data);
                            setOpenSendReminder(true);
                          },
                          icon: <NotificationsActiveOutlinedIcon />,
                          hideCallback: (data) => !!data.with_reminders,
                        },
                        {
                          label: 'Cancel Reminders',
                          action: () => {
                            setSelectedMedicine(data);
                            setOpenConfirmCancelation(true);
                          },
                          icon: <NotInterestedIcon />,
                          hideCallback: (data) => !data.with_reminders,
                        },
                        {
                          label: 'Update',
                          action: () => handleUpdateAction(data),
                          icon: <EditOutlinedIcon />,
                        },
                        {
                          label: 'Delete',
                          action: () => handleDeleteAction(data),
                          icon: <DeleteOutlineIcon sx={{ color: 'red' }} />,
                          color: 'error',
                        },
                      ]}
                    />
                  </Box>
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap="20px">
                    <PatientField label="Generic Name" value={data.generic_name} />
                    <PatientField label="Brand Name" value={data.brand_name} />
                    <PatientField label="Dosage" value={data.dosage} />
                    <PatientField label="Quantity" value={data.quantity} />
                    <PatientField
                      label="Instructions"
                      value={generateInstruction(data)}
                      sx={{ gridColumn: 'span 2' }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>

      <Box display="none">
        <PrintablePrescription
          ref={printablePrescriptionRef}
          prescription={{ schedule: prescription.schedule, created_at: new Date() }}
          medicines={lineItems ?? []}
          patient={patient}
          doctor={doctor}
        />
      </Box>

      <CustomModal
        header={selectedMedicine ? 'Update Instruction' : 'Add Medicine'}
        open={openForm}
        setOpen={setOpenForm}
      >
        <PrescriptionLineItemForm
          prescriptionLineItem={selectedMedicine}
          callbackAfterSubmit={() => {
            fetchData();
            setOpenForm(false);
            setSelectedMedicine(undefined);
          }}
          prescriptionId={prescription.id}
        />
      </CustomModal>

      <CustomModal header="Send Reminder" open={openSendReminder} setOpen={setOpenSendReminder}>
        <SendReminders
          prescription={selectedMedicine}
          patientDetails={patient}
          callbackAfterSubmit={() => {
            enqueueSnackbar('Successfully set reminder!', { variant: 'success' });
            setOpenSendReminder(false);
            fetchData();
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        title="Medication Reminder"
        content="Are you sure you want to cancel the reminder?"
        open={openConfirmCancelation}
        setOpen={setOpenConfirmCancelation}
        onConfirm={() => {
          if (selectedMedicine)
            cancelMedicationReminder(selectedMedicine.id).then((response) => {
              enqueueSnackbar('Successfully cancelled reminders!', { variant: 'success' });
              fetchData();
            });
        }}
      />

      <SmsScheduleModal
        open={openSmsSchedules}
        setOpen={setOpenSmsSchedules}
        programCode="appointments"
        showEditFrequency
        patientID={patient.patient_id}
        fromPatientManagement
        prescriptionID={prescription.id}
      />

      {selectedMedicine && (
        <ConfirmationDialog
          onConfirm={() => handleDeleteConfirm(selectedMedicine)}
          open={deleteModal}
          subContent="Are you sure you want to delete this medicine"
          setOpen={setDeleteModal}
        />
      )}
    </>
  );
};

export default PrescriptionContainer;
