import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactNode } from 'react';
import { tokens } from 'theme/theme';

type Props = {
  label: string;
  value?: string | number | ReactNode;
  sx?: SxProps<Theme>;
  valueIsComponent?: boolean;
  fieldFontSize?: string;
};

const PatientField: React.FC<Props> = ({ label, value, sx, valueIsComponent, fieldFontSize }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1px',
        flexDirection: 'column',
        ...sx,
        '@media screen and (max-width: 768px)': {
          gridColumn: 'span 2',
        },
      }}
    >
      <Typography
        color={!isMobilePhone ? colors.primary : colors.grey_text}
        fontSize={isMobilePhone ? '12px' : fieldFontSize ?? '12px'}
      >
        {label.includes(':') ? label : `${label}: `}
      </Typography>
      {valueIsComponent ? (
        value
      ) : (
        <Typography fontSize={isMobilePhone ? '12px' : fieldFontSize ?? '13px'}>{value ?? '-'}</Typography>
      )}
    </Box>
  );
};

export default PatientField;
