import { ApiQuery } from 'core/model/interface';
import { Doctors } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'doctors';

export const getDoctor = (id: number) => {
  return axios.get(`${baseURL}/${path}/one/${id}`);
};

export const getDoctors = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, { params: query });
};

export const getDoctorClinics = (id: number | null) => {
  return axios.get(`${baseURL}/${path}/clinics/${id}`);
};

export const getAllServiceProviders = () => {
  return axios.get(`${baseURL}/${path}/all`);
};

export const createDoctor = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateDoctor = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteDoctor = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getDoctorsAssigned = (facilityId: number, query: ApiQuery & { include_facilities?: boolean }) => {
  return axios.get(`${baseURL}/doctors/assigned/${facilityId}`, { params: query });
};
export const getDoctorsNotAssigned = (clinic_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-assigned/${clinic_id}`, { params: query });
};

export const removeDoctorFromClinic = (clinic_id: number, doctors: Doctors) => {
  return axios.delete(`${baseURL}/${path}/clinic/${clinic_id}`, { data: doctors });
};

export const assignDoctorToClinic = (clinic_id: number, doctors: any) => {
  return axios.post(`${baseURL}/${path}/clinic/${clinic_id}`, doctors);
};

export const updateDoctorSchedules = (clinic_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/schedules/${clinic_id}/${id}`, data);
};
