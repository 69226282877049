import { Box, Divider, Typography, useTheme } from '@mui/material';
import { formatCurrency, formatNumber, getDiscountAmount } from 'core/utils';

import { DISCOUNT_TYPE } from 'core/model/interface';
import { SIZES } from 'theme/constants';
import styled from '@emotion/styled';
import { tokens } from 'theme/theme';

type Props = {
  orders: any;
  totalAmount: number;
};

const OrderDetails = (props: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <InvoiceSubContainer sx={{ backgroundColor: colors.light_blue_background }}>
      <Title>Order Details</Title>
      <Box display="flex" flexDirection="column" gap={SIZES.paddingS}>
        {props.orders.map((order: any, index: number) => (
          <Box key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SubTitleContent flex="1">{order.item_name}</SubTitleContent>
            <Box display="flex" alignItems="center" gap="5px">
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <OrderPrice>{formatCurrency(order.original_price)}</OrderPrice>

                <Box>
                  {(order.discount || order.sc_pwd_discounted) && (
                    <Box display="flex" alignItems="center" gap="5px">
                      <Typography color="gray" fontWeight="500" fontSize="13px">
                        {getDiscountAmount(order, true)}
                      </Typography>
                      {order.discount?.discount_type === DISCOUNT_TYPE.PERCENTAGE && (
                        <Typography
                          fontWeight="500"
                          fontSize="13px"
                          sx={{
                            backgroundColor: colors.light_blue_background_2,
                            paddingInline: SIZES.paddingS,
                            borderRadius: '10px',
                          }}
                        >
                          {order.discount.percentage}% OFF
                        </Typography>
                      )}
                      {!!order.sc_pwd_discounted && (
                        <Typography
                          fontWeight="500"
                          fontSize="13px"
                          sx={{
                            backgroundColor: colors.light_blue_background_2,
                            paddingInline: SIZES.paddingS,
                            borderRadius: '10px',
                          }}
                        >
                          20% OFF - SC/PWD
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <SubTitleContent>× {order.quantity}</SubTitleContent>
            </Box>
          </Box>
        ))}
      </Box>

      <Divider />
      <OrderContainer>
        <Title>Total Amount</Title>
        <TotalAmount> Php {formatNumber(props.totalAmount)}</TotalAmount>
      </OrderContainer>
    </InvoiceSubContainer>
  );
};

export default OrderDetails;

const InvoiceSubContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  margin-bottom: 30px;
`;

const Title = styled(Typography)`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SubTitleContent = styled(Typography)`
  font-size: 13px;
`;

const OrderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
`;

const OrderPrice = styled(Typography)`
  font-size: 14px;
  color: #239bd7;
  font-weight: 600;
`;

const TotalAmount = styled(Typography)`
  font-size: 18px;
  color: #239bd7;
  font-weight: bold;
`;
