import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getServiceById, getServicesAssigned } from 'company/api/services';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';
import { getServicesNotAdded } from 'company/api/service-groups';
interface ServiceSearchDropdownProps extends TemplateDropdownProps {
  useGetNotAddedServiceAPI?: boolean;
  groupId?: number;
}

const ServiceSearchDropdown: React.FC<ServiceSearchDropdownProps> = (props) => {
  const { facility } = useContext(FacilityContext);
  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        entity={'Service'}
        getData={(query: ApiQuery) => {
          if (props.useGetNotAddedServiceAPI && props.groupId) return getServicesNotAdded(facility.id, props.groupId);
          else {
            return getServicesAssigned(facility.id, query);
          }
        }}
        getById={(id: number) => getServiceById(id)}
        processOption={(record) => ({ key: record.service_name, value: record.id, data: record })}
        processNewRecord={(record) => record.service}
      />
    </WaitForFacility>
  );
};

export default ServiceSearchDropdown;
