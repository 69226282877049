/* eslint-disable react-hooks/exhaustive-deps */

import 'core/styles/RichTextEditor.css';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deleteClinicService, getServiceById, getServices, getServicesAssigned } from 'company/api/services';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ClinicsTable from '../ClinicsTable';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientNoteTemplatesTable } from 'company/entities/tables';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RichTextEditor from 'core/components/RichTextEditor';
import { SIZES } from 'theme/constants';
import ServiceDetails from './components/ServiceDetails';
import { ServiceForm } from 'company/entities/forms';
import ServiceGroups from './components/ServiceGroups';
import ServiceNoteModal from './ServiceNoteModal';
import { deleteServiceNote } from 'company/api/service-notes';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export interface ServiceResponse {
  assigned_facilities: string;
  assigned_facilities_ids: string;
  banner_image: string;
  buffer_time_value: 5;
  clinic_price: string;
  company_id: number;
  tax: string;
  description: null | string;
  duration: number;
  full_name: string;
  full_name_with_title: string;
  id: number;
  lead_time_value: number;
  price: string;
  provider_id: number;
  service_name: string;
  slots: number;
  sms_name: string;
}

type ServicesProps = {
  selectedTab: number;
  facilities: any[];
};

const Services: React.FC<ServicesProps> = ({ selectedTab, facilities }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const drawerRefModal: any = useRef();
  const [selectedService, setSelectedService] = useState<number>();
  const [rawService, setRawService] = useState<ServiceResponse>();
  const [toUpdateService, setToUpdateService] = useState<ServiceResponse>();

  const [openAddService, setOpenAddService] = useState(false);
  const [openDeleteService, setOpenDeleteService] = useState(false);
  const [openDeleteNote, setOpenDeleteNote] = useState(false);
  const [openLinkedNotesModal, setOpenLinkedNotesModal] = useState(false);

  const [addedService, setAddedService] = useState<number>();
  const [updatedService, setUpdatedService] = useState<ServiceResponse>();
  const [deletedService, setDeletedService] = useState<number>();
  const [noteToDelete, setNoteToDelete] = useState<any>();

  const [openActionModal, setOpenActionModal] = useState(false);

  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();

  const [updatedClinics, setUpdatedClinics] = useState<number>(0);

  const [openServiceGroups, setOpenServiceGroups] = useState<boolean>(false);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const getData = (query: ApiQuery) => {
    if (facility) return getServicesAssigned(facility.id, query);
    return getServices(query); //check
  };

  const handleDelete = () => {
    if (selectedService) {
      deleteClinicService(selectedService).then((response: any) => {
        const currentOrders = JSON.parse(localStorage.getItem('orders') || '[]');
        const updatedOrders = currentOrders.filter((order: any) => order.item_id !== selectedService);
        localStorage.setItem('orders', JSON.stringify(updatedOrders));

        // localStorage.removeItem('orders');

        setDeletedService(parseInt(response.data.service));
        setOpenDeleteService(false);
      });
    }
  };

  const handleDeleteNote = () => {
    noteToDelete && removeServiceNote(noteToDelete.company_service_notes_id);
  };

  const removeServiceNote = async (note: number) => {
    try {
      await deleteServiceNote(note);
      enqueueSnackbar(`Note successfully removed!`, { variant: 'success' });

      drawerRefModal.current.refreshTable();
    } catch {
      enqueueSnackbar(`Error occured! Unable to remove note.`, { variant: 'error' });
    }
  };

  const displayItem = (item: ServiceResponse) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedService && !isMobilePhone ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.service_name}
          </Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (!selectedService) return;
    getServiceById(selectedService).then((response) => {
      // setAddedService(response.data.id);
      setRawService(response.data);
    });
  }, [selectedService, updatedClinics]);

  if (openServiceGroups) return <ServiceGroups setOpenServiceGroups={setOpenServiceGroups} />;
  return (
    <>
      <InfiniteScrollContainer
        urlKeyId="sid"
        title="List of Services"
        getData={getData}
        getSingleData={getServiceById}
        renderItem={displayItem}
        selected={selectedService}
        setSelected={setSelectedService}
        addedNewItem={addedService}
        updatedItem={updatedService}
        deleteId={deletedService}
        containerHeight="calc(100vh - 170px)"
        headerComponents={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '100%' }}>
            <RegularButton
              startIcon={<PeopleAltOutlinedIcon />}
              variant="outlined"
              label="Service Groups"
              onClick={() => setOpenServiceGroups(true)}
              styles={{ width: '100%' }}
            />
            <RegularButton
              startIcon={<AddIcon />}
              variant="outlined"
              label="Add Service"
              onClick={() => setOpenAddService(true)}
              styles={{ width: '100%' }}
            />
          </Box>
        }
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {rawService?.service_name}
          </Typography>
        )}
        actions={
          !isMobilePhone
            ? [
                {
                  icon: <DriveFileRenameOutlineIcon />,
                  label: 'Update',
                  action: (data) => {
                    setOpenAddService(true);
                    setToUpdateService(rawService);
                  },
                },
                {
                  icon: <DeleteOutlineIcon />,
                  label: 'Delete',
                  color: 'error',
                  action: () => {
                    setOpenDeleteService(true);
                  },
                },
              ]
            : undefined
        }
      >
        {!isMobilePhone ? (
          <ServiceDetails services={rawService} />
        ) : (
          <Box>
            {rawService && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Typography sx={{ fontSize: '1.25rem', fontWeight: '700', color: colors.accent }}>
                    {rawService.service_name}
                  </Typography>
                  <MoreHorizIcon
                    sx={{ color: colors.primary, fontSize: '2rem' }}
                    onClick={() => {
                      setOpenActionModal(true);
                      setToUpdateService(rawService);
                    }}
                  />
                </Box>
                <Typography sx={{ fontSize: '0.825rem', fontWeight: '600' }}>Service Details</Typography>
                <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
                  <PatientField label="Service Name" value={rawService.service_name} />
                  <PatientField label="SMS Name" value={rawService.sms_name} />
                  <PatientField label="Clinic" value={rawService.assigned_facilities ?? ''} />
                  <PatientField label="Tax" value={rawService.tax ?? ''} />
                  <PatientField
                    label="Description"
                    valueIsComponent
                    value={
                      <Box>
                        <RichTextEditor content={rawService.description ?? ''} readOnly />
                      </Box>
                    }
                    sx={{ gridColumn: 'span 2' }}
                  />
                </Box>
              </>
            )}
            <CustomBottomPopover title="Actions" open={openActionModal} setOpen={setOpenActionModal}>
              <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <RegularButton
                  onClick={() => setOpenAddService(true)}
                  label="Update"
                  startIcon={<EditOutlinedIcon />}
                  variant="outlined"
                  fullWidth
                  styles={{ border: 'none', backgroundColor: colors.light_blue_background }}
                />
                <RegularButton
                  onClick={() => {
                    setOpenDeleteService(true);
                  }}
                  label="Delete"
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  variant="outlined"
                  fullWidth
                  styles={{ color: colors.redAccent, backgroundColor: colors.light_red_background_2, border: 'none' }}
                />
              </Box>
            </CustomBottomPopover>
          </Box>
        )}
        <Box pt={SIZES.paddingL}>
          <ClinicsTable source="Services" selectedService={rawService} setRefreshExternalDetails={setUpdatedClinics} />
          <PatientNoteTemplatesTable
            tableRef={drawerRefModal}
            serviceId={selectedService}
            hideAddUpdateDeleteAction
            tableComponent={{
              headerComponent: (
                <Box>
                  <Typography variant="h5" fontWeight={'bold'}>
                    Linked Notes
                  </Typography>
                </Box>
              ),
              filterComponent: !isMobilePhone ? (
                <Typography>
                  Appointments with this service will automatically generate notes from the linked notes.
                </Typography>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                  <Typography>
                    Appointments with this service will automatically generate notes from the linked notes.
                  </Typography>
                  <RegularButton
                    size="medium"
                    startIcon={<AddIcon />}
                    label="Add Note"
                    onClick={() => {
                      setOpenLinkedNotesModal(true);
                    }}
                    fullWidth
                  />
                </Box>
              ),
              afterSearchbarComponent: !isMobilePhone ? (
                <RegularButton
                  styles={{ width: '150px' }}
                  size="medium"
                  startIcon={<AddIcon />}
                  label="Add Note"
                  onClick={() => {
                    setOpenLinkedNotesModal(true);
                  }}
                />
              ) : undefined,
              rowHeight: 45,
            }}
            tableAction={{
              rowActions: [
                {
                  style: { flex: 2 },
                  label: 'Remove',
                  action: (data) => {
                    setNoteToDelete(data);
                    setOpenDeleteNote(true);
                  },
                  icon: <DeleteOutlineIcon />,
                  color: 'error',
                },
              ],
            }}
          />
          <ServiceNoteModal
            drawerRefModal={drawerRefModal}
            open={openLinkedNotesModal}
            setOpen={setOpenLinkedNotesModal}
            facility={facility}
            serviceId={selectedService as number}
          />
        </Box>
      </InfiniteScrollContainer>

      <CustomModal
        header={toUpdateService ? 'Update Service' : 'Add Service'}
        open={openAddService}
        setOpen={setOpenAddService}
        onClose={() => setToUpdateService(undefined)}
      >
        <ServiceForm
          service={toUpdateService}
          callbackAfterSubmit={(data: any) => {
            setOpenAddService(false);
            setToUpdateService(undefined);
            localStorage.removeItem('orders');

            if (toUpdateService) {
              setUpdatedService(data.service);
              setRawService(data.service);
            } else {
              setAddedService(data.service.id);
            }
          }}
        />
      </CustomModal>

      {rawService && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + rawService.service_name + '?'}
          open={openDeleteService}
          setOpen={setOpenDeleteService}
          onConfirm={handleDelete}
          bottomDrawerDialog={isMobilePhone}
        />
      )}
      {noteToDelete && (
        <ConfirmationDialog
          bottomDrawerDialog={isMobilePhone}
          content={'Are you sure you want to delete ' + noteToDelete.header + '?'}
          open={openDeleteNote}
          setOpen={setOpenDeleteNote}
          onConfirm={handleDeleteNote}
        />
      )}
    </>
  );
};

export default Services;
