import { Box } from '@mui/material';
import ProductVariantTable from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantTable';
import ReportTemplate from 'company/screens/Templates/ReportTemplate';

const Sandbox = () => {
  return (
    <Box>
      <ReportTemplate />
    </Box>
  );
};

export default Sandbox;
