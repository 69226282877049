import { Box, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext } from 'react';
import { calculateAge, formatArray, formatDate, formatDateTime } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { ContainerColumn } from 'core/components/containers';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { PrescriptionLineItemModel } from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionLineItem/PrescriptionLineItemModel';
import { SIZES } from 'theme/constants';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';

type Props = {
  prescription: any;
  patient: PatientModel;
  medicines: PrescriptionLineItemModel[];
  doctor?: ServiceProviderModel;
};

type FacilityDetailsProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const PrintablePrescription = forwardRef(
  ({ prescription, patient, doctor, medicines }: Props, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const { facility } = useContext(FacilityContext);

    const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon }) => {
      if (label) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
            <Icon color="primary" />
            <Typography>{label}</Typography>
          </Box>
        );
      }
      return <></>;
    };

    return (
      <Box>
        <style>{styles}</style>
        <Box ref={ref}>
          <Box>
            {!!user && (
              <ContainerColumn sx={{ position: 'fixed' }}>
                <Box>
                  <Box alignSelf="flex-start" position="absolute" top="0">
                    <img
                      alt="cphi-logo"
                      width="80px"
                      height="80px"
                      id="logo"
                      src={process.env.PUBLIC_URL + `${user.company?.company_logo}`}
                    />
                  </Box>
                  <Box
                    width="75%"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap="4px"
                    margin="0 auto"
                  >
                    <Typography variant="h1" fontWeight="bold" textAlign="center" sx={{ color: colors.accent }}>
                      {facility.facility_name}
                    </Typography>

                    <FacilityDetails
                      Icon={CallOutlinedIcon}
                      label={formatArray([facility?.smart_mobile_number, facility?.globe_mobile_number], ' | ')}
                    />
                    <FacilityDetails Icon={EmailOutlinedIcon} label={facility.email} />
                    <FacilityDetails
                      Icon={LocationOnOutlinedIcon}
                      label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
                    />
                  </Box>
                  <Box alignSelf="flex-start" position="absolute" top="0" right="0">
                    <img
                      alt="rx-logo"
                      width="100px"
                      height="100px"
                      id="logo"
                      src={process.env.PUBLIC_URL + `/assets/rx-img.png`}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '5px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      m: '20px 0 20px',
                    }}
                  >
                    <Typography variant="h3" sx={{ fontWeight: '600' }}>
                      Prescription
                    </Typography>
                    <Typography variant="body2">{formatDate(prescription.created_at)}</Typography>
                  </Box>
                </Box>
              </ContainerColumn>
            )}
          </Box>
          <Box paddingTop="220px" marginBottom="-220px">
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <PersonPinOutlinedIcon color="primary" />
                <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                  Patient Information
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={SIZES.paddingS} mt={SIZES.padding}>
                <PatientField label="Full Name" value={patient.full_name} />
                <PatientField label="Sex" value={patient.sex} />
                <PatientField label="Birthday" value={formatDate(patient.birthday)} />
                <PatientField label="Age" value={calculateAge(patient.birthday)} />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: SIZES.padding, mt: SIZES.paddingL }}>
              <MedicationLiquidOutlinedIcon color="primary" />
              <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                Medication
              </Typography>
            </Box>
          </Box>
          <Box>
            <ContainerColumn gap={SIZES.paddingL}>
              <Box>
                <table>
                  <thead>
                    <tr>
                      <td width="5%">
                        <Typography fontWeight="600">#</Typography>
                      </td>
                      <td width="25%">
                        <Typography fontWeight="600">Generic Name</Typography>
                      </td>
                      <td width="25%">
                        <Typography fontWeight="600">Brand Name</Typography>
                      </td>
                      <td width="15%">
                        <Typography fontWeight="600">Quantity</Typography>
                      </td>
                      <td width="30%">
                        <Typography fontWeight="600">Instructions</Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {medicines.map((medicine, index) => (
                      <tr>
                        <td>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.generic_name}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.brand_name}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.quantity}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.instruction}</Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5}>
                        <Box width="100%">
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-end',
                              padding: '60px 30px',
                            }}
                          >
                            <Box>
                              <Typography sx={{ color: colors.accent, fontWeight: '600' }}>
                                {doctor?.full_name_with_title ?? doctor?.full_name}
                              </Typography>
                              <Typography>License No. {doctor?.license_number}</Typography>
                              <Typography>PTR No. {doctor?.ptr_number}</Typography>
                            </Box>
                          </Box>

                          <Box position="fixed" bottom="0">
                            <Box width="100%" textAlign="center">
                              <Typography sx={{ fontSize: '10px', fontWeight: '400' }}>
                                <strong style={{ color: '#45464E' }}>Disclaimer</strong>: Any unauthorized review, use,
                                tampering, disclosure, or distribution of this prescription is strictly prohibited.
                              </Typography>
                            </Box>

                            <hr
                              style={{
                                border: 'none',
                                borderTop: '0.5px solid #0192D5',
                                width: '100%',
                                transform: 'scaleY(0.5)',
                              }}
                            />

                            <Box width="100%">
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                <img
                                  alt="cphi-logo"
                                  width="35px"
                                  height="35px"
                                  id="logo"
                                  src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                                />

                                <Box sx={{ textAlign: 'left', gap: '4px' }}>
                                  <Typography sx={{ fontSize: '8px', color: colors.accent }}>
                                    Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Box>
            </ContainerColumn>
          </Box>
        </Box>
      </Box>
    );
  }
);

const styles = `
  @page {
    size: A4;
    margin: 1cm 2cm 2cm;
  }
    
  table {
    border-collapse: collapse;
    padding: 10px;
    width: 100%;
  }

  thead tr td {
    padding-top: 220px;
  }

  thead td, tbody td {
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
  }
`;

export default PrintablePrescription;
