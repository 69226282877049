import {
  INVOICE_ITEM_ENTITY_NAME,
  InvoiceItemModel,
  invoiceItemFields,
  invoiceItemTableColumns,
} from './InvoiceItemModel';
import { deleteInvoiceItem, getInvoiceItems } from 'company/api/invoice-items';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import TemplateTable from '../../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { tokens } from 'theme/theme';
import { formatCurrency } from 'core/utils';

type Props = {
  visibleColumns?: string[];
  hideActions?: boolean;
  tableRef?: any;
  useColoredTable?: boolean;
  invoiceId: number;
  onGetData?: (data: any) => void;
};

const InvoiceItemTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, hideActions, tableRef, useColoredTable, invoiceId, onGetData } = props;

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<InvoiceItemModel>();
  const isMobile = useMediaQuery('(max-width:768px)');
  const getData = async (query: ApiQuery) => {
    return getInvoiceItems(facility.id, 'all', invoiceId, { ...query, length: 1000 });
  };

  const [invoiceItemsDetails, setInvoiceItemsDetails] = useState<any>(null);

  useEffect(() => {
    async function getData(query: ApiQuery) {
      try {
        if (!invoiceId) return;
        const res = await getInvoiceItems(facility.id, 'all', invoiceId, { ...query, length: 1000 });
        setInvoiceItemsDetails(res.data.data);
      } catch (err) {
        console.error(err);
      }
    }
    getData({});
  }, [invoiceId]);

  const handleDelete = (data: any) => {
    return deleteInvoiceItem(facility!.id, data).then((res) => {
      mainRef.current?.refreshTable();
    });
  };

  // useEffect(() => {
  //   mainRef.current?.refreshTable();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invoiceId]);

  const totalAmount =
    invoiceItemsDetails &&
    invoiceItemsDetails.reduce((total: any, item: any) => {
      return total + parseFloat(item.total_price);
    }, 0);

  if (isMobile && invoiceItemsDetails)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          mt: '10px',
        }}
      >
        {invoiceItemsDetails.map((invoiceItem: any) => {
          const totalAmountWithoutDiscount = invoiceItem.quantity * parseFloat(invoiceItem.original_price);

          const totalDiscount =
            invoiceItem.discount_type === 'percentage'
              ? totalAmountWithoutDiscount * (invoiceItem.discount_percentage / 100)
              : invoiceItem.discount_type === 'new_unit_price'
              ? totalAmountWithoutDiscount - invoiceItem.unit_price * invoiceItem.quantity
              : 0;

          // Calculate total discounted amount
          const totalDiscountedAmount = totalAmountWithoutDiscount - totalDiscount;

          return (
            <Box
              key={invoiceItem.id}
              sx={{
                backgroundColor: colors.light_blue_background,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px 20px',
                borderRadius: '10px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{invoiceItem.item_name}</Typography>
                <Typography>
                  {invoiceItem.original_price} x {invoiceItem.quantity}
                </Typography>
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  {invoiceItem.discount_type && invoiceItem.discount_type === 'percentage' && (
                    <Typography
                      sx={{ padding: '0 15px', backgroundColor: colors.textBackground, borderRadius: '15px' }}
                    >
                      {`- ${invoiceItem.discount_percentage}%`}
                    </Typography>
                  )}
                  {invoiceItem.discount_type && <Typography>{`- ${formatCurrency(totalDiscount)}`}</Typography>}
                </Box>
              </Box>

              <Typography sx={{ fontSize: '14px', fontWeight: '600', color: colors.primary }}>
                {formatCurrency(invoiceItem.total_price)}
              </Typography>
            </Box>
          );
        })}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: '10px' }}>
          <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>Total Amount</Typography>
          <Typography sx={{ fontSize: '1rem', fontWeight: '600', color: colors.primary }}>
            {formatCurrency(totalAmount)}
          </Typography>
        </Box>
      </Box>
    );
  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        hideAddButton={true}
        hideDeleteAction={hideActions}
        hideUpdateAction={true}
        useColoredTable={useColoredTable}
        ref={mainRef}
        entityName={INVOICE_ITEM_ENTITY_NAME}
        fields={invoiceItemFields}
        visibleColumns={visibleColumns ?? invoiceItemTableColumns}
        rowId="id"
        getData={getData}
        onGetData={onGetData}
        handleDelete={handleDelete}
        hideSearch
        hidePagination
        tableAction={{
          hideRowActionCallback: (data) => data.status === 'paid',
        }}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </WaitForFacility>
  );
});

export default InvoiceItemTable;
