import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'public';

export const getPrograms = () => {
  return axios.get(`${baseURL}/${path}/public-programs`);
};

export const onboardNewClientInSignUp = (data: any) => {
  return axios.post(`${baseURL}/${path}/public-onboard-new-client`, data);
};

export const requestDemo = (data: any) => {
  return axios.post(`${baseURL}/${path}/public-request-demo`, data);
};