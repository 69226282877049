import { Badge, Box, Popover, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CustomCheckbox from './CustomCheckbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import RegularButton from './buttons/RegularButton';
import { tokens } from 'theme/theme';

interface Props {
  optionList: CheckboxOptionProps[];
  buttonLabel?: string;
  customIcon?: React.ReactNode;
  setSelected?: Dispatch<SetStateAction<string[]>>;
  selected?: string[];
  filterLabel?: string;
  selectAllOption?: boolean;
  span?: number;
  noOptionContent?: ReactNode;
}

export type CheckboxOptionProps = {
  label: string;
  id: string;
  data?: any;
  isHeader?: boolean;
};

const CustomFilter: React.FC<Props> = ({
  optionList,
  buttonLabel = 'Filters',
  customIcon,
  setSelected,
  selected,
  filterLabel,
  selectAllOption = false,
  span = 1,
  noOptionContent,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openFilterPopover = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelected) {
      if (event.target.checked) {
        setSelected((prev) => [...prev, event.target.name]);
      } else {
        setSelected((prev) => prev.filter((item) => item !== event.target.name));
      }
    }
  };

  const handleClearSelection = () => {
    if (setSelected) {
      setSelected([]);
    }
  };

  const handleSelectAll = () => {
    if (setSelected) {
      const allOptionsSelected = optionList.map((option) => option.id);
      setSelected(allOptionsSelected);
    }
  };

  const gridColumn = useMemo(() => 'repeat(' + (isMobile ? 1 : span) + ', minmax(0, 1fr))', [span, isMobile]);

  return (
    <>
      <Badge
        badgeContent={selected?.length ?? undefined}
        color="primary"
        sx={{ '& .MuiBadge-badge': { top: 3, right: 5 } }}
      >
        <RegularButton
          aria-describedby={'filter-popover'}
          startIcon={customIcon ?? <FilterListIcon />}
          variant="outlined"
          color="primary"
          onClick={handleClick}
          label={buttonLabel}
          styles={{
            '@media screen and (max-width: 768px)': { width: '100%', height: '40px', padding: '20px' },
          }}
        />
      </Badge>
      
      <Popover
        id={'filter-popover'}
        open={openFilterPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiSvgIcon-root': { color: colors.primary },
          '& .MuiPaper-root': { borderRadius: '15px', width: isMobile ? '90%' : 'auto', maxWidth: '90vw' },
        }}
      >
        <Box
          padding={'15px 10px'}
          display={'flex'}
          alignContent={'left'}
          flexDirection={'column'}
          gap={'5px'}
          sx={{ display: 'flex' }}
        >
          {!optionList.length ? (
            noOptionContent
          ) : (
            <>
              <Box display={'flex'} justifyContent={'space-between'} alignItems="center" sx={{ paddingInline: '5px' }}>
                <Typography flex={1} fontWeight="600">
                  {filterLabel ?? buttonLabel}
                </Typography>

                {selectAllOption && (
                  <RegularButton
                    styles={{ flex: 1 }}
                    variant="text"
                    label="Select All"
                    onClick={handleSelectAll}
                    size="small"
                  />
                )}
              </Box>
              <Box
                display="grid"
                gridTemplateColumns={gridColumn}
                columnGap="7px"
                rowGap="7px"
                sx={{ 
                  maxHeight: isMobile ? '50vh' : '60vh', 
                  overflow: 'auto', 
                  paddingInline: '5px',
                  '@media screen and (max-width: 768px)': {
                    gridTemplateColumns: '1fr',
                  }
                }}
              >
                {optionList.map((option) => {
                  return option.isHeader ? (
                    <Typography
                      key={option.id}
                      sx={{ 
                        gridColumn: isMobile ? '1' : 'span ' + span, 
                        maxWidth: '100%', 
                        textWrap: 'wrap', 
                        wordBreak: 'break-word' 
                      }}
                      fontWeight={500}
                    >
                      {option.label}
                    </Typography>
                  ) : (
                    <Box
                      key={option.id}
                      display="flex"
                      alignItems="left"
                      flexDirection="column"
                      sx={{ maxWidth: '100%', textWrap: 'wrap', wordBreak: 'break-word' }}
                    >
                      <CustomCheckbox
                        style={{
                          color: colors.accent,
                          padding: '5px 12px',
                          backgroundColor: 'rgba(21, 129, 196, 0.04)',
                          borderRadius: '10px',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          width: '100%',
                        }}
                        label={option.label}
                        handleChange={handleFilterChange}
                        value={selected?.includes(option.id)}
                        fieldName={option.id}
                      />
                    </Box>
                  );
                })}
              </Box>

              {(selected || []).length > 0 && (
                <RegularButton
                  aria-describedby={'filter-popover'}
                  startIcon={<CloseOutlinedIcon style={{ fill: '#FFFFFF' }} />}
                  variant="contained"
                  color="primary"
                  onClick={handleClearSelection}
                  label="Clear"
                  size="small"
                  fullWidth
                />
              )}
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default CustomFilter;
