import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CalendarEvent, ViewProps } from '.';
import React, { useContext, useEffect, useState } from 'react';
import { calculatePositionedEvents, formatCalenderTime } from './utils';

import { EditCalendarOutlined } from '@mui/icons-material';
import { FacilityContext } from 'core/context/facility.context';
import { RegularButton } from 'core/components';
import { tokens } from 'theme/theme';

interface ScheduleHour {
  start: string;
  end: string;
}

interface ScheduleDay {
  day: string;
  week_day: string;
  hours: ScheduleHour[];
}

const eventHeight = 60;
export const calculateEventPosition = (event: any, startHour: number) => {
  const startDate = new Date(event.schedule);
  const startMinutes = startDate.getHours() * 60 + startDate.getMinutes();
  const top = ((startMinutes - startHour * 60) / 60) * eventHeight;
  const height = (event.height / 60) * eventHeight;
  return { top, height };
};

const WeekView: React.FC<ViewProps> = ({
  date,
  staff,
  events,
  // hours,
  // startHour,
  // endHour,
  facilitySchedule,
  setOpenServiceProviders,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const isMobile = useMediaQuery('(max-width:768px)');

  const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);

  const visibleDays = facilitySchedule
    ? (() => {
        const firstScheduledDayIndex = facilitySchedule.findIndex((day) => day && day.hours.length > 0);
        const lastScheduledDayIndex = facilitySchedule.reduceRight((acc, day, index) => {
          if (day && day.hours.length > 0 && acc === -1) {
            return index;
          }
          return acc;
        }, -1);

        if (firstScheduledDayIndex === -1 || lastScheduledDayIndex === -1) {
          return daysOfWeek;
        }

        return daysOfWeek.slice(firstScheduledDayIndex, lastScheduledDayIndex + 1);
      })()
    : daysOfWeek;

  const openDays = visibleDays.length;

  let earliestStartTime = 23;
  let latestEndTime = 0;

  facilitySchedule.forEach((day, index) => {
    if (day && day.hours && day.hours.length > 0) {
      day.hours.forEach((hour) => {
        const startHourString = hour.start.split(':')[0];
        const endHourString = hour.end.split(':')[0];
        const startPeriod = hour.start.split(' ')[1];
        const endPeriod = hour.end.split(' ')[1];

        let startHour = parseInt(startHourString);
        let endHour = parseInt(endHourString);

        if (startPeriod === 'PM' && startHour !== 12) {
          startHour += 12;
        } else if (startPeriod === 'AM' && startHour === 12) {
          startHour += 1;
        }

        if (endPeriod === 'PM' && endHour !== 12) {
          endHour += 13;
        } else if (endPeriod === 'AM' && endHour === 12) {
          endHour += 1;
        } else if (endPeriod === 'AM' && endHour <= 12) {
          endHour += 1;
        }
        earliestStartTime = Math.min(earliestStartTime, startHour);
        latestEndTime = Math.max(latestEndTime, endHour);
      });
    }
  });

  const hoursRange = Array.from({ length: latestEndTime - earliestStartTime }, (_, i) => (i + earliestStartTime) % 24);

  const calculateEventPosition = (event: any) => {
    const startDate = new Date(event.schedule);
    const startMinutes = startDate.getHours() * 60 + startDate.getMinutes();
    const top = ((startMinutes - earliestStartTime * 60) / 60) * 60;
    const height = (event.height / 60) * 60;
    return { top, height };
  };

  // useEffect(() => {
  //   const updateCurrentTimePosition = () => {
  //     const now = new Date();
  //     if (now.getHours() >= startHour && now.getHours() <= endHour) {
  //       const minutesSinceStartOfDay = (now.getHours() - startHour) * 60 + now.getMinutes();
  //       setCurrentTimePosition(minutesSinceStartOfDay);
  //     } else {
  //       setCurrentTimePosition(null);
  //     }
  //   };

  //   updateCurrentTimePosition();
  //   const intervalId = setInterval(updateCurrentTimePosition, 60000);

  //   return () => clearInterval(intervalId);
  // }, [startHour, endHour]);

  const getEventsForStaffAndDay = (staffId: number, facilityId: number, day: Date) => {
    return calculatePositionedEvents(
      events.filter((event) => {
        const scheduleStart = new Date(event.schedule);
        return (
          ((event.provider_id === staffId && event.facility_id === facilityId) || (!staffId && !event.provider_id)) &&
          scheduleStart.getFullYear() === day.getFullYear() &&
          scheduleStart.getMonth() === day.getMonth() &&
          scheduleStart.getDate() === day.getDate()
        );
      })
    );
  };

  return (
    <Box>
      <Box className="week-view" display="flex">
        {/* <Box position="absolute" top="110px"> */}
        <Box>
          <Box
            height={isMobile ? '132.5px' : '112px'}
            position="sticky"
            top={0}
            sx={{ backgroundColor: colors.secondary_background, borderBottom: '3px solid #ccc' }}
          />
          {/* CHANGED: Use hoursRange instead of hours */}
          {hoursRange.map((hour) => (
            <Box key={hour} className="time-slot" sx={{ height: `60px` }}>
              <Box className="time-label" textAlign="right" width="60px" paddingRight="10px">
                <Typography>{formatCalenderTime(hour)}</Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box width="100%">
          <Box display="flex" position="sticky" top={0} zIndex={11} sx={{ backgroundColor: colors.background }}>
            {visibleDays.map((day, dayIndex) => {
              const currentDay = new Date(startOfWeek);
              currentDay.setDate(startOfWeek.getDate() + daysOfWeek.indexOf(day));

              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={dayIndex}
                  sx={{ backgroundColor: colors.secondary_background }}
                  width={`${100 / openDays}%`}
                >
                  <Box flex={1} className="day-of-week">
                    <Typography fontWeight={600}>
                      {currentDay.toLocaleDateString('en-US', { weekday: 'short' })}
                      {', '}
                      {isMobile && <br />}
                      {currentDay.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    </Typography>
                  </Box>

                  <Box display="flex">
                    {staff.map((staffMember, staffIndex) => (
                      <Box
                        flex={1}
                        key={staffMember.assignment_id}
                        className="staff-column"
                        sx={{ borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined }}
                      >
                        <Box
                          className="staff-name"
                          sx={{ borderBottom: '3px solid ' + colors.primary, borderColor: staffMember.color }}
                        >
                          <Box sx={{ wordBreak: 'break-word' }}>
                            <Typography fontWeight={500}>
                              {staffMember.first_name}{' '}
                              {staffMember.last_name ? staffMember.last_name?.charAt(0) + '.' : ''}
                            </Typography>
                            {facility?.is_admin && <Typography fontSize="11px">{staffMember.facility_name}</Typography>}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box display="flex" position="relative">
            {!staff.length && <NoServiceProviderDisplay setOpenServiceProviders={setOpenServiceProviders} />}

            {visibleDays.map((day, dayIndex) => {
              const currentDay = new Date(startOfWeek);
              currentDay.setDate(startOfWeek.getDate() + daysOfWeek.indexOf(day));

              return (
                // CHANGE: Adjust width based on number of open days
                <Box display="flex" flexDirection="column" key={dayIndex} width={`${100 / openDays}%`}>
                  <Box display="flex">
                    {staff.map((staffMember, staffIndex) => (
                      <Box
                        flex={1}
                        key={staffMember.assignment_id}
                        className="staff-column"
                        sx={{ borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined }}
                      >
                        <Box position="relative">
                          <Box className="events" sx={{ position: 'relative', height: `${60 * hoursRange.length}px` }}>
                            {hoursRange.map((hour) => (
                              <Box key={hour} className="time-slot" sx={{ height: `60px` }} />
                            ))}
                            {getEventsForStaffAndDay(staffMember.doctor_id, staffMember.facility_id, currentDay).map(
                              (event) => {
                                const { top, height } = calculateEventPosition(event);
                                return (
                                  <Box key={event.id} sx={{ position: 'absolute', top: `${top}px`, width: '100%' }}>
                                    <CalendarEvent
                                      event={{ ...event, height }}
                                      staffColor={staffMember.color || null}
                                      staffCount={staff.length}
                                    />
                                  </Box>
                                );
                              }
                            )}
                          </Box>

                          {currentTimePosition !== null && currentDay.getDate() === new Date().getDate() && (
                            <Box
                              sx={{
                                top: `${currentTimePosition * (eventHeight / 60)}px`,
                                position: 'absolute',
                                height: '2px',
                                width: '100%',
                                backgroundColor: colors.redAccent,
                                zIndex: 2,
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface NoServiceProviderDisplayProps {
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoServiceProviderDisplay: React.FC<NoServiceProviderDisplayProps> = ({ setOpenServiceProviders }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        top: '100px',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 11,
        width: '100%',
        height: '100%',
        backgroundColor: colors.documentBackground,
        opacity: 0.85,
      }}
    >
      <Box
        sx={{
          width: '30%',
          height: '30%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '10%',
          gap: '10px',
        }}
      >
        <EditCalendarOutlined sx={{ color: colors.accent, fontSize: '36px' }} />
        <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
          To view the service provider's schedules, kindly select the service providers below or click the 'Select
          Service Provider' button found in the upper right part of the calendar.
        </Typography>
        <RegularButton label="View Service Provider" onClick={() => setOpenServiceProviders(true)} />
      </Box>
    </Box>
  );
};

export default WeekView;
