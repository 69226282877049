import TemplateTable from 'company/entities/Template/TemplateTable';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { forwardRef, useRef, useState } from 'react';
import {
  HA_ENTITY_NAME,
  healthAssessmentColumnTable,
  healthAssessmentFields,
  HealthAssessmentModel,
} from './HealthAssessmentModel';
import { ApiQuery } from 'core/model/interface';
import { getHealthAssessments } from 'company/api/health-assessments';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
};

const HealthAssessmentTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<HealthAssessmentModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const getData = (query: ApiQuery) => {
    return getHealthAssessments(query);
  };

  return (
    <TemplateTable
      useColoredTable
      ref={mainRef}
      entityName={HA_ENTITY_NAME}
      fields={healthAssessmentFields}
      getData={getData}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      visibleColumns={visibleColumns ?? healthAssessmentColumnTable}
    />
  );
});

export default HealthAssessmentTable;
