import { EntityFields, processFormField } from '../utils';
import { RESPONSE, showSnackbar } from 'constants/snackbar_message';
import { changeNullToBlank, tranformFormErrors } from 'core/utils';
import { useEffect, useState } from 'react';

import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';

type Props = {
  submitButtonId?: string;
  entityName: string;
  initialValues: any;
  fields: EntityFields[];
  visibleFields: string[];
  dropdownOptions?: any;

  entity?: any;
  schema?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  hideDefaultSnackbar?: boolean;
  submitButtonText?: string;
  formSubmitApiFunction: (data: any) => Promise<any>;
  callbackAfterSubmit?: (data?: any) => void;
  altSubmitComponent?: any;
  processInitialValues?: (setFieldData: React.Dispatch<any>, values: any) => void;
  customFields?: EntityFields[];
  useInitialValue?: boolean;
};

const TemplateForm = (props: Props) => {
  const {
    submitButtonId,
    entityName,
    initialValues,
    fields,
    visibleFields,
    dropdownOptions,
    entity,
    schema,
    readOnly,
    showSubmitAtTop,
    hideDefaultSnackbar,
    submitButtonText,
    formSubmitApiFunction,
    callbackAfterSubmit,
    altSubmitComponent,
    processInitialValues,
    customFields,
    useInitialValue,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (customFields) {
      setFormFields(processFormField([...fields, ...customFields], visibleFields, dropdownOptions));
    } else {
      setFormFields(processFormField(fields, visibleFields, dropdownOptions));
    }
  }, [fields, visibleFields, dropdownOptions, customFields]);

  const handleSubmitUpdate = async (data: any, formikHelpers: FormikHelpers<any>) => {
    // var assigned_facilities = data.facilities.map((facility: { value: { toString: () => any } }) =>
    //   facility.value.toString()
    // );
    // data.assigned_facilities = JSON.stringify(assigned_facilities);

    try {
      setLoading(true);
      const res = await formSubmitApiFunction(data);
      const action = entity ? 'updated' : 'created';
      if (!hideDefaultSnackbar) showSnackbar(RESPONSE.SUCCESS, enqueueSnackbar, { action, entityName });
      callbackAfterSubmit && callbackAfterSubmit(res.data);
    } catch (error: any) {
      console.error(error);
      if (error.response?.data.errors) {
        formikHelpers.setErrors(tranformFormErrors(error.response?.data.errors));
      }
      showSnackbar(RESPONSE.ERROR, enqueueSnackbar, {
        customMessage: error.response?.message ?? 'Error occured while submitting! Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomForm
      onSubmit={handleSubmitUpdate}
      fields={formFields}
      initialValues={entity && !useInitialValue ? changeNullToBlank(entity) : initialValues}
      schema={schema}
      loading={loading}
      readOnly={readOnly}
      showSubmitAtTop={showSubmitAtTop}
      submitButtonId={submitButtonId}
      submitButtonText={submitButtonText}
      altSubmitComponent={altSubmitComponent}
      processInitialValues={processInitialValues}
      submitButtonStyles={{ flex: isMobilePhone ? 2 : null }}
    />
  );
};

export default TemplateForm;
