import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close'; // Import the close icon
import { ContainerRow } from '../containers';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { RegularButton } from 'core/components';
import { tokens } from 'theme/theme';

type ConfirmationDialogProps = {
  content?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  open: boolean;
  setOpen: (openDialog: boolean) => void;
  onConfirm: (data?: any) => void;
  onCancel?: () => void;
  loadingConfirm?: boolean;
  title?: string;
  confirmButtonColor?: boolean;
  cancelButtonColor?: boolean;
  onClose?: () => void;
  data?: any;
  icon?: any;
  subContent?: string;
  confirmButtonIcon?: any;
  cancelButtonIcon?: any;
  bottomDrawerDialog?: boolean;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  content,
  setOpen,
  open,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  loadingConfirm,
  title = 'Confirmation',
  confirmButtonColor,
  cancelButtonColor,
  onClose,
  data,
  icon,
  subContent,
  confirmButtonIcon,
  cancelButtonIcon,
  bottomDrawerDialog = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    handleClose();
  };

  if (bottomDrawerDialog) {
    return (
      <CustomBottomPopover title="Confirmation" open={open} setOpen={setOpen}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '15px 0',
              textAlign: 'center',
            }}
          >
            <Typography sx={{ fontWeight: '400', fontSize: '1rem' }}>{content}</Typography>
          </Box>
          <ContainerRow sx={{ padding: '0 40px 30px' }}>
            <RegularButton
              startIcon={cancelButtonIcon}
              onClick={handleCancel}
              label={cancelButtonLabel ?? 'Cancel'}
              fullWidth
              variant="outlined"
            />
            <RegularButton
              startIcon={confirmButtonIcon}
              label={confirmButtonLabel ? confirmButtonLabel : 'Yes'}
              fullWidth
              onClick={() => {
                setOpen(false);
                onConfirm(data);
              }}
              loading={loadingConfirm}
            />
          </ContainerRow>
        </Box>
      </CustomBottomPopover>
    );
  }
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      disableEscapeKeyDown
      PaperProps={{ sx: { borderRadius: '15px' } }}
      maxWidth={'lg'}
    >
      <Box minWidth="400px" sx={{ '@media (max-width: 600px)': { minWidth: '200px' } }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box width="100%">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: '20px 30px',
                paddingBottom: '0',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: colors.accent,
                  fontSize: '20px',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {icon && icon}
                {title}
              </Typography>
              <IconButton edge="end" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <DialogContent sx={{ textAlign: 'center' }}>
              <Box p="5px 30px" width="100%" maxWidth="500px">
                <Typography variant="body1" fontSize={'16px'}>
                  {content}
                </Typography>
                <Typography variant="body1" fontSize={'13px'} sx={{ mt: '5px' }}>
                  {subContent}
                </Typography>
              </Box>
            </DialogContent>
          </Box>
        </Box>

        <ContainerRow sx={{ padding: '0 40px 30px' }}>
          <RegularButton
            startIcon={cancelButtonIcon}
            onClick={handleCancel}
            label={cancelButtonLabel ?? 'Cancel'}
            fullWidth
            variant="outlined"
          />
          <RegularButton
            startIcon={confirmButtonIcon}
            label={confirmButtonLabel ? confirmButtonLabel : 'Yes'}
            fullWidth
            onClick={() => {
              setOpen(false);
              onConfirm(data);
            }}
            loading={loadingConfirm}
          />
        </ContainerRow>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
