import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';
import { isDevMode } from 'core/components/HideOrShowComponent';

export const PRODUCT_VARIANT_ENTITY_NAME = 'Product Variant';
export interface ProductVariantModel {
  id: number;
  variant_name: any;
  variant_description: string;
  price: string;
  sku: string;
  product_name: string;
  quantity_in_stock: string;
  product_id?: number;
  exclusive_supplier?: number;
  exclusive_supplier_name?: string;
  made_to_order?: number;
  tax: 'non-vat' | 'vat';
  initial_quantity?: number;
  attributes?: ProductVariantAttributesModel[];
  attribute_ids?: string;
}

export interface ProductVariantAttributesModel {
  attribute_id: number;
  attribute_name: string;
  value: string;
  variant_id: number;
}

export const createProductVariantModelSchema = yup.object().shape({
  // product_id: yup.number().required('Product is required'),
  variant_price: yup.number().required('Price is required').typeError('Invalid Value'),
});

export const variantPrice = yup.object().shape({
  facility_inventory_price: yup.number().required('Price is required').typeError('Invalid Value'),
});

export const addQuantitySchema = yup.object().shape({
  transaction_date: yup.date().required('Transaction Date is required').typeError('Invalid Date'),
  supplier: yup.string().required('Supplier is required'),
  quantity_in_stock: yup
    .number()
    .required('Quantity is required.')
    .typeError('Invalid Value.')
    .min(1, 'Quantity must be greater than 0'),
  product_cost: yup.number().typeError('Invalid Value.'),
});

export const editQuantitySchema = yup.object().shape({
  quantity_in_stock: yup
    .number()
    .required('Quantity is required')
    .typeError('Invalid Value.')
    .min(1, 'Quantity must be greater than 0'),
  reason: yup.string().required('Purpose is required'),
});

export const transferSchema = yup.object().shape({
  transfer_quantity: yup
    .number()
    .required('Quantity is required')
    .typeError('Invalid Value.')
    .min(1, 'Quantity must be greater than 0'),
  dest_facility_id: yup.number().required('Clinic is required'),
});

export interface ProductVariantInput {
  sku: string;
  variant_description: string;
  variant_name?: string;
  variant_price: string;
  made_to_order: boolean;
  tax: string;
}
export const productVariantFields: EntityFields[] = [
  {
    fieldName: 'variant_name',
    displayName: 'Variant Name',
    span: 2,
    generateLink: (data) => `'/company/inventory/products/variant?id=${data.id}'`,
    linkPreviousLocation: '/company/inventory/products',
    // optional: true
  },
  { fieldName: 'variant_price', displayName: 'Price', type: 'currency' },
  { fieldName: 'variant_description', displayName: 'Description', multiline: true, rows: 3, span: 4, optional: true },
  { fieldName: 'sku', displayName: 'SKU', optional: true },
  { fieldName: 'product_id', displayName: 'Product', type: DROPDOWN_FIELD.PRODUCT },
  { fieldName: 'exclusive_supplier', displayName: 'Exclusive Supplier', type: DROPDOWN_FIELD.SUPPLIER, optional: true },
  { fieldName: 'exclusive_supplier_name', displayName: 'Exclusive Supplier' },
  { fieldName: 'made_to_order', displayName: 'Made-to-Order', type: 'checkbox' },
  {
    fieldName: 'tax',
    displayName: 'Tax',
    type: 'radiogroup',
    options: [
      { key: 'VAT', value: 'vat' },
      { key: 'Non-VAT', value: 'non-vat' },
    ],
    // hidden: !isDevMode(),
  },
  {
    fieldName: 'initial_quantity',
    displayName: 'Initial Quantity',
    type: 'number',
    hiddenBasedOnOtherField: (data: any) => data.made_to_order,
  },
];

export const productVariantVisibleColumns = ['variant_name', 'sku', 'exclusive_supplier_name'];
export const productVariantFormFields = [
  'variant_price',
  'sku',
  'exclusive_supplier',
  'variant_description',
  'made_to_order',
  'initial_quantity',
  'tax',
  'attributes',
];
export const productVariantFormFieldsExclusive = [
  'product_id',
  'variant_price',
  'sku',
  // 'exclusive_supplier',
  'variant_description',
  'made_to_order',
  'initial_quantity',
  'tax',
  'attributes',
];
export const productVariantUpdateFormFields = [
  'sku',
  'exclusive_supplier',
  'variant_description',
  'made_to_order',
  'tax',
  'attributes',
];
export const productVariantInitialValues = {
  variant_name: '',
  variant_price: '',
  variant_description: '',
  sku: '',
  made_to_order: false,
  tax: 'non-vat',
  initial_quantity: 0,
};

export const productVariantFormFieldsExclusiveWithProduct = [
  'product_id',
  'variant_price',
  'sku',
  'exclusive_supplier',
  'variant_description',
  'made_to_order',
  'initial_quantity',
  'tax',
  'attributes',
];
