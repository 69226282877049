import * as yup from 'yup';

import { Box, Tooltip, Typography } from '@mui/material';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';

export const APPOINTMENT_ENTITY_NAME = 'Appointment';
export const appointment_schema = yup.object().shape({
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  consultation_purpose: yup.string(),
  service_id: yup.array().min(1, 'Service is required.'),
  walk_in: yup.boolean(),
  patient_id: yup.array().min(1, 'At least one patient must be selected.'),
});

export const STATUS = {
  CANCELLED: 'cancelled',
  DONE: 'done',
  NO_SHOW: 'no-show',
  PENDING: 'pending',
  RESCHEDULED: 'rescheduled',
};

const renderStatusCell = (status: any) => {
  let icon: any;
  let tooltip = '';
  switch (status) {
    case STATUS.DONE:
      icon = <img height={30} src="/assets/AppointmentSchedule/Status.svg" alt="Attended" />;
      tooltip = 'Attended';
      break;
    case STATUS.PENDING:
      icon = <img height={30} src="/assets/AppointmentSchedule/Status2.svg" alt="Confirmed" />;
      tooltip = 'Confirmed';
      break;
    case STATUS.RESCHEDULED:
      icon = <img height={30} src="/assets/AppointmentSchedule/Status3.svg" alt="Rescheduled" />;
      tooltip = 'Rescheduled';
      break;
    case STATUS.CANCELLED:
      icon = <img height={30} src="/assets/AppointmentSchedule/Status4.svg" alt="Cancelled" />;
      tooltip = 'Cancelled';
      break;
    case STATUS.NO_SHOW:
      icon = <img height={30} src="/assets/AppointmentSchedule/Status5.svg" alt="No Show" />;
      tooltip = 'No Show';
      break;
  }

  return (
    <Tooltip title={tooltip} placement="right" arrow>
      {icon}
    </Tooltip>
  );
};

export interface CompanyAppointmentModel {
  id: number;
  company_id: number;
  service_id: number;
  facility_name: string;
  facility_id?: number;
  patient_id: number;
  schedule: string;
  attendance: 0 | 1;
  consultation_purpose: string;
  previous_consultation: string;
  lab_tests: string;
  type: string;
  status: string;
  appointment_notes: string;
  is_active: boolean;
  full_name: string;
  first_name: string;
  mobile_number: string;
  birthday: string;
  program_name: string;
  service_names: string;
  invoice_id: number;
  duration: number;
  no_appointment?: boolean;
  provider_id?: number;
  service_provider?: string;
  service_provider_with_title?: string;
  package_patient_id?: number;
}
export interface AppointmentInput {
  patient_id: any[];
  schedule: string;
  service_id: any[];
  provider_id: number | string;
  consultation_purpose: string;
}

export interface AppointmentFieldInput extends FieldInput {
  field_name?: keyof AppointmentInput;
}

export const AppointmentInitialValues: AppointmentInput = {
  patient_id: [],
  schedule: '',
  service_id: [],
  provider_id: '',
  consultation_purpose: '',
};
// const { facility } = useContext(FacilityContext);

// fieldName should be unique
export const appointmentFields: EntityFields[] = [
  {
    fieldName: 'id',
    displayName: 'ID',
  },
  {
    fieldName: 'full_name',
    displayName: 'Patient Name',
    generateLink: (data) => `/company/patient-management?patient_id=${data.patient_id}`,
  },
  {
    fieldName: 'service_id',
    displayName: 'Services',
    type: DROPDOWN_FIELD.SERVICE,
    multiple: true,
    onChange: (value, setFieldValue, setCustomDisplayName, setDropdownData) => {
      if (setFieldValue) {
        if (!value?.length) setFieldValue('provider_id', '');
        else setFieldValue('provider_id', value[0].data.provider_id);
      }
    },
  },
  {
    fieldName: 'provider_id',
    displayName: 'Service Provider',
    type: DROPDOWN_FIELD.SERVICE_PROVIDER,
    optional: true,
    hiddenBasedOnOtherField: (data: any) => {
      return data.service_id < 1;
    },
  },
  {
    fieldName: 'service_names',
    displayName: 'Services',
    flex: 1.5,
    renderCell: (params: any) =>
      params.stringOnly ? (
        params.value
      ) : (
        <Box display="flex" flexDirection="column">
          <Typography> {params.value} </Typography>
          {params.row.service_provider && (
            <Typography sx={{ fontSize: '12px', color: 'gray' }}>
              Assigned Staff: {params.row.service_provider_with_title ?? params.row.service_provider}
            </Typography>
          )}
        </Box>
      ),
  },
  {
    fieldName: 'status',
    displayName: 'Status',
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: (params) => <>{renderStatusCell(params.row.status)}</>,
  },
  {
    fieldName: 'sms_status',
    displayName: 'SMS Status',
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: (params) => (
      <Tooltip
        title={!params.row.sms_status || params.row.sms_status === 'Completed' ? 'Sent' : 'Pending'}
        placement="right"
        arrow
      >
        {!params.row.sms_status || params.row.sms_status === 'Completed' ? (
          <img height={30} src="/assets/AppointmentSchedule/Status6.svg" alt="Sent" />
        ) : (
          <img height={30} src="/assets/AppointmentSchedule/Status7.svg" alt="Not Sent" />
        )}
      </Tooltip>
    ),
  },
  {
    fieldName: 'walk_in',
    displayName: 'Walk In',
    type: 'checkbox',
    span: 4,
    onChange: (value, setFieldValue) => {
      if (setFieldValue) {
        if (value) setFieldValue('schedule', new Date());
        else setFieldValue('schedule', '');
      }
    },
  },
  { fieldName: 'patient_id', type: DROPDOWN_FIELD.PATIENT, span: 4, multiple: true },
  {
    fieldName: 'schedule',
    displayName: 'Appointment Schedule',
    type: 'datetime',
    generateLink: (data) => `/company/appointments/${data.id}`,
    minutesStep: 5,
    span: 2,
    onChange: (value: string, setFieldValue: any) => {
      setFieldValue(value, 'note');
    },
  },
  {
    fieldName: 'consultation_purpose',
    displayName: 'Purpose',
    type: 'text',
    multiline: true,
    rows: 3,
    span: 4,
    optional: true,
  },
  {
    fieldName: 'approval_code',
    displayName: 'LOA Approval Code',
    type: 'text',
    span: 2,
  },
  {
    fieldName: 'approved_by',
    displayName: 'LOA Approved By',
    type: 'text',
    span: 2,
    optional: true,
  },
];

// Table
export const appointmentTableColumns: string[] = [
  // 'patient_id',
  'schedule',
  'full_name',
  'service_names',
  'status',
  // 'sms_status',
  'invoice_number',
];

export const patientAppointmentTableColumns: string[] = ['schedule', 'service_names', 'status', 'invoice_number'];

// Forms
export const appointmentFormFields: string[] = [
  'walk_in',
  'patient_id',
  'schedule',
  'service_id',
  'provider_id',
  'consultation_purpose',
  'approval_code',
  'approved_by',
];
