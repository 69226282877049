import { Box, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext, useEffect, useState } from 'react';
import { calculateAge, formatArray, formatCurrency, formatDate, formatDateTime, getDiscountAmount } from 'core/utils';

import { ContainerColumn } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import { InvoiceItemModel } from 'company/entities/modules/ClinicManagement/PatientBilling/InvoiceItem/InvoiceItemModel';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import { OrderProps } from 'company/screens/InventorySystem/POS';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { getInvoiceItems } from 'company/api/invoice-items';
import { getPatient } from 'company/api/patient';
import { tokens } from 'theme/theme';

type Props = {
  invoice: any;
};

type FacilityDetailsProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const PrintableInvoice = forwardRef(({ invoice }: Props, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  const [patient, setPatient] = useState<PatientModel>();
  const [items, setItems] = useState<OrderProps[]>([]);

  useEffect(() => {
    if (invoice && facility) {
      getPatient(facility.id, invoice.patient_id).then((patient) => {
        setPatient(patient.data);
      });
      getInvoiceItems(facility.id, 'all', invoice.id, { length: 1000 }).then((items) => {
        setItems(items.data.data);
      });
    }
  }, [facility, invoice]);

  const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon }) => {
    if (label) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
          <Icon color="primary" />
          <Typography>{label}</Typography>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <Box>
      <style>{styles}</style>
      <Box ref={ref}>
        <Box>
          {!!user && (
            <Box display={'flex'} justifyContent={'space-between'} mt={'20px'} mb={'40px'}>
              <Box flex={1} display={'flex'} flexDirection={'column'} rowGap={'20px'} alignItems={'start'}>
                <Typography variant="h1" textAlign="right" fontWeight={'bold'}>
                  INVOICE
                </Typography>
                <Box display={'flex'} flexDirection={'column'} rowGap={'8px'} alignItems={'start'} mt={'5px'}>
                  <Typography variant="h5" textAlign="right" fontWeight={'bold'}>
                    Invoice Date
                  </Typography>
                  <Typography variant="h5" textAlign="left">
                    {formatDate(invoice.issue_date)}
                  </Typography>
                  <Typography variant="h5" textAlign="right" fontWeight={'bold'}>
                    Invoice No.
                  </Typography>
                  <Typography variant="h5" textAlign="left">
                    {invoice.invoice_number}
                  </Typography>
                </Box>
              </Box>

              <Box flex={2} display={'flex'} flexDirection={'column'} rowGap={'5px'} alignItems={'end'}>
                {user.company?.company_logo && (
                  <img alt="clinic-logo" width="90px" height="90px" id="logo" src={user.company?.company_logo} />
                )}
                <Typography variant="h3" textAlign="right" color={colors.primary} fontWeight={'bold'}>
                  {facility.facility_name}
                </Typography>
                <Typography variant="h5" textAlign="right">
                  {formatArray([facility.address, facility.municipality_name, facility.province_name])}
                </Typography>
                <Typography variant="h5" textAlign="right">
                  {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' | ')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={SIZES.padding}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <PersonPinOutlinedIcon color="primary" />
              <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                Patient Information
              </Typography>
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={SIZES.paddingS} mt={SIZES.paddingS}>
              <PatientField label="Full Name" value={patient?.full_name} />
              <PatientField label="HMO" value={patient?.hmo_name ?? 'None'} />
              <PatientField label="HMO Card Number" value={patient?.hmo_card_number ?? 'None'} />
            </Box>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <MedicalInformationIcon color="primary" />
              <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                Service Information
              </Typography>
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={SIZES.paddingS} mt={SIZES.paddingS}>
              <PatientField label="Service Provider Name" value={invoice.service_provider_names ?? 'None'} />
              <PatientField label="LOA Approval Code" value={invoice.approval_code ?? 'None'} />
              <PatientField label="LOA Approved By" value={invoice.approved_by ?? 'None'} />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: SIZES.padding }}>
            <ReceiptIcon color="primary" />
            <Typography variant="h5" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
              Invoice Details
            </Typography>
          </Box>
        </Box>
        <Box>
          <ContainerColumn gap={SIZES.paddingL}>
            <Box>
              <table>
                <thead>
                  <tr>
                    <td width="5%">
                      <Typography fontWeight="600">#</Typography>
                    </td>
                    <td width="35%">
                      <Typography fontWeight="600">Services/Products</Typography>
                    </td>
                    <td width="5%" align="right">
                      <Typography fontWeight="600">Qty</Typography>
                    </td>
                    <td width="15%" align="right">
                      <Typography fontWeight="600">Unit Price</Typography>
                    </td>
                    <td width="20%" align="right">
                      <Typography fontWeight="600">Discount</Typography>
                    </td>
                    <td width="20%" align="right">
                      <Typography fontWeight="600">Amount</Typography>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {items.map((invoiceItem, index) => (
                    <tr>
                      <td>
                        <Typography>{index + 1}</Typography>
                      </td>
                      <td>
                        <Typography>{invoiceItem.item_name}</Typography>
                      </td>
                      <td align="right">
                        <Typography>{invoiceItem.quantity}</Typography>
                      </td>
                      <td align="right">
                        <Typography>{formatCurrency(invoiceItem.original_price)}</Typography>
                      </td>
                      <td align="right">
                        <Typography>{getDiscountAmount(invoiceItem)}</Typography>
                        <Box display="flex" gap="5px" alignItems="center" width="100%" justifyContent="flex-end">
                          {(!!invoiceItem.discount_type || !!invoiceItem.sc_pwd_discounted) && (
                            <LocalMallIcon
                              style={{
                                verticalAlign: 'middle',
                                fontSize: '12px',
                                marginTop: '-4px',
                                marginLeft: '10px',
                              }}
                            />
                          )}
                          {!!invoiceItem.discount_type && <Typography>Promo</Typography>}
                          {!!invoiceItem.discount_type && !!invoiceItem.sc_pwd_discounted && <Typography>&</Typography>}
                          {!!invoiceItem.sc_pwd_discounted && <Typography>SC/PWD</Typography>}
                        </Box>
                      </td>
                      <td align="right">
                        <Typography>{formatCurrency(invoiceItem.total_price)}</Typography>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3} style={{ backgroundColor: '#f3f9ff' }}></td>
                    <td colSpan={2} align="right" style={{ backgroundColor: '#f3f9ff' }}>
                      <Typography>Subtotal:</Typography>
                      <Typography>Total VAT (12%):</Typography>
                      <Typography>VAT Exempt:</Typography>
                      <Typography>Total Discount:</Typography>
                      <Typography fontWeight="600">Total:</Typography>
                    </td>
                    <td align="right" style={{ backgroundColor: '#f3f9ff' }}>
                      <Typography>{formatCurrency(invoice.original_amount)}</Typography>
                      <Typography>{formatCurrency(invoice.total_tax)}</Typography>
                      <Typography>{formatCurrency(invoice.total_vat_exempt)}</Typography>
                      <Typography>{formatCurrency(invoice.total_discount)}</Typography>
                      <Typography fontWeight="600">{formatCurrency(invoice.total_amount)}</Typography>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <Box width="100%">
                        <Typography sx={{ height: '50px' }}></Typography>
                        <Box position="fixed" bottom="0" width="100%">
                          <hr
                            style={{
                              border: 'none',
                              borderTop: '0.5px solid #0192D5',
                              width: '100%',
                              transform: 'scaleY(0.5)',
                            }}
                          />

                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                              <img
                                alt="cphi-logo"
                                width="35px"
                                height="35px"
                                id="logo"
                                src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                              />

                              <Box sx={{ textAlign: 'left', gap: '4px' }}>
                                <Typography sx={{ fontSize: '8px', color: colors.accent }}>
                                  Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </Box>
          </ContainerColumn>
        </Box>
      </Box>
    </Box>
  );
});

const styles = `
  @page {
    size: A4;
    margin: 1cm 2cm 2cm;
  }

  table {
    border-collapse: collapse;
    padding: 10px;
    width: 100%;
  }

  // thead tr td {
  //   padding-top: 220px;
  // }

  thead td, tbody td {
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
  }
`;

export default PrintableInvoice;
