import './Calendar.css';

import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close, Schedule } from '@mui/icons-material';
import { ColorResult, SketchPicker } from 'react-color';
import { CustomAnimatedDrawer, CustomIconButton, CustomModal, RegularButton } from 'core/components';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { addMinutes, format, parse } from 'date-fns';
import { capitalizeWord, formatTimeWithoutAMPM } from 'core/utils';

import AppointmentSettings from 'company/screens/Appointments/components/AppointmentSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { ContainerRow } from 'core/components/containers';
import DayView from './DayView';
import { FacilityContext } from 'core/context/facility.context';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { PositionedEvent } from './utils';
import { ProtectedComponent } from 'core/components';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SIZES } from 'theme/constants';
import ServiceProviderDrawer from 'company/screens/Appointments/screens/ServiceProviderDrawer';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import WeekView from './WeekView';
import axios from 'axios';
import { getAppointmentInDateRange } from 'company/api/appointments';
import { getFacility } from 'company/api/facility';
import moment from 'moment';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

interface CalendarEventProps {
  event: PositionedEvent;
  staffColor: string | null;
  dayView?: boolean;
  staffCount: number;
}

interface ScheduleHour {
  start: string;
  end: string;
}

interface ScheduleDay {
  day: string;
  week_day: string;
  hours: ScheduleHour[];
}

interface FacilityData {
  id: number;
  name: string;
  schedule: string;
}

export interface ViewProps {
  date: Date;
  staff: ServiceProviderModel[];
  events: CompanyAppointmentModel[];
  hours: number[];
  startHour: number;
  endHour: number;
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
  facilitySchedule: ScheduleDay[];
}

export const CalendarEvent: React.FC<CalendarEventProps> = ({ event, staffColor, dayView, staffCount }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);

  const formatTimeRange = (startDateTime: string, durationInMinutes: number) => {
    const startDate = parse(startDateTime, 'yyyy-MM-dd HH:mm:ss', new Date());
    const endDate = addMinutes(startDate, durationInMinutes);
    return `${format(startDate, 'MMM d, yyyy h:mm a')} - ${format(endDate, 'h:mm a')}`;
  };

  const handlePopover = (event: any) => {
    event.stopPropagation();
    setOpenPopover((prevOpen) => !prevOpen);
  };

  const Status = () => {
    return (
      <Box
        sx={{
          borderRadius: '5px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          backgroundColor: colors.accent,
          color: 'white',
          padding: '3px 10px',
          fontSize: '10px',
        }}
      >
        <CalendarMonthIcon sx={{ fontSize: '13px' }} />
        <Typography sx={{ fontSize: '10px' }}>{capitalizeWord(event.status)}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Tooltip title={event.service_names} arrow>
        <Box
          key={event.id}
          className="event"
          sx={{
            top: `${event.top}px`,
            height: `${event.height}px`,
            left: `${event.left * (100 / event.width)}%`,
            width: `${100 / event.width}%`,
            overflow: 'hidden',
            cursor: 'pointer',
            backgroundColor: `${staffColor}`,
            borderColor: `${staffColor}`,
          }}
          display="flex"
          alignItems="flex-start"
          // flexDirection="column"
          ref={anchorRef}
          onClick={handlePopover}
          gap={SIZES.paddingS}
        >
          {dayView && staffCount <= 4 && <Status />}
          <Typography fontSize="12px" color={colors.black_text}>
            {formatTimeWithoutAMPM(event.start!)} - {formatTimeWithoutAMPM(event.end!)}:{' '}
            <span style={{ fontWeight: 'bold' }}>{event.service_names}</span>
          </Typography>
        </Box>
      </Tooltip>
      <Popover
        open={openPopover}
        anchorEl={anchorRef.current}
        onClose={handlePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ zIndex: 2 }}
      >
        <Box width="280px">
          <Box
            padding="10px 20px"
            sx={{ backgroundColor: colors.light_blue_background_2 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color={colors.primary}
              display="flex"
              gap="10px"
              alignItems="center"
            >
              <Span onClick={() => navigate(`/company/patient-management?patient_id=${event?.patient_id}`)}>
                {event && event.full_name}
              </Span>

              {!facility?.is_admin && (
                <ProtectedComponent
                  requiredAuth={['MANAGE_APPOINTMENTS']}
                  requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
                >
                  <Box width="100%" mb={SIZES.paddingL}>
                    <AppointmentSettings
                      appointment={event!}
                      updateAppointmentData={() => {
                        // handleClosePopover();
                        // refreshEvents();
                      }}
                      navigateToEditor
                    />
                  </Box>
                </ProtectedComponent>
              )}
            </Typography>
            <IconButton onClick={handlePopover}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box padding="20px">
            <Typography variant="h6" display="flex" alignItems="center" gap="5px" padding={'0 0 20px 0'}>
              <Schedule sx={{ color: colors.primary, fontSize: '15px' }} />
              <Span onClick={() => navigate(`/company/appointments/${event?.id}`)}>
                <Typography fontSize={'13px'}> {formatTimeRange(event.schedule, event.height)}</Typography>
              </Span>
            </Typography>
            <Box display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Mobile Number</Typography>
              <Typography>{event.mobile_number}</Typography>
            </Box>
            <Box display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Service</Typography>
              <Typography>{event.service_names}</Typography>
            </Box>
            <Box display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Service Provider</Typography>
              <Typography>{event.service_provider_with_title ?? event.service_provider}</Typography>
            </Box>
            <Box alignItems="center" display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Status</Typography>
              <Status />
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const ServiceProviderCalendar = forwardRef((props, ref) => {
  const { facility } = useContext(FacilityContext);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState<'week' | 'day'>('week');
  const [openServiceProviders, setOpenServiceProviders] = useState<boolean>(false);
  const [selectedProviders, setSelectedProviders] = useState<ServiceProviderModel[]>([]);
  const [events, setEvents] = useState<CompanyAppointmentModel[]>([]);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<string>('#fff');
  const isMobile = useMediaQuery('(max-width:768px)');
  const open = 6;
  const close = 19;
  const hours = Array.from({ length: close - open + 1 }, (_, i) => i + open);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [facilitySchedule, setFacilitySchedule] = useState<ScheduleDay[]>([]);
  const [openDays, setOpenDays] = useState(7);
  const [startHour, setStartHour] = useState(6);
  const [endHour, setEndHour] = useState(19);

  const updateCalendarSettings = (schedule: ScheduleDay[]) => {
    const openDays = schedule.filter((day) => day.hours.length > 0).length;
    setOpenDays(openDays);

    let earliest = 23;
    let latest = 0;
    schedule.forEach((day: ScheduleDay) => {
      day.hours.forEach((hour: ScheduleHour) => {
        const startHour = parseInt(hour.start.split(':')[0]);
        const endHour = parseInt(hour.end.split(':')[0]);
        earliest = Math.min(earliest, startHour);
        latest = Math.max(latest, endHour);
      });
    });

    setStartHour(earliest);
    setEndHour(latest);
  };

  useEffect(() => {
    if (facility) {
      getFacility(facility.id)
        .then((response) => {
          const facilityData: FacilityData = response.data;
          const parsedSchedule: ScheduleDay[] = JSON.parse(facilityData.schedule);
          setFacilitySchedule(parsedSchedule);
          updateCalendarSettings(parsedSchedule);
        })
        .catch((error) => console.error('Error fetching facility data:', error));
    }
  }, [facility]);

  const handleColorOnChange = (color: ColorResult) => {
    setCurrentColor(color.hex);
  };

  useImperativeHandle(ref, () => ({ getAppointments }));

  const prev = () => {
    if (view === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
    }
  };

  const next = () => {
    if (view === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
    }
  };

  const formatHeader = () => {
    if (view === 'week') {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      const options: any = { month: 'short', day: 'numeric' };
      const startDateStr = startOfWeek.toLocaleDateString('en-US', options);
      const endDateStr =
        startOfWeek.getMonth() === endOfWeek.getMonth()
          ? endOfWeek.toLocaleDateString('en-US', { day: 'numeric' })
          : endOfWeek.toLocaleDateString('en-US', options);
      return `${startDateStr} - ${endDateStr}`;
    } else if (view === 'day') {
      return currentDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    }
  };

  useEffect(() => {
    getAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, currentDate]);

  const getAppointments = () => {
    if (facility) {
      const start = currentDate;
      const end = new Date(currentDate);
      end.setDate(end.getDate() + (view === 'week' ? 7 : 1));

      getAppointmentInDateRange(facility.id, moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')).then(
        (res) => {
          setEvents(
            res.data.data.map((appointment: CompanyAppointmentModel) => ({
              ...appointment,
              start: appointment.schedule,
              end: moment(appointment.schedule)
                .add(appointment.duration > 0 ? appointment.duration : 30, 'minutes')
                .format(),
            }))
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Box width="100%">
      <div className="calendar">
        <Box position="relative">
          {isMobile ? (
            <Box sx={{ position: 'absolute', right: 0, top: '25px' }}>
              <IconButton onClick={() => setOpenServiceProviders(true)}>
                <PeopleAltOutlinedIcon style={{ color: colors.primary, fontSize: '18px' }} />
              </IconButton>
            </Box>
          ) : (
            <RegularButton
              label="View Service Provider"
              onClick={() => setOpenServiceProviders(true)}
              startIcon={<RemoveRedEyeOutlinedIcon />}
              styles={{ position: 'absolute', right: 0, top: '30px' }}
            />
          )}

          {/* <RegularButton
            label="View Color Picker"
            onClick={() => setOpenColorPicker(true)}
            startIcon={<FormatColorFillOutlinedIcon />}
            styles={{ position: 'absolute', right: 225, top: '30px' }}
          /> */}
          {isMobile ? (
            <ContainerRow
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingBlock: SIZES.padding,
                color: colors.primary,
              }}
            >
              <CustomIconButton
                onClick={prev}
                icon={<ChevronLeftIcon sx={{ fontSize: '20px', color: colors.primary }} />}
              />
              <Typography variant="h2" fontWeight="bold" width="120px" textAlign="center" fontSize={'20px'}>
                {formatHeader()}
              </Typography>
              <CustomIconButton
                onClick={next}
                icon={<ChevronRightIcon sx={{ fontSize: '20px', color: colors.primary }} />}
              />
            </ContainerRow>
          ) : (
            <ContainerRow sx={{ justifyContent: 'center', alignItems: 'center', paddingBlock: SIZES.padding }}>
              <CustomIconButton onClick={prev} icon={<ChevronLeftIcon sx={{ fontSize: '30px' }} />} />
              <Typography variant="h2" fontWeight="bold" width="170px" textAlign="center">
                {formatHeader()}
              </Typography>
              <CustomIconButton onClick={next} icon={<ChevronRightIcon sx={{ fontSize: '30px' }} />} />
            </ContainerRow>
          )}
        </Box>
        <Box display="flex" gap="10px" justifyContent="space-between" mb={SIZES.paddingS}>
          <RegularButton
            variant={'outlined'}
            onClick={() => {
              setCurrentDate(new Date());
            }}
            label="Today"
            size="small"
          />
          <ButtonGroup>
            <RegularButton
              variant={view === 'day' ? 'outlined' : undefined}
              onClick={() => setView('week')}
              label="Week"
              size="small"
            />
            <RegularButton
              variant={view === 'week' ? 'outlined' : undefined}
              onClick={() => {
                setView('day');
                setCurrentDate(new Date());
              }}
              label="Day"
              size="small"
            />
          </ButtonGroup>
        </Box>
        <Box height="calc(100vh - 200px)" sx={{ overflowX: 'auto' }}>
          {view === 'week' && (
            <WeekView
              date={currentDate}
              staff={selectedProviders}
              events={events}
              hours={hours}
              startHour={startHour}
              endHour={endHour}
              setOpenServiceProviders={setOpenServiceProviders}
              facilitySchedule={facilitySchedule}
            />
          )}
          {view === 'day' && (
            <DayView
              date={currentDate}
              staff={selectedProviders}
              events={events}
              hours={hours}
              startHour={open}
              endHour={close}
              facilitySchedule={facilitySchedule}
              setOpenServiceProviders={setOpenServiceProviders}
            />
          )}
        </Box>
      </div>
      <CustomAnimatedDrawer
        open={openServiceProviders}
        setOpen={setOpenServiceProviders}
        header="Service Providers"
        width={600}
      >
        <ServiceProviderDrawer
          setOpenServiceProviders={setOpenServiceProviders}
          handleSelectProviders={setSelectedProviders}
        />
      </CustomAnimatedDrawer>
      <CustomAnimatedDrawer open={openColorPicker} setOpen={setOpenColorPicker} header="Choose a color" width={600}>
        <SketchPicker color={currentColor} onChangeComplete={handleColorOnChange} />
      </CustomAnimatedDrawer>
    </Box>
  );
});

export default ServiceProviderCalendar;
const Span = styled.span`
  cursor: pointer;
  &:hover {
    color: #25638b;
  }
`;
