import { Box, Divider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmailOutlined, PhoneIphone } from '@mui/icons-material';
import { buildAddress, calculateAge, formatDateTime } from 'core/utils';
import { useContext, useEffect, useRef, useState } from 'react';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { ContainerColumn } from 'core/components/containers';
import { FORM_MODE } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import { SIZES } from 'theme/constants';
import { UserContext } from 'core/context/user.context';
import { formatDate } from 'core/utils';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';

type DocumentHeaderProps = {
  mode: FORM_MODE;
  name: string;
  patient?: PatientModel;
  setName: (name: string) => void;
  note?: PatientNoteModel;
  autoSave: () => void;
  lastUpdate: { editor: any; updated_at: string } | null;
};

const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  name,
  setName,
  mode,
  note,
  patient,
  lastUpdate,
  autoSave,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const prevName = useRef(name);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [updatedBy, setUpdatedBy] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<string>('');

  useEffect(() => {
    if (!isNameFocused && prevName.current !== name) {
      autoSave();
      prevName.current = name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNameFocused]);

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      setIsNamedFocused(false);
    }
  };

  useEffect(() => {
    if (lastUpdate) {
      setUpdatedBy(lastUpdate.editor);
      setLastUpdated(lastUpdate.updated_at);
    } else if (note) {
      setUpdatedBy(note.last_editor_name ?? '');
      setLastUpdated(note.updated_at);
    }
  }, [lastUpdate, note]);

  return (
    <>
      <Box sx={{ padding: '6px 12px' }} mb="20px">
        {user &&
          user.company &&
          mode === FORM_MODE.VIEW &&
          (isMobilePhone ? (
            <ContainerColumn sx={{ alignItems: 'left', mb: SIZES.paddingL, position: 'relative' }}>
              <ContainerColumn gap="5px" sx={{ width: '60%', textAlign: 'left' }}>
                <Typography variant="h2" fontWeight="600" textAlign="left">
                  {user.company.company_name}
                </Typography>

                <Typography variant="body1" display="flex" justifyContent="left" gap="5px">
                  <PhoneIphone />
                  {[facility.globe_mobile_number, facility.smart_mobile_number]
                    .filter((number) => !!number)
                    .join(' / ')}
                </Typography>

                {facility.email && (
                  <Typography variant="body1" display="flex" justifyContent="left" gap="5px">
                    <EmailOutlined /> {facility.email}
                  </Typography>
                )}

                <Typography variant="body1">{buildAddress(facility)}</Typography>
              </ContainerColumn>
              <img
                alt="profile-user"
                width="50px"
                height="auto"
                id="logo"
                style={{ position: 'absolute', right: 0, top: 0 }}
                src={
                  theme.palette.mode === 'light'
                    ? user.company?.company_logo
                      ? user.company?.company_logo
                      : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
                    : user.company?.company_logo_dark
                    ? user.company?.company_logo_dark
                    : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
                }
              />
            </ContainerColumn>
          ) : (
            <ContainerColumn sx={{ alignItems: 'center', mb: SIZES.paddingL, position: 'relative' }}>
              <img
                alt="profile-user"
                width="80px"
                height="auto"
                id="logo"
                style={{ position: 'absolute', left: 0, top: 0 }}
                src={
                  theme.palette.mode === 'light'
                    ? user.company?.company_logo
                      ? user.company?.company_logo
                      : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
                    : user.company?.company_logo_dark
                    ? user.company?.company_logo_dark
                    : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
                }
              />
              <ContainerColumn gap="5px" sx={{ width: '60%', textAlign: 'center' }}>
                <Typography variant="h2" fontWeight="600" textAlign="center">
                  {user.company.company_name}
                </Typography>

                <Typography variant="body1" display="flex" justifyContent="center" gap="5px">
                  <PhoneIphone />
                  {[facility.globe_mobile_number, facility.smart_mobile_number]
                    .filter((number) => !!number)
                    .join(' / ')}
                </Typography>

                {facility.email && (
                  <Typography variant="body1" display="flex" justifyContent="center" gap="5px">
                    <EmailOutlined /> {facility.email}
                  </Typography>
                )}

                <Typography variant="body1">{buildAddress(facility)}</Typography>
              </ContainerColumn>
            </ContainerColumn>
          ))}

        {mode !== FORM_MODE.VIEW && (isNameFocused || !name) ? (
          <TextField
            variant="standard"
            autoFocus
            // inputProps={{ className: classes.name }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyDown={keyPress}
            onBlur={(event) => setIsNamedFocused(false)}
            InputProps={{
              style: {
                fontWeight: '600',
                fontSize: '24px',
              },
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {},
            }}
          />
        ) : (
          <Typography
            variant="h3"
            fontWeight="600"
            onClick={() => {
              setIsNamedFocused(true);
            }}
            sx={
              mode === FORM_MODE.VIEW
                ? undefined
                : {
                    '&:hover': {
                      borderBottom: '1px solid ' + colors.primary,
                      cursor: 'pointer',
                    },
                  }
            }
          >
            {name}
          </Typography>
        )}

        {note?.first_name && note.collection_name !== 'Medical Certificate' && (
          <Box
            paddingTop={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px">
              {patient && (
                <>
                  <PatientField label="First Name" value={patient.first_name} />
                  <PatientField label="Middle Name" value={patient.middle_name} />
                  <PatientField label="Last Name" value={patient.last_name} />
                  <PatientField label="Birthday" value={formatDate(patient.birthday)} />
                  <PatientField label="Age" value={calculateAge(patient.birthday)} />
                  <PatientField label="Sex" value={patient.sex} />
                </>
              )}
              <Divider sx={{ gridColumn: 'span 2' }} />
            </Box>

            {patient && patient?.corporate_client_id && (
              <Box width="100%" marginTop={SIZES.paddingS}>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                  <BusinessCenterIcon color="primary" />
                  <h3>Employee Details</h3>
                </Typography>
                <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px">
                  <PatientField label="Employee ID" value={patient.employee_id} />
                  {isDevMode() && <PatientField label="Department" value={patient.department_name} />}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DocumentHeader;
