/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography, useTheme } from '@mui/material';
import { CareGoTable, PageBuilderDrawerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { CustomForm, CustomModal, RegularButton } from 'core/components';
import { Fragment, useContext, useEffect, useState } from 'react';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { add_local_template_schema, template_category_schema, template_schema } from 'carego-admin/model/schema';
import {
  createCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
} from 'carego-admin/api/carego-sms-template-categories';
import { createLocal, deleteLocal, getAllLocal, updateLocal } from 'carego-admin/api/carego-sms-templates-local';
import { createTemplate, deleteTemplate, getAll, updateTemplate } from 'carego-admin/api/carego-sms-templates';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GridColDef } from '@mui/x-data-grid';
import ReactMarkdown from 'react-markdown';
import { SMSTemplateModel } from 'company/model/Entities';
import { formatDate } from 'core/utils';
import { getLanguages } from 'company/api/languages';
import { specialTokens } from 'core/data/list';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type TemplateInput = {
  template_name: string;
  template_code: string;
  category_id: number | string;
};

type TemplateCategoryInput = {
  category_name: string;
};

type LocalTemplateInput = {
  language_id: number | string;
  template_body: string;
};

interface TemplateFieldInput extends FieldInput {
  field_name: keyof TemplateInput;
}

interface TemplateCategoryFieldInput extends FieldInput {
  field_name: keyof TemplateCategoryInput;
}

interface LocalTemplateFieldInput extends FieldInput {
  field_name?: keyof LocalTemplateInput;
}

const initialValues: TemplateInput = {
  template_name: '',
  template_code: '',
  category_id: '',
};

const templateCategoryInitialValues: TemplateCategoryInput = {
  category_name: '',
};

const CareGoSMSTemplates = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);
  const [forceRefreshLocalTable, setForceRefreshLocalTable] = useState<number>(0);

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [selectedRow, setSelectedRow] = useState<SMSTemplateModel>();
  const [categories, setCategories] = useState<any>();
  const [languages, setLanguages] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  const [preview, setPreview] = useState('');
  const [templateBody, setTemplateBody] = useState('');

  // Table Columns
  const _columns: GridColDef[] = [
    { field: 'template_name', headerName: 'Template Name', flex: 2 },
    { field: 'template_code', headerName: 'Template Code', flex: 2 },
    { field: 'category_name', headerName: 'Category', flex: 2 },
  ];

  const local_columns: GridColDef[] = [
    {
      field: 'language_id',
      headerName: 'Language',
      flex: 1,
      renderCell: (params) => {
        return languages.find((language: any) => language.id === params.row.language_id).language;
      },
    },
    { field: 'template_body', headerName: 'Template Body', flex: 2 },
  ];

  const handleDeleteLocalConfirm = async (data: any) => {
    return deleteLocal(data);
  };

  const category_columns: GridColDef[] = [{ field: 'category_name', headerName: 'Category Name', flex: 2 }];

  // Form Fields
  const fields: TemplateFieldInput[] = [
    { field_name: 'template_name', display_name: 'Template Name', type: 'text', span: 4 },
    { field_name: 'template_code', display_name: 'Template Code', type: 'text', span: 4 },
    {
      field_name: 'category_id',
      display_name: 'Category',
      type: 'select',
      span: 4,
      options: categories?.map((u: any) => {
        return { key: u.category_name, value: u.id };
      }),
    },
  ];

  const categoryFields: TemplateCategoryFieldInput[] = [
    { field_name: 'category_name', display_name: 'Category Name', type: 'text', span: 4 },
  ];

  const localFields: LocalTemplateFieldInput[] = [
    {
      field_name: 'language_id',
      display_name: 'Language',
      type: 'select',
      options: languages?.map((u: any) => {
        return { key: u.language, value: u.id };
      }),
      // hidden: toAdd ? false : true,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Preview ({templateBody.length} characters)
          </Typography>
          <Box
            color={colors.text}
            sx={{
              marginBottom: '20px',
              whiteSpace: 'pre-line',
              '& code': { color: 'white', backgroundColor: colors.main, padding: '2px 5px', borderRadius: '5px' },
            }}
          >
            <ReactMarkdown children={preview} />
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Special tokens
          </Typography>{' '}
          <br></br>
          <Box display="grid" gridTemplateColumns="150px 1fr 150px 1fr" gap="10px">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                >
                  <span style={{ backgroundColor: colors.textBackground, borderRadius: '5px', padding: '6px' }}>
                    {' '}
                    {token.token}{' '}
                  </span>
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {token.description}
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary, display: 'flex', gap: '20px' }}>
          <Typography variant="h6" color={colors.text}>
            Special tokens
          </Typography>
          <Box display="flex" gap="5px">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    transition: 'color 0.3s',
                    color: colors.text,
                  }}
                  onClick={() => handleTokenClick(token.token)}
                >
                  <span
                    style={{
                      backgroundColor: colors.textBackground,
                      borderRadius: '5px',
                      padding: '6px',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondary;
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = colors.textBackground;
                      e.currentTarget.style.color = colors.text;
                    }}
                  >
                    {token.token}
                  </span>
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      field_name: 'template_body',
      display_name: 'SMS Template',
      type: 'text',
      multiline: true,
      rows: 3,
      span: 4,
      onChange: (value) => setTemplateBody(value),
    },
  ];

  // Functions
  const getData = (query: ApiQuery) => {
    return getAll(query);
  };

  const getLocalData = (query: ApiQuery) => {
    return getAllLocal(query, selectedRow!.id);
  };

  const getCategoryData = (query: ApiQuery) => {
    return getAllCategories(query);
  };

  const handleSelectRow = (selected: any) => {
    setSelectedRow(selected);
    setForceRefreshLocalTable((prev) => prev + 1);
  };

  const closeDrawer = () => {
    setSelectedRow(undefined);
  };

  const handleSubmit = async (data: any) => {
    if (data.id) return updateTemplate(data.id, data);
    return createTemplate(data).then(() => {
      enqueueSnackbar('Template successfully created!', { variant: 'success' });
      setForceRefreshTable((prev) => prev + 1);
    });
  };

  const handleSubmitCategory = async (data: any) => {
    if (data.id) return updateCategory(data.id, data);
    return createCategory(data).then(() => {
      enqueueSnackbar('Category successfully created!', { variant: 'success' });
      setForceRefreshTable((prev) => prev + 1);
      getCategories();
    });
  };

  const handleSubmitLocal = async (data: any) => {
    setButtonLoading(true);
    if (selectedTemplate) {
      return updateLocal(selectedTemplate.id, data).then(() => {
        setTemplateBody('');
        enqueueSnackbar('Local Template successfully updated!', { variant: 'success' });
        setForceRefreshLocalTable((prev) => prev + 1);
        setButtonLoading(false);
        setOpenTemplateModal(false);
      });
    } else {
      data.category_id = selectedRow!.category_id;
      data.template_id = selectedRow!.id;
      return createLocal(data).then(() => {
        setTemplateBody('');
        enqueueSnackbar('Local Template successfully created!', { variant: 'success' });
        setForceRefreshLocalTable((prev) => prev + 1);
        setButtonLoading(false);
        setOpenTemplateModal(false);
      });
    }
  };

  const handleDelete = (data: any) => {
    return deleteTemplate(data);
  };

  const handleDeleteCategory = (data: any) => {
    return deleteCategory(data);
  };

  const getCategories = () => {
    getAllCategories().then((categories) => {
      setCategories(categories.data.data);
    });
  };

  const getAllLanguages = () => {
    getLanguages().then((languages) => {
      setLanguages(languages.data.data);
    });
  };

  const handleTokenClick = (clickedToken: string) => {
    const updatedTemplateBody = templateBody + ' ' + clickedToken;
    setTemplateBody(updatedTemplateBody);
    setPreview(replacePreview(updatedTemplateBody));
  };

  const replacePreview = (template: string) => {
    if (!template || template.trim() === '') {
      setTemplateBody('');
      return '';
    }
    let updated = template
      ?.replaceAll('[Name]', '`Cheska Dela Cruz`')
      ?.replaceAll('[FirstName]', '`Cheska`')
      ?.replaceAll('[Facility_Name]', '`CPHI`')
      ?.replaceAll('[Facility_Type]', '`clinic`')
      ?.replaceAll('[Facility_Contact]', '`09123456789`')
      ?.replaceAll('[Date]', '`' + formatDate(new Date()) + '`')
      ?.replaceAll('[Time]', '`9:30 AM`')
      ?.replaceAll('[Health_Program]', '`appointment`')
      ?.replaceAll('[Day]', '`4`')
      ?.replaceAll('[Hour]', '`12`');

    return updated ?? '';
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'CareGo SMS Templates' }]);
    getCategories();
    getAllLanguages();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      const newColumns = [_columns[0]];
      setColumns(newColumns);
    } else {
      setColumns(_columns);
    }
  }, [selectedRow]);

  useEffect(() => {
    setPreview(replacePreview(templateBody));
  }, [templateBody]);

  useEffect(() => {
    setPreview(replacePreview(''));
  }, []);

  useEffect(() => {
    if (!openTemplateModal) {
      setTemplateBody('');
      setSelectedTemplate(undefined);
    }
  }, [openTemplateModal]);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.DRAWER,
      parentHeader: 'CareGo SMS Templates',
      parentSubHeader: 'Click the row to see more details.',
      childHeader: selectedRow ? `${selectedRow.template_name}` : '',
      parentWidth: 500,
      showChild: !!selectedRow,
      handleClose: closeDrawer,
      parentComponent: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Template',
        doNotWaitForFacility: true,
        tableComponent: {
          columns,
          getData: getData,
          selectableRow: true,
          onSelectRow: handleSelectRow,
          forceRefresh: forceRefreshTable,
          headerComponent: (
            <Box display="flex" gap="10px">
              <RegularButton
                onClick={() => {
                  setOpen(true);
                }}
                label="Template Category"
              />
            </Box>
          ),
        },
        tableForm: {
          formFields: fields,
          formInitialValues: initialValues,
          formSchema: template_schema,
          formSubmitApiFunction: handleSubmit,
        },
        tableAction: {
          handleRowActionsClick: getCategories,
          deleteApiFunction: handleDelete,
          includeUpdateAction: true,
          includeDeleteAction: true,
          includeAddButton: true,
        },
      } as PageBuilderTableType,
      childComponent: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Local Template',
        doNotWaitForFacility: true,
        tableComponent: {
          hideTable: !selectedRow,
          columns: local_columns,
          getData: getLocalData,
          forceRefresh: forceRefreshLocalTable,
        },
        tableAction: {
          includeAddButton: true,
          includeUpdateAction: false,
          includeDeleteAction: true,
          handleAddButtonClick: () => setOpenTemplateModal(true),
          deleteApiFunction: handleDeleteLocalConfirm,
          getRow: setSelectedTemplate,
          doNotHideActionForInactiveCompany: true,
          rowActions: [
            {
              label: 'Update',
              action: () => {
                setTemplateBody(selectedTemplate?.template_body);
                setOpenTemplateModal(true);
              },
              icon: <EditOutlinedIcon />,
            },
          ],
        },
      } as PageBuilderTableType,
    } as PageBuilderDrawerType,
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />
      <CustomModal open={open} setOpen={setOpen} header="Template Category">
        <CareGoTable
          entityName="Template Category"
          doNotWaitForFacility
          tableComponent={{
            columns: category_columns,
            getData: getCategoryData,
            forceRefresh: forceRefreshTable,
          }}
          tableForm={{
            formFields: categoryFields,
            formInitialValues: templateCategoryInitialValues,
            formSchema: template_category_schema,
            formSubmitApiFunction: handleSubmitCategory,
          }}
          tableAction={{
            includeAddButton: true,
            includeDeleteAction: true,
            includeUpdateAction: true,
            deleteApiFunction: handleDeleteCategory,
          }}
        />
      </CustomModal>

      <CustomModal
        width={950}
        header={selectedTemplate ? 'Edit Local Template' : 'Create Local Template'}
        open={openTemplateModal}
        setOpen={setOpenTemplateModal}
      >
        <CustomForm
          initialValues={
            selectedTemplate
              ? {
                  language_id: selectedTemplate.language_id,
                  template_body: templateBody,
                }
              : {
                  language_id: '',
                  template_body: templateBody,
                }
          }
          onSubmit={handleSubmitLocal}
          fields={localFields}
          schema={add_local_template_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default CareGoSMSTemplates;
