import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomModal,
  HideOrShowComponent,
  RegularButton,
  RowAction,
} from 'core/components';
import React, { useContext, useEffect, useState } from 'react';
import { UserGroupAuthority, UserGroups } from 'company/model/Entities';
import {
  applyAllUserGroup,
  createManyUserGroupAuthority,
  createUserGroupAuthority,
  deleteAllUserGroupAuthority,
  deleteManyUserGroupAuthority,
  deleteUserGroupAuthority,
  getUserGroupAuthority,
} from 'company/api/user-group-authorities';
import {
  caregoApplyAllUserGroup,
  caregoCreateManyUserGroupAuthority,
  caregoCreateUserGroupAuthority,
  caregoDeleteAllUserGroupAuthority,
  caregoDeleteManyUserGroupAuthority,
  caregoDeleteUserGroupAuthority,
  caregoGetUserGroupAuthority,
} from 'carego-admin/api/carego-user-group-authorities';
import { caregoDeleteGroupAuthority, caregoDeleteGroupAuthorityByName } from 'carego-admin/api/carego-user-authorities';

import { AccountType } from 'core/model/Entities';
import AddIcon from '@mui/icons-material/Add';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RemoveIcon from '@mui/icons-material/Remove';
import { UserContext } from 'core/context/user.context';
import UserGroupAuthorityForm from 'company/entities/modules/UserManagement/UserGroupAuthority/UserGroupAuthorityForm';
import { caregoGetUserAuthorities } from 'carego-admin/api/carego-user-authorities';
import { getUserAuthorities } from 'company/api/user-authorities';
import { tokens } from 'theme/theme';

// where authority and description are parsable strings
type PermissionCardProps = {
  group_name: string;
  authority: string[];
  description: string[];
  ids: string[];
  selectedGroup: UserGroups;
  groupPermission?: UserGroupAuthority[];
  setOpenModal?: (state: any) => void;
  setOpenDialog?: (state: any) => void;
  setGroupSelected?: (groupData: any) => void;

  selectedGroupAuthCount?: number;
  setSelectedGroupAuthCount?: (state: any) => void;

  forceRefresh?: number;
  setForceRefresh?: (state: any) => void;
};

const PermissionCard: React.FC<PermissionCardProps> = (props) => {
  const { user } = useContext(UserContext);
  const {
    ids,
    authority,
    group_name,
    description,
    selectedGroup,
    groupPermission,
    setOpenModal,
    setOpenDialog,
    setGroupSelected,

    selectedGroupAuthCount,
    setSelectedGroupAuthCount,
  } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedUserGroupId, setSelectedUserGroupId] = useState<number>();

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [authState, setAuthState] = useState(
    authority.map((auth, index) => ({
      id: index,
      name: auth,
      state: !!groupPermission?.find((groupPerm) => groupPerm.authority === auth),
    }))
  );

  const callbackAfterManyOperation = (state: boolean) => (response: any) => {
    setAuthState(
      authState.map((auth) => ({
        id: auth.id,
        name: auth.name,
        state,
      }))
    );

    // caching
    if (state)
      return (
        selectedGroupAuthCount !== undefined &&
        setSelectedGroupAuthCount &&
        setSelectedGroupAuthCount(selectedGroupAuthCount + response.data['user_group_authorities'].length)
      );

    return (
      selectedGroupAuthCount !== undefined &&
      setSelectedGroupAuthCount &&
      setSelectedGroupAuthCount(selectedGroupAuthCount - response.data['user_group_authorities'].length)
    );

    // safe response (slower)
    // setForceRefresh && forceRefresh !== undefined && setForceRefresh(forceRefresh + 1);
  };

  // procedure to create all by user group authority
  const handleCreateManyCallback = () => {
    user.account_type === AccountType.CAREGO
      ? caregoCreateManyUserGroupAuthority(selectedGroup.id as number, group_name).then(
          callbackAfterManyOperation(true)
        )
      : createManyUserGroupAuthority(selectedGroup.id as number, group_name).then(callbackAfterManyOperation(true));
  };

  // procedure to delete multiple permission by user group name
  const handleDeleteManyCallback = () => {
    user.account_type === AccountType.CAREGO
      ? caregoDeleteManyUserGroupAuthority(selectedGroup.id as number, group_name).then(
          callbackAfterManyOperation(false)
        )
      : deleteManyUserGroupAuthority(selectedGroup.id as number, group_name).then(callbackAfterManyOperation(false));
  };

  // updates the auth state of the card
  // to search which index has a true value
  useEffect(() => {
    setAuthState(
      authority.map((auth, index) => ({
        id: index,
        name: auth,
        state: !!groupPermission?.find((groupPerm) => groupPerm.authority === auth),
      }))
    );
  }, [authority, groupPermission]);

  // for checking whether all the auth are checked
  useEffect(() => {
    authState.filter((auth) => auth.state === false).length === 0 ? setAllChecked(true) : setAllChecked(false);
  }, [authState]);

  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: colors.primary,
        borderWidth: '3px',
        borderRadius: '10px',
        padding: '5px',
        paddingTop: '0px',
        width: '100%',
        // maxHeight: '20em',
        overflowY: 'auto',
        scrollbarWidth: 'none',
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {!selectedGroup ? (
            <>
              <List sx={{ listStyleType: 'disc' }}>
                <ListItem sx={{ display: 'list-item', listStylePosition: 'inside', fontSize: 20 }}>
                  <Typography display="inline-block" component="div" fontWeight="bold" variant="h4" mb={0}>
                    {group_name !== null ? group_name : 'NULL'}
                  </Typography>
                </ListItem>
              </List>
              {user?.account_type === AccountType.CAREGO && (
                <RowAction
                  actions={[
                    {
                      label: 'Add Permission',
                      icon: <AddIcon />,
                      action: () => {
                        if (setOpenModal && setGroupSelected) {
                          setGroupSelected({ group_name, description: '', authority: '' });
                          setOpenModal(true);
                        }
                      },
                    },
                    {
                      label: 'Delete',
                      icon: <RemoveIcon />,
                      action: () => {
                        if (setGroupSelected && setOpenDialog) {
                          setGroupSelected({ group_name, type: 'delete-many', id: -1 });
                          setOpenDialog(true);
                        }
                      },
                    },
                  ]}
                />
              )}
            </>
          ) : (
            <FormControlLabel
              label={
                <Typography fontWeight="bold" variant="h5">
                  {group_name !== null ? group_name : 'NULL'}
                </Typography>
              }
              control={
                <Checkbox
                  checked={allChecked}
                  // checked={ group && (group.group_name == "COMPANY_ADMIN") ? allChecked : !allChecked}
                  onClick={() => {
                    if (!allChecked) return handleCreateManyCallback();
                    handleDeleteManyCallback();
                  }}
                  disabled={selectedGroup.group_name === 'COMPANY_ADMIN' || isMobilePhone}
                />
              }
            />
          )}
        </Box>
        <List disablePadding dense sx={{ listStyleType: 'disc', ml: 2 }}>
          {authority.map((perm: any, index) =>
            !selectedGroup ? (
              <ListItemButton key={index}>
                <ListItem
                  secondaryAction={
                    user?.account_type === AccountType.CAREGO && (
                      <>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            if (setGroupSelected && setOpenModal) {
                              setGroupSelected({
                                id: parseInt(ids[index]),
                                group_name,
                                authority: perm,
                                description: description[index],
                                type: 'update',
                              });
                              setOpenModal(true);
                            }
                          }}
                        >
                          <EditNoteIcon opacity={0.5} color="info" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            if (setGroupSelected && setOpenDialog) {
                              setGroupSelected({ authority: perm, id: parseInt(ids[index]) });
                              setOpenDialog(true);
                            }
                          }}
                        >
                          <DeleteIcon opacity={0.5} color="error" />
                        </IconButton>
                      </>
                    )
                  }
                  key={index}
                  sx={{ display: 'list-item', listStylePosition: 'inside', fontSize: 18 }}
                >
                  <Typography display="inline-block" fontWeight="bold" variant="h6">
                    {perm}
                  </Typography>
                  <Box sx={{ display: 'flex', color: 'grey', ml: 4 }}>
                    <Typography variant="h6">{description[index]}</Typography>
                  </Box>
                </ListItem>
              </ListItemButton>
            ) : (
              <ListItemButton key={index}>
                <ListItemIcon>
                  <Checkbox
                    sx={{ marginTop: '-1.5em' }}
                    checked={authState.find((auth) => auth.id === index)?.state}
                    onChange={(event: any) => {
                      const state = authState.find((auth) => auth.id === index);
                      const user_authority_id = ids[index];
                      const group_id = selectedGroup.id;

                      const operationCallback = (state: any) => (response: any) => {
                        if (selectedGroupAuthCount !== undefined && setSelectedGroupAuthCount) {
                          state
                            ? setSelectedGroupAuthCount(selectedGroupAuthCount + 1)
                            : setSelectedGroupAuthCount(selectedGroupAuthCount - 1);

                          // slower (but sure) implementation
                          // setForceRefresh && forceRefresh !== undefined && setForceRefresh(forceRefresh + 1);

                          setAuthState([
                            ...authState.filter((auth) => auth.id !== index),
                            { id: index, name: perm, state },
                          ]);
                        }
                      };

                      if (state?.state) {
                        user.account_type === AccountType.CAREGO
                          ? caregoDeleteUserGroupAuthority(group_id as number, parseInt(user_authority_id)).then(
                              operationCallback(false)
                            )
                          : deleteUserGroupAuthority(group_id as number, parseInt(user_authority_id)).then(
                              operationCallback(false)
                            );
                      } else {
                        user.account_type === AccountType.CAREGO
                          ? caregoCreateUserGroupAuthority({
                              user_authority_id: parseInt(user_authority_id),
                              user_group_id: group_id as number,
                            }).then(operationCallback(true))
                          : createUserGroupAuthority({
                              user_authority_id: parseInt(user_authority_id),
                              user_group_id: group_id as number,
                            }).then(operationCallback(true));
                      }
                    }}
                    disabled={selectedGroup.group_name === 'COMPANY_ADMIN' || isMobilePhone}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography fontWeight="bold" variant="h6">
                    {perm}
                  </Typography>
                  <Box sx={{ display: 'flex', color: 'grey' }}>
                    <Typography variant="h6">{description[index]}</Typography>
                  </Box>
                </ListItemText>
              </ListItemButton>
            )
          )}
        </List>
      </CardContent>
    </Card>
  );
};

const initialGroupSelectedValues = {
  group_name: '',
  description: '',
  authority: '',
  type: '',
  id: NaN,
};

type Props = { group?: any; refresh?: number };
const UserGroupPermission: React.FC<Props> = (props) => {
  const { group, refresh } = props;
  const { user } = useContext(UserContext);

  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [groupPermissions, setGroupPermissions] = useState<UserGroupAuthority[]>();
  const [userPermissions, setUserPermissions] = useState<PermissionCardProps[]>();
  const [groupSelected, setGroupSelected] = useState(initialGroupSelectedValues);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [totalAuthCount, setTotalAuthCount] = useState(0);
  const [selectedGroupAuthCount, setSelectedGroupAuthCount] = useState(0);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  // retrieves the permissions when loaded
  useEffect(() => {
    const getUserAuthoritiesCallback = (response: any) => {
      const authority: any = response.data.data;
      const allPermissions = authority.map((auth: any) => ({
        group_name: auth.group_name,
        ids: JSON.parse(auth.ids),
        authority: JSON.parse(auth.authority),
        description: JSON.parse(auth.description),
      }));

      const totalPermissions = allPermissions
        .map((auth: any) => auth.ids.length)
        .reduce((total: number, authLength: number) => total + authLength, 0);

      setTotalAuthCount(totalPermissions);
      setUserPermissions(allPermissions);
    };

    user?.account_type === AccountType.CAREGO
      ? caregoGetUserAuthorities().then(getUserAuthoritiesCallback)
      : getUserAuthorities().then(getUserAuthoritiesCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh, refresh]);

  useEffect(() => {
    const getUserGroupAuthCallback = (response: any) => {
      setSelectedGroupAuthCount(response.data.length);
      setGroupPermissions(response.data);
    };

    if (group) {
      user?.account_type === AccountType.CAREGO
        ? caregoGetUserGroupAuthority(group.id).then(getUserGroupAuthCallback)
        : getUserGroupAuthority(group.id).then(getUserGroupAuthCallback);
    }
  }, [group, forceRefresh, user?.account_type]);

  return (
    <>
      <CustomContainer>
        {!group && user.account_type === AccountType.CAREGO && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <RegularButton
              size="large"
              startIcon={<AddModeratorIcon />}
              label="Create Permission"
              onClick={() => {
                setOpenModal(true);
                setGroupSelected(initialGroupSelectedValues);
              }}
            />
          </Box>
        )}
        <HideOrShowComponent hidden={!group || isMobilePhone}>
          {selectedGroupAuthCount === totalAuthCount ? (
            <FormControlLabel
              label={
                <Typography fontWeight="bold" variant="h5">
                  Uncheck All
                </Typography>
              }
              control={
                <Checkbox
                  checked={false}
                  onClick={() => {
                    user.account_type === AccountType.CAREGO
                      ? caregoDeleteAllUserGroupAuthority(group.id).then((response) => {
                          setForceRefresh(forceRefresh + 1);
                        })
                      : deleteAllUserGroupAuthority(group.id).then((response) => {
                          setForceRefresh(forceRefresh + 1);
                        });
                  }}
                />
              }
            />
          ) : selectedGroupAuthCount === 0 ? (
            <FormControlLabel
              label={
                <Typography fontWeight="bold" variant="h5">
                  Check All
                </Typography>
              }
              control={
                <Checkbox
                  checked={false}
                  onClick={() => {
                    user.account_type === AccountType.CAREGO
                      ? caregoApplyAllUserGroup(group.id).then((response) => {
                          setForceRefresh(forceRefresh + 1);
                        })
                      : applyAllUserGroup(group.id).then((response) => {
                          setForceRefresh(forceRefresh + 1);
                        });
                  }}
                />
              }
            />
          ) : (
            <></>
          )}
        </HideOrShowComponent>

        <Box
          sx={{
            padding: '1em',
            display: 'grid',
            gridTemplateColumns: group ? '1fr ' : '1fr 1fr',
            gap: '1em',
            width: '100%',
          }}
        >
          {userPermissions ? (
            userPermissions.map((userPerm, index) => (
              <PermissionCard
                key={index}
                ids={userPerm.ids}
                authority={userPerm.authority}
                description={userPerm.description}
                group_name={userPerm.group_name}
                selectedGroup={group}
                groupPermission={groupPermissions}
                setOpenModal={setOpenModal}
                setGroupSelected={setGroupSelected}
                setOpenDialog={setOpenDialog}
                // experiment caching
                selectedGroupAuthCount={selectedGroupAuthCount}
                setSelectedGroupAuthCount={setSelectedGroupAuthCount}
              />
            ))
          ) : (
            <></>
          )}
        </Box>
      </CustomContainer>
      <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        header={`Add ${groupSelected.group_name === '' ? 'User Group' : ''} Permission`}
        children={
          <UserGroupAuthorityForm
            selectedGroup={groupSelected}
            callbackAfterSubmit={() => {
              setOpenModal(false);
              setForceRefresh(forceRefresh + 1);
            }}
          />
        }
      />
      <ConfirmationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        content={'Are you sure you want to delete ' + groupSelected.authority + '?'}
        onConfirm={() => {
          if (groupSelected && !isNaN(groupSelected.id)) {
            if (groupSelected.type === 'delete-many')
              return caregoDeleteGroupAuthorityByName(groupSelected.group_name).then(() => {
                setForceRefresh(forceRefresh + 1);
              });

            caregoDeleteGroupAuthority(groupSelected.id).then(() => {
              setForceRefresh(forceRefresh + 1);
            });
          }
        }}
      />
    </>
  );
};

export default UserGroupPermission;
