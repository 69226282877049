import {
  APPOINTMENT_ENTITY_NAME,
  AppointmentInitialValues,
  CompanyAppointmentModel,
  appointmentFields,
  appointmentFormFields,
  appointment_schema,
} from './AppointmentModel';
import { scheduleAppointment, updateAppointment } from 'company/api/appointments';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';

type Props = {
  readonly?: boolean;
  appointment?: CompanyAppointmentModel;
  showSubmitAtTop?: boolean;
  facility: FacilityModel;
  callbackAfterSubmit?: (data: any) => void;
  patientId?: number;
};

const AppointmentForm: React.FC<Props> = (props) => {
  const { facility, readonly, appointment, showSubmitAtTop, callbackAfterSubmit, patientId } = props;

  const handleSubmitApi = (data: any) => {
    const final = { ...data };
    final.patients = JSON.stringify(data.patient_id.map((patient: any) => patient.value));
    final.services = JSON.stringify(data.service_id.map((service: any) => service.value));
    return appointment
      ? updateAppointment(facility.id, appointment!.id, final)
      : scheduleAppointment(facility.id, final);
  };

  return (
    <TemplateForm
      entityName={APPOINTMENT_ENTITY_NAME}
      fields={appointmentFields}
      entity={appointment}
      schema={appointment_schema}
      formSubmitApiFunction={handleSubmitApi}
      visibleFields={
        patientId ? appointmentFormFields.filter((field) => field !== 'patient_id') : appointmentFormFields
      }
      initialValues={
        appointment
          ? changeNullToBlank(appointment)
          : { ...AppointmentInitialValues, patient_id: patientId ? [{ value: patientId }] : [] }
      }
      callbackAfterSubmit={callbackAfterSubmit}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readonly}
    />
  );
};

export default AppointmentForm;
