/* eslint-disable react-hooks/exhaustive-deps */

import {
  BOOKING_ENTITY_NAME,
  bookingFields,
  bookingFormFields,
  bookingInitialValues,
  createBookingSchema,
} from './BookingModel';

import { FacilityModel } from 'core/model/Entities';
import TemplateForm from '../../../Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';
import { createBooking } from 'company/api/bookings';

type Props = {
  facility: FacilityModel;
  booking?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const BookingForm = (props: Props) => {
  const { facility, booking, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;

  const handleSubmit = async (data: any) => {
    return createBooking(facility.id, data);
  };

  return (
    <TemplateForm
      entityName={BOOKING_ENTITY_NAME}
      fields={bookingFields}
      visibleFields={bookingFormFields}
      entity={booking}
      initialValues={booking ? changeNullToBlank(booking) : bookingInitialValues}
      schema={createBookingSchema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default BookingForm;
