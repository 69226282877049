import { Divider, InputBase, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SIZES } from 'theme/constants';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from 'theme/theme';

type SearchBarProps = {
  handleSearch: (searchKey: string) => void;
  backgroundColor?: string;
  styles?: SxProps<Theme>;
  fullWidth?: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({ handleSearch, backgroundColor, styles, fullWidth }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [search, setSearch] = useState<string>('');
  const prevSearchKeyRef = useRef('');

  const handleSearchClick = () => {
    if (search !== prevSearchKeyRef.current) {
      handleSearch(search);
      prevSearchKeyRef.current = search;
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: fullWidth ? 'none' : '300px',
        backgroundColor: backgroundColor ?? colors.input_background,
        borderRadius: SIZES.borderRadiusS,
        height: '40px',
        paddingLeft: '5px',
        marginLeft: '-10px',
        '@media screen and (max-width: 768px)': { width: '140px', height: '40px', padding: '0px' },
        ...styles,
      }}
    >
      <IconButton type="button" onClick={() => handleSearchClick()}>
        <SearchIcon style={{ fontSize: isMobile ? '20px' : '' }} />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <InputBase
        sx={{ m: SIZES.paddingS, flex: 1, fontSize: '12px' }}
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={handleKeyPress}
        onBlur={handleSearchClick}
      />
    </Box>
  );
};

export default SearchBar;
