import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patient-billing';

export const getInvoices = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: { ...query } });
};

export const getPatientInvoices = (facility_id: number, patient_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patient/${patient_id}`, { params: { ...query } });
};

export const getInvoiceServiceProviders = (facility_id: number, invoice_id: number)=>{
  return axios.get(`${baseURL}/${path}/${facility_id}/service-providers/${invoice_id}`)
}

export const updateInvoiceServiceProvider = (facility_id: number, invoice_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/service-providers/${invoice_id}`, data);
}

export const getInvoice = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getAllPatientInvoicesWithClientId = (facility_id: number, client_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patient/client/${client_id}`, { params: query });
};

export const createInvoice = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};

export const updateInvoice = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deleteInvoice = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const addInvoicePayment = (facility_id: number, id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/add-payment/${id}`, data);
};

export const getAvailableServices = (facility_id: number, invoice_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/services/${invoice_id}`, { params: query });
};

export const getAvailableProducts = (facility_id: number, invoice_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/products/${invoice_id}`, { params: query });
};

export const exportInvoices = (facility_id: number, clinic_name: string, query: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/${facility_id}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', clinic_name + ' Invoices.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const exportPatientInvoices = (
  facility_id: number,
  patient_id: number,
  patient_name: string,
  query: ApiQuery
) => {
  return axios
    .post(
      `${baseURL}/${path}/${facility_id}/export/${patient_id}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', patient_name + ' Invoices.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const createPOS = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/pos`, data);
};

export const createInvoiceAppointment = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/appointment/invoice`, data);
};

export const postInvoice = (facility_id: number, invoiceId: number) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/post/${invoiceId}`);
};

export const reverseInvoice = (facility_id: number, invoiceId: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/reverse/${invoiceId}`, data);
};

export const exportHmoInvoices = (
  facility_id: number,
  hmo_id: number,
  hmo_name: string,
  query: ApiQuery
) => {
  return axios
    .post(
      `${baseURL}/${path}/${facility_id}/export/hmo/${hmo_id}`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', hmo_name + ' Invoices.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
