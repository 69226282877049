/* eslint-disable react-hooks/exhaustive-deps */

import { Dispatch, useEffect, useState } from 'react';

import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { CustomModal } from 'core/components';
import { POS } from 'company/screens/InventorySystem';

type CreateInvoiceProps = {
  appointment: CompanyAppointmentModel;
  orders: any;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  refreshPatientSchedules?: () => void;
  setSelectedAppointment?: Dispatch<any>;
};

const CreateInvoice: React.FC<CreateInvoiceProps> = ({
  appointment,
  orders,
  openModal,
  setOpenModal,
  refreshPatientSchedules,
}) => {
  const [localOrders, setLocalOrders] = useState<any>([]);

  useEffect(() => {
    if (openModal && appointment) {
      setLocalOrders(orders);
    }
  }, [openModal, orders, appointment]);

  return (
    <CustomModal header={'Create Invoice'} open={openModal} setOpen={setOpenModal} width="90%">
      <POS
        appointment={appointment}
        initialOrders={localOrders}
        afterPayment={() => {
          refreshPatientSchedules && refreshPatientSchedules();
          setOpenModal(false);
        }}
        fromAppointmentCreateInvoice
      />
    </CustomModal>
  );
};

export default CreateInvoice;
