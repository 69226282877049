import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface DetailsViewerDataFormat {
  summary: any;
  values: any;
  json?: any;
  isMono?: boolean;
  isJSON?: boolean;
  hasJSON?: boolean;
}

export interface NestedDetailsProps {
  data: any[] | any;
}

export interface DetailsViewerProps {
  data: DetailsViewerDataFormat[];
}

const NestedDetails: React.FC<NestedDetailsProps> = ({ data }) => {
  const arrayOrObjInstance = (data: any) => {
    return typeof data === 'object' || Array.isArray(data);
  };

  return (
    <>
      {Object.keys(data).map((key) => {
        return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'bold'}>{key}</Typography>
            </AccordionSummary>
            {data[key] === null ? (
              <AccordionDetails sx={{ overflowWrap: 'break-word' }}>
                <Typography>[NULL]</Typography>
              </AccordionDetails>
            ) : Array.isArray(data) ? (
              data.map((arrayElement) =>
                arrayOrObjInstance(arrayElement) ? (
                  <NestedDetails data={arrayElement} />
                ) : (
                  <AccordionDetails sx={{ overflowWrap: 'break-word' }}>
                    <Typography>{arrayElement}</Typography>
                  </AccordionDetails>
                )
              )
            ) : arrayOrObjInstance(data[key]) ? (
              Object.keys(data[key]).map((subkey) =>
                arrayOrObjInstance(data[key][subkey]) ? (
                  <NestedDetails data={data[key][subkey]} />
                ) : (
                  <AccordionDetails sx={{ overflowWrap: 'break-word' }}>
                    <Typography>{data[key][subkey]}</Typography>
                  </AccordionDetails>
                )
              )
            ) : (
              <AccordionDetails sx={{ overflowWrap: 'break-word' }}>
                <Typography>{data[key]}</Typography>
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </>
  );
};

const DetailsViewer: React.FC<DetailsViewerProps> = ({ data }) => {
  return (
    <Box gap={3}>
      {data.length > 0
        ? data.map((requestData) => {
            return (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={'bold'}>{requestData.summary}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    fontFamily: requestData.isMono ? 'monospace' : '',
                    fontSize: requestData.isMono ? '11px' : '',
                    overflowWrap: 'break-word',
                  }}
                >
                  {requestData.hasJSON && requestData.json ? (
                    <>
                      <Typography gutterBottom>{requestData.values}</Typography>
                      <NestedDetails data={requestData.json} />
                    </>
                  ) : requestData.isJSON ? (
                    <NestedDetails data={requestData.values} />
                  ) : !requestData.values || requestData.values !== '' ? (
                    <Typography>{requestData.values}</Typography>
                  ) : (
                    <Typography>NULL</Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })
        : 'No request data to be shown'}
    </Box>
  );
};

export default DetailsViewer;
