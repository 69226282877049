/* eslint-disable react-hooks/exhaustive-deps */

import {
  ConfirmationDialog,
  CustomCheckbox,
  CustomForm,
  CustomInlineForm,
  CustomModal,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'core/components';
import { DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { assignClinic, getClinicsAdded, getClinicsNotAdded, removeAssignedClinic } from 'company/api/corporate-clients';
import { assignPackageToClinic, getHealthPackageClinics, removePackagesFromClinic } from 'company/api/health-packages';
import {
  assignServiceToClinic,
  getServicesClinics,
  removeServicesFromClinic,
  updateAllClinicServicePrice,
  updateClinicServicePrice,
  updateServiceVisibility,
} from 'company/api/services';
import { assignUserToClinic, getUserFacilities, removeUsersFromClinic } from 'company/api/users';
import {
  getClinicsWithoutPackageId,
  getClinicsWithoutProviderId,
  getClinicsWithoutServiceId,
} from 'company/api/facility';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/system';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WeeklySchedule from './WeeklySchedule';
import { formatCurrency } from 'core/utils';
import { getDoctorClinics } from 'company/api/doctors';
import { updateFacilityServices } from 'core/components/ProtectedRoute';
import { useSnackbar } from 'notistack';

type ClinicsTableProps = {
  source: 'Services' | 'User Access' | 'Service Providers' | 'Health Services' | 'Clients';
  selectedClient?: any;
  selectedService?: any;
  selectedProvider?: any;
  selectedUser?: any;
  selectedPackage?: any;
  externalRefresh?: number;
  setRefreshExternalDetails?: (refresh: any) => void;
};

const ClinicsTable: React.FC<ClinicsTableProps> = ({
  source,
  selectedClient,
  selectedService,
  selectedProvider,
  selectedUser,
  selectedPackage,
  externalRefresh,
  setRefreshExternalDetails,
}) => {
  const { setFacility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [addId, setAddId] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [service, setService] = useState<any>();
  const [doctor, setDoctor] = useState<any>();
  const [disabled, setDisabled] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clinicsNotAdded, setClinicsNotAdded] = useState<any[]>([]);
  const [deleteContent, setDeleteContent] = useState<string>(
    'Are you sure you want to remove the service from this clinic?'
  );
  const [addContent, setAddContent] = useState<string>('Do you want to add service to this clinic?');
  const [formFields, setFormFields] = useState<any>(null);
  const [formInitialValues, setFormInitialValues] = useState<any>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    setting();
  }, [source, selectedRow, addId]);

  const setting = async () => {
    if (source === 'Clients') {
      selectedClient &&
        getClinicsNotAdded(selectedClient.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'Services') {
      selectedRow &&
        setFormFields([
          { field_name: 'clinic_price', display_name: 'Price (Php)', type: 'text' },
          { field_name: 'provider_id', type: DROPDOWN_FIELD.SERVICE_PROVIDER, optional: true },
        ]);
      selectedRow &&
        setFormInitialValues({ clinic_price: selectedRow.clinic_price, provider_id: selectedRow.provider_id });
      selectedService &&
        getClinicsWithoutServiceId(selectedService.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'Service Providers') {
      setDeleteContent('Are you sure you want to unassign doctor from this facility?');
      setAddContent('Do you want to add provider to this clinic?');
      selectedProvider &&
        getClinicsWithoutProviderId(selectedProvider.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'User Access') {
      setDeleteContent('Are you sure you want to remove user from this facility?');
      setAddContent('Do you want to add user to this clinic?');
      selectedUser &&
        getClinicsWithoutProviderId(selectedUser.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else {
      setDeleteContent('Are you sure you want remove health session from this facility?');
      setAddContent('Do you want to add health session to this clinic?');
      selectedPackage &&
        getClinicsWithoutPackageId(selectedPackage.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    }
  };

  useEffect(() => {
    refreshTable();
    setting();
  }, [selectedService, selectedProvider, selectedUser, selectedPackage, externalRefresh]);

  const getData = async () => {
    if (source === 'Clients') {
      return selectedClient && getClinicsAdded(selectedClient.id);
    } else if (source === 'Services') {
      return selectedService && getServicesClinics(selectedService.id);
    } else if (source === 'Service Providers') {
      return selectedProvider && getDoctorClinics(selectedProvider.id);
    } else if (source === 'User Access') {
      return selectedUser && getUserFacilities(selectedUser.id);
    } else {
      return selectedPackage && getHealthPackageClinics(selectedPackage.id);
    }
  };

  const handleUpdateFormSubmit = async (data: any) => {
    if (source === 'Services') {
      setLoading(true);
      selectedRow &&
        selectedService &&
        updateClinicServicePrice(selectedRow.facility_id, selectedService.id, data)
          .then(() => {
            enqueueSnackbar('Service successfully updated!', { variant: 'success' });
          })
          .finally(() => {
            setLoading(false);
            setOpenUpdateModal(false);
            refreshTable();
            localStorage.removeItem('orders');
          });
    }
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const handleDeleteConfirm = (data: any) => {
    setData(data);

    if (source === 'Clients') {
      setDeleteContent('Are you sure you want to remove the client from this clinic?');
    }
    setOpenConfirmDialog(true);
  };

  const handleDelete = () => {
    if (source === 'Clients') {
      return removeAssignedClinic({ facilities: [data.id], corporate_client_id: selectedClient.id })
        .then(() => {
          setForceRefresh((prev: number) => prev + 1);
          enqueueSnackbar('Clinic successfully removed!', { variant: 'success' });
          setting();
          setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
        })
        .then(() => localStorage.removeItem('orders'));
    } else if (source === 'Services') {
      const serviceList = JSON.stringify(data ? [data.service_id] : selectedRows);
      const services = { services: serviceList };

      return removeServicesFromClinic(data.facility_id, services)
        .then(() => {
          setForceRefresh((prev: number) => prev + 1);
          updateFacilityServices(setFacility);
          enqueueSnackbar('Service successfully removed!', { variant: 'success' });
          setting();
          setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
        })
        .then(() => localStorage.removeItem('orders'));
    } else if (source === 'Service Providers') {
      const doctorList = JSON.stringify(data ? [data.user_id] : selectedRows);
      const doctors = { users: doctorList };

      return removeUsersFromClinic(data.facility_id, doctors).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Service Provider successfully unassigned!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'User Access') {
      const userList = JSON.stringify(data ? [data.user_id] : selectedRows);
      const users = { users: userList };

      return removeUsersFromClinic(data.facility_id, users).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('User successfully removed!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else {
      const packageList = JSON.stringify(data ? [data.package_id] : selectedRows);
      const packages = { packages: packageList };

      return removePackagesFromClinic(data.clinic_id, packages).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Health Session successfully removed!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    }
  };

  const handleAdd = () => {
    if (source === 'Clients') {
      return assignClinic({ facilities: [addId], corporate_client_id: selectedClient.id }).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Clinic successfully removed!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'Services') {
      const addData = { data: JSON.stringify([selectedService && selectedService.id]) };

      return assignServiceToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Service successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'Service Providers') {
      const addData = { data: JSON.stringify([selectedProvider && selectedProvider.id]) };

      return assignUserToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Service Provider successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'User Access') {
      const addData = { data: JSON.stringify([selectedUser && selectedUser.id]) };

      return assignUserToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('User successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else {
      const addData = { data: JSON.stringify([selectedPackage && selectedPackage.id]) };

      return assignPackageToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Health Session successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    }
  };

  const setCustomSchedule = async (data: any) => {
    setOpenScheduleModal(true);
    selectedService && setService(data);
    selectedProvider && setDoctor(data);
    selectedService && setDisabled(!!!data.available_schedule);
    selectedProvider && setDisabled(!!!data.schedule);
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddClick = (data: any) => {
    setAddId(data);
    setOpenAddModal(true);
  };

  const setVisibility = (data: any) => {
    const serviceList = JSON.stringify(data ? [data.service_id] : selectedRows);
    const services = {
      services: serviceList,
      visible: !data.visible,
    };

    updateServiceVisibility(data.facility_id, services)
      .then((res: any) => {
        localStorage.removeItem('orders');
        enqueueSnackbar('Service visibility successfully updated!', { variant: 'success' });
        refreshTable();
        setService(undefined);
        updateFacilityServices(setFacility);
      })
      .catch((error) => console.error(error));
  };

  const priceFields: FieldInput[] = [
    {
      field_name: 'clinic_price',
      display_name: 'Price',
      type: 'text',
      span: 1,
    },
  ];

  const handleUpdatePriceAll = async (data: any) => {
    if (source === 'Services') {
      setLoading(true);
      selectedService &&
        updateAllClinicServicePrice(selectedService?.id, data)
          .then(() => {
            enqueueSnackbar('Service successfully updated!', { variant: 'success' });
          })
          .finally(() => {
            setLoading(false);
            setOpenUpdateModal(false);
            refreshTable();
            localStorage.removeItem('orders');
          });
    }
  };

  const columns: GridColDef[] =
    source === 'Services'
      ? [
          { field: 'facility_name', headerName: 'Clinic Name', flex: 2 },
          {
            field: 'clinic_price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => <Typography>{formatCurrency(params.value)}</Typography>,
            align: 'right',
            headerAlign: 'right',
          },
          {
            field: 'full_name',
            headerName: 'Service Provider',
            flex: 2,
            sortable: false,
          },
          {
            field: 'visible',
            headerName: 'Visibility',
            flex: 1,
            renderCell: (params) => <Typography>{params.row.visible ? 'Visible' : 'Hidden'}</Typography>,
          },

          {
            field: 'action',
            sortable: false,
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
              <div>
                <RowAction actions={actions} data={params.row} getRow={setSelectedRow} />
              </div>
            ),
          },
        ]
      : [
          { field: 'facility_name', headerName: 'Clinic Name', flex: 2 },
          {
            field: 'action',
            sortable: false,
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
              <div>
                <RowAction actions={actions} data={params.row} getRow={setSelectedRow} />
              </div>
            ),
          },
        ];

  const actions: DropdownOptionProps[] =
    source === 'Services'
      ? [
          {
            label: 'Update Clinic Service',
            action: () => {
              setOpenUpdateModal(true);
            },
            icon: <PriceChangeOutlinedIcon />,
          },
          { label: 'Set Custom Schedule', action: setCustomSchedule, icon: <CalendarMonthOutlinedIcon /> },
          {
            label: selectedRow && selectedRow.visible ? 'Hide Service' : 'Show Service',
            action: setVisibility,
            icon: selectedRow && selectedRow.visible ? <VisibilityOffIcon /> : <VisibilityIcon />,
          },
          { label: 'divider' },
          { label: 'Delete', color: 'error', action: handleDeleteConfirm, icon: <DeleteOutlinedIcon /> },
        ]
      : source === 'Service Providers'
      ? [
          { label: 'Delete', action: handleDeleteConfirm, icon: <DeleteOutlinedIcon /> },
          { label: 'Update Schedule', action: setCustomSchedule, icon: <DateRangeIcon /> },
        ]
      : [{ label: 'Delete', action: handleDeleteConfirm, icon: <DeleteOutlinedIcon /> }];
  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Clinic',
        doNotWaitForFacility: true,
        useColoredTable: true,
        tableComponent: {
          // hideSearch: isMobilePhone,
          columns: columns,
          getData: getData,
          forceRefresh: forceRefresh,
          // checkboxSelection: true,
          selectableRow: true,

          afterSearchbarComponent: (
            <Box display="flex" gap="10px">
              {!isMobilePhone && (
                <ProtectedComponent requiredRole={['COMPANY_ADMIN']}>
                  <RegularButton
                    styles={{ width: '150px' }}
                    label={`Add Clinics`}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  />
                </ProtectedComponent>
              )}

              <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                keepMounted
                sx={{
                  marginTop: '0.5rem',
                }}
              >
                <Box>
                  {clinicsNotAdded.length === 0 ? <MenuItem disabled={true}>All clinics are added</MenuItem> : <></>}
                  {clinicsNotAdded &&
                    clinicsNotAdded.map((clinic) => (
                      <MenuItem key={clinic.facility_name} value={clinic.id} onClick={() => handleAddClick(clinic.id)}>
                        {clinic.facility_name}
                      </MenuItem>
                    ))}
                </Box>
              </Menu>
            </Box>
          ),
          onSelectRow: handleSelectRows,
          rowHeight: 60,
          headerComponent: isMobilePhone ? (
            <Box sx={{ flex: 2 }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>Clinics</Typography>
              {source === 'Services' && (
                <CustomInlineForm
                  submitButtonSize="small"
                  fields={priceFields}
                  onSubmit={handleUpdatePriceAll}
                  initialValues={{ clinic_price: '' }}
                  submitButtonText="Apply To All"
                  enableRowButtonMobile={true}
                />
              )}
            </Box>
          ) : (
            <Box>
              <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>Clinics</Typography>
              {source === 'Services' && (
                <CustomInlineForm
                  submitButtonSize="small"
                  fields={priceFields}
                  onSubmit={handleUpdatePriceAll}
                  initialValues={{ clinic_price: '' }}
                  submitButtonText="Apply To All"
                />
              )}
            </Box>
          ),
        },
        tableAction: {
          getRow: setService,
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />

      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        content={deleteContent}
        onConfirm={handleDelete}
      />
      <ConfirmationDialog open={openAddModal} setOpen={setOpenAddModal} content={addContent} onConfirm={handleAdd} />

      <CustomModal open={openUpdateModal} setOpen={setOpenUpdateModal} header={'Update Clinic Service'}>
        <CustomForm
          fields={formFields && formFields}
          initialValues={formInitialValues && formInitialValues}
          onSubmit={handleUpdateFormSubmit}
          loading={loading}
        />
      </CustomModal>

      <CustomModal
        header={source === 'Services' ? 'Set Custom Schedule' : 'Update Schedule'}
        subHeader={
          source === 'Services'
            ? service?.service_name
            : selectedProvider?.full_name_with_title
            ? selectedProvider?.full_name_with_title
            : selectedProvider?.full_name
        }
        open={openScheduleModal}
        setOpen={setOpenScheduleModal}
        width={800}
      >
        <Box>
          {(service || doctor) && (
            <Box display={'flex'} gap="2px" alignItems={'center'}>
              <CustomCheckbox
                label={'Use Clinic Schedule'}
                value={disabled}
                handleChange={() => {
                  setDisabled((prev: boolean) => !prev);
                }}
                fieldName={''}
              />
            </Box>
          )}
        </Box>
        {(service || doctor) && (
          <WeeklySchedule
            schedule={source === 'Services' ? service.available_schedule : doctor.schedule}
            id={source === 'Services' ? service.service_id : doctor.assignment_id}
            type={source === 'Services' ? 'service' : 'doctor'}
            refreshTable={refreshTable}
            disabled={disabled}
            clinic_id={source === 'Services' ? service.facility_id : doctor.facility_id}
          />
        )}
      </CustomModal>
    </>
  );
};

export default ClinicsTable;
