import {
  SERVICE_ENTITY_NAME,
  serviceCreateFormFields,
  serviceFields,
  serviceFormFields,
  serviceInitialValues,
  update_service_schema,
} from './ServiceModel';
import { createClinicService, updateClinicService } from 'company/api/services';
import { useContext, useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';
import { changeNullToBlank } from 'core/utils';

type Props = {
  service?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const ServiceForm = (props: Props) => {
  const { service, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);

  useEffect(() => {
    if (user) {
      setDropdownOptions({
        facilities: user
          ? user?.facilities?.map((facility) => ({
              key: facility.facility_name,
              value: facility.id,
            }))
          : [],
      });
    }
  }, [user]);

  const handleSubmit = async (data: any) => {
    if (data.facilities) {
      var assigned_facilities = data.facilities.map((facility: { value: { toString: () => any } }) =>
        facility.value.toString()
      );
      data.assigned_facilities = JSON.stringify(assigned_facilities);
    }
    return service ? updateClinicService(service.id, data) : createClinicService(data);
  };

  useEffect(() => {
    if (service) {
      const facilitiesArray = service.assigned_facilities?.split(', ') ?? [];
      const idsArray = service.assigned_facilities_ids?.split(',').map(Number) ?? [];

      const selectedClinics = facilitiesArray.map((key: any, index: string | number) => ({
        key,
        value: idsArray[index],
      }));
      service.facilities = service.assigned_facilities?.length > 0 ? selectedClinics : [];
    }
  }, [service]);

  return (
    <TemplateForm
      submitButtonId={'create_service_submit_button'}
      entityName={SERVICE_ENTITY_NAME}
      fields={serviceFields}
      visibleFields={service ? serviceFormFields.filter((field) => field !== 'price') : serviceCreateFormFields}
      dropdownOptions={dropdownOptions}
      entity={service}
      initialValues={service ? changeNullToBlank(service) : serviceInitialValues}
      schema={update_service_schema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ServiceForm;
