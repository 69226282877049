import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'note-templates';

export const getTemplates = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getTemplatesNotAdded = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-added`, { params: query });
};

export const getTemplate = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getCareGoTemplate = (id: number) => {
  return axios.get(`${baseURL}/${path}/carego/${id}`);
};

export const getCareGoTemplates = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/carego`, { params: query });
};

export const createTemplate = (template: any) => {
  return axios.post(`${baseURL}/${path}`, template);
};

export const updateTemplate = (template: any, id: number) => {
  return axios.put(`${baseURL}/${path}/${id}`, template);
};

export const updateTemplateTableFormat = (table_format: any, id: number) => {
  return axios.patch(`${baseURL}/${path}/table-format/${id}`, { table_format });
};

export const deleteTemplate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
