/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, SelectOption } from 'core/model/interface';
import {
  CorporateClientForm,
  HealthPackageForm,
  MedicineForm,
  PatientGroupForm,
  ProductForm,
  ServiceForm,
  ServiceProviderForm,
  SupplierForm,
} from 'company/entities/forms';
import { Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AttributeValueForm from 'company/entities/modules/InventorySystem/AttributeValue/AttributeValueForm';
import Autocomplete from '@mui/material/Autocomplete';
import CorporateClientDepartmentForm from 'company/screens/CorporateClients/components/Departments/CorporateClientDepartmentForm';
import { CustomModal } from 'core/components';
import ExpenseTypeForm from 'company/entities/modules/AccountingSystem/ExpenseType/ExpenseTypeForm';
import { FacilityContext } from 'core/context/facility.context';
import { HmoForm } from 'company/screens/CompanyHMOs/components/HMOModel';
import ICDCodeForm from 'company/entities/modules/ClinicManagement/ICDCode/ICDCodeForm';
import PatientForm from 'company/entities/modules/ClinicManagement/Patient/PatientForm';
import ProductCategoryForm from 'company/entities/modules/InventorySystem/ProductCategory/ProductCategoryForm';
import ProductVariantForm from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantForm';
import TextField from '@mui/material/TextField';
import UserGroupForm from 'company/entities/modules/UserManagement/UserGroup/UserGroupForm';
import { debounce } from '@mui/material/utils';
import { tokens } from 'theme/theme';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
export interface TemplateDropdownProps {
  id?: string;
  displayName?: string;
  initialValue?: any;
  fieldName?: string;
  span?: number;
  touched?: boolean;
  error?: any;
  disabled?: boolean;
  optional?: boolean;
  size?: 'small' | 'medium' | undefined;
  width?: string;
  limit?: number;
  limitTags?: number;
  multiple?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleChangeCallback?: (value: any) => void;
  getById?: (id: number) => Promise<any>;
  processNewRecord?: (newRecord: any) => any;
  data?: any;
  hideAddOption?: boolean;
  useTemporaryMultipleInitialValueSolution?: boolean;
  preventRemovalOfInitialValue?: boolean;
}

interface Props extends TemplateDropdownProps {
  entity: string;
  refresh?: number;
  getData: (query: ApiQuery) => Promise<any>;
  processOption: (option: any) => SelectOption;
}

const TemplateDropdown: React.FC<Props> = ({
  id,
  entity,
  displayName,
  initialValue,
  fieldName,
  span,
  handleChangeCallback,
  setFieldValue,
  disabled,
  optional,
  touched,
  error,
  size = 'small',
  width,
  limit,
  limitTags,
  refresh,
  getData,
  getById,
  processOption,
  processNewRecord,
  multiple,
  data,
  hideAddOption,
  useTemporaryMultipleInitialValueSolution = false,
  preventRemovalOfInitialValue = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState<any>(multiple ? [] : '');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly SelectOption[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const { facility } = useContext(FacilityContext);
  const [newRecord, setNewRecord] = useState<any>();
  const [firstLoad, setFirstLoad] = useState(true);

  const display_name = useMemo(() => displayName ?? entity, [displayName, entity]);

  const fetch = useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results: readonly any[]) => void) => {
        const res = await getData({ length: 10, page: 1, order_by: 'created_at', search: request.input });
        callback(res.data.data);
      }, 400),
    [getData]
  );

  useEffect(() => {
    if (!multiple) {
      if (initialValue && getById) {
        getById(initialValue).then((res) => {
          const newRecord = res.data;
          setNewRecord(newRecord);
          setOptions([processOption(newRecord)]);
        });
      } else {
        setValue('');
      }
    }
  }, [initialValue]);

  useEffect(() => {
    if (!useTemporaryMultipleInitialValueSolution && firstLoad && multiple && initialValue && options.length) {
      setValue(initialValue);
      setFirstLoad(false);
    }
  }, [initialValue, options]);

  useEffect(() => {
    if (multiple && firstLoad && initialValue && options.length && useTemporaryMultipleInitialValueSolution) {
      // Ensures that the options are loaded before setting the initial value
      const matchingOptions = options.filter((option) =>
        initialValue.some((initial: any) => initial.value === option.value)
      );
      if (matchingOptions.length) {
        setValue(matchingOptions);
        setFirstLoad(false);
      }
    }
  }, [initialValue, options, firstLoad, multiple]);

  useEffect(() => {
    if (fieldName && setFieldValue) setFieldValue(fieldName, multiple ? value : value.value ?? '');
  }, [fieldName, multiple, setFieldValue, value]);

  useEffect(() => {
    if (inputValue) setOptions([{ key: 'Searching...', value: 'search-loading' }]);
    fetch({ input: inputValue }, (results: readonly any[]) => {
      const options: SelectOption[] =
        results?.map((option) => {
          return processOption(option);
        }) ?? [];
      if (!options.length) options.push({ key: display_name + ' not found', value: 'not-found' });
      if (!hideAddOption) options.push({ key: 'Create ' + display_name, value: 'create-new', icon: <AddIcon /> });
      setOptions(options);
    });
  }, [inputValue, refresh]);

  useEffect(() => {
    if (newRecord && processOption) {
      const processed = processOption(newRecord);
      if (multiple) {
        handleChangeCallback && handleChangeCallback([...value, processed]);
        setValue((prev: any) => [...prev, processed]);
      } else {
        setValue(processed);
        handleChangeCallback && handleChangeCallback(processed);
      }
      setNewRecord(undefined);
    }
  }, [options, newRecord, processOption]);

  const handleSelectChange = (event: any, value: any) => {
    let selected;
    if (multiple) selected = value.length ? value[value.length - 1].value : '';
    else selected = value?.value ?? '';

    if (selected === 'create-new') {
      setOpenCreateModal(true);
    } else if (selected !== '') {
      setValue(value);
      //   const preselected = patients?.find((patient) => patient.patient_id === selected);
      handleChangeCallback && handleChangeCallback(value);
    } else {
      setValue(multiple ? [] : '');
      handleChangeCallback && handleChangeCallback(null);
    }
  };

  const handleSelectChangeAvoidRemovalOfInitialValue = (event: any, selectedValue: any) => {
    if (multiple) {
      // Filter out any options that are part of initialValue and restore them
      const protectedValues = initialValue ?? [];
      const newValue = selectedValue.filter(
        (option: any) => !protectedValues.some((initial: any) => initial.value === option.value)
      );

      // Merge the protected initial values with the updated selection
      const updatedValue = [...newValue, ...protectedValues];
      setValue(updatedValue); // Update the state, ensuring initialValues are kept

      handleChangeCallback && handleChangeCallback(updatedValue);
    } else {
      setValue(selectedValue ? selectedValue.value : '');
      handleChangeCallback && handleChangeCallback(selectedValue);
    }
  };

  const handleSubmit = (data: any) => {
    const newRecord = processNewRecord ? processNewRecord(data) : data.data;
    if (!newRecord) console.error('Cannot process new record');
    setNewRecord(newRecord);
    setOptions([processOption(newRecord)]);
    setOpenCreateModal(false);
  };

  return (
    <>
      <CustomModal header={'Create ' + display_name} open={openCreateModal} setOpen={setOpenCreateModal}>
        {entity === 'Medicine' && <MedicineForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Patient' && <PatientForm facility={facility} callbackAfterSubmit={handleSubmit} />}
        {entity === 'Product Category' && <ProductCategoryForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Service' && <ServiceForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Service Provider' && <ServiceProviderForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Supplier' && <SupplierForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Corporate Client' && <CorporateClientForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Patient Group' && <PatientGroupForm callbackAfterSubmit={handleSubmit} facility={facility} />}
        {entity === 'User Group' && <UserGroupForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Product' && <ProductForm callbackAfterSubmit={handleSubmit} source={'dropdown'} />}
        {entity === 'Expense Type' && <ExpenseTypeForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Health Session' && <HealthPackageForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Product Variant' && <ProductVariantForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'ICD-10 Code' && <ICDCodeForm callbackAfterSubmit={handleSubmit} />}
        {entity === 'Corporate Client Department' && (
          <CorporateClientDepartmentForm callbackAfterSubmit={handleSubmit} source="dropdown" />
        )}
        {entity === 'Variant Attribute Value' && (
          <AttributeValueForm callbackAfterSubmit={handleSubmit} attribute_id={data?.attribute_id} />
        )}
        {entity === 'HMO' && <HmoForm callbackAfterSubmit={handleSubmit} />}
      </CustomModal>

      <Autocomplete
        id={id}
        multiple={multiple}
        limitTags={limitTags ? limitTags : 3}
        disabled={disabled}
        getOptionDisabled={(option) =>
          (value.length && value.length >= (limit ? limit : 5)) ||
          option.value === 'not-found' ||
          option.value === 'search-loading'
        }
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.key)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={display_name + ' not found'}
        onChange={preventRemovalOfInitialValue ? handleSelectChangeAvoidRemovalOfInitialValue : handleSelectChange}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={(displayName ?? 'Search ' + display_name) + (optional ? ' (optional)' : '')}
            placeholder={'Search ' + display_name}
            error={!!touched && !!error}
            helperText={touched && error}
            size={size}
            sx={{
              '.MuiFormLabel-root.Mui-focused': { color: colors.light },
              '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
              '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
              '.MuiFilledInput-root:before': { border: 'none' },
              '.Mui-disabled:before': { border: 'none !important' },
              '.MuiFilledInput-root:after': {
                borderBottom: `1px solid ${colors.primary}`,
                borderRadius: 2,
                height: '100%',
              },
              '.MuiFilledInput-input': { height: '22px' },
              '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
              gridColumn: span ? 'span ' + span : undefined,
              width: width && width,
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              {option.icon && (
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: 1,
                    marginRight: '10px',
                    '& .MuiSvgIcon-root': {
                      fontSize: '15px',
                    },
                  }}
                >
                  {option.icon}
                </Typography>
              )}
              {option.key}
            </li>
          );
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        sx={{
          gridColumn: span ? 'span ' + span : undefined,
        }}
      />
    </>
  );
};

export default TemplateDropdown;
