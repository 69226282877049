import { ApiQuery } from 'core/model/interface';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-patients';

export const getPatients = (facility_id: number, query?: ApiQuery, tags?: any) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: { ...tags, ...query } });
};
export const getAllPatients = (facility_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/all`);
};

export const getPatient = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createPatient = (facility_id: number, patient: PatientModel) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, patient);
};

export const updatePatient = (facility_id: number, id: number, patient: PatientModel) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, patient);
};

export const deletePatient = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getPatientsNotEnrolledInProgram = (facility_id: number, program_code: string, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/not-enrolled/${program_code}`, {
    params: query,
  });
};

export const getPatientAppointments = (facility_id: number, id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/appointments/${id}`, { params: query });
};

export const getPatientHealthPrograms = (facility_id: number, id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/health-programs/${id}`, { params: query });
};

export const getPatientCampaigns = (facility_id: number, id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/campaigns/${id}`, { params: query });
};

export const importPatients = (file: any) => {
  return axios.post(
    `${baseURL}/${path}/import`,
    { file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const getPatientsWithTags = (facility_id: number, tags: any, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/with-tags`, { params: { ...tags, ...query } });
};

export const getPatientsWithGroupId = (facility_id: number, group_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${group_id}/with-group`, { params: query });
};

export const getPatientsWithClientId = (facility_id: number, client_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/client/${client_id}`, { params: query });
};

export const updatePatientProfilePhoto = (facility_id: number, id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/profile-photo/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  // return axios.patch(`${baseURL}/${path}/${facility_id}/profile-photo/${id}`, data);
};

// export const updatePatientProfilePhoto = async (facility_id: number, id: number, formData: FormData) => {
//   try {
//     const response = await axios.patch(
//       `${baseURL}/${path}/${facility_id}/profile-photo/${id}`,
//       formData,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error updating patient profile photo:', error);
//     throw error;
//   }
// };
