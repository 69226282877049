import { Box, useMediaQuery } from '@mui/material';
import { getTotalPaid, getTotalPaidCustom, getTotalRemainingBalance } from 'company/api/billing-payment';
import { useContext, useEffect, useState } from 'react';

import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import { FacilityContext } from 'core/context/facility.context';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { PatientBillingTable } from 'company/entities/tables';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { SIZES } from 'theme/constants';
import { WaitForFacility } from 'company/components';
import { formatCurrency } from 'core/utils';
import { useLocation } from 'react-router-dom';

type Props = {
  patient: PatientModel;
};

const PatientTransactions: React.FC<Props> = ({ patient }) => {
  const { facility } = useContext(FacilityContext) || {};
  const [totalAmountPaid, setTotalAmountPaid] = useState('P 0.00');
  const [totalRemainingBalance, setTotalRemainingBalance] = useState('P 0.00');
  const [refreshWidgets, setRefreshWidgets] = useState(0);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const location = useLocation();

  // retrieve the balances
  useEffect(() => {
    if (facility && facility.id && patient) {
      getTotalRemainingBalance(facility.id, { patient_id: patient.id }).then((response) => {
        setTotalRemainingBalance(formatCurrency(response.data.data));
      });
      getTotalPaidCustom(facility.id, { patient_id: patient.id }).then((response) => {
        setTotalAmountPaid(formatCurrency(response.data.data));
      });
    }
  }, [facility, patient, refreshWidgets, location]);

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={SIZES.padding}>
      {facility ? (
        <WaitForFacility facility={facility}>
          <Box display={'flex'} width={isMobilePhone ? '100%' : '60%'} gap="5px">
            <CustomDashboardWidget count={totalAmountPaid} label="Total Amount Paid" icon={PaidOutlinedIcon} />
            <CustomDashboardWidget
              count={totalRemainingBalance}
              label="Total Remaining Balance"
              icon={PaidOutlinedIcon}
            />
          </Box>
          <PatientBillingTable useColoredTable setRefreshWidgets={setRefreshWidgets} patient={patient} />
        </WaitForFacility>
      ) : (
        <div>Loading facility data...</div>
      )}
    </Box>
  );
};

export default PatientTransactions;
