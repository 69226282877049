import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { displayAttribute, formatCurrency, formatNumber } from 'core/utils';

import Chip from '@mui/material/Chip';
import { ContainerColumn } from 'core/components/containers';
import { Labels } from '../styles/styles';
import { Link } from 'react-router-dom';
import { OrderProps } from '..';
import { RegularButton } from 'core/components';
import { SIZES } from 'theme/constants';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { tokens } from 'theme/theme';
import { useMemo } from 'react';

export interface LineItem {
  id: number;
  product_id: number;
  variant_price: number;
  price?: number;
  quantity: number;
  product_name: string;
  variant_name: string;
  quantity_in_stock: number;
  made_to_order?: boolean;
  service_name: string;
  attributes: string;
}

type Props = {
  item: LineItem;
  addToCart: (item: any) => void;
  type: 'product' | 'service';
  viewMode: 'grid' | 'list';
  orders: OrderProps[];
};

const POSLineItem: React.FC<Props> = ({ item, addToCart, type, viewMode, orders }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const itemAttributes = useMemo(() => {
    return displayAttribute(item.attributes);
  }, [item]);

  const itemsInCart = useMemo(
    () =>
      type === 'service'
        ? 0
        : orders
            .filter((order) => order.item_id === item.id && order.item_type === 'product')
            .reduce((result, order) => result + order.quantity, 0),
    [item.id, orders, type]
  );

  const MadeToOrderTag = () => {
    return item.made_to_order ? (
      <Chip label="Made to Order" variant="outlined" color="primary" sx={{ fontSize: '9px' }} size="small" />
    ) : (
      <></>
    );
  };

  const AddToCartButton = () => {
    const gridView = viewMode === 'grid';
    return (
      <RegularButton
        startIcon={<ShoppingCartOutlinedIcon sx={{ marginRight: gridView ? undefined : '-12px' }} />}
        size="small"
        label={gridView ? 'Add to Cart' : ''}
        onClick={() => addToCart({ ...item, type, unit_price: item.price })}
        styles={{ padding: '6px 6px' }}
        disabled={type !== 'service' && item.quantity_in_stock - itemsInCart <= 0 && !item.made_to_order}
        fullWidth={gridView}
      />
    );
  };

  const ItemLabel = () => {
    return type === 'service' ? (
      <Tooltip title={item.service_name}>
        <Labels color={colors.text} sx={{ '&:hover': { color: colors.accent } }}>
         {item.service_name}
        </Labels>
      </Tooltip>
    ) : (
      <Tooltip title={`${item.product_name}`}>
        <Link
          to={`/company/inventory/products/variant?id=${item?.id}`}
          style={{ textDecoration: 'none', fontWeight: 700 }}
          state={{ from: '/company/inventory/pos' }}
        >
          <Labels color={colors.text} sx={{ '&:hover': { color: colors.accent } }}>
            {`${item.product_name}`}
          </Labels>
        </Link>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        position: 'relative',
      }}
    >
      {viewMode === 'grid' ? (
        <Box
          justifyContent={'space-between'}
          sx={{
            backgroundColor: colors.light_blue_background_2,
            padding: '15px',
            width: '100%',
            borderRadius: '20px',
            height: '100%',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ItemLabel />
              <MadeToOrderTag />
            </Box>
            <Typography fontWeight={600} fontSize={'14px'} color={colors.accent}>
              {formatCurrency(item.price)}
            </Typography>
          </Box>
          <Box sx={{ height: type === 'product' ? '80px' : '50px' }} />
          <ContainerColumn gap="5px" sx={{ position: 'absolute', bottom: 0, left: 0, padding: '15px' }}>
            {type === 'product' && (
              <Box>
                {itemAttributes}
                {!item.made_to_order && (
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    color="#888"
                    lineHeight={1}
                    fontSize={'14px'}
                    marginTop="3px"
                  >
                    Available Qty: {formatNumber(item.quantity_in_stock - itemsInCart, 0)}
                  </Typography>
                )}
              </Box>
            )}
            <AddToCartButton />
          </ContainerColumn>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={SIZES.paddingS}
          sx={{
            backgroundColor: colors.light_blue_background_2,
            padding: '10px 20px',
            width: '100%',
            borderRadius: '12px',
          }}
          width="100%"
        >
          <Box width="250px">
            <ItemLabel />
            <MadeToOrderTag />
            {type === 'product' && !item.made_to_order && (
              <Typography
                variant="subtitle1"
                fontWeight={500}
                color="#888"
                lineHeight={1}
                fontSize={'14px'}
                marginTop="3px"
              >
                Available Qty: {formatNumber(item.quantity_in_stock - itemsInCart, 0)}
              </Typography>
            )}
          </Box>
          <Box width="100px">
            <Typography fontWeight={600} fontSize={'14px'} color={colors.accent} textAlign="right">
              {formatCurrency(item.price)}
            </Typography>
          </Box>
          <Box width="200px">{itemAttributes}</Box>
          <AddToCartButton />
        </Box>
      )}
    </Box>
  );
};

export default POSLineItem;
