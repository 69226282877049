/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomModal,
  HideOrShowComponent,
  RegularButton,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { deleteDoctor, getDoctor, getDoctorClinics, getDoctors } from 'company/api/doctors';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ClinicsTable from '../ClinicsTable';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import KeyIcon from '@mui/icons-material/Key';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import PersonIcon from '@mui/icons-material/Person';
import RichTextEditor from 'core/components/RichTextEditor';
import { ServiceProviderForm } from 'company/entities/forms';
import { formatArray } from 'core/utils';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type DoctorsProps = {
  selectedTab: number;
};

const Doctors: React.FC<DoctorsProps> = ({ selectedTab }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [doctor, setDoctor] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedDoctor, setSelectedDoctor] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [addedDoctor, setAddedDoctor] = useState<number>();
  const [updatedDoctor, setUpdatedDoctor] = useState<any>();
  const [deletedDoctor, setDeletedDoctor] = useState<any>();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [updatedClinics, setUpdatedClinics] = useState<number>(0);
  const [openActionMobile, setOpenActionMobile] = useState<boolean>(false);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const handleUpdate = () => {
    setOpenModal(true);
    setIsAdd(false);
    setModalHeader(`Update ${doctor?.full_name}`);
  };

  //actions
  const actions: DropdownOptionProps[] = [
    {
      label: 'Update',
      action: handleUpdate,
      icon: <EditOutlinedIcon />,
    },
    {
      label: 'Delete',
      action: () => setOpenDeleteDialog(true),
      icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
      color: 'error',
    },
  ];

  const displayItem = (item: any) => {
    const isSelected = selectedDoctor === item.doctor_id;
    return (
      <ContainerRow>
        <Typography variant="h5" color={isSelected && !isMobilePhone ? 'white' : colors.text} fontWeight="600">
          {item.full_name}
        </Typography>
      </ContainerRow>
    );
  };

  const handleDelete = async () => {
    if (doctor) {
      try {
        await deleteDoctor(doctor.id);
        setDeletedDoctor(doctor.doctor_id);
        enqueueSnackbar('Doctor successfully delete!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Doctor not deleted.', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    selectedDoctor &&
      getDoctor(selectedDoctor).then((doctor) => {
        setDoctor(doctor.data);
      });

    doctor && getDoctorClinics(doctor.id).then((doctorClinics) => {});
  }, [selectedDoctor, updatedClinics]);

  useEffect(() => {
    updatedDoctor &&
      getDoctor(updatedDoctor.doctor_id).then((doctor) => {
        setDoctor(doctor.data);
      });
  }, [updatedDoctor]);

  return (
    <>
      {
        <InfiniteScrollContainer
          title="List of Service Providers"
          getData={(query) => getDoctors(query)}
          getSingleData={(id) => getDoctor(id)}
          renderItem={displayItem}
          selected={selectedDoctor}
          setSelected={setSelectedDoctor}
          urlKeyId="doctor"
          addedNewItem={addedDoctor}
          itemIdName="doctor_id"
          updatedItem={updatedDoctor}
          deleteId={deletedDoctor}
          containerHeight="calc(100vh - 170px)"
          headerComponents={
            // isDevMode() ? (
            <CompanyProtectedComponent requiredAuth={['MANAGE_SERVICE_PROVIDERS']}>
              <Box width="100%">
                <RegularButton
                  variant="outlined"
                  startIcon={<AddIcon />}
                  label="Add"
                  onClick={() => {
                    setOpenModal(true);
                    setIsAdd(true);
                    setModalHeader('Service Provider');
                  }}
                  styles={{ width: '100%' }}
                />
              </Box>
            </CompanyProtectedComponent>
            // ) : undefined
          }
          displaySelectedTitle={() => (
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {doctor?.full_name}
            </Typography>
          )}
          actions={!isMobilePhone ? actions : undefined}
        >
          {doctor && (
            <ContainerColumn>
              <ContainerRow>
                <ContainerColumn sx={{ justifyContent: 'flex-start' }} gap="15px">
                  {isMobilePhone ? (
                    <>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ fontSize: '1.25rem', fontWeight: '700', color: colors.accent }}>
                          {doctor.full_name ? doctor.full_name : `${doctor.first_name} ${doctor.last_name}`}
                        </Typography>
                        <MoreHorizIcon
                          sx={{ color: colors.primary, fontSize: '2rem' }}
                          onClick={() => setOpenActionMobile(true)}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <PersonIcon sx={{ color: colors.primary, fontSize: '1.25rem' }} />
                        <Typography sx={{ fontSize: '0.825rem', fontWeight: '600' }}>Personal Information</Typography>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr 1fr"
                        rowGap="15px"
                        columnGap="50px"
                        paddingTop={2}
                      >
                        <PatientField label="First Name" value={doctor.first_name} />
                        <PatientField label="Middle Name" value={doctor.middle_name} />
                        <PatientField label="Last Name" value={doctor.last_name} />
                        <PatientField label="Designation" value={doctor.title ?? ''} />
                        <PatientField label="License No." value={doctor.license_number} />
                        <PatientField label="PTR No." value={doctor.ptr_number} />
                        <PatientField
                          label="Mobile Number"
                          value={formatArray([doctor.mobile_number_1, doctor.mobile_number_2], ', ')}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography variant="h3" fontWeight={700}>
                        Personal Information
                      </Typography>
                      <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr 1fr"
                        rowGap="15px"
                        columnGap="50px"
                        paddingTop={2}
                      >
                        <PatientField label="First Name" value={doctor.first_name} />
                        <PatientField label="Middle Name" value={doctor.middle_name} />
                        <PatientField label="Last Name" value={doctor.last_name} />
                        <PatientField label="Designation" value={doctor.title} />
                        <PatientField label="License No." value={doctor.license_number} />
                        <PatientField label="PTR No." value={doctor.ptr_number} />
                        <PatientField
                          label="Mobile Number"
                          value={formatArray([doctor.mobile_number_1, doctor.mobile_number_2], ', ')}
                        />
                      </Box>
                    </>
                  )}

                  {isMobilePhone ? (
                    <>
                      <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <KeyIcon sx={{ color: colors.primary, fontSize: '1.25rem' }} />
                        <Typography sx={{ fontSize: '0.825rem', fontWeight: '600' }}>User Access</Typography>
                      </Box>
                      <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px">
                        <PatientField label="Username" value={doctor.username} />
                        <PatientField label="Email" value={doctor.email} />
                        <PatientField label="Clinic" value={doctor.assigned_facilities} sx={{ gridColumn: 'span 2' }} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography variant="h3" fontWeight={700} mt={!isMobilePhone ? '3rem' : '1rem'}>
                        User Access
                      </Typography>
                      <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
                        <PatientField label="Username" value={doctor.username} />
                        <PatientField label="Email" value={doctor.email} />
                        <PatientField label="Clinic" value={doctor.assigned_facilities} sx={{ gridColumn: 'span 2' }} />
                      </Box>
                    </>
                  )}

                  {isMobilePhone ? (
                    <HideOrShowComponent hidden={!isDevMode()}>
                      <Box sx={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
                        <AssignmentIndIcon sx={{ color: colors.primary, fontSize: '1.25rem' }} />
                        <Typography sx={{ fontSize: '0.825rem', fontWeight: '600' }}>Profile</Typography>
                      </Box>

                      <Box>
                        <RichTextEditor content={doctor && doctor.profile ? doctor.profile : ''} readOnly />
                      </Box>
                    </HideOrShowComponent>
                  ) : (
                    <HideOrShowComponent hidden={!isDevMode()}>
                      <Typography variant="h3" fontWeight={700} mt={'3rem'}>
                        Profile
                      </Typography>

                      <Box>
                        <RichTextEditor content={doctor && doctor.profile ? doctor.profile : ''} readOnly />
                      </Box>
                    </HideOrShowComponent>
                  )}
                </ContainerColumn>
              </ContainerRow>
              <ClinicsTable
                source="Service Providers"
                selectedProvider={doctor}
                setRefreshExternalDetails={setUpdatedClinics}
              />
            </ContainerColumn>
          )}
        </InfiniteScrollContainer>
      }
      <CustomModal open={openModal} setOpen={setOpenModal} header={modalHeader}>
        <ServiceProviderForm
          serviceProvider={isAdd ? undefined : doctor}
          callbackAfterSubmit={(data) => {
            setOpenModal(false);
            if (isAdd) {
              setAddedDoctor(data.company_doctor.doctor_id);
            } else {
              setUpdatedDoctor(data.company_doctor);
            }
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        bottomDrawerDialog={isMobilePhone}
        title={'Confirmation'}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={`Are you sure you want to delete ${doctor?.full_name} ?`}
        onConfirm={handleDelete}
      />

      <CustomBottomPopover open={openActionMobile} setOpen={setOpenActionMobile} title="Actions">
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <RegularButton
            onClick={() => setOpenModal(true)}
            label="Update"
            startIcon={<EditOutlinedIcon />}
            variant="outlined"
            fullWidth
            styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
          />
          <RegularButton
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
            label="Delete"
            startIcon={<DeleteOutlineOutlinedIcon />}
            variant="outlined"
            fullWidth
            styles={{
              color: colors.redAccent,
              backgroundColor: colors.light_red_background_2,
              border: 'none',
              fontWeight: '400',
            }}
          />
        </Box>
      </CustomBottomPopover>
    </>
  );
};

export default Doctors;
