import { ColorResult, SketchPicker } from 'react-color';
import {
  SERVICE_PROVIDER_ENTITY_NAME,
  ServiceProviderModel,
  createServiceProviderVisibleFields,
  serviceProviderFields,
  serviceProviderInitialValues,
  service_provider_schema,
  service_provider_update_schema,
  updateServiceProviderVisibleFields,
} from './ServiceProviderModel';
import { createDoctor, updateDoctor } from 'company/api/doctors';
import { useContext, useEffect, useState } from 'react';

import TemplateForm from '../../../Template/TemplateForm';
import { UserContext } from 'core/context/user.context';

type Props = {
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  serviceProvider?: ServiceProviderModel;
  callbackAfterSubmit?: (data?: any) => void;
};

const ServiceProviderForm: React.FC<Props> = (props) => {
  const { readOnly, serviceProvider, showSubmitAtTop, callbackAfterSubmit } = props;
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<string>('#fff');
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setDropdownOptions({
        facilities: user
          ? user?.facilities?.map((facility) => ({
              key: facility.facility_name,
              value: facility.id,
            }))
          : [],
      });
    }
  }, [user]);

  const handleColorOnChange = (color: ColorResult) => {
    setCurrentColor(color.hex);
  };

  const handleCreate = (data: any) => {
    data.assigned_facilities = data.facilities
      ? JSON.stringify(data.facilities.map((facility: { value: any }) => facility.value))
      : '[]';

    return createDoctor(data);
  };

  const handleSubmitApiFunction = (data: any) => {
    data.user_group_id = 8; //COMPANY_DOCTOR

    data.assigned_facilities = data.facilities
      ? JSON.stringify(data.facilities.map((facility: { value: any }) => facility.value))
      : '[]';

    return serviceProvider ? updateDoctor(serviceProvider.id, data) : handleCreate(data);
  };

  useEffect(() => {
    if (serviceProvider) {
      const facilitiesArray = serviceProvider.assigned_facilities?.split(', ') ?? [];
      const idsArray = serviceProvider.assigned_facilities_ids?.split(',').map(Number) ?? [];

      const selectedClinics = facilitiesArray.map((key: any, index: any) => ({
        key,
        value: idsArray[index],
      }));

      setDropdownOptions({
        facilities: selectedClinics ? selectedClinics : [],
      });
    }
  }, [serviceProvider]);

  return (
    <TemplateForm
      submitButtonId={'create_provider_submit_button'}
      entityName={SERVICE_PROVIDER_ENTITY_NAME}
      entity={serviceProvider}
      fields={serviceProviderFields}
      initialValues={serviceProvider ? serviceProvider : serviceProviderInitialValues}
      visibleFields={serviceProvider ? updateServiceProviderVisibleFields : createServiceProviderVisibleFields}
      dropdownOptions={dropdownOptions}
      readOnly={readOnly}
      showSubmitAtTop={showSubmitAtTop}
      formSubmitApiFunction={handleSubmitApiFunction}
      schema={serviceProvider ? service_provider_update_schema : service_provider_schema}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ServiceProviderForm;
