/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useMediaQuery } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import CustomFilter, { CheckboxOptionProps } from 'core/components/CustomFilter';
import { CustomForm, CustomModal, HideForInactiveCompany, HideOrShowComponent, PrimaryButton } from 'core/components';
import { formatCurrency, formatDate } from 'core/utils';
import { getTotalPaid, getTotalPaidCustom, getTotalRemainingBalance } from 'company/api/billing-payment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import { ContainerRow } from 'core/components/containers';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import { FacilityContext } from 'core/context/facility.context';
import MobileCareGoPage from 'core/PageBuilder/MobileCareGoPage';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PatientBillingForm from 'company/entities/modules/ClinicManagement/PatientBilling/PatientBillingForm';
import { PatientBillingTable } from 'company/entities/tables';
import { SIZES } from 'theme/constants';
import dayjs from 'dayjs';
import { getClients } from 'company/api/corporate-clients';
import { getCompanyTags } from 'company/api/company-tags';
import { getHMOs } from 'company/api/company-hmos';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { reverseInvoice } from 'company/api/patient-billing';
import { reverse_invoice_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';

type MobilePatientBillingProps = {
  viewInPatientProfile?: boolean;
  viewInInventory?: boolean;
  fromAccounting?: boolean;
  patient?: any;
};

const MobilePatientBilling: React.FC<MobilePatientBillingProps> = ({
  viewInPatientProfile,
  patient,
  viewInInventory,
  fromAccounting,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const tableRef: any = useRef();
  const [selectedRow, setSelectedRow] = useState<any>();

  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState<boolean>(false);
  const [openReverseInvoiceDialog, setOpenReverseInvoiceDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPaidInYear, setTotalPaidInYear] = useState<string>('0.00');
  const [totalPaidCustom, setTotalPaidCustom] = useState<string>('0.00');
  const [totalRemainingBalance, setTotalRemainingBalance] = useState<string>('0.00');
  const [viewUnpaid, setViewUnpaid] = useState<boolean>(false);
  const [otherFilter, setOtherFilter] = useState<any>({});
  const [paymentFilters, setPaymentFilters] = useState<string[]>([]);

  const [tags, setTags] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [hmos, setHmos] = useState<any[]>([]);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const refreshTable = async () => {
    setOpenCreateInvoiceModal(false);
    tableRef.current.refreshTable();
  };

  const getTags = () => {
    if (facility) {
      getCompanyTags(facility.id, { length: 1000 }).then((res) => {
        setTags(res.data.data);
      });
    }
  };

  const getClientsData = () => {
    if (facility) {
      getClients({ length: 1000 }).then((res) => {
        setClients(res.data.data);
      });
    }
  };

  const getHMOsData = () => {
    if (facility) {
      getHMOs({ length: 1000 }).then((res) => {
        setHmos(res.data.data);
      });
    }
  };

  const getDefaultStartDate = (): string => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    return startDate.toISOString().slice(0, 10);
  };

  const getDefaultEndDate = (): string => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return lastDayOfMonth.toISOString().slice(0, 10);
  };

  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([getDefaultStartDate(), getDefaultEndDate()]);

  useEffect(() => {
    if (facility) {
      getTags();
      getClientsData();
      getWidgetData();
      getHMOsData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility.id]);

  useEffect(() => {
    getTotalPaidCustomDate();
    getRemainingBalance();
  }, [otherFilter, filterDateRange]);

  const handleReverseInvoice = async (data: any) => {
    if (selectedRow) {
      return reverseInvoice(facility.id, selectedRow.id, data).then((res) => {
        setOpenReverseInvoiceDialog(false);
        enqueueSnackbar(`Invoice successfully changed to void!`, { variant: 'success' });
        refreshTable();
      });
    }
  };

  const getWidgetData = () => {
    // getTotalPaidInYear();
    getTotalPaidCustomDate();
    getRemainingBalance();
  };

  // const getTotalPaidInYear = async () => {
  //   getTotalPaid(facility.id).then((res) => {
  //     setTotalPaidInYear(formatCurrency(res.data.data));
  //   });
  // };

  const getTotalPaidCustomDate = async (query?: ApiQuery) => {
    const startDate = filterDateRange[0];
    const endDate = filterDateRange[1];

    const dateFilter: { start_date?: string; end_date?: string } = {};

    if (startDate) {
      dateFilter['start_date'] = startDate;
    }

    if (endDate) {
      dateFilter['end_date'] = endDate;
    }

    const finalQuery = { ...dateFilter, ...query, patient_id: patient?.id };

    getTotalPaidCustom(facility?.id, finalQuery).then((res) => {
      setTotalPaidCustom(formatCurrency(res.data.data));
    });
  };

  const getRemainingBalance = async (query?: ApiQuery) => {
    const startDate = filterDateRange[0];
    const endDate = filterDateRange[1];

    const dateFilter: { start_date?: string; end_date?: string } = {};

    if (startDate) {
      dateFilter['start_date'] = startDate;
    }

    if (endDate) {
      dateFilter['end_date'] = endDate;
    }

    const finalQuery = { ...dateFilter, ...query };

    getTotalRemainingBalance(facility.id, finalQuery).then((res) => {
      setTotalRemainingBalance(formatCurrency(res.data.data));
    });
  };

  useEffect(() => {
    const corporate_client_filter = paymentFilters
      .filter((value) => value.startsWith('corporate:'))
      .map((value) => parseInt(value.split(':')[1]));

    const hmo_filter = paymentFilters
      .filter((value) => value.startsWith('hmo:'))
      .map((value) => parseInt(value.split(':')[1]));
    const status_filter = paymentFilters.filter((value) => ['unpaid', 'partial', 'paid'].includes(value));

    setOtherFilter({
      ...otherFilter,
      get_unposted: paymentFilters.includes('unposted'),
      get_reversed: paymentFilters.includes('reversed'),
      corporate_client_filter,
      hmo_filter,
      status_filter,
    });
  }, [paymentFilters]);

  const formatDateRange = (start: any, end: any) => {
    if (isMobilePhone) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        const monthName = startDate.toLocaleString('default', { month: 'short' });
        return `${monthName} ${startDate.getDate()} - ${endDate.getDate()}, ${startDate.getFullYear()}`;
      } else {
        return `${formatDate(start)} - ${formatDate(end)}`;
      }
    } else {
      return `${formatDate(start)} - ${formatDate(end)}`;
    }
  };

  const totalPaid = `Total Paid  ${new Date().getFullYear()}`;
  const labelContentTotalPaid =
    filterDateRange[0] && filterDateRange[1]
      ? `Total Paid ${formatDate(filterDateRange[0])} - ${formatDate(filterDateRange[1])}`
      : filterDateRange[0]
      ? `Total Paid since ${formatDate(filterDateRange[0])}`
      : filterDateRange[1]
      ? `Total Paid until ${formatDate(filterDateRange[1])}`
      : `Total Paid for ${dayjs().format('MMMM')}`;

  const labelContentRemainingBalance =
    filterDateRange[0] && filterDateRange[1]
      ? `Total Remaining Balance ${formatDate(filterDateRange[0])} - ${formatDate(filterDateRange[1])}`
      : filterDateRange[0]
      ? `Total Remaining Balance since ${formatDate(filterDateRange[0])}`
      : filterDateRange[1]
      ? `Total Remaining Balance until ${formatDate(filterDateRange[1])}`
      : `Total Remaining Balance for ${dayjs().format('MMMM')}`;

  useEffect(() => {
    if (!patient) {
      setBreadcrumb([{ label: fromAccounting ? 'Invoices' : 'Patient Transactions' }]);
    }
  }, [patient]);

  useEffect(() => {
    const tags: any[] = [];
    const clients: any[] = [];
    const start_date = filterDateRange[0];
    const end_date = filterDateRange[1];

    setOtherFilter((prev: any) => ({
      ...prev,
      start_date,
      end_date,
      tags: JSON.stringify(tags),
      clients: JSON.stringify(clients),
    }));
  }, [filterDateRange, viewUnpaid]);

  useEffect(() => {
    if (filterDateRange[0] !== undefined || filterDateRange[1] !== undefined) {
      setFilterDateRange(filterDateRange);
    }
  }, [filterDateRange]);

  const filters: CheckboxOptionProps[] = useMemo(() => {
    let list = [
      { label: 'Status', id: 'status', isHeader: true },
      { label: 'Unposted', id: 'unposted' },
      { label: 'Unpaid', id: 'unpaid' },
      { label: 'Partial', id: 'partial' },
      { label: 'Paid', id: 'paid' },
      { label: 'Reversed', id: 'reversed' },
    ];

    if (hmos.length) {
      list = [
        ...list,
        { label: 'HMO', id: 'hmo', isHeader: true },
        ...hmos.map((hmo) => ({ label: hmo.hmo_name, id: 'hmo:' + hmo.id })),
      ];
    }
    if (clients.length) {
      list = [
        ...list,
        { label: 'Corporate Client', id: 'corporate_client', isHeader: true },
        ...clients.map((client) => ({ label: client.company_name, id: 'corporate:' + client.id })),
      ];
    }
    if (tags.length) {
      list = [
        ...list,
        { label: 'Patient Group', id: 'patient_group', isHeader: true },
        ...tags.map((tag) => ({ label: tag.tag_name, id: 'group:' + tag.id })),
      ];
    }

    return list;
  }, [tags, hmos, clients]);

  return (
    <Box
      width="100%"
      sx={{
        overflowX: 'hidden',
        position: 'relative',
        minHeight: '100vh',
        overflowY: 'auto',
        '&&': {
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
            width: 0,
            background: 'transparent',
          },
        },
      }}
    >
      <CareGoPage header="Patient Transactions">
        {/* Stacked Widgets */}
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            mb: SIZES.padding,
            zIndex: 1,
            maxHeight: '80vh',
          }}
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <CustomDashboardWidget
              count={totalRemainingBalance}
              label={labelContentRemainingBalance}
              icon={PaidOutlinedIcon}
            />
            {/* <CustomDashboardWidget count={totalPaidInYear} label={totalPaid} icon={PaidOutlinedIcon} /> */}
            <CustomDashboardWidget count={totalPaidCustom} label={labelContentTotalPaid} icon={PaidOutlinedIcon} />
          </Box>
        </Box>

        <PatientBillingTable
          tableRef={tableRef}
          otherFilter={otherFilter}
          refreshWidgets={getWidgetData}
          hideActions={true}
          headerComponent={
            <Box display="flex" gap="15px" flexDirection="column" width={selectedInvoice && 300}>
              <ContainerRow gap={SIZES.paddingS} sx={{ alignItems: 'center' }}>
                <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
              </ContainerRow>
            </Box>
          }
          afterSearchbarComponent={
            <Box display="flex-start" gap="15px" flexDirection="column" width={selectedInvoice && 300}>
              <ContainerRow gap={SIZES.paddingS} sx={{ alignItems: 'center' }}>
                <CustomFilter
                  buttonLabel="Filter"
                  span={2}
                  optionList={filters}
                  selected={paymentFilters}
                  setSelected={setPaymentFilters}
                />
              </ContainerRow>
            </Box>
          }
        />
      </CareGoPage>
    </Box>
  );
};

export default MobilePatientBilling;
