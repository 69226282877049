import {
  BackButton,
  ConfirmationDialog,
  CustomModal,
  CustomSelectField,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { createNote, getNote, updateIcdCodesOfNote, updateNote } from 'company/api/patient-notes';
import { debounce, formatDateTime } from 'core/utils';
import { getNote as getCareGoNote, updateNotes } from 'carego-admin/api/carego-notes-templates';
import { getCareGoTemplate, getTemplate, updateTemplate } from 'company/api/note-templates';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import BackToTopButton from 'core/components/BackToTopButton';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomDatePicker from 'core/components/CustomDatePicker';
import CustomDivider from 'core/components/CustomDivider';
import DocumentChart from './components/DocumentChart';
import DocumentForm from './components/DocumentForm';
import DocumentHeader from './components/DocumentHeader';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FORM_MODE } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import ICDCodeSearchDropdown from 'company/components/dropdown/ICDCodeSearchDropdown';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PatientField from '../PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { PatientNoteModel } from 'company/model/Entities';
import PrescriptionContainer from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionContainer';
import PrescriptionForm from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionForm';
import { PrescriptionModel } from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionModel';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import React from 'react';
import { SIZES } from 'theme/constants';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getAllPatientAppointments } from 'company/api/appointments';
import { getAppointment } from 'company/api/appointments';
import { getCodeOfPatientNote } from 'company/api/icdcode';
import { getPatient } from 'company/api/patient';
import { getPrescription } from 'company/api/prescriptions';
import { isDevMode } from 'core/components/HideOrShowComponent';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

export type Components = {
  id: string;
  type: 'rich_text' | 'form' | 'chart';
  title?: string;
  description?: string;
  fields?: any[];
  content?: string; // richtext
  responses?: any; // forms
  chart_type?: string; // charts
};

const fieldSpan = 12 / 2;

type DocumentEditorProps = {
  source: string;
  isPreview?: boolean;
  template_id?: number;
  patient_id?: number;
  patient?: PatientModel;
  prescription_id?: number;
  triggerPrint?: boolean;
  fromPatientRecords?: boolean;
  stickyTop?: string;
  refreshTimeline?: () => void;
  formMode?: FORM_MODE;
};

const DocumentEditor: React.FC<DocumentEditorProps> = ({
  source,
  isPreview = false,
  template_id,
  patient_id,
  prescription_id,
  triggerPrint,
  fromPatientRecords,
  stickyTop,
  refreshTimeline,
  formMode,
}) => {
  const { initialMode } = useParams() as any;
  const { id } = useParams() as any;
  const { pathname } = useLocation() as any;
  const { facility } = useContext(FacilityContext);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();

  const [patient, setPatient] = useState<PatientModel>();
  const [appointment, setAppointment] = useState<any>();

  const [name, setName] = useState('');
  const [components, setComponents] = useState<Components[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mode, setMode] = useState<FORM_MODE>(
    isPreview
      ? FORM_MODE.VIEW
      : [FORM_MODE.VIEW, FORM_MODE.EDIT, FORM_MODE.RESPOND].includes(initialMode)
      ? initialMode
      : fromPatientRecords || source === 'patient'
      ? formMode ?? FORM_MODE.VIEW
      : FORM_MODE.VIEW
  );

  const [patientAppointments, setPatientAppointments] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<number>();
  const [appointmentDetails, setAppointmentDetails] = useState<CompanyAppointmentModel>();
  const [selectedDateRecorded, setSelectedDateRecorded] = useState<any>();

  const [forceSubmit, setForceSubmit] = useState(false);
  const [note, setNote] = useState<PatientNoteModel>();

  const [openAddConfirmModal, setOpenAddConfirmModal] = useState<boolean>(false);
  const [addSectionMenuOpen, setAddSectionMenuOpen] = useState(false);
  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedComponent, setSelectedComponent] = useState(0);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deletingSectionIndex, setDeletingSectionIndex] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValueCode, setInitialValueCodes] = useState<any[]>([]);
  const [hideICDCodes, setHideICDCodes] = useState(false);

  const [displayPrescriptionContainer, setDisplayPrescriptionContainer] = useState<boolean>(false);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState<boolean>(false);
  const [prescription, setPrescription] = useState<PrescriptionModel>();
  const [updatedBy, setUpdatedBy] = useState<string>('');

  const [lastUpdate, setLastUpdate] = useState<{ editor: any; updated_at: string } | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string>('');

  const [backToTopButtonVisible, setBackToTopButtonVisible] = useState(false);
  const scrollRef = useRef();

  const idToUse = template_id ?? id;

  const notesNotFromCurrentFacility = useMemo(() => {
    if (note && facility) return facility.id !== note.facility_id;
    return false;
  }, [facility, note]);

  useEffect(() => {
    if (lastUpdate) {
      setUpdatedBy(lastUpdate.editor);
      setLastUpdated(lastUpdate.updated_at);
    } else if (note) {
      setUpdatedBy(note.last_editor_name ?? '');
      setLastUpdated(note.updated_at);
    }
  }, [lastUpdate, note]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, template_id, source]);

  useEffect(() => {
    if (patient) {
      getAllPatientAppointments(facility.id, patient.patient_id).then((docs) => {
        const filteredAppointments = docs.data.filter(
          (doc: any) => doc.status !== 'cancelled' && doc.status !== 'no-show'
        );

        setPatientAppointments(
          filteredAppointments.map((u: any) => {
            return { key: `${formatDateTime(u.schedule)} - ${u.service_names}`, value: u.id, data: u };
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.patient_id]);

  useEffect(() => {
    if (selectedAppointment && patientAppointments.length) {
      const found = patientAppointments.find((appointment) => appointment.value === selectedAppointment);
      if (found) setAppointmentDetails(found.data);

      //auto-save when updating appointments
      if (note && selectedAppointment !== note?.appointment_id) {
        note.appointment_id = selectedAppointment;
        handleSavebuttonClicked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment, patientAppointments]);

  useEffect(() => {
    setHideICDCodes(true);
    setTimeout(() => setHideICDCodes(false), 200);

    if (note && facility) {
      setSelectedDateRecorded(note?.date_recorded ?? '');
      if (note?.appointment_id && (!appointment || appointment.id !== note.appointment_id)) {
        getAppointment(facility.id, note.appointment_id).then((appointmentdoc) => {
          setAppointment(appointmentdoc.data);
        });
      }

      if (note.patient_id && (!patient || patient.id !== note.patient_id)) {
        getPatient(facility.id, note.patient_id).then((doc) => {
          setPatient(doc.data);
        });
      }

      setSelectedAppointment(note.appointment_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, facility?.id]);

  useEffect(() => {
    if (note?.patient_id && prescription_id) {
      getPrescription(facility.id, prescription_id).then((doc) => {
        setPrescription(doc.data);
        setDisplayPrescriptionContainer(true);
      });
    } else {
      setPrescription(undefined);
      setDisplayPrescriptionContainer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note?.patient_id, prescription_id]);

  useEffect(() => {
    if (triggerPrint) {
      printNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPrint]);

  const getData = () => {
    if (!idToUse) return;
    if (source === 'patient') {
      if (facility) getPatientNote();
    } else if (source === 'company') {
      if (facility) {
        getTemplate(idToUse).then((template) => {
          setNote(template.data);
          setName(template.data.header);
          setComponents(JSON.parse(template.data.body ? template.data.body : '[]'));
        });
      }
    } else if (source === 'admin') {
      getCareGoNote(idToUse).then((template) => {
        setNote(template.data);
        setName(template.data.header);
        setComponents(JSON.parse(template.data.body ? template.data.body : '[]'));
      });
    } else {
      getCareGoTemplate(idToUse).then((template) => {
        setNote(template.data);
        setName(template.data.header);
        setComponents(JSON.parse(template.data.body ? template.data.body : '[]'));
      });
    }
  };

  const getPatientNote = () => {
    getNote(facility.id, idToUse).then((note: any) => {
      setNote(note.data);
      setName(note.data.header);
      if (note.data.body) {
        if (note.data.template_id) {
          getTemplate(note.data.template_id).then((template) => {
            const from_note_template = JSON.parse(template.data.body.replace(/^s:\d+:"(.+)";$/, '$1'));
            const from_patient_note = JSON.parse(note.data.body.replace(/^s:\d+:"(.+)";$/, '$1'));
            if (from_patient_note.length > 0 && from_note_template.length > 0 && from_note_template[0]?.fields) {
              from_patient_note[0].fields = from_note_template[0].fields;
            }
            setComponents(from_patient_note);
          });
        } else {
          var originalString = note.data.body.replace(/^s:\d+:"(.+)";$/, '$1');
          setComponents(JSON.parse(originalString));
        }
      } else {
        setComponents([]);
      }
      getCodesOfNotes(note.data.id);
    });
  };

  const getCodesOfNotes = (note_id: number) => {
    getCodeOfPatientNote(note_id).then((res) => {
      const formattedData = res.data.data.map((item: { id: number; code: string; description: string }) => ({
        key: item.code + ' - ' + item.description,
        value: item.code,
      }));

      setInitialValueCodes(formattedData ? formattedData : []);
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectAppointment = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAppointment(event.target.value as unknown as number);
  };

  const handleDateRecordedChange = (dateRecorded: string | null) => {
    if (note) updateNote(facility.id, note?.id, { ...note, date_recorded: dateRecorded });
  };

  const handleCreate = async () => {
    const data = {
      template_id: idToUse,
      source: 'clinic',
      appointment_id: appointment?.id,
    };

    return (
      facility &&
      patient_id &&
      createNote(facility.id, patient_id, data)
        .then((res) => {
          enqueueSnackbar(`Note successfully added!`, { variant: 'success' });
          // setRefreshForNotes && setRefreshForNotes((prev: number) => prev + 1);
          navigate(`/company/patient-notes/respond/${res.data.note.id}`);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`Error occured! Unable to add note.`, { variant: 'error' });
        })
    );
  };

  const getNewUrl = (newMode: FORM_MODE) => {
    const tokens = pathname.split('/');
    tokens[3] = newMode;
    return tokens.join('/');
  };

  const handleItemClick = (newMode: FORM_MODE) => {
    const newUrl = getNewUrl(newMode);
    setMode(newMode);
    if (!fromPatientRecords) {
      navigate(newUrl);
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const printNote = () => {
    document.title = `${note?.header} - ${patient?.full_name}`;
    setTimeout(() => (document.title = `CareGo Health Suite`), 3000);
    handlePrint();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => '',
    // pageStyle: `
    //   @page {
    //     size: A5;
    //     margin: 20mm;
    //   }
    //   body {
    //     margin: 0;
    //   }
    // `,
  });

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const newList = Array.from(components);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setComponents(newList);
  };

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedComponent(index);
    setAddButtonAnchorEl(event.currentTarget);
    setAddSectionMenuOpen(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAddSection = (type: 'rich_text' | 'form' | 'chart') => {
    const newSection: Components = {
      id: String(Date.now()),
      type,
      title: '',
      description: '',
      fields: [
        {
          field_name: 'first_field',
          display_name: 'Field Name',
          type: 'text',
          span: fieldSpan,
        },
      ],
      responses: { first_field: '' },
    };

    setComponents((prevComponents) => {
      const newList = [...prevComponents];
      newList.splice(selectedComponent + 1, 0, newSection);
      return newList;
    });

    setAddButtonAnchorEl(null);
  };

  const handleDeleteSection = (index: number) => {
    setDeletingSectionIndex(index);
    setConfirmationDialogOpen(true);
  };

  const confirmDeleteSection = () => {
    if (deletingSectionIndex !== null) {
      setComponents((prevComponents) => {
        const newList = [...prevComponents];
        newList.splice(deletingSectionIndex, 1);
        return newList;
      });
      setDeletingSectionIndex(null);
      setConfirmationDialogOpen(false);
    }
  };

  const cancelDeleteSection = () => {
    setDeletingSectionIndex(null);
    setConfirmationDialogOpen(false);
  };

  const addContainer = (content: any, index: number) => {
    if (mode !== FORM_MODE.EDIT) return content;
    return (
      <Box>
        <Box display="flex" justifyContent="flex-end" marginBottom="-24px">
          <RegularButton
            label="Delete Section"
            size="small"
            color="error"
            variant="outlined"
            startIcon={<CloseOutlinedIcon />}
            onClick={() => handleDeleteSection(index)}
          />
        </Box>
        {content}
      </Box>
    );
  };

  const renderAddButton = (index: number) => {
    return (
      <Box
        width="100%"
        height="10px"
        borderRadius="5px"
        sx={{
          position: 'relative',
          backgroundColor: colors.hoverBackground,
          cursor: 'pointer',
          '& .add-section': { visibility: 'visible' },
        }}
      >
        <Box
          className="add-section"
          sx={{
            display: 'inline',
            position: 'absolute',
            left: 'calc(50% - 20px)',
            top: '-17px',
            width: 'auto',
            zIndex: 1,
          }}
        >
          <Tooltip title={'Add Section'} arrow>
            <IconButton
              size="large"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddClick(event, index)}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const renderComponents = (component: Components, index: number) => {
    let content = <></>;

    switch (component.type) {
      case 'form':
        content = addContainer(
          <DocumentForm
            components={components}
            noteId={idToUse}
            component={component}
            mode={mode}
            forceSubmit={forceSubmit}
            autoSave={autoSave}
            appointment={appointmentDetails}
            patient={patient}
            setLastUpdate={setLastUpdate}
          />,
          index
        );
        break;
      case 'chart':
        content = addContainer(<DocumentChart component={component} mode={mode} forceSubmit={forceSubmit} />, index);
        break;
    }

    return (
      <Fragment key={component.id}>
        {index === 0 && mode === FORM_MODE.EDIT && renderAddButton(-1)}
        {content}
        {mode === FORM_MODE.EDIT && renderAddButton(index)}
      </Fragment>
    );
  };

  const isEmpty = components.length === 0;

  const buttonIcon = useMemo(() => {
    return mode === FORM_MODE.EDIT ? (
      <EditOutlinedIcon />
    ) : mode === FORM_MODE.VIEW ? (
      <VisibilityOutlinedIcon />
    ) : FORM_MODE.RESPOND ? (
      <RateReviewOutlinedIcon />
    ) : null;
  }, [mode]);

  const buttonColor = useMemo(() => {
    return mode === FORM_MODE.EDIT
      ? 'primary'
      : mode === FORM_MODE.VIEW
      ? 'warning'
      : mode === FORM_MODE.RESPOND
      ? 'success'
      : 'success';
  }, [mode]);

  const handleSavebuttonClicked = (autoSave?: boolean) => {
    setForceSubmit(true);
    setTimeout(async () => {
      const newNote = {
        ...note,
        body: JSON.stringify(components),
        header: name,
      };

      try {
        setIsSaving(true);
        if (source === 'patient') await updateNote(facility.id, idToUse, newNote);
        else if (source === 'company') await updateTemplate(newNote, idToUse);
        else await updateNotes(idToUse, newNote);

        if (!autoSave) {
          enqueueSnackbar('Note successfully updated!', { variant: 'success' });
          getData();
        }
      } catch (error) {
        if (!autoSave) {
          enqueueSnackbar('Error occured! Unable to update note.', { variant: 'error' });
        }
      } finally {
        setTimeout(() => setIsSaving(false), 500);
        setForceSubmit(false);
      }
    }, 300);
  };

  const autoSave = debounce(() => {
    handleSavebuttonClicked(true);
  }, 1000);

  useEffect(() => {
    if (!fromPatientRecords) setBreadcrumb([{ label: 'Document Editor' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scrollRefCurrent = scrollRef.current as any;

    const handleScroll = (e: any) => {
      if (scrollRefCurrent.scrollTop > 100) {
        setBackToTopButtonVisible(true);
      } else {
        setBackToTopButtonVisible(false);
      }
    };

    if (scrollRefCurrent) {
      scrollRefCurrent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRefCurrent) scrollRefCurrent.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef]);

  return (
    <Box sx={{ position: 'relative' }}>
      {!fromPatientRecords &&
        (isPreview ? <RegularButton onClick={() => setOpenAddConfirmModal(true)} label="Use" /> : <BackButton />)}
      {!isPreview && (
        <ContainerRow
          sx={{
            alignItems: 'flex-start',
            position: 'sticky',
            top: stickyTop ? stickyTop : fromPatientRecords ? 20 : 0,
            left: 0,
            background: colors.secondary_background,
            zIndex: 2,
            paddingBlock: SIZES.paddingS,
          }}
        >
          <ContainerColumn gap={SIZES.paddingS} sx={{ paddingTop: SIZES.paddingS }}>
            {notesNotFromCurrentFacility && (
              <Typography variant="body2" fontWeight={600}>
                From: {note?.facility_name}
              </Typography>
            )}
            <Box display="flex" gap="10px">
              <RegularButton
                startIcon={buttonIcon}
                endIcon={<ArrowDropDownOutlinedIcon />}
                label={mode + ' Mode'}
                onClick={handleClick}
                color={buttonColor}
                disabled={notesNotFromCurrentFacility}
              />
              <Menu
                id="document-editor-mode"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {mode !== FORM_MODE.VIEW && <MenuItem onClick={() => handleItemClick(FORM_MODE.VIEW)}>View</MenuItem>}
                {source === 'patient' && mode !== FORM_MODE.RESPOND && note?.facility_id === facility.id && (
                  <MenuItem onClick={() => handleItemClick(FORM_MODE.RESPOND)}>Edit</MenuItem>
                )}
                {source !== 'carego' && source !== 'patient' && mode !== FORM_MODE.EDIT && !isMobilePhone && (
                  <MenuItem onClick={() => handleItemClick(FORM_MODE.EDIT)}>Modify Template</MenuItem>
                )}
              </Menu>
              {mode !== FORM_MODE.EDIT && (
                <RegularButton
                  startIcon={<LocalPrintshopOutlinedIcon />}
                  disabled={mode === FORM_MODE.RESPOND}
                  label="Print"
                  onClick={() => printNote()}
                  variant="outlined"
                  color="secondary"
                  helperText={mode === FORM_MODE.RESPOND ? 'Switch to View mode to print' : undefined}
                  styles={{ width: '150px' }}
                />
              )}
              {mode !== FORM_MODE.VIEW && isMobilePhone && (
                <Box width={200} display="flex" marginBottom={'-20px'}>
                  <RegularButton
                    startIcon={<SaveOutlinedIcon />}
                    label={'Save'}
                    onClick={() => handleSavebuttonClicked()}
                  />
                  <Typography
                    fontStyle="italic"
                    sx={{ visibility: isSaving ? 'visible' : 'hidden', textAlign: 'center', marginLeft: '10px' }}
                  >
                    Saving updates ...
                  </Typography>
                </Box>
              )}
            </Box>
            {source === 'patient' && note && (
              <Box display="flex" gap="10px">
                <Box width="100%" maxWidth="250px">
                  <CustomSelectField
                    options={patientAppointments}
                    label={'Appointments'}
                    fieldName={'appointments'}
                    value={note.appointment_id}
                    handleChange={handleSelectAppointment}
                  />
                </Box>
                {!note.appointment_id && (
                  <Box width="100%" maxWidth="250px">
                    <CustomDatePicker
                      fieldName=""
                      label="or Select Date Recorded"
                      value={selectedDateRecorded}
                      setFieldValue={(field: string, value: any) => {
                        const stringValue =
                          value || value !== null ? moment(new Date(value)).format('YYYY-MM-DD') : null;
                        setSelectedDateRecorded(stringValue);
                        handleDateRecordedChange(stringValue);
                      }}
                    />
                  </Box>
                )}
                {isDevMode() && (
                  <Box width="100%" maxWidth="250px">
                    {!hideICDCodes && (
                      <ICDCodeSearchDropdown
                        initialValue={initialValueCode}
                        limitTags={1}
                        handleChangeCallback={(data) => {
                          if (data && Array.isArray(data)) {
                            const values = data.map((item) => item.value);
                            return updateIcdCodesOfNote(facility.id, note.id, {
                              codes: values ? JSON.stringify(values) : [],
                            });
                          }
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>
            )}
          </ContainerColumn>
          {mode !== FORM_MODE.VIEW && !isMobilePhone && (
            <Box
              width={200}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              gap="10px"
              marginTop={isMobilePhone ? '10px' : ''}
            >
              <RegularButton
                startIcon={<SaveOutlinedIcon />}
                label={'Save'}
                onClick={() => handleSavebuttonClicked()}
              />
              <Typography fontStyle="italic" sx={{ visibility: isSaving ? 'visible' : 'hidden' }}>
                Saving updates ...
              </Typography>
            </Box>
          )}
        </ContainerRow>
      )}
      <Box
        ref={scrollRef}
        width="100%"
        // className="show-scrollbar"
        className="hide-scrollbar"
        sx={{
          backgroundColor: colors.secondary_background,
          height: '100%',
          overflowY: 'auto',
          padding: isMobilePhone ? '5px' : '25px',
          paddingTop: 0,
          borderRadius: 2,
          maxHeight: '700px',
        }}
      >
        {/* <RegularButton label={readOnly ? 'Edit' : 'View'} onClick={() => {setReadOnly(!readOnly)}} /> */}

        <BackToTopButton parentRef={scrollRef} visible={backToTopButtonVisible} position={{ bottom: 100, right: 45 }} />
        <Box
          sx={{
            width: '100%',
            maxWidth: '850px',
            margin: '0 auto',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: mode !== FORM_MODE.EDIT ? 0 : '10px',
            paddingY: mode !== FORM_MODE.EDIT ? '24px' : undefined,
          }}
          ref={componentRef}
        >
          <DocumentHeader
            name={name}
            setName={setName}
            mode={mode}
            note={note}
            autoSave={autoSave}
            patient={patient}
            lastUpdate={lastUpdate}
          />

          {mode !== FORM_MODE.EDIT ? (
            <>
              {components.map((component, index) => (
                <Fragment key={component.id}>{renderComponents(component, index)}</Fragment>
              ))}
            </>
          ) : (
            <>
              <DraggableList
                list={components}
                droppableId="temnplate-editor"
                handleDragEnd={handleDragEnd}
                displayItem={(component, index) => renderComponents(component, index)}
                style={{ padding: '5px', marginLeft: '-35px' }}
              />

              {/* {renderSelectedComponent()} */}
            </>
          )}
        </Box>

        {isEmpty && mode === FORM_MODE.EDIT && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem',
              height: '100%',
            }}
          >
            <RegularButton
              startIcon={<AddIcon />}
              label="Create New Section"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddClick(event, 0)}
              variant="outlined"
              color="primary"
            />
          </Box>
        )}

        <Menu
          id="add-section-menu"
          anchorEl={addButtonAnchorEl}
          keepMounted
          open={Boolean(addButtonAnchorEl) && addSectionMenuOpen}
          onClose={() => {
            setAddSectionMenuOpen(false);
            setAddButtonAnchorEl(null);
          }}
          autoFocus={false}
        >
          <MenuItem onClick={() => handleAddSection('form')}>Add Form</MenuItem>
          <MenuItem onClick={() => handleAddSection('chart')}>Add Chart</MenuItem>
        </Menu>

        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={cancelDeleteSection}
          onConfirm={confirmDeleteSection}
          // title="Confirm Deletion"
          content="Are you sure you want to delete this section?"
          setOpen={setConfirmationDialogOpen}
        />
        <ConfirmationDialog
          content="Are you sure you want to use this template?"
          open={openAddConfirmModal}
          setOpen={setOpenAddConfirmModal}
          onConfirm={handleCreate}
          confirmButtonColor
        />
      </Box>

      <Box display="grid" gridTemplateColumns="1fr 1fr " gap="10px">
        <Divider sx={{ gridColumn: 'span 2' }} />
        <PatientField fieldFontSize="11px" label="Created By:" value={note?.creator_name} />
        <PatientField fieldFontSize="11px" label="Date Created:" value={formatDateTime(note?.created_at)} />
        <PatientField fieldFontSize="11px" label="Last Updated By:" value={updatedBy} />
        <PatientField fieldFontSize="11px" label="Last Updated:" value={formatDateTime(lastUpdated)} />
      </Box>

      {source === 'patient' && patient && isDevMode() && (
        <>
          <CustomDivider />

          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="20px">
            {!prescription_id && !prescription && (
              <PrimaryButton
                onClick={() => {
                  setOpenPrescriptionModal(true);
                }}
                label="Create Prescription"
              />
            )}

            {displayPrescriptionContainer && prescription && patient && (
              <PrescriptionContainer prescription={prescription} patient={patient} />
            )}
          </Box>

          <CustomModal header="Create Prescription" open={openPrescriptionModal} setOpen={setOpenPrescriptionModal}>
            <PrescriptionForm
              patientID={patient_id ? patient_id : patient.id}
              noteID={note?.id}
              appointmentId={note?.appointment_id}
              callbackAfterSubmit={(data: any) => {
                setPrescription(data.data);
                setOpenPrescriptionModal(false);
                setDisplayPrescriptionContainer(true);
                refreshTimeline && refreshTimeline();
              }}
            />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default DocumentEditor;
