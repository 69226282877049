/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Divider, Typography, useTheme } from '@mui/material';
import { CompanyProtectedComponent, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { calculateAge, formatArray, formatDate } from 'core/utils';
import { useContext, useMemo, useState } from 'react';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientField from './PatientField';
import { PatientForm } from 'company/entities/forms';
import PatientHeader from 'company/screens/PatientManagement/components/PatientHeader';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { SIZES } from 'theme/constants';
import { WaitForFacility } from 'company/components';
import { deletePatient } from 'company/api/patient';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  patient: PatientModel;
  onUpdate: (id: number, data: PatientModel) => void;
  onDelete: (id: number) => void;
};

const PatientProfile: React.FC<Props> = ({ patient, onUpdate, onDelete }) => {
  return (
    <WaitForFacility facility={!!patient}>
      <PatientHeader patient={patient} onUpdate={onUpdate} onDelete={onDelete} />
      <Box paddingLeft={SIZES.paddingL}>
        {patient && (
          <Box width="100%">
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="20px">
              <PatientField label="Patient ID:" value={patient.id} sx={{ gridColumn: 'span 3' }} />
              <PatientField label="First Name" value={patient.first_name} />
              <PatientField label="Middle Name" value={patient.middle_name} />
              <PatientField label="Last Name" value={patient.last_name} />
              <PatientField label="Sex Assigned at Birth" value={patient.sex} />
              <PatientField label="Birthday" value={formatDate(patient.birthday)} />
              <PatientField label="Age" value={patient.birthday ? calculateAge(patient.birthday) : '-'} />
              <PatientField label="Occupation" value={patient.occupation} />
              <PatientField label="PWD ID" value={patient.pwd_id} />
              <PatientField label="Senior Citizen ID" value={patient.senior_citizen_id} />
              <PatientField label="Date Registered" value={formatDate(patient.created_at)} />
              <PatientField label="Mobile Number (Primary)" value={patient.mobile_number} />
              <PatientField label="Mobile Number (Secondary)" value={patient.mobile_number_2} />
              <PatientField label="Landline Number" value={patient.landline_number} />
              <PatientField label="Email Address" value={patient.email} />
              <PatientField
                label="Address"
                value={formatArray([patient.address, patient.province_city], ', ')}
                sx={{ gridColumn: 'span 3' }}
              />
              <Divider sx={{ gridColumn: 'span 3' }} />
              {patient.company_name && (
                <>
                  <PatientField label="Company" value={patient.company_name} />
                  {/* <PatientField label="Department" value={patient.department_name} /> */}
                  <PatientField label="Employee ID" value={patient.employee_id} sx={{ gridColumn: 'span 2' }} />
                </>
              )}
              <PatientField label="HMO" value={patient.hmo_name} />
              <PatientField label="HMO Number" value={patient.hmo_account_number} />
              <PatientField label="HMO Card Number" value={patient.hmo_card_number} />

              <PatientField label="HMO Account Name" value={patient.hmo_account_name} />
              <PatientField
                label="Patient Group"
                value={patient.tags.length ? patient.tags.map((tag) => tag.tag_name).join(', ') : '-'}
                sx={{ gridColumn: 'span 2' }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </WaitForFacility>
  );
};

export const PatientProfileMobile: React.FC<Props> = ({ patient, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openAction, setOpenAction] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const handleDelete = async () => {
    try {
      await deletePatient(facility.id, patient.patient_id);
      enqueueSnackbar('Patient successfully deleted!', { variant: 'success' });
      onDelete(patient.patient_id);
    } catch (error) {
      enqueueSnackbar('Error occured! Unable to delete patient.', { variant: 'error' });
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const updateData = useMemo(
    () => ({
      ...patient,
      tags: patient?.tags.map((tag: any) => {
        return { key: tag.tag_name, value: tag.id };
      }),
    }),
    [patient]
  );
  function handleOpenAction() {
    setOpenAction(true);
  }
  return (
    <WaitForFacility facility={!!patient}>
      <Box width="100%">
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: '10px', margin: '10px 0' }}>
            <AssignmentIndIcon sx={{ color: colors.accent }} />
            <Typography sx={{ fontWeight: '600', color: colors.dark_grey_text }}>Basic Information</Typography>
          </Box>
          <MoreHorizIcon sx={{ fontSize: '20px', color: colors.accent }} onClick={handleOpenAction} />
        </Box>
        {patient && (
          <Box width="100%" mt="10px">
            {/* Changed the grid layout to two columns */}
            <Box display="grid" gridTemplateColumns="1fr" gap="10px">
              <PatientField label="Patient ID:" value={patient.id} />
              <PatientField label="First Name:" value={patient.first_name} />
              <PatientField label="Middle Name:" value={patient.middle_name} />
              <PatientField label="Last Name:" value={patient.last_name} />
              <PatientField label="Sex Assigned at Birth:" value={patient.sex} />
              <PatientField
                label="Birthday:"
                value={formatDate(patient.birthday)}
                sx={{ display: 'flex', gap: '5px', gridColumn: 'span 2' }}
              />
              <PatientField label="Occupation:" value={patient.occupation} />

              <PatientField label="Mobile No.:" value={patient.mobile_number} />
              {/* Adjusted to span two columns */}
              <PatientField label="Email:" value={patient.email} />
              {/* Adjusted to span two columns */}
              <PatientField label="Address:" value={formatArray([patient.address, patient.province_city], ', ')} />
              {/* Adjusted to span two columns */}
              {/* <Divider sx={{ gridColumn: 'span 2' }} /> */}
              {/* {patient.company_name && ( */}
              {/* <> */}
              <Box sx={{ display: 'flex', gap: '10px', margin: '10px 0', alignItems: 'center', gridColumn: 'span 2' }}>
                <ContactEmergencyIcon sx={{ color: colors.accent }} />
                <Typography sx={{ fontWeight: '600', color: colors.dark_grey_text }}>Other Information</Typography>
              </Box>
              <PatientField label="PWD ID:" value={patient.pwd_id} />
              <PatientField label="Senior Citizen ID:" value={patient.senior_citizen_id} />
              <PatientField label="HMO" value={patient.hmo_name} />
              <PatientField
                label="Patient Group"
                value={patient.tags.length ? patient.tags.map((tag) => tag.tag_name).join(', ') : '-'}
              />

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gridColumn: 'span 2',
                }}
              >
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '10px 0' }}>
                  <RecordsIcon sx={{ color: colors.accent }} />
                  <Typography sx={{ fontWeight: '600', color: colors.dark_grey_text }}>Patient Records</Typography>
                </Box>
                <IconButton onClick={handleOpenSwitchView} style={{ padding: 0 }}>
                  <SwitchAccessShortcutIcon sx={{ fontSize: '20px', color: colors.accent }} />
                </IconButton> */}
              </Box>

              {/* <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gridColumn: 'span 2',
                }}
              >
                <SectionDropdown
                  optionList={sections}
                  popupContainerStyle={{ padding: '10px' }}
                  urlKeyId="section"
                  stickyTop={45}
                  hiddenActionsFor={[
                    'Patient Overview',
                    'Patient Transactions',
                    'Campaigns Received',
                    'Health Sessions Enrolled',
                    'Uploaded Files',
                  ]}
                />
              </Box> */}
            </Box>
          </Box>
        )}
      </Box>

      <CustomBottomPopover open={openAction} title="Actions" setOpen={setOpenAction}>
        <Box
          display="flex"
          gap={SIZES.paddingS}
          alignItems="center"
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 0.5rem',
            paddingTop: '2rem',
          }}
        >
          <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENTS']}>
            <RegularButton
              size="small"
              variant="outlined"
              label="Update Information"
              onClick={() => setOpenUpdateModal(true)}
              startIcon={<EditOutlinedIcon />}
              fullWidth
              styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
            />
            <RegularButton
              size="small"
              variant="outlined"
              color="error"
              label="Delete Patient"
              onClick={() => setOpenDeleteDialog(true)}
              startIcon={<DeleteOutlinedIcon />}
              fullWidth
              styles={{ border: 'none', backgroundColor: colors.light_red_background, fontWeight: '400' }}
            />
          </CompanyProtectedComponent>
        </Box>
      </CustomBottomPopover>

      <CustomModal header="Update Patient" open={openUpdateModal} setOpen={setOpenUpdateModal}>
        <PatientForm
          facility={facility}
          patient={updateData}
          callbackAfterSubmit={(data) => {
            setOpenUpdateModal(false);
            onUpdate(patient.patient_id, data);
          }}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title="Delete Patient"
        content={`Are you sure you want to delete patient "${patient ? patient.full_name : ''}"?`}
        onConfirm={handleDelete}
      />
    </WaitForFacility>
  );
};

export default PatientProfile;
