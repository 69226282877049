import { Box, Typography, useTheme } from '@mui/material';
import { ColoredTable, ConfirmationDialog, CustomForm, CustomModal, RowAction } from 'core/components';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { deleteFilters, getFilters, updateFilters } from 'company/api/filter';
import { useEffect, useState } from 'react';

import { ContainerRow } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FieldInput } from 'core/model/interface';
import { tokens } from 'theme/theme';

type Props = {
  template_id: number;
  addFilterFields: FieldInput[];
  formatFilterData: any;
};

const CustomFilterTable: React.FC<Props> = ({ template_id, addFilterFields, formatFilterData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [refresh, setRefresh] = useState(0);
  const [filterStatus, setFilterStatus] = useState<number>(1);
  const [filters, setFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openUpdateFilterForm, setOpenUpdateFilterForm] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [filterIdToDelete, setFilterIdToDelete] = useState<number | null>(null);

  const handleDelete = async () => {
    if (filterIdToDelete !== null) {
      try {
        await deleteFilters(filterIdToDelete);
        setRefresh((prev) => prev + 1);
      } catch (error) {
        console.error('Failed to delete filter:', error);
      } finally {
        setOpenDeleteDialog(false);
        setFilterIdToDelete(null);
      }
    } else {
      console.error('No filter selected for deletion');
      setOpenDeleteDialog(false);
    }
  };

  const updateRow = (row: GridRowModel) => {
    const filter = filters.find((f) => f.id === row.id);
    if (filter) {
      setSelectedFilter({
        id: filter.id,
        filter_name: filter.filter_name,
        source: filter.condition.source_field,
        condition: filter.condition.condition,
        value: filter.condition.value,
      });
      setOpenUpdateFilterForm(true);
    }
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    try {
      const formattedData = formatFilterData(data);
      await updateFilters(selectedFilter.id, formattedData);
      setRefresh((prev) => prev + 1);
      setOpenUpdateFilterForm(false);
    } catch (error) {
      console.error('Error updating filter:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'filter_name',
      headerName: 'Filter Name',
      type: 'string',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'condition',
      headerName: 'Condition',
      type: 'number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => (
        <Typography>{`${params.row.condition.source_field} ${params.row.condition.condition} ${params.row.condition.value}`}</Typography>
      ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      type: 'string',
      flex: 0.5,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => setFilterStatus(params.row.is_active)}
          style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}
        >
          <RowAction actions={actions} data={params.row} />
        </div>
      ),
    },
  ];

  filters.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

  const rows: GridRowModel[] = filters.map((filter) => ({
    id: filter.id,
    filterName: filter.filter_name,
    condition: `${filter.condition.source_field} ${filter.condition.condition} ${filter.condition.value}`,
  }));

  const actions = [
    {
      label: 'Update Filter',
      action: (row: GridRowModel) => updateRow(row),
      icon: <EditOutlinedIcon />,
    },
    {
      label: 'Delete Filter',
      action: (row: GridRowModel) => {
        setFilterIdToDelete(row.id);
        setOpenDeleteDialog(true);
      },
      icon: <DeleteOutlinedIcon />,
      //deleteRow(row.id)
    },
  ];

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getFilters(template_id);
        setFilters(response.data.data);
      } catch (error) {
        console.error('Failed to fetch filters:', error);
      }
    };

    fetchFilters();
  }, [template_id, refresh]);

  return (
    <Box>
      <ContainerRow sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ColoredTable
          hideSearch
          columns={columns}
          rowId="id"
          getData={() => getFilters(template_id)}
          forceRefresh={refresh}
        />
      </ContainerRow>

      <CustomModal open={openUpdateFilterForm} setOpen={setOpenUpdateFilterForm} header={'Update Filter'} width={400}>
        <CustomForm
          fields={addFilterFields}
          initialValues={selectedFilter || {}}
          onSubmit={handleUpdate}
          loading={loading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title={`Delete Filter`}
        content={`Are you sure you want to delete this filter?`}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default CustomFilterTable;
