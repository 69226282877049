import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getCodeById, getCodes } from 'company/api/icdcode';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import WaitForFacility from '../WaitForFacility';
import { useContext } from 'react';

const ICDCodeSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  return (
    <WaitForFacility facility={facility}>
      <TemplateDropdown
        {...props}
        entity={'ICD-10 Code'}
        multiple
        getData={(query: ApiQuery) => getCodes(query)}
        getById={(id: number) => getCodeById(id)}
        processOption={(record) => ({
          key: record.description ? `${record.code} - ${record.description}` : record.code,
          value: record.code,
        })}
        processNewRecord={(record) => record.code}
        hideAddOption
      />
    </WaitForFacility>
  );
};

export default ICDCodeSearchDropdown;
