import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { block, getAll } from 'carego-admin/api/carego-request-logs';
import { CustomForm, CustomGridCell, CustomModal, RegularButton } from 'core/components';
import { PageBuilderPageType } from 'core/PageBuilder';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { formatDatetimeJS, simpleQueryParser } from 'core/utils';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArchiveIcon from '@mui/icons-material/Archive';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ApiQuery, FieldInput } from 'core/model/interface';
import DetailsViewer, { DetailsViewerDataFormat } from '../components/DetailsViewer';

const RequestLogs: React.FC = () => {
  const [forceRefresh, setForceRefresh] = useState(0);
  const [openShowDetails, setOpenShowDetails] = useState(false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<DetailsViewerDataFormat[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  // advanced search values
  const [ipSearch, setIpSearch] = useState(searchParams.get('ip') ?? '');
  const [methodSearch, setMethodSearch] = useState(searchParams.get('methods') ?? '');
  const [startDatetimeSearch, setStartDatetimeSearch] = useState(searchParams.get('start_datetime') ?? '');
  const [endDatetimeSearch, setEndDatetimeSearch] = useState(searchParams.get('end_datetime') ?? '');
  const [sessionSearch, setSessionSearch] = useState(searchParams.get('session') ?? '');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSearchParams({
      ip: ipSearch,
      methods: methodSearch,
      start_datetime: startDatetimeSearch,
      end_datetime: endDatetimeSearch,
      session: sessionSearch,
    });
  }, [ipSearch, methodSearch, startDatetimeSearch, endDatetimeSearch, sessionSearch]);

  useEffect(() => {
    setForceRefresh(forceRefresh + 1);
  }, [searchParams]);

  const getRequestLogs = (apiQuery: ApiQuery) => {
    return getAll({
      ...apiQuery,
      session: searchParams.get('session') ?? '',
      methods: searchParams.get('methods') ?? '',
      start_datetime: searchParams.get('start_datetime') ?? '',
      end_datetime: searchParams.get('end_datetime') ?? '',
      ip: searchParams.get('ip') ?? '',
    });
  };

  /////////////////////////////////
  //  Advanced search variables  //
  /////////////////////////////////
  const searchInitialValues = {
    ip: ipSearch,
    start_datetime: searchParams.get('start_datetime'),
    end_datetime: searchParams.get('end_datetime'),
    session: searchParams.get('session'),
    methods:
      searchParams.get('methods') && searchParams.get('methods') !== ''
        ? searchParams
            .get('methods')
            ?.split(',')
            .map((method) => {
              return {
                key: method,
                value: method.toLowerCase(),
              };
            })
        : [],
  };

  // dropdown for company filter (/api/client-management)
  const searchFormFields: FieldInput[] = [
    { field_name: 'ip', type: 'text', display_name: 'IP Address (x.x.x.x)' },
    {
      field_name: 'methods',
      type: 'multiselect',
      display_name: 'Request method',
      options: [
        { key: 'GET', value: 'get' },
        { key: 'POST', value: 'post' },
        { key: 'PUT', value: 'put' },
        { key: 'DELETE', value: 'delete' },
      ],
    },
    // { field_name: 'start_datetime', type: 'datetime', display_name: 'Start Date' },
    // { field_name: 'end_datetime', type: 'datetime', display_name: 'End Date' },
    { field_name: 'start_datetime', type: 'date', display_name: 'Start Date' },
    { field_name: 'end_datetime', type: 'date', display_name: 'End Date' },
    { field_name: 'breaker', type: 'divider' },
    { field_name: 'subheader', type: 'subsection_header', body_text: 'Other Searching Methods' },
    { field_name: 'company', type: 'select', display_name: 'Company', options: [], disabled: true },
    { field_name: 'session', type: 'text', display_name: 'Sesssion ID' },
  ];

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'ip', headerName: 'IP Address', flex: 1 },
    { field: 'method', headerName: 'Method', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'path', headerName: 'Path', flex: 3 },
    {
      field: 'created_at',
      headerName: 'Datetime',
      flex: 2,
      renderCell: (param) => <CustomGridCell>{formatDatetimeJS(param.row.created_at)}</CustomGridCell>,
    },
  ];

  //////////////////////////////
  //  Page builder variables  //
  //////////////////////////////
  const content: PageBuilderPageType = {
    type: BLOCK_TYPE.PAGE,
    header: 'Request Logs',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Request Logs',
        doNotWaitForFacility: true,
        tableAction: {
          rowActions: [
            {
              label: 'Show Details',
              icon: <OpenWithIcon />,
              action: (row: any) => {
                const [rawpath, queries] = simpleQueryParser(row.path);
                setSelectedDetails([
                  { summary: 'IP Address', values: row.ip },
                  { summary: 'Full Request Path', values: rawpath, json: queries, hasJSON: true },
                  { summary: 'Request Headers', values: JSON.parse(row.header), isMono: true, isJSON: true },
                  { summary: 'Request Body', values: JSON.parse(row.body ?? '{}'), isMono: true, isJSON: true },
                ]);
                setOpenShowDetails(true);
              },
            },
            {
              label: 'Block IP',
              color: 'error',
              icon: <GppMaybeIcon />,
              action: (row: any) => {
                block(row.id)
                  .then((response) => {
                    enqueueSnackbar('Request IP successfully blocked', { variant: 'success' });
                  })
                  .catch(() => {
                    enqueueSnackbar('Failed blocking IP (try again later)', { variant: 'error' });
                  });
              },
            },
          ],
        },
        tableComponent: {
          hideViews: true,
          forceRefresh: forceRefresh,
          columns: columns,
          getData: getRequestLogs,
          headerComponent: (
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <RegularButton
                startIcon={<ManageSearchIcon />}
                label="Advanced Search"
                onClick={() => setOpenAdvancedSearch(true)}
              />
              <RegularButton startIcon={<ArchiveIcon />} label="Archive Current Logs" onClick={() => alert('hello')} />
              <RegularButton
                startIcon={<SettingsApplicationsIcon />}
                label="Logger Settings"
                onClick={() => alert('hello')}
              />
            </Box>
          ),
        },
      },
    },
  };

  return (
    <>
      <CustomModal
        header={'Request Details'}
        subHeader="Click to expand request values"
        open={openShowDetails}
        setOpen={setOpenShowDetails}
      >
        <DetailsViewer data={selectedDetails} />
      </CustomModal>
      <CustomModal header={'Advanced Search'} open={openAdvancedSearch} setOpen={setOpenAdvancedSearch}>
        <CustomForm
          showCancelButton
          cancelBtnText="Clear Search"
          submitButtonText="Apply Search"
          loading={false}
          initialValues={searchInitialValues}
          fields={searchFormFields}
          onSubmit={(data: any) => {
            setIpSearch(data.ip);
            setMethodSearch(data.methods.map((method: any) => method.value).join(','));
            setSessionSearch(data.session);
            setEndDatetimeSearch(data.end_datetime);
            setStartDatetimeSearch(data.start_datetime);

            setForceRefresh(forceRefresh + 1);
            setOpenAdvancedSearch(false);
          }}
          onCancel={() => {
            setIpSearch('');
            setMethodSearch('');
            setSessionSearch('');
            setEndDatetimeSearch('');
            setStartDatetimeSearch('');

            setForceRefresh(forceRefresh + 1);
            setOpenAdvancedSearch(false);
          }}
        />
      </CustomModal>
      <PageBuilder content={content} />
    </>
  );
};

export default RequestLogs;
