import {
  BookAppointments,
  Booking,
  ClinicLandingPage,
  CompanyDashboard,
  Feedback,
} from 'company/screens';
import { BreadcrumbContext, useBreadcrumb } from 'core/context/breadcrumb.context';
import { ColorModeContext, useMode } from './theme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CustomSnackBarWrapper, ProtectedRoute } from 'core/components';
import { FacilityContext, useFacility } from 'core/context/facility.context';
import { ForbiddenScreen, LoginScreen, NotFoundScreen } from 'core/screens';
import { Route, Routes } from 'react-router-dom';
import { TourContext, useTour } from 'core/context/tour.context';
import { UserContext, useUser } from 'core/context/user.context';

import { AccountType } from 'core/model/Entities';
import AccountingRoutes from 'routes/Company/AccountingRoutes';
import AdminRoutes from 'routes/Company/AdminRoutes';
import CareGoAdminRoutes from 'routes/CareGoAdmin/admin';
import ClinicManagementRoutes from 'routes/Company/ClinicManagementRoutes';
import DemoPage from 'LandingPage/DemoPage';
import InventoryRoutes from 'routes/Company/InventoryRoutes';
import Landing from 'LandingPage/Landing';
import LguRoutes from 'routes/LGU/lgu';
import Sandbox from 'core/screens/Helper/Sandbox';
import SettingsRoutes from 'routes/Company/SettingsRoutes';
import SignUpScreen from 'core/screens/Signup';

function App() {
  const [theme, colorMode] = useMode();
  const [user] = useUser();
  const [facility] = useFacility();
  const [breadcrumb] = useBreadcrumb();
  // const { facility  } = useContext(FacilityContext);

  const [tourState] = useTour();

  return (
    <ColorModeContext.Provider value={colorMode as any}>
      <ThemeProvider theme={theme as any}>
        <CssBaseline />
        <div className="app">
          <UserContext.Provider value={user as any}>
            <FacilityContext.Provider value={facility as any}>
              <TourContext.Provider value={tourState as any}>
                <BreadcrumbContext.Provider value={breadcrumb as any}>
                  <CustomSnackBarWrapper>
                    <Routes>
                      {/* Login */}
                      <Route path="/" element={<Landing />} />
                      <Route path="/admin-login" element={<LoginScreen isAdmin />} />
                      <Route path="/login" element={<LoginScreen />} />

                      {/* CareGo Admin */}
                      {CareGoAdminRoutes}

                      {/* LGU */}
                      {LguRoutes}

                      {/* Private Company */}
                      {ClinicManagementRoutes}
                      {InventoryRoutes}
                      {SettingsRoutes}
                      {AccountingRoutes}
                      {AdminRoutes}
                      <Route path="/company" element={<ProtectedRoute parent accountType={AccountType.COMPANY} />}>
                        <Route index element={<CompanyDashboard />} />
                        <Route path="not-found" element={<NotFoundScreen />} />
                        <Route path="forbidden" element={<ForbiddenScreen />} />
                        <Route path="sandbox" element={<Sandbox />} />
                        <Route path="*" element={<NotFoundScreen />} />
                      </Route>

                      {/* Public */}
                      <Route path="/signup" element={<SignUpScreen />} />
                      <Route path="/book/appointment/:facility_code" element={<ClinicLandingPage />} />
                      <Route path="/book/appointment/:facility_code/:service_id" element={<BookAppointments />} />
                      <Route path="/booking/:id" element={<Booking />} />
                      <Route path="/feedback/:facility_code" element={<Feedback />} />
                      <Route path="/demo" element={<DemoPage />} />
                      <Route path="*" element={<NotFoundScreen />} />
                      
                    </Routes>
                  </CustomSnackBarWrapper>
                </BreadcrumbContext.Provider>
              </TourContext.Provider>
            </FacilityContext.Provider>
          </UserContext.Provider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
