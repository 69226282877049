import { Box, Typography, useTheme } from '@mui/material';
import {
  CustomContainer,
  CustomIconButton,
  CustomSelectField,
  CustomTextField,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import CustomSearchDropdownField, { dropdownFields } from './CustomSearchDropdownField';
import { DROPDOWN_FIELD, SelectOption } from 'core/model/interface';
import React, { useEffect, useMemo, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { tokens } from 'theme/theme';

export type CustomTableFormColumn = {
  fieldName: string;
  displayName: string;
  type?: string;
  optional?: boolean;
  flex?: number;
  options?: SelectOption[];
  hidden?: boolean;
  hiddenBasedOnOtherField?: (data: any) => boolean;
  onCellChange?: (
    value: any,
    rowId: any,
    handleRowEdit: (id: number, field: string, value: string | number) => void
  ) => void;
};

type CustomTableFormProps = {
  columns: CustomTableFormColumn[];
  initialValues?: string;
  handleChange: (rows: any[]) => void;
};

const CustomTableForm: React.FC<CustomTableFormProps> = ({ columns, initialValues, handleChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const [rows, setRows] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [focusedRow, setFocusedRow] = useState<number>(0);
  const [finalColumns, setFinalColumns] = useState<CustomTableFormColumn[]>([]);
  const totalFlex = useMemo(
    () => finalColumns.reduce((result, column) => result + (column.flex ?? 1), 0),
    [finalColumns]
  );

  useEffect(() => {
    setFinalColumns(columns);
  }, [columns]);

  useEffect(() => {
    if (initialValues) {
      setRows(JSON.parse(initialValues).map((row: any, index: number) => ({ ...row, id: index + 1 })));
    } else {
      clearRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleRowEdit = (updatedRow: any, field: string, value: string | number) => {
    updatedRow[field] = value;
    handleChange(rows);
  };

  const handleRowDelete = (event: any, id: number) => {
    event.stopPropagation();
    const updatedRows = rows.filter((row) => row.id !== id);
    if (updatedRows.length) {
      setRows(updatedRows);
      handleChange(updatedRows);
    } else {
      clearRows();
    }
  };

  const handleRowClick = (id: number) => {
    if (id === rows[rows.length - 1].id) {
      // Auto add new row if last row is clicked
      // addRow();
    }
  };

  const addRow = () => {
    const newId = rows.length ? rows[rows.length - 1].id + 1 : 0;
    const newData = finalColumns.reduce((result: any, column) => {
      result[column.fieldName] = '';
      return result;
    }, {});
    setRows([...rows, { id: newId, ...newData }]);
  };

  const clearRows = () => {
    const updatedRows = [1].map((index) =>
      finalColumns.reduce(
        (result: any, column) => {
          result[column.fieldName] = '';
          return result;
        },
        { id: index }
      )
    );
    setRows(updatedRows);
    handleChange(updatedRows);
  };

  const getField = (row: any, column: CustomTableFormColumn) => {
    if (dropdownFields.includes(column.type as DROPDOWN_FIELD)) {
      return (
        <CustomSearchDropdownField
          field={column}
          initialValue={row[column.fieldName]}
          onChange={(value) => {
            column.onCellChange && column.onCellChange(value, row, handleRowEdit);
            handleRowEdit(row, column.fieldName, value ? value.value : '');
          }}
        />
      );
    }

    switch (column.type) {
      case 'select':
        return (
          <CustomSelectField
            value={row[column.fieldName]}
            handleChange={(e) => handleRowEdit(row, column.fieldName, e.target.value)}
            label={column.displayName}
            fieldName={column.fieldName}
            options={column.options!}
          />
        );
      default:
        return (
          <CustomTextField
            value={row[column.fieldName]}
            handleChange={(e) => handleRowEdit(row, column.fieldName, e.target.value)}
            label={column.displayName}
            fieldName={column.fieldName}
          />
        );
    }
  };

  return (
    <CustomContainer>
      <div style={{ width: '100%' }}>
        <table style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {finalColumns.map((column, index) => (
                <th key={index}>
                  <Typography fontWeight="bold">{column.displayName}</Typography>
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr
                key={row.id}
                style={{
                  borderRadius: '10px',
                  background: focusedRow === row.id ? colors.hoverBackground : 'transparent',
                }}
                onClick={() => handleRowClick(row.id)}
                onMouseEnter={() => setFocusedRow(row.id)}
                onMouseLeave={() => setFocusedRow(0)}
              >
                {finalColumns.map((column, columIndex) => (
                  <td
                    key={columIndex}
                    style={{
                      width: (100 / totalFlex) * (column.flex ?? 1) + '%',
                      padding: columIndex === 0 ? '10px 5px 10px 10px' : '10px 5px 10px 5px',
                      borderRadius: columIndex === 0 ? '10px 0 0 10px' : undefined,
                    }}
                  >
                    {column.hidden || (column.hiddenBasedOnOtherField && column.hiddenBasedOnOtherField(row)) ? (
                      <></>
                    ) : (
                      getField(row, column)
                    )}
                  </td>
                ))}

                <td width="50px" style={{ borderRadius: '0 10px 10px 0', padding: '0 10px 0 0' }}>
                  <CustomIconButton onClick={(event) => handleRowDelete(event, row.id)} icon={<DeleteOutlinedIcon />} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Box display="flex" justifyContent="space-between" width="100%" mt="20px">
          <Box display="flex" gap="10px">
            <PrimaryButton onClick={addRow} label="Add Row" size="small" />
            <RegularButton variant="outlined" onClick={clearRows} label="Clear All Rows" size="small" />
          </Box>
        </Box>
      </div>
    </CustomContainer>
  );
};

export default CustomTableForm;
