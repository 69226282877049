import { EntityFields } from 'company/entities/utils';
import { Typography } from '@mui/material';
import { formatDateTimeWithDay } from 'core/utils';
import { generateInstruction } from 'core/utils';
import { DROPDOWN_FIELD } from 'core/model/interface';

export interface PrescriptionModel {
  id: number;
  company_id: number;
  facility_id: number;
  patient_id: number;
  doctor_id: number;
  is_active: number;
  patient_name: string;
  doctor_name: string;
  facility_name: string;
  license_number: string;
  ptr_number: string;
  created_at: Date;
  updated_at: Date;
  appointment_id: number;
  schedule: string;
}

export const PRESCRIPTION_ENTITY_NAME = 'Prescription';
export const prescriptionFields: EntityFields[] = [
  {
    fieldName: 'schedule',
    displayName: 'Appointment Schedule',
    renderCell: (params) => (
      <Typography>{params.row.schedule ? formatDateTimeWithDay(params.row.schedule) : ''}</Typography>
    ),
  },
  { fieldName: 'doctor_id', displayName: 'Doctor', type: 'select', options: [], span: 4 },
  { fieldName: 'appointment_id', displayName: 'Appointment', type: 'select', options: [], span: 4, optional: true },
  { fieldName: 'doctor_name', displayName: 'Doctor Name' },
  {
    fieldName: 'created_at',
    displayName: 'Date Added',
    renderCell: (params) => <Typography>{formatDateTimeWithDay(params.row.created_at)}</Typography>,
  },
  // {
  //   fieldName: 'preview',
  //   displayName: 'Preview',
  //   headerAlign: 'center',
  //   align: 'center',
  //   flex: 0.5,
  //   renderCell: (params) => (
  //     <CustomGridCell>
  //       <RegularButton
  //         label="Preview"
  //         variant="text"
  //         onClick={(e) => {
  //           e.stopPropagation();
  //         }}
  //       />
  //     </CustomGridCell>
  //   ),
  // },
];

export const prescriptionVisibleColumns = ['schedule', 'doctor_name', 'created_at', 'preview'];
export const prescriptionFormFields = ['doctor_id', 'appointment_id'];
export const prescriptionInitialValues = {
  doctor_id: '',
  appointment_id: '',
};

export const prescriptionWithMedicineFormFields: EntityFields[] = [
  { fieldName: 'doctor_id', displayName: 'Doctor', type: 'select', options: [], span: 4 },
  { fieldName: 'appointment_id', displayName: 'Appointment', type: 'select', options: [], span: 4, optional: true },
  {
    fieldName: 'medicine_id',
    type: DROPDOWN_FIELD.MEDICINE,
    optional: false,
    span: 2,
  },
  { fieldName: 'quantity', displayName: 'Quantity', type: 'number', span: 2 },
  {
    fieldName: 'instruction',
    displayName: 'Instruction',
    optional: true,
    renderCell: (params: any) => <Typography>{generateInstruction(params.row)}</Typography>,
    span: 4,
    flex: 3,
    multiline: true,
    rows: 3,
  },
];

export const prescriptionWithMedicineVisibleFormFields = [
  'doctor_id',
  'appointment_id',
  'medicine_id',
  'quantity',
  'instruction',
];

export const prescriptionWithMedicineInitialValues = {
  ...prescriptionInitialValues,
  medicine_id: '',
  quantity: '',
  instruction: '',
};
