import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import CompanyTemplates from 'company/screens/Templates/CompanyTemplates';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { formatDateTime } from 'core/utils';
import { tokens } from 'theme/theme';

type CreatePatientNoteModalProps = {
  patient_id?: number;
  forceRefresh?: number;
  patient?: PatientModel;
  setForceRefresh?: (forceRefresh: any) => void;
  setRefreshForNotes?: (refreshForNotes: any) => void;
  appointment?: any;
  onCreate?: (data: any) => void;
};

const CreatePatientNoteModal: React.FC<CreatePatientNoteModalProps> = ({
  patient_id,
  patient,
  forceRefresh,
  setForceRefresh,
  setRefreshForNotes,
  appointment,
  onCreate,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  return (
    <>
      <CompanyTemplates
        isFromPatientManagement
        appointment={appointment}
        patient_id={patient_id}
        refreshFromCopy={forceRefresh}
        setRefreshForNotes={setRefreshForNotes}
        onCreate={onCreate}
      />
    </>
  );
};

export default CreatePatientNoteModal;
