import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { Typography } from '@mui/material';
import { capitalizeWord } from 'core/utils';
import { Link } from 'react-router-dom';

export const HA_ENTITY_NAME = 'Health Assessment';

export const healthAssessmentFields: EntityFields[] = [
  { fieldName: 'patient_id', displayName: 'Patient', type: DROPDOWN_FIELD.PATIENT },
  {
    fieldName: 'full_name',
    displayName: 'Patient Name',
    renderCell: (params) => (
      <Typography sx={{ fontSize: '16px' }}>
        <Link style={{ textDecoration: 'none' }} to={`/company/patient-management?patient_id=${params.row.patient_id}`}>
          {params.row.full_name}
        </Link>
      </Typography>
    ),
  },
  { fieldName: 'assessment_date', displayName: 'Asssessment Date', type: 'date' },
  {
    fieldName: 'status',
    displayName: 'Status',
    renderCell: (params) => <Typography>{capitalizeWord(params.row.status)}</Typography>,
  },
];

export interface HealthAssessmentModel {
  id: number;
  company_id: number;
  patient_id: number;
  assessment_date: Date;
  status: string;
}

export const healthAssessmentColumnTable: string[] = ['full_name', 'assessment_date', 'status'];
