import { Box, Tooltip, Typography } from '@mui/material';

import Decimal from 'decimal.js';
import { FacilityModel } from 'core/model/Entities';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import moment from 'moment';

export const formatDate = (date?: string | Date, defaultValue?: string) => {
  return date ? moment(date).format('ll') : defaultValue ?? '';
};

export const formatDateWithCompleteMonth = (date?: string | Date) => {
  return date ? moment(date).format('MMMM D, YYYY') : '';
};

export const formatDateTime = (date?: string | Date, defaultValue?: string) => {
  return date ? moment(date).format('lll') : defaultValue ?? '';
};

export const formatTime = (date?: string | Date) => {
  return date ? moment(date).format('LT') : '';
};

export const formatTimeWithoutAMPM = (value: string) => {
  const date = new Date(value);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  hours = hours % 12 || 12;

  return `${hours}:${minutes}`;
};

export const format24HourTime = (time?: string | Date) => {
  return time ? moment(time, ['HH:mm']).format('LT') : '';
};

export const formatDateTimeWithDay = (date?: string | Date) => {
  return date ? moment(date).format('llll') : '';
};

export const formatCalendar = (date?: string | Date) => {
  return date ? moment(date).calendar() : '';
};

export const formatGenLedgerDate = (startDate: Date, endDate: Date) => {
  const startDateStr = formatDate(startDate).split(' ');
  const endDateStr = formatDate(endDate).split(' ');

  startDateStr[1] = startDateStr[1].replace(',', '');
  endDateStr[1] = endDateStr[1].replace(',', '');

  if (startDateStr[0] === endDateStr[0] && startDateStr[1] === endDateStr[1] && startDateStr[2] === endDateStr[2]) {
    return [startDateStr[0], startDateStr[1], startDateStr[2]].join(' ');
  }

  // same month && year checking
  if (startDateStr[0] === endDateStr[0] && startDateStr[2] === endDateStr[2]) {
    return [startDateStr[0], `${startDateStr[1]} - ${endDateStr[1]}`, startDateStr[2]].join(' ');
  }

  // same year checking
  if (startDateStr[2] === endDateStr[2]) {
    return [startDateStr[0], startDateStr[1], '-', endDateStr[0], endDateStr[1], startDateStr[2]].join(' ');
  }

  return [startDateStr[0], startDateStr[1], startDateStr[2], '-', endDateStr[0], endDateStr[1], endDateStr[2]].join(
    ' '
  );
};

export const calculateAge = (birthday?: string | Date) => {
  if (!birthday) return 0;

  var diff_ms = Date.now() - new Date(birthday).getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const tranformFormErrors = (errors: any) => {
  for (var key in errors) {
    errors[key] = errors[key].join(' ');
  }

  return errors;
};

export const changeNullToBlank: any = (obj: any) => {
  for (var key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    }
  }

  return obj;
};

export const formatDatetimeJS = (datetime: string) => {
  return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
};

export const capitalizeWord = (string: string) => {
  return string ? string.toLowerCase().replace(/(?:^|\s|[-"'([{])+\S/g, (c) => c.toUpperCase()) : '';
};

export const formatNumber = (number: number | string, decimal: number = 2) => {
  let num = parseFloat(number as string);
  if (isNaN(num)) num = 0;
  return num.toLocaleString('en-US', { maximumFractionDigits: decimal, minimumFractionDigits: decimal });
};

export const formatNumberDecimal = (number: number | string, decimal: number = 2) => {
  let num = parseFloat(number as string);
  if (isNaN(num)) num = 0;
  return parseFloat(
    num.toLocaleString('en-US', { maximumFractionDigits: decimal, minimumFractionDigits: decimal }).replaceAll(',', '')
  );
};

export const preciseformatNumber = (number: number | string, decimal: number = 2): string => {
  let numStr = typeof number === 'number' ? number.toString() : number;
  numStr = numStr.replace(/,/g, '');
  let [intPart, fracPart = ''] = numStr.split('.');
  fracPart = fracPart.padEnd(decimal, '0').slice(0, decimal);
  intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimal > 0 ? `${intPart}.${fracPart}` : intPart;
};

export const formatCurrency = (number: number | string = 0, decimal: number = 2) => {
  return '₱ ' + formatNumber(number, decimal);
};

export const getLineItemDiscountedPrice = (order: any, numberOnly?: boolean) => {
  let vatAmount = 0;
  let lineItemPrice = order.unit_price;

  if (order.sc_pwd_discounted) {
    if (order.tax === 'vat') {
      vatAmount = lineItemPrice * (3 / 28);
      lineItemPrice -= vatAmount;
    }
    lineItemPrice = lineItemPrice * 0.8;
  }

  return numberOnly ? lineItemPrice * order.quantity : formatCurrency(lineItemPrice * order.quantity);
};

export const getDiscountAmount = (order: any, fromPOS?: boolean) => {
  if (fromPOS) {
    return (
      '-' +
      formatCurrency(
        parseFloat(order.original_price) * order.quantity - (getLineItemDiscountedPrice(order, true) as number)
      )
    );
  }

  return '-' + formatCurrency(parseFloat(order.original_price) * order.quantity - parseFloat(order.total_price));
};

export const debounce = <T extends unknown[]>(func: (...args: T) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const replaceCompanySmsTemplate = (template: string, facility: FacilityModel) => {
  if (!template) return '';
  let updated = template
    .replaceAll('[Name]', '`Cheska Dela Cruz`')
    .replaceAll('[FirstName]', '`Cheska`')
    .replaceAll('[Date]', '`' + formatDate(new Date()) + '`')
    .replaceAll('[Time]', '`9:30 AM`')
    .replaceAll('[Health_Program]', '`appointment`')
    .replaceAll('[Day]', '`4`')
    .replaceAll('[Hour]', '`12`');

  if (facility) {
    const contacts = [];
    facility.globe_mobile_number && contacts.push(facility.globe_mobile_number);
    facility.smart_mobile_number && contacts.push(facility.smart_mobile_number);
    updated = updated
      .replaceAll('[Name]', '`Cheska Dela Cruz`')
      .replaceAll('[FirstName]', '`Cheska`')
      .replaceAll('[Date]', '`' + formatDate(new Date()) + '`')
      .replaceAll('[Time]', '`9:30 AM`')
      .replaceAll('[Health_Program]', '`appointment`')
      .replaceAll('[Day]', '`4`')
      .replaceAll('[Hour]', '`12`')
      .replaceAll('[Facility_Type]', '`' + facility?.facility_type + '`')
      .replaceAll('[Facility_Contact]', '`' + contacts.join(' / ') + '`')
      .replaceAll('[Facility_Name]', '`' + facility?.facility_short_name + '`');
  }

  return updated;
};

export const filterArrayByKey = (array: any[], _key: any, key_value: any, value: any) => {
  const filteredItems = array.filter((item) => item[_key] === key_value);
  return filteredItems[0][value];
};

export const filterArrayByValue = (array: any[], _value: any, value_value: any, key: any) => {
  const filteredItems = array.filter((item) => item[_value] === value_value);
  return filteredItems[0][key];
};

export const customReplaceCompanySmsTemplate = (
  facility: FacilityModel,
  template: string,
  patient: any,
  appointment: any
) => {
  let updated = template
    .replaceAll('[Name]', patient?.full_name)
    .replaceAll('[FirstName]', patient?.first_name)
    .replaceAll('[Date]', formatDate(appointment?.schedule))
    .replaceAll('[Time]', formatTime(appointment?.schedule))
    .replaceAll('[Health_Program]', 'appointment')
    .replaceAll('[Day]', '4')
    .replaceAll('[Hour]', '12');

  if (facility) {
    const contacts = [];
    facility.globe_mobile_number && contacts.push(facility.globe_mobile_number);
    facility.smart_mobile_number && contacts.push(facility.smart_mobile_number);
    updated = updated
      .replaceAll('[Name]', patient?.full_name)
      .replaceAll('[FirstName]', patient?.first_name)
      .replaceAll('[Date]', appointment ? formatDate(appointment?.schedule) : formatDate(new Date()))
      .replaceAll('[Time]', appointment ? formatTime(appointment?.schedule) : '9:30 AM')
      .replaceAll('[Health_Program]', 'appointment')
      .replaceAll('[Day]', '4')
      .replaceAll('[Hour]', '12')
      .replaceAll('[Facility_Type]', '' + facility?.facility_type + '')
      .replaceAll('[Facility_Contact]', '' + contacts.join(' / ') + '')
      .replaceAll('[Facility_Name]', '' + facility?.facility_short_name + '');
  }

  return updated;
};

export const formatArray = (data: string[], separator?: string): string => {
  separator = separator ?? ', ';
  return data.filter((item) => !!item).join(separator);
};

export const formatNumericalDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

export const generateInstruction = (data: any): string => {
  const displayFrequency = () => {
    if (data.frequency === 'weekly') {
      const days = Array.isArray(data.days_of_week)
        ? data.days_of_week.map((day: any) => day.key).join(', ')
        : data.days_of_week
        ? data.days_of_week
            .split(',')
            .map((day: string) => day.charAt(0).toUpperCase() + day.slice(1))
            .join(', ')
        : '';

      return 'every ' + data.days_of_week ? days : '[day]';
    }
    return data.frequency;
  };

  const displayInterval = () => {
    return data.hours_interval && data.frequency_per_day > 1
      ? `one dose every ${data.hours_interval ?? 0} hour${data.hours_interval > 1 ? 's' : ''}`
      : '';
  };

  let start_date = '';
  if (data.start_date) start_date = moment(data.start_date).format('YYYY-MM-DD');

  if (!data.frequency) return data.instruction ?? '-';

  return (
    `Take ${displayFrequency()}` +
    `${data.frequency_per_day > 1 ? `, ${data.frequency_per_day} times a day, ` : ''}` +
    `${displayInterval()}` +
    `${data.duration ? ` for ${data.duration} day${data.duration > 1 ? 's' : ''}` : ''}` +
    `${
      data.time_to_take || start_date
        ? ` starting at ${
            start_date && !data.time_to_take
              ? formatDate(start_date)
              : start_date
              ? formatDateTime(start_date + ' ' + data.time_to_take)
              : formatTime('2024-01-01 ' + data.time_to_take)
          }`
        : ''
    }. ${data.instruction ?? ''}`
  );
};

export const convertJsonToCsv = (json: object[]): string => {
  if (json.length === 0) return '';

  const headers = Object.keys(json[0]);
  const csvRows: string[] = [];

  // Add the headers row
  csvRows.push(headers.join(','));

  // Add the data rows
  for (const row of json) {
    const values = headers.map((header) => {
      const escaped = ('' + (row as any)[header]).replace(/"/g, '""');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
};

export const shuffle = (array: any[]) => {
  array = [...array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const rotateArray = (array: any[]) => {
  array = [...array];
  const startIndex = Math.floor(Math.random() * array.length);
  return array.slice(startIndex).concat(array.slice(0, startIndex));
};

export const nameToHex = (name: string) => {
  // Convert name to a numerical value by summing ASCII values of characters
  let numericalValue = 0;
  for (let i = 0; i < name.length; i++) {
    numericalValue += name.charCodeAt(i);
  }

  // Use the numerical value to generate RGB values
  const r = (numericalValue * 1234567) % 256;
  const g = (numericalValue * 2345671) % 256;
  const b = (numericalValue * 3456781) % 256;

  // Convert RGB values to a hex string
  const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
  return hexColor;
};

export const formatAttributeValues = (
  attributes: any,
  useDefaultFormat?: boolean,
  format?: (value: string) => string
): string => {
  if (!attributes) return ''; // Check if attributes exist

  try {
    const parsedAttributes = Array.isArray(attributes) ? attributes : JSON.parse(attributes);

    if (!Array.isArray(parsedAttributes) || parsedAttributes.length === 0) return '';

    const formattedAttributes = parsedAttributes
      .map((item: { value: string }) => (item.value ? item.value.replace('+', '') : ''))
      .filter((value: string) => value !== '') // Remove empty strings
      .sort((a: string, b: string) => a.localeCompare(b))
      .join(', ');

    if (useDefaultFormat) return formattedAttributes ? ` (${formattedAttributes})` : '';
    return format ? format(formattedAttributes) : formattedAttributes;
  } catch (error) {
    console.error('Error parsing attributes:', error);
    return '';
  }
};

export const displayAttribute = (attribute_str: string) => {
  const combined = formatAttributeValues(attribute_str);
  return (
    <Box display="flex" gap="3px" alignItems="center">
      <LocalMallOutlinedIcon color="primary" sx={{ fontSize: '18px' }} />
      <Tooltip title={combined} placement="left">
        <Typography
          variant="subtitle1"
          fontWeight={500}
          color="#888"
          sx={{
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
          }}
        >
          {combined}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export const formatPaymentMethodLabel = (key: string) => {
  if (key === 'debitcredit') {
    return 'Debit/Credit';
  }
  if (key === 'bank') {
    return 'Bank Transfer';
  }
  return key.charAt(0).toUpperCase() + key.slice(1);
};

export const formatNumberNoRounding = (number: Decimal | number | string, decimals: number = 2): string => {
  const decimalNumber = new Decimal(number);
  return decimalNumber.toFixed(decimals);
};

export const buildAddress = (facility: FacilityModel) => {
  if (facility && facility.address) {
    if (facility.municipality_name) {
      if (facility.province_name) {
        return facility.address + ', ' + facility.municipality_name + ', ' + facility.province_name;
      }
      return facility.address + ', ' + facility.municipality_name;
    }
    return facility.address;
  }
};

export const extractFirewallRequestMetadata = (row: string) => {
  const metadata: {
    log_middleware_id: null | number;
    log_session_id: null | number;
  } = {
    log_middleware_id: 0,
    log_session_id: 0,
  };

  if (!row) return metadata;

  // middleware id extraction
  let firstCut = row.split('&request_logger_middleware_id=');
  if (firstCut.length <= 1) {
    metadata['log_middleware_id'] = null;
  } else {
    firstCut = firstCut[1].split('&');
    if (firstCut.length <= 1) metadata['log_middleware_id'] = null;
    metadata['log_middleware_id'] = firstCut[0] && firstCut[0] !== '' ? parseInt(firstCut[0]) : null;
  }

  // session id extraction
  firstCut = row.split('&request_logger_session_id=');
  if (firstCut.length <= 1) {
    metadata['log_session_id'] = null;
  } else {
    metadata['log_session_id'] =
      firstCut && firstCut[1] !== '' && firstCut[1] !== 'null' ? parseInt(firstCut[1]) : null;
  }

  return metadata;
};

export const simpleQueryParser = (path: string) => {
  const queries: any = {};
  const splitPath = path.split('?');
  const rawpath = splitPath[0];

  if (splitPath.length <= 1) return [rawpath, queries];
  splitPath.shift();

  // manual parsing
  for (const queryable of splitPath) {
    const pairs = queryable.split('&');
    for (const pairedkeyvalue of pairs) {
      const keyvalue = pairedkeyvalue.split('=');
      if (keyvalue.length >= 2) queries[keyvalue[0]] = keyvalue[1];
    }
  }

  return [rawpath, queries];
};
