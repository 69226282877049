import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { FORM_MODE, FieldInput } from 'core/model/interface';
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomCheckbox from '../CustomCheckbox';
import CustomCheckboxGroup from '../CustomCheckboxGroup';
import CustomDatePicker from '../CustomDatePicker';
import CustomDateTimePicker from '../CustomDatetimePicker';
import CustomRadioGroup from '../CustomRadioGroup';
import CustomRichTextEditor from '../CustomRichTextEditor';
import CustomSelectField from '../CustomSelectField';
import CustomTextField from '../CustomTextField';
import CustomTimePicker from '../CustomTimePicker';
import DocumentRichText from 'company/screens/DocumentEditor/components/DocumentRichText';
import { FacilityContext } from 'core/context/facility.context';
import FileUpload from './FileUpload';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import SavingUpdates from './SavingUpdates';
import { WaitForFacility } from 'company/components';
import { autoSavePatientNote } from 'company/api/patient-notes';
import { debounce } from 'core/utils';
import { getDoctor } from 'company/api/doctors';

type Props = {
  noteId?: number;
  components?: any;
  appointment?: CompanyAppointmentModel;
  patient: PatientModel;
  fields: FieldInput[];
  responses: any;
  setLastUpdate?: React.Dispatch<React.SetStateAction<any>>;
};

type FieldContainerProps = {
  field: FieldInput;
  children: ReactNode;
};

const PatientRecordForm: React.FC<Props> = ({
  noteId,
  components,
  appointment,
  patient,
  fields,
  responses,
  setLastUpdate,
}) => {
  const span = 12;
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const gridColumn = `repeat(${span}, minmax(0, 1fr))`;
  const { facility } = useContext(FacilityContext);

  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [provider, setProvider] = useState<any>();
  const [allResponses, setResponses] = useState<any>(responses);
  const componentRef: any = useRef();

  useEffect(() => {
    setFormFields(fields);
  }, [fields]);

  useEffect(() => {
    if (appointment?.provider_id) {
      getDoctor(appointment.provider_id).then((res) => setProvider(res.data));
    }
  }, [appointment]);

  const getDefaultSpan = useCallback(
    (type: string) =>
      [
        'subsection_header',
        'section_header',
        'group_header',
        'body_text',
        'divider',
        'component',
        'rich_text',
      ].includes(type)
        ? span
        : span / 2,
    []
  );

  const FieldContainer: React.FC<FieldContainerProps> = ({ field, children }) => {
    return <Box sx={{ gridColumn: 'span ' + (field.span ?? getDefaultSpan(field.type as string)) }}>{children}</Box>;
  };

  const updateField = (field: FieldInput, value: any) => {
    responses[field.field_name] = value;
    // if (['text', 'rich_text'].includes(field.type as string)) setResponses({ ...responses });
    autoUpdate();
  };

  const autoUpdate = debounce(async () => {
    try {
      componentRef.current?.setIsSaving(true);
      if (components && noteId) await autoSavePatientNote(facility.id, noteId, JSON.stringify(components));
      // setLastUpdate &&
      //   setLastUpdate({
      //     editor: user.first_name + ' ' + user.last_name,
      //     updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   });
    } finally {
      setTimeout(() => {
        componentRef.current?.setIsSaving(false);
      }, 1000);
    }
  }, 1000);

  return (
    <WaitForFacility facility={facility}>
      <Box
        display="grid"
        columnGap={'25px'}
        rowGap={'25px'}
        gridTemplateColumns={gridColumn}
        alignItems="flex-end"
        sx={{
          '& > div': { gridColumn: isNonMobile ? undefined : `span ${span}` },
        }}
      >
        {formFields?.map((field, index) => {
          if (field.hidden || (field.hiddenBasedOnOtherField && field.hiddenBasedOnOtherField(allResponses))) {
            return false;
          }
          switch (field.type) {
            case 'divider':
              return (
                <FieldContainer field={field} key={index}>
                  <Divider />
                </FieldContainer>
              );

            case 'subsection_header':
            case 'section_header':
              return (
                <FieldContainer field={field} key={index}>
                  <Typography
                    variant={
                      field.type === 'subsection_header' ? 'h5' : field.type === 'section_header' ? 'h4' : undefined
                    }
                    fontWeight={'bold'}
                  >
                    {field.subsection_header ?? field.section_header}
                  </Typography>
                </FieldContainer>
              );

            case 'body_text':
              return (
                <FieldContainer field={field} key={index}>
                  <DocumentRichText
                    isBodyText
                    component={{ id: '', type: 'rich_text', content: field.body_text }}
                    mode={FORM_MODE.RESPOND}
                    halfWidth={field.span === span / 2}
                    patient={patient}
                    appointment={appointment}
                    provider={provider}
                  />
                </FieldContainer>
              );

            case 'select':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomSelectField
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    options={field.options!}
                    handleChange={(e) => updateField(field, e.target.value)}
                  />
                </FieldContainer>
              );

            case 'date':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomDatePicker
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'time':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomTimePicker
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'datetime':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomDateTimePicker
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'radiogroup':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomRadioGroup
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    options={field.options!}
                    handleChange={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'checkbox_group':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomCheckboxGroup
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    options={field.options!}
                    handleChangeCallback={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'checkbox':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomCheckbox
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(e) => updateField(field, e.target.value)}
                  />
                </FieldContainer>
              );

            case 'rich_text':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomRichTextEditor
                    editFormMode
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    height={field.height}
                    handleChangeCallback={(value) => updateField(field, value)}
                  />
                </FieldContainer>
              );

            case 'file_upload':
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <FileUpload
                    source={'forms'}
                    maxFilesonDrop={field.maxFilesonDrop}
                    maxFilesAllowed={field.maxFilesAllowed}
                    maxSize={field.maxSize}
                    fileTypes={field.fileTypes}
                    span={field.span ?? span}
                    formMode={FORM_MODE.RESPOND}
                    appointmentId={appointment?.id}
                    patientId={patient?.patient_id}
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(values) => updateField(field, values)}
                    patientNoteId={noteId}
                  />
                </FieldContainer>
              );

            default:
              return (
                <FieldContainer field={field} key={field.field_name}>
                  <CustomTextField
                    {...field}
                    label={field.display_name!}
                    value={responses[field.field_name]}
                    fieldName={field.field_name}
                    handleChange={(e) => updateField(field, e.target.value)}
                  />
                </FieldContainer>
              );
          }
        })}
      </Box>
      <SavingUpdates ref={componentRef} />
    </WaitForFacility>
  );
};

export default PatientRecordForm;
