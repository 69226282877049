import { Box, Typography } from '@mui/material';
import { DISPLAY_ONLY_FIELDS, FieldInput } from 'core/model/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ContainerRow } from 'core/components/containers';
import { CustomForm } from 'core/components';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import { getTemplates } from 'company/api/note-templates';
import { report_template_schema } from 'carego-admin/model/schema';

type NoteTemplate = {
  id: number;
  template_name: string;
  fields: FieldInput[];
};

type ReportTemplateField = {
  form_id: number;
  field_name: string;
};

const ReportTemplate = () => {
  const [templates, setTemplates] = useState<NoteTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [reorderedFields, setReorderedFields] = useState<ReportTemplateField[]>([]);

  const getNoteTemplates = async () => {
    try {
      const { data } = await getTemplates({ length: 1000 });
      setTemplates(
        data.data.map((template: any) => {
          let fields: FieldInput[] = JSON.parse(template.body)[0].fields ?? [];
          fields = fields.filter((field) => !DISPLAY_ONLY_FIELDS.includes(field.type as string));
          return {
            id: template.id,
            template_name: template.header,
            fields: fields,
          };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    getNoteTemplates();
  }, []);

  const fields: FieldInput[] = useMemo(() => {
    let fields: FieldInput[] = [];
    if (selectedTemplate) {
      fields = templates.find((template) => template.id === selectedTemplate)?.fields ?? [];
    }
    return [
      {
        field_name: 'form_id',
        display_name: 'Template',
        type: 'select',
        options: templates.map((template) => ({ value: template.id, key: template.template_name })),
        onChange: (value, setFieldValue) => {
          setSelectedTemplate(value);
          setFieldValue && setFieldValue('field', '');
        },
      },
      {
        field_name: 'field_name',
        display_name: 'Field',
        type: 'select',
        options: fields.map((field) => ({ value: field.field_name, key: field.display_name })),
        disabled: !selectedTemplate,
      },
    ] as FieldInput[];
  }, [templates, selectedTemplate]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newFields = [...reorderedFields];
    const [movedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, movedField);

    if (result.source.index !== result.destination.index) {
      //call backend
    }
    setReorderedFields(newFields);
  };

  const AddedFields = useCallback(() => {
    return (
      <Box flex="1">
        <DraggableList
          title={'Fields'}
          droppableId={'template-fields'}
          fromModal
          handleDragEnd={handleDragEnd}
          list={reorderedFields}
          style={{ padding: '5px 10px' }}
          displayItem={(item, index) => {
            const form = templates.find((template) => template.id === item.form_id);
            const field = form?.fields.find((field) => field.field_name === item.field_name);
            return (
              <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography fontWeight="bold">
                    {form?.template_name} - {field?.display_name}
                  </Typography>
                </Box>
              </Box>
            );
          }}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderedFields, templates]);

  return (
    <Box>
      <ContainerRow>
        <AddedFields />
        <Box flex="1">
          <CustomForm
            fields={fields}
            onSubmit={(data, formikHelpers) => {
              setReorderedFields((prev) => [...prev, { form_id: data.form_id, field_name: data.field_name }]);
              formikHelpers.resetForm();
            }}
            initialValues={{ form_id: '', field_name: '' }}
            loading={false}
            schema={report_template_schema}
          />
        </Box>
      </ContainerRow>
    </Box>
  );
};

export default ReportTemplate;
