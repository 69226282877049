/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import ReactMarkdown from 'react-markdown';
import { formatDate } from 'core/utils';
import { specialTokens } from 'core/data/list';
import { tokens } from 'theme/theme';

type UserInput = {
  name: string;
  event_start: string;
  event_end: string;
  id?: number;
  sms_template: string;
  description: string;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const _initialValues: UserInput = {
  name: '',
  event_start: '',
  event_end: '',
  sms_template: '',
  description: '',
};

type CreateOutreachProps = {
  outreachDetails: any;
  setOutreachDetails: any;
  updatedOutreach: any;
  toEdit: boolean;
};

const CreateOutreach: React.FC<CreateOutreachProps> = ({
  outreachDetails,
  setOutreachDetails,
  updatedOutreach,
  toEdit,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [preview, setPreview] = useState('');
  const [templateBody, setTemplateBody] = useState<string>(outreachDetails ? outreachDetails.sms_template : '');
  const [updateInitialValues, setUpdateInitialValues] = useState<UserInput>(toEdit ? outreachDetails : _initialValues);

  // Form Fields
  const fields: UserFieldInput[] = [
    {
      field_name: 'name',
      display_name: 'Event Name',
      type: 'text',
      span: 4,
      onChange: (value) => handleFieldChange('name', value),
    },
    {
      field_name: 'event_start',
      display_name: 'Event Start',
      type: 'datetime',
      minutesStep: 5,
      optional: true,
      onChange: (value) => handleFieldChange('event_start', value),
    },
    {
      field_name: 'event_end',
      display_name: 'Event End',
      type: 'datetime',
      minutesStep: 5,
      optional: true,
      onChange: (value) => handleFieldChange('event_end', value),
    },
    {
      field_name: 'description',
      display_name: 'Description',
      type: 'text',
      multiline: true,
      optional: true,
      span: 4,
      rows: 3,
      onChange: (value) => handleFieldChange('description', value),
    },
    {
      type: 'component',
      component: (
        <Box>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            SMS Template
          </Typography>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Preview ({templateBody.length} characters)
          </Typography>
          <Box
            color={colors.text}
            sx={{
              marginBottom: '20px',
              whiteSpace: 'pre-line',
              '& code': { color: 'white', backgroundColor: colors.main, padding: '2px 5px', borderRadius: '5px' },
            }}
          >
            <ReactMarkdown children={preview} />
          </Box>
        </Box>
      ),
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary, display: 'flex', gap: '20px' }}>
          <Typography variant="h5" color={colors.text} fontWeight={500}>
            Special tokens
          </Typography>
          <Box display="flex" gap="5px" width="100%" flexWrap="wrap">
            {specialTokens.map((token, index) => (
              <Box key={index} paddingBlock="5px">
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    transition: 'color 0.3s',
                    color: colors.text,
                  }}
                  onClick={() => handleTokenClick(token.token)}
                >
                  <span
                    style={{
                      backgroundColor: colors.light_blue_background_2,
                      borderRadius: '5px',
                      padding: '6px',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondary;
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = colors.light_blue_background_2;
                      e.currentTarget.style.color = colors.text;
                    }}
                  >
                    {token.token}
                  </span>
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ),
    },
    {
      field_name: 'sms_template',
      display_name: 'SMS Template',
      type: 'text',
      multiline: true,
      rows: 3,
      span: 4,
      onChange: (value) => setTemplateBody(value),
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Special tokens
          </Typography>
          <br></br>
          <Box display="grid" gridTemplateColumns="150px 1fr 150px 1fr" gap="5px" alignItems="center">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                >
                  <span
                    style={{ backgroundColor: colors.light_blue_background_2, borderRadius: '5px', padding: '6px' }}
                  >
                    {token.token}
                  </span>
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {token.description}
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
    },
  ];

  const handleTokenClick = (clickedToken: string) => {
    setTemplateBody((prevTemplate) => prevTemplate + ' ' + clickedToken);
  };

  const replacePreview = (template: string) => {
    if (!template || template.trim() === '') {
      setTemplateBody('');
      return '';
    }
    let updated = template
      ?.replaceAll('[Name]', '`Cheska Dela Cruz`')
      ?.replaceAll('[FirstName]', '`Cheska`')
      ?.replaceAll('[Facility_Name]', '`CPHI`')
      ?.replaceAll('[Facility_Type]', '`clinic`')
      ?.replaceAll('[Facility_Contact]', '`09123456789`')
      ?.replaceAll('[Date]', '`' + formatDate(new Date()) + '`')
      ?.replaceAll('[Time]', '`9:30 AM`')
      ?.replaceAll('[Health_Program]', '`appointment`')
      ?.replaceAll('[Day]', '`4`')
      ?.replaceAll('[Hour]', '`12`');

    return updated ?? '';
  };

  const handleSubmit = (data: any) => {
    setOutreachDetails(data);
  };

  const handleFieldChange = (field_name: keyof UserInput, value: any) => {
    setUpdateInitialValues((prevDetails: any) => ({
      ...prevDetails,
      [field_name]: value,
    }));
  };

  useEffect(() => {
    setPreview(replacePreview(templateBody));
    setUpdateInitialValues((prevValues) => ({ ...prevValues, sms_template: templateBody }));
  }, [templateBody]);

  useEffect(() => {
    setOutreachDetails(updateInitialValues);
  }, [updateInitialValues]);

  useEffect(() => {
    if (updatedOutreach) {
      setPreview(replacePreview(updatedOutreach.sms_template));

      setUpdateInitialValues({
        ...updatedOutreach,
        name: updatedOutreach.name,
        description: updatedOutreach.description,
        event_start: updatedOutreach.event_start,
        event_end: updatedOutreach.event_end,
      });
    } else if (outreachDetails) {
      setPreview(replacePreview(outreachDetails.sms_template));

      setUpdateInitialValues({
        ...outreachDetails,
        name: outreachDetails.name,
        description: outreachDetails.description,
        event_start: outreachDetails.event_start,
        event_end: outreachDetails.event_end,
      });
    } else {
      setPreview('');
      setTemplateBody('');
    }
  }, [updatedOutreach, outreachDetails]);

  return (
    <Box sx={{ width: '80%', margin: '0 auto' }}>
      <CustomForm
        onSubmit={handleSubmit}
        fields={fields}
        initialValues={updateInitialValues}
        loading={false}
        hideSubmitButton
        readOnly={outreachDetails.outreach_status === 'published' ? true : false}
      />
    </Box>
  );
};

export default CreateOutreach;
