import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { capitalizeWord, formatDate, formatDateTime } from 'core/utils';
import {
  getAppointmentProducts,
  getAppointmentServices,
  updateAttendance,
  updateNoShow,
} from 'company/api/appointments';

import { APPOINTMENT_ACTION } from 'company/screens/Appointments/components/AppointmentSettings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AppointmentSettingsModal from 'company/screens/Appointments/components/modal/AppointmentSettingsModal';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import ConfirmationDialog from '../modals/ConfirmationDialog';
import CreateInvoice from 'company/screens/Transactions/components/CreateInvoice';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import CustomModal from '../modals/CustomModal';
import { DropdownOptionProps } from '../Dropdown';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FacilityContext } from 'core/context/facility.context';
import HideOrShowComponent from '../HideOrShowComponent';
import InfoItem from 'company/entities/Template/components/InfoItem';
import MedicationIcon from '@mui/icons-material/Medication';
import { MobileViewConfig } from 'core/PageBuilder/CareGoTable';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PrescriptionForm from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionForm';
import ReactDOMServer from 'react-dom/server';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RegularButton from '../buttons/RegularButton';
import { STATUS } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import ViewInvoiceClick from 'company/screens/RegistrationBoard/components/ViewInvoiceClick';
import { simpleStringify } from 'company/components/ServiceProviderCalendar/utils';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type Props = {
  columns: GridColDef[];
  tableRows: GridRowModel[];
  rowActions?: DropdownOptionProps[];
  mobileViewConfig?: (data: any) => MobileViewConfig;
  mobileViewDetailsDisplay?: (data: any, actions?: any) => React.ReactNode;
  showAppointmentActionsButton?: boolean;
  appointment?: CompanyAppointmentModel;
  openModal?: boolean;
  updateAppointmentData?: () => void;
  refreshTable?: any;
  defaultOpen?: boolean;
  showViewInvoiceButton?: boolean;
  // setSelectedAppointment?: Dispatch<any>;
};

const MobileView: React.FC<Props> = ({
  columns,
  tableRows,
  rowActions,
  mobileViewConfig,
  mobileViewDetailsDisplay,
  showAppointmentActionsButton = false,
  appointment,
  refreshTable,
  updateAppointmentData,
  defaultOpen,
  showViewInvoiceButton = false,
  // setSelectedAppointment,
}) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { facility } = useContext(FacilityContext);

  const colors = tokens(theme.palette.mode);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(defaultOpen ? 0 : null);
  const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState<boolean>(false);
  const [services, setServices] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<CompanyAppointmentModel | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openCreatePrescription, setOpenCreatePrescription] = useState<boolean>(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [action, setAction] = useState<APPOINTMENT_ACTION>(APPOINTMENT_ACTION.NO_ACTION);
  const [dialogAlert, setDialogAlert] = useState('');
  const [confirmationNoShowDialogOpen, setConfirmationNoShowDialogOpen] = useState(false);
  const [confirmationAttendedDialogOpen, setConfirmationAttendedDialogOpen] = useState(false);

  const modalRef: any = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [openInvoiceClick, setOpenInvoiceClick] = useState(false);
  const handleOpenDrawer = (row: any) => {
    setSelectedRow(row);
    setDrawerOpen(true);
  };

  const handleOpenInvoice = (row: any) => {
    setSelectedRow(row);
    setOpenInvoiceClick(true);
  };

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const fetchServices = async (appointmentId: any) => {
    try {
      const response = await getAppointmentServices(facility.id, appointmentId);
      setServices(response.data.map((service: any) => ({ ...service, original_price: service.unit_price })));
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const fetchProducts = async (appointmentId: any) => {
    try {
      const response = await getAppointmentProducts(facility.id, appointmentId);
      setProducts(response.data.map((product: any) => ({ ...product, original_price: product.unit_price })));
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const confirmNoShow = () => {
    selectedRow &&
      setDialogAlert('Are you sure you want to update attendance of ' + selectedRow.full_name + ' to "no-show" ?');

    setConfirmationNoShowDialogOpen(true);
  };

  const confirmAttended = () => {
    selectedRow &&
      setDialogAlert(`Are you sure you want to update attendance of ${selectedRow.full_name} to Attended?`);

    setConfirmationAttendedDialogOpen(true);
  };

  const handleNoShow = () => {
    const data = { appointments: selectedRow.id };
    updateNoShow(facility.id, data)
      .then(() => {
        enqueueSnackbar('Appointment status successfully updated!', { variant: 'success' });
      })
      .then(() => refreshTable());
  };

  const handleCancel = (row: CompanyAppointmentModel) => {
    setSelectedAppointment(row);
    setOpenAppointmentModal(true);
    setAction(APPOINTMENT_ACTION.CANCEL);
    refreshTable();
  };

  const handleOpenCreateInvoiceModal = (row: CompanyAppointmentModel) => {
    setSelectedAppointment(row);
    setOpenCreateInvoiceModal(true);
  };

  const handleReschedule = (row: CompanyAppointmentModel) => {
    setSelectedAppointment(row);
    setOpenAppointmentModal(true);
    setAction(APPOINTMENT_ACTION.RESCHEDULE);
  };

  const handleUpdateService = (row: CompanyAppointmentModel) => {
    setSelectedAppointment(row);
    setOpenAppointmentModal(true);
    setAction(APPOINTMENT_ACTION.UPDATE_SERVICE);
  };

  const handleAttended = (row: CompanyAppointmentModel) => {
    updateAttendance(facility.id, selectedRow.id, true).then(() => {
      enqueueSnackbar('Appointment status successfully updated!', { variant: 'success' });
      refreshTable();
    });
  };

  const handleOpenCreatePrescription = (row: CompanyAppointmentModel) => {
    setSelectedAppointment(row);
    setOpenCreatePrescription(true);
  };

  const handleOpenCreateNotesAppointmentSettingsModal = (row: CompanyAppointmentModel) => {
    setSelectedAppointment(row);
    setOpenAppointmentModal(true);
    setAction(APPOINTMENT_ACTION.CREATE_NOTE);
  };

  const renderRowActions = useCallback(
    (row: any) => {
      const visibleActions = rowActions?.filter((action) => {
        if (action.hidden) return false;
        if (action.hideCallback && action.hideCallback(row)) return false;
        return true;
      });

      return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px" rowGap="10px" mt="10px">
          {visibleActions?.map((action, index) => (
            <RegularButton
              color={action.color}
              key={index}
              label={action.label}
              onClick={() => action.action && action.action(row)}
              startIcon={action.icon}
              fullWidth
              styles={{ flex: 1 }}
            />
          ))}
        </Box>
      );
    },
    [rowActions]
  );

  useEffect(() => {
    if (selectedAppointment) {
      fetchServices(selectedAppointment.id);
      fetchProducts(selectedAppointment.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment]);

  const displayMobileView = useCallback(
    (row: any) => {
      if (mobileViewConfig) {
        const { title_fields, title_link, subtitle_fields } = mobileViewConfig(row);

        const getValueString = (fields: string[]) => {
          return fields
            .map((field) => {
              const renderCell = columns.find((col) => col.field === field)?.renderCell;
              const renderedContent = renderCell
                ? renderCell({ row, value: row[field], stringOnly: true } as any)
                : row[field];

              // Check if renderedContent is a React element and extract its text content
              if (React.isValidElement(renderedContent)) {
                return ReactDOMServer.renderToString(renderedContent)
                  .replace(/<[^>]+>/g, '')
                  .trim();
              }

              return renderedContent;
            })
            .join(' | ');
        };

        return (
          <>
            <Typography
              sx={{ fontSize: '1rem', cursor: 'pointer', fontWeight: '600' }}
              onClick={title_link ? () => navigate(title_link) : undefined}
            >
              {getValueString(title_fields)}
            </Typography>
            {subtitle_fields && (
              <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>{getValueString(subtitle_fields)}</Typography>
            )}
          </>
        );
      }

      return (
        <Typography color={colors.accent} sx={{ fontSize: '1rem', cursor: 'pointer', fontWeight: '400' }}>
          {row[columns[0].field]}
          {columns[1] ? ' | ' + row[columns[1].field] : ''}
        </Typography>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, mobileViewConfig]
  );

  const btnStyles = {
    backgroundColor: colors.light_blue_background_2,
    color: colors.primary,
    boxShadow: 'none',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: colors.light_blue_background_2,
    },
  };
  const body = useMemo(() => {
    return columns.filter((col) => col.field !== 'action');
  }, [columns]);

  useEffect(() => {
    if (defaultOpen) {
      setExpandedIndex(0);
    }
  }, [defaultOpen]);

  return (
    <Box>
      {tableRows.length === 0 ? (
        <Typography variant="h6" color="textSecondary" align="center">
          No records found.
        </Typography>
      ) : (
        tableRows.map((row, index) => {
          return (
            <Accordion
              defaultExpanded={defaultOpen}
              key={index}
              expanded={expandedIndex === index}
              onChange={() => handleAccordionChange(index)}
              sx={{
                border: 'none',
                boxShadow: 'none',
                '& .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
                '& .MuiAccordionDetails-root': { padding: '10px 10px 0 10px' },
              }}
            >
              <AccordionSummary
                sx={{
                  paddingInline: '10px',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  '&.Mui-expanded': { backgroundColor: colors.hoverTableRow, paddingInline: '20px' },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box>{displayMobileView(row)}</Box>
              </AccordionSummary>

              <AccordionDetails>
                <Box display="flex" flexDirection="column" gap="10px" sx={{ '& .emphasis': { color: colors.accent } }}>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box display="flex" flexDirection="column" gap="5px">
                      {body.map((column, index) => (
                        <InfoItem
                          key={column.field}
                          label={column.headerName ?? ''}
                          last={index === body.length - 1}
                          value={
                            column.renderCell
                              ? column.renderCell({ row, value: row[column.field] } as any)
                              : row[column.field]
                          }
                        />
                      ))}

                      {showAppointmentActionsButton && (
                        <RegularButton label={'Appointment Actions'} onClick={() => handleOpenDrawer(row)} fullWidth />
                      )}

                      {showViewInvoiceButton && (
                        <RegularButton label={'View Invoice'} onClick={() => handleOpenInvoice(row)} fullWidth />
                      )}
                    </Box>
                  </Box>

                  {mobileViewDetailsDisplay && mobileViewDetailsDisplay(row, rowActions)}

                  {renderRowActions(row)}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
      <Box
        sx={{
          marginTop: '20px',
          '& .MuiTablePagination-toolbar': { display: 'grid', gridTemplateColumns: '10% 1fr 1fr 1fr' },
          '& .MuiTablePagination-selectLabel': { gridColumn: 'span 2' },
          '& .MuiTablePagination-displayedRows': { gridColumnStart: 2 },
          '& .MuiTablePagination-actions': { gridColumnStart: 3, gridColumn: 'span 2', margin: 0, textAlign: 'left' },
        }}
      >
        <TablePagination
          component="div"
          count={100}
          page={1}
          onPageChange={() => undefined}
          rowsPerPage={10}
          onRowsPerPageChange={() => undefined}
          showFirstButton
          showLastButton
        />
      </Box>

      <CustomBottomPopover open={drawerOpen} setOpen={setDrawerOpen} title={selectedRow?.full_name}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', gap: '15px' }}>
            <AccessTimeIcon sx={{ color: colors.primary }} />
            <Typography sx={{ fontWeight: '600' }}>{formatDateTime(selectedRow?.schedule)}</Typography>
          </Box>

          <Grid container spacing={1} sx={{ width: '90%' }}>
            <Grid item xs={5}>
              <Typography sx={{ color: colors.grey_text }}>Mobile Number:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{selectedRow?.mobile_number}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography sx={{ color: colors.grey_text }}>Service Provider:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{selectedRow?.service_provider}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography sx={{ color: colors.grey_text }}>Service:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{selectedRow?.service_names}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography sx={{ color: colors.grey_text }}>Status:</Typography>
            </Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  padding: '6px',
                  backgroundColor: selectedRow?.status === 'cancelled' ? colors.redAccent : colors.primary,
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  color: colors.input_background,
                }}
              >
                {selectedRow?.status === 'cancelled' && (
                  <EventBusyIcon sx={{ fontSize: '18px', color: colors.input_background }} />
                )}
                {selectedRow?.status === 'pending' && (
                  <PendingActionsIcon sx={{ fontSize: '18px', color: colors.input_background }} />
                )}
                {selectedRow?.status === 'rescheduled' && (
                  <EventRepeatIcon sx={{ fontSize: '18px', color: colors.input_background }} />
                )}
                {selectedRow?.status === 'done' && (
                  <EventAvailableIcon sx={{ fontSize: '18px', color: colors.input_background }} />
                )}

                <Typography>
                  {selectedRow?.status === 'cancelled'
                    ? 'Cancelled'
                    : capitalizeWord(selectedRow?.status) &&
                      (selectedRow?.status === 'pending' ? 'Pending' : capitalizeWord(selectedRow?.status))}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ padding: '20px', width: '100%' }}>
            <Accordion
              sx={{
                boxShadow: 'none',
                '&:before': {
                  display: 'none',
                },
                '&.MuiAccordion-root': {
                  boxShadow: 'none',
                },
              }}
            >
              <AccordionSummary
                onClick={(e) => e.stopPropagation()}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: '8px',
                  boxShadow: 'none',
                  color: colors.input_background,
                }}
                expandIcon={<ExpandMoreIcon sx={{ color: colors.input_background }} />}
              >
                <Typography>Appointment Actions</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >
                <Box width="100%" display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1}>
                  {!(
                    selectedRow?.invoice_id !== null || [STATUS.NO_SHOW, STATUS.CANCELLED].includes(selectedRow.status)
                  ) && (
                    <HideOrShowComponent hidden={facility.is_admin}>
                      <RegularButton
                        styles={btnStyles}
                        startIcon={<ReceiptIcon />}
                        fullWidth
                        label="Create Invoice"
                        onClick={() => handleOpenCreateInvoiceModal(selectedRow)}
                      />
                    </HideOrShowComponent>
                  )}

                  <RegularButton
                    styles={btnStyles}
                    startIcon={<MedicationIcon />}
                    fullWidth
                    label="Create Prescription"
                    onClick={() => handleOpenCreatePrescription(selectedRow)}
                  />

                  {selectedRow && ![STATUS.NO_SHOW, STATUS.CANCELLED].includes(selectedRow.status) && (
                    <RegularButton
                      styles={btnStyles}
                      startIcon={<CreateOutlinedIcon />}
                      fullWidth
                      label="Create Note"
                      onClick={() => handleOpenCreateNotesAppointmentSettingsModal(selectedRow)}
                    />
                  )}

                  {selectedRow && ![STATUS.DONE, STATUS.CANCELLED].includes(selectedRow.status) && (
                    <RegularButton
                      styles={btnStyles}
                      startIcon={<EventRepeatIcon />}
                      fullWidth
                      label="Reschedule"
                      onClick={() => handleReschedule(selectedRow)}
                    />
                  )}

                  {!(selectedRow?.status !== STATUS.PENDING && selectedRow?.status !== STATUS.RESCHEDULED) && (
                    <RegularButton
                      styles={btnStyles}
                      startIcon={<VaccinesIcon />}
                      fullWidth
                      label="Update Service"
                      onClick={() => handleUpdateService(selectedRow)}
                    />
                  )}
                  {!(selectedRow && [STATUS.DONE, STATUS.CANCELLED].includes(selectedRow.status)) && (
                    <RegularButton
                      styles={btnStyles}
                      startIcon={<CheckBoxOutlinedIcon />}
                      fullWidth
                      label="Attended"
                      onClick={() => confirmAttended()}
                    />
                  )}
                  {!(selectedRow && [STATUS.CANCELLED, STATUS.NO_SHOW].includes(selectedRow.status)) && (
                    <RegularButton
                      styles={btnStyles}
                      startIcon={<PersonOffIcon />}
                      fullWidth
                      label="No show"
                      onClick={() => confirmNoShow()}
                    />
                  )}
                  <RegularButton
                    styles={{
                      backgroundColor: colors.light_red_background_2,
                      color: colors.redAccent,
                      boxShadow: 'none',
                      borderRadius: '5px',
                      '&:hover': {
                        backgroundColor: colors.light_red_background_2,
                      },
                    }}
                    startIcon={<EventBusyIcon />}
                    fullWidth
                    label="Cancel"
                    onClick={() => handleCancel(selectedRow)}
                  />

                  {selectedRow?.invoice_id && (
                    <RegularButton
                      styles={btnStyles}
                      fullWidth
                      label="View Invoice"
                      onClick={() => setOpenInvoiceClick(true)}
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </CustomBottomPopover>

      {selectedAppointment && (
        <CreateInvoice
          appointment={selectedAppointment}
          orders={[...services, ...products]}
          openModal={openCreateInvoiceModal}
          setOpenModal={setOpenCreateInvoiceModal}
          refreshPatientSchedules={updateAppointmentData}
          setSelectedAppointment={setSelectedAppointment}
        />
      )}

      {/* {selectedRow && (
        <ViewInvoiceClick
          invoiceId={selectedRow?.invoice_id}
          openModal={openInvoiceClick}
          setOpenModal={setOpenInvoiceClick}
        />
      )} */}

      {selectedRow && (
        <CustomModal header="Create Prescription" open={openCreatePrescription} setOpen={setOpenCreatePrescription}>
          <PrescriptionForm
            appointmentId={selectedRow.id}
            patientID={selectedRow.patient_id}
            callbackAfterSubmit={(data: any) => {
              // onCreateItem && onCreateItem(data.medicine, 'prescription');
              setOpenCreatePrescription(false);
            }}
          />
        </CustomModal>
      )}

      {selectedRow && facility && (
        <AppointmentSettingsModal
          ref={modalRef}
          openModal={openAppointmentModal}
          setOpenModal={setOpenAppointmentModal}
          appointment={selectedRow}
          facility={facility}
          action={action}
          // navigateToEditor
          // navigateToEditor={action === APPOINTMENT_ACTION.CREATE_NOTE}
          // onCreateNote={(data) => onCreateItem && onCreateItem(data, 'note')}
          updateAppointmentData={() => {
            // updateAppointmentData();
            refreshTable();
          }}
        />
      )}
      <ViewInvoiceClick openModal={openInvoiceClick} setOpenModal={setOpenInvoiceClick} invoiceId={selectedRow?.id} />

      <ConfirmationDialog
        open={confirmationNoShowDialogOpen}
        setOpen={setConfirmationNoShowDialogOpen}
        content={dialogAlert}
        onConfirm={handleNoShow}
        bottomDrawerDialog
      />

      <ConfirmationDialog
        open={confirmationAttendedDialogOpen}
        setOpen={setConfirmationAttendedDialogOpen}
        content={dialogAlert}
        onConfirm={handleAttended}
        bottomDrawerDialog
      />
    </Box>
  );
};

export default MobileView;
