import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { CareGoPage } from 'core/PageBuilder';
import Cart from './components/Cart';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { CustomTabs } from 'core/components';
import ItemList from './components/ItemList';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { TabProps } from 'core/components/CustomTabs';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { formatAttributeValues } from 'core/utils';

export interface OrderProps {
  item_type: 'product' | 'service';
  item_id: number;
  unit_price: number;
  original_price: string;
  total_price?: number;
  quantity: number;
  item_name: string;
  attributes: string;
  made_to_order: boolean;
  quantity_in_stock: number;
  product_id?: number;
  isEdit?: boolean;
  sc_pwd_discounted?: boolean;
  tax: string;
  discount?: {
    discount_type: string;
    percentage?: number;
  };

  id?: number;
  service_id?: number;
  variant_id?: number;
  service_name?: string;
  discount_type?: string;
  discount_percentage?: number;
  patientId?: number;
  invoice_id?: number;
  service_tax?: string;
  variant_tax?: string;
}

export interface POSProps {
  fromRunningInvoice?: boolean;
  fromCreateInvoice?: boolean;
  fromUpdateInvoiceItems?: boolean;
  appointment?: CompanyAppointmentModel;
  initialOrders?: OrderProps[];
  afterPayment?: (orders: OrderProps[]) => void;
  setOpenInvoiceItemModal?: any;
  refreshInvoiceDetails?: () => void;
  fromAppointmentCreateInvoice?: boolean;
}

const POS = ({
  fromRunningInvoice,
  fromCreateInvoice,
  fromUpdateInvoiceItems,
  appointment,
  initialOrders,
  afterPayment,
  setOpenInvoiceItemModal,
  refreshInvoiceDetails,
  fromAppointmentCreateInvoice,
}: POSProps) => {
  const [selected, setSelected] = useState<number>(0);
  const [orders, setOrders] = useState<OrderProps[]>([]);
  const productListRef: any = useRef<any>();

  const handleAfterPayment = (orders: OrderProps[]) => {
    productListRef.current?.refreshItems(orders);
    afterPayment && afterPayment(orders);
  };

  useEffect(() => {
    if (initialOrders) {
      initialOrders.forEach((order) => {
        if (order.service_id) {
          if (!order.item_name) order.item_name = order.service_name!;
          order.item_type = 'service';
          order.item_id = order.service_id!;
          order.tax = order.service_tax!;
        } else {
          order.item_type = 'product';
          order.item_id = order.variant_id!;
          order.tax = order.variant_tax!;
        }

        if (order.discount_type) {
          order.discount = {
            discount_type: order.discount_type,
          };

          if (order.discount_type === 'percentage') {
            order.discount.percentage = order.discount_percentage;
          }
        }
      });
      setOrders(initialOrders);
    } else {
      setOrders(JSON.parse(localStorage.getItem('orders') || '[]'));
    }
  }, [initialOrders]);

  useEffect(() => {
    if (!initialOrders) localStorage.setItem('orders', JSON.stringify(orders));
  }, [orders, initialOrders]);

  const addToCart = (item: any) => {
    let newQuantityInStock = 0;
    if (item.type === 'product' && !item.made_to_order) {
      const existingOrders = orders.filter((order) => order.item_id === item.id && order.item_type === 'product');
      newQuantityInStock = item.quantity_in_stock;
      if (existingOrders.length) {
        newQuantityInStock = existingOrders[0].quantity_in_stock;
        existingOrders.forEach((order) => (order.quantity_in_stock = newQuantityInStock - 1));
      }
    }

    const newItem: OrderProps = {
      item_type: item.type,
      item_id: item.id,
      product_id: item.product_id ?? 0,
      unit_price: item.unit_price,
      original_price: item.unit_price,
      quantity: 1,
      item_name:
        item.type === 'product' ? item.product_name + formatAttributeValues(item.attributes, true) : item.service_name,
      attributes: item.attributes,
      made_to_order: item.type === 'product' ? item.made_to_order : '',
      tax: item.tax,
      quantity_in_stock: item.type === 'product' ? newQuantityInStock - 1 : 0,
    };
    if (!initialOrders) localStorage.setItem('orders', JSON.stringify([...orders, newItem]));
    setOrders((prev) => [...prev, newItem]);
  };

  const removeFromCart = (itemToRemove: OrderProps) => {
    setOrders((prevOrders) =>
      prevOrders.filter(
        (order) => !(order.item_id === itemToRemove.item_id && order.item_type === itemToRemove.item_type)
      )
    );
  };
  const tabs: TabProps[] = [
    {
      label: 'Services ',
      icon: <VaccinesIcon />,
      content: <ItemList addToCart={addToCart} type="service" orders={orders} fromCreateInvoice={fromCreateInvoice} />,
    },
    {
      label: 'Products ',
      icon: <SellOutlinedIcon />,
      content: (
        <ItemList
          addToCart={addToCart}
          type="product"
          orders={orders}
          ref={productListRef}
          fromCreateInvoice={fromCreateInvoice}
        />
      ),
    },
  ];

  return (
    <CareGoPage
      header={fromCreateInvoice || appointment ? undefined : 'POS'}
      breadcrumb={fromCreateInvoice || appointment ? undefined : [{ label: 'POS' }]}
    >
      <Box display="grid" columnGap="20px" gridTemplateColumns="2fr 1.3fr">
        <CustomTabs
          tabs={tabs}
          setSelected={setSelected}
          selected={selected}
          stopParamChange={fromAppointmentCreateInvoice ?? false}
        />
        <Cart
          orders={orders}
          setOrders={setOrders}
          initialOrders={initialOrders}
          afterPayment={handleAfterPayment}
          fromRunningInvoice={fromRunningInvoice}
          fromCreateInvoice={fromCreateInvoice}
          removeFromCart={removeFromCart}
          appointment={appointment}
          fromUpdateInvoiceItems={fromUpdateInvoiceItems}
          setOpenInvoiceItemModal={setOpenInvoiceItemModal}
          refreshInvoiceDetails={refreshInvoiceDetails}
        />
      </Box>
    </CareGoPage>
  );
};

export default POS;
