import { Box, Typography } from '@mui/material';
import HealthAssessmentTable from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentTable';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import React, { useContext, useEffect } from 'react';

export default function HealthAssessments() {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  useEffect(() => {
    setBreadcrumb([{ label: 'Health Assessments' }]);
  }, []);
  return (
    <Box>
      <CareGoPage header="Health Assessments">
        <HealthAssessmentTable />
      </CareGoPage>
    </Box>
  );
}
