/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomContainer, CustomForm, CustomModal, RegularButton } from 'core/components';
import CustomTabs, { TabProps } from 'core/components/CustomTabs';
import { PROGRAM_CODES, getFormFields, getFormFieldsValues } from 'company/screens/Utils/Customization';
import { SERVICE_ADD_STEPS, TOUR_ID } from 'core/screens/Helper/GuidedTour/steps';
import { formatDateTimeWithDay, tranformFormErrors } from 'core/utils';
import {
  getAppointmentInDateRange,
  getAppointments,
  getTotalPatientsServedToday,
  scheduleAppointment,
} from 'company/api/appointments';
import { getServices, getServicesAssigned } from 'company/api/services';
import { useContext, useEffect, useRef, useState } from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AppointmentTable from 'company/entities/modules/ClinicManagement/Appointment/AppointmentTable';
import BookingsForConfirmation from './BookingsForConfirmation';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Calendar from '../../CompanyDashboard/components/Calendar';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { CareGoPage } from 'core/PageBuilder';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FormikHelpers } from 'formik';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ServiceProviderCalendar from 'company/components/ServiceProviderCalendar';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SmsPreview from '../components/modal/SmsPreview';
import { SmsScheduleModal } from 'company/components';
import { TourContext } from 'core/context/tour.context';
import { UserContext } from 'core/context/user.context';
import { getBookings } from 'company/api/bookings';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const AppointmentsTabs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { setTourState } = useContext(TourContext);
  const { enqueueSnackbar } = useSnackbar();
  const tableRef: any = useRef();
  const serviceViewRef: any = useRef();
  const calendarViewRef: any = useRef();

  const [selected, setSelected] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(true);
  //Schedule Patients
  const [openEnrollModal, setOpenEnrollModal] = useState(false);

  const [openSmsModal, setOpenSmsModal] = useState(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [schema, setSchema] = useState<any>();
  const [formFields, setFormFields] = useState<FieldInput[]>([]);

  const [schedule, setSchedule] = useState<Date>();

  const [bookingsNum, setBookingsNum] = useState<number>(0);
  const [appointmentsNum, setAppointmentsNum] = useState<number>(0);
  const [totalPatientsServedToday, setTotalPatientsServedToday] = useState<number>(0);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [appointments, setAppointments] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
    setBookingsCount();
    setAppointmentsCount();
    tableRef.current?.refreshTable();
    serviceViewRef.current?.getAppointments();
  };

  const tabs: TabProps[] = [
    {
      label: 'Calendar View',
      icon: <CalendarTodayOutlinedIcon />,
      content: (
        <CustomContainer>
          <Calendar refresh={refresh} handleDateSelected={handleDateClick} />
        </CustomContainer>
      ),
      hidden: isMobilePhone,
    },
    {
      label: 'Schedule View',
      content: (
        <CustomContainer>
          {showTable && <BookingsForConfirmation onUpdate={() => refreshTable()} setShowTable={setShowTable} />}
          <AppointmentTable tableRef={tableRef} stackHeaderComponents={false} />
        </CustomContainer>
      ),
      icon: <EventNoteOutlinedIcon />,
    },
    {
      label: 'Provider View',
      content: (
        <CustomContainer>
          <ServiceProviderCalendar ref={serviceViewRef} />
        </CustomContainer>
      ),
      icon: <PermContactCalendarOutlinedIcon />,
      hidden: user?.user_group.group_name !== 'COMPANY_ADMIN',
    },
  ];

  const schedulePatients = () => {
    setSchedule(undefined);
    setOpenEnrollModal(true);
  };

  useEffect(() => {
    if (selectedDate) {
      initialValues.schedule = selectedDate;
      setOpenEnrollModal(true);
    }
  }, [selectedDate]);

  const actions = [
    {
      label: 'Schedule Appointment',
      action: schedulePatients,
      icon: <GroupAddOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
    {
      label: 'SMS Schedules',
      action: () => {
        setOpenSmsModal(true);
      },
      icon: <SmsOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
  ];

  const setBookingsCount = async () => {
    if (facility && !facility.is_admin) {
      try {
        getBookings(facility.id, { status: 'pending' }).then((res) => {
          setBookingsNum(res.data.data.length);
        });
      } catch (error) {
        setBookingsNum(0);
      }
    }
  };

  const setAppointmentsCount = async () => {
    if (facility && !facility.is_admin) {
      const dates = getCurrentWeekDates();
      try {
        getAppointmentInDateRange(facility.id, dates.start, dates.end).then((res) => {
          setAppointmentsNum(res.data.data.length);
        });
      } catch (error) {
        setAppointmentsNum(0);
      }
    }
  };

  const getCurrentWeekDates = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week'); // Sunday
    const weekEnd = currentDate.clone().endOf('week'); // Saturday

    return {
      start: new Date(weekStart.toDate().toDateString()),
      end: new Date(weekEnd.toDate().toDateString()),
    };
  };

  const getAllAppointments = async (query: ApiQuery) => {
    try {
      const res = await getAppointments(facility.id, query);
      setAppointments(res.data.data);
    } catch (error) {
      console.error();
    }
  };
  
  useEffect(() => {
    setRefresh((refresh) => refresh + 1);
  }, [selected]);

  useEffect(() => {
    if (facility) {
      getAllAppointments({});
    }
  }, [facility]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Appointments' }]);
  }, [facility]);

  useEffect(() => {
    if (facility?.id) {
      setBookingsCount();
      setAppointmentsCount();
      getTotalPatientsServedToday(facility?.id).then((res) => setTotalPatientsServedToday(res?.data?.quantity));
    }
  }, [facility?.id]);

  // useEffect(() => {
  //   if (facility) {
  //     getFormFields(PROGRAM_CODES.APPOINTMENTS, setFormFields, setSchema, facility);
  //     getFormFieldsValues(PROGRAM_CODES.APPOINTMENTS, setInitialValues);
  //   }
  // }, [facility]);

  useEffect(() => {
    if (facility) {
      getFormFields(PROGRAM_CODES.APPOINTMENTS, setFormFields, setSchema, facility, undefined, appointments);
      getFormFieldsValues(PROGRAM_CODES.APPOINTMENTS, setInitialValues);
    }
  }, [facility, appointments]);

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (data.service_id.length) {
      try {
        const final = { ...data };
        final.patients = JSON.stringify(data.patient_id.map((patient: any) => patient.value));
        final.services = JSON.stringify(data.service_id.map((service: any) => service.value));
        const res = await scheduleAppointment(facility.id, final);
        successFormSubmit(res, formikHelpers);
      } catch (error: any) {
        if (error.response?.data?.errors) formikHelpers.setErrors(tranformFormErrors(error.response.data.errors));
        enqueueSnackbar('Error occured! Please try again.', { variant: 'error' });
      } finally {
        setButtonLoading(false);
      }
    } else {
      formikHelpers.setErrors({ service_id: 'Service is required' });
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`Patient successfully scheduled!`, { variant: 'success' });
      setOpenEnrollModal(false);
    } else if (res.response.status === 400) {
    }
    setButtonLoading(false);
    refreshTable();
  };

  return (
    <Box>
      <CareGoPage
        header="Appointments"
        showActionButton={!facility?.is_admin && user?.authorities?.includes('MANAGE_APPOINTMENTS')}
        actionButtonLabel="Schedule Patients"
        actionButtonIcon={<AddOutlinedIcon />}
        dropdownActions={actions}
        regularButtonStyle={{ width: '200px' }}
      >
        {/* <ContainerColumn> */}
        <Box
          display="grid"
          gridTemplateColumns={isMobilePhone ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'}
          // gridAutoRows="140px"
          gap="10px"
          mb="10px"
          width={isMobilePhone ? '100%' : '75%'}
        >
          <CustomDashboardWidget
            count={bookingsNum.toString()}
            label="No. of Bookings for Confirmation"
            icon={EventNoteOutlinedIcon}
            sx={{ backgroundColor: '#0380BC' }}
            iconBackgroundColor="transparent"
          />
          <CustomDashboardWidget
            count={appointmentsNum.toString()}
            label="No. of Appointments per Week"
            icon={CalendarMonthOutlinedIcon}
            sx={{ backgroundColor: '#337497' }}
            iconBackgroundColor="transparent"
          />
          <CustomDashboardWidget
            count={String(totalPatientsServedToday)}
            label="No. of Patients Served Today"
            icon={PeopleOutlinedIcon}
            sx={{ backgroundColor: '#0085A0' }}
            iconBackgroundColor="transparent"
          />
        </Box>
        <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} tabMinWidth="250px" />
        {/* </ContainerColumn> */}

        <CustomModal
          open={open}
          setOpen={setOpen}
          width={400}
          header="Service Required"
          headerIcon={<ReportProblemOutlinedIcon />}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Sorry, you don't have services added in the clinic.
              <br />
              Please add services in the Clinic Management tab.
            </Typography>
            <RegularButton
              onClick={() =>
                setTourState((prev: any) => {
                  return {
                    ...prev,
                    steps: SERVICE_ADD_STEPS,
                    tourId: TOUR_ID.SERVICE_ADD_STEPS,
                    run: true,
                    tourActive: true,
                    stepIndex: 0,
                  };
                })
              }
              label="How to add Service?"
              styles={{ marginTop: 2 }}
              startIcon={<LiveHelpOutlinedIcon />}
            />
          </Box>
        </CustomModal>
      </CareGoPage>

      <>
        <CustomModal
          header="Schedule Patients"
          subHeader={schedule ? formatDateTimeWithDay(schedule) : ''}
          open={openEnrollModal}
          setOpen={setOpenEnrollModal}
        >
          <CustomForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            fields={formFields}
            schema={schema}
            loading={buttonLoading}
            footerComponent={<SmsPreview templateCode="appointment_reminder" facility={facility} mt="20px" />}
          />
        </CustomModal>

        <SmsScheduleModal
          open={openSmsModal}
          setOpen={setOpenSmsModal}
          programCode="appointments"
          fromAppointment
          passActualPatientID
          showEditFrequency
        />
      </>
    </Box>
  );
};

export default AppointmentsTabs;
