import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography } from '@mui/material';
import {
  ColoredTable,
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomForm,
  CustomInlineForm,
  CustomModal,
  CustomTable,
  Header,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortDirection,
} from '@mui/x-data-grid';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { changeNullToBlank, tranformFormErrors } from 'core/utils';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FormikHelpers } from 'formik';
import { UserContext } from 'core/context/user.context';
import { WaitForFacility } from 'company/components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';

export type TableComponentProps = {
  columns?: GridColDef[];
  rowId?: string;
  showAppointmentActionsButton?: boolean;
  showViewInvoiceButton?:boolean;
  // Components
  mainHeaderComponent?: React.ReactNode;
  headerComponent?: React.ReactNode;
  showHeaderBelowTable?: boolean;
  filterComponent?: React.ReactNode;

  // Functions
  exportRecords?: (query: ApiQuery) => void;
  getData?: (query: ApiQuery) => Promise<any>;
  onGetData?: (data: any) => void;
  onSelectRow?: (selectedRow: any) => void;
  onRowsSelected?: (selectedRowIds: number[]) => void;

  // Optionals
  clearSelected?: number;
  forceRefresh?: number;
  rowHeight?: number;
  tableHeight?: string | number;
  otherFilter?: any;
  columnVisibilityModel?: GridColumnVisibilityModel;
  hideOnTablet?: string[];
  hideViews?: boolean;

  hideTable?: boolean;
  checkboxSelection?: boolean;
  hideAddSelectedButton?: boolean;
  disableSelectionOnClick?: boolean;
  hideSearch?: boolean;
  hidePagination?: boolean;
  keepNonExistentRowsSelected?: boolean;
  selectableRow?: boolean;
  showRefreshButton?: boolean;
  stickyTop?: number;

  afterSearchbarComponent?: ReactNode;

  customSelectableRow?: (params: GridRowParams) => boolean;

  stackHeaderComponents?: boolean;
  fileViewer?: boolean;

  onFilesRefresh?: () => void;

  // Default values
  initialPage?: number;
  initialPageSize?: number;
  initialOrder?: GridSortDirection;
  initialOrderBy?: string;

  displayChildComponent?: (row: any) => ReactNode | ReactNode[];

  //grid
  onGridItemClick?: (item: any) => void;

  //mobile view
  mobileViewDetailsDisplay?: (data: any, actions?: DropdownOptionProps[]) => React.ReactNode;
  mobileViewConfig?: (data: any) => MobileViewConfig;
};

export type MobileViewConfig = {
  title_fields: string[];
  title_link?: string;
  subtitle_fields?: string[];
};

export type TableFormProps = {
  formInitialValues?: any;
  formFields?: FieldInput[];
  formModalWidth?: number;
  formRowGap?: number | string;
  formSchema?: any;
  formSubmitApiFunction?: (data: any) => Promise<any>;
  formAfterSubmitFunction?: (data?: any) => void;
  updateFormFields?: FieldInput[] | null;
  updateFormSchema?: any;
  updateFormInitialValues?: any;
  disableSubmitButton?: boolean;
  submitButtonText?: string;
  processFormInitialValues?: (data: any) => void;
  customForm?: ReactNode;
  useInlineForm?: boolean;
  buttonPosition?: 'start' | 'center' | 'end';
};

export type TableActionProps = {
  includeAddButton?: boolean;
  includeUpdateAction?: boolean;
  includeDeleteAction?: boolean;

  disableAddButton?: boolean;
  disableUpdateButton?: boolean;
  disableDeleteButton?: boolean;

  rowActions?: DropdownOptionProps[];
  rowActionsComponent?: (data: any) => ReactNode;
  rowActionRequiredAuth?: string[];
  addActionRequiredAuth?: string[];
  actionFlex?: number;

  showAddModal?: boolean;

  handleAddButtonClick?: () => void;
  handleRowActionsClick?: (selectedRow: any, action: string) => void;
  deleteApiFunction?: (...args: any[]) => void;
  callbackAfterDelete?: () => void;

  addButtonLabel?: string;
  addButtonStyles?: React.CSSProperties;
  deleteButtonLabel?: string;
  updateButtonLabel?: string;
  alertCustomObjectColumn?: string;
  getRow?: React.Dispatch<any>;
  customDeleteSnackBarMessageOnSuccess?: string;
  getRowCount?: React.Dispatch<any>;
  hideUpdateCallback?: (data: any) => boolean;
  hideDeleteCallback?: (data: any) => boolean;
  hideRowActionCallback?: (data: any) => boolean;
  doNotHideActionForInactiveCompany?: boolean;

  addMobileLabel?: string;
  addHeaderContainerStyles?: React.CSSProperties;
};

export type CareGoTableProps = {
  entityName?: string; // for modals
  tableName?: string; // displayed name
  doNotWaitForFacility?: boolean;

  tableComponent: TableComponentProps;
  tableForm?: TableFormProps;
  tableAction?: TableActionProps;
  useColoredTable?: boolean;
};

const CareGoTable: React.FC<CareGoTableProps> = ({
  entityName = '',
  tableName = '',
  doNotWaitForFacility = false,
  tableComponent,
  tableForm,
  tableAction,
  useColoredTable,
}) => {
  const {
    rowId = 'id',
    columns,
    mainHeaderComponent,
    headerComponent,
    filterComponent,
    exportRecords,
    getData,
    onGetData,
    onSelectRow,
    clearSelected,
    forceRefresh,
    rowHeight,
    tableHeight,
    otherFilter,
    columnVisibilityModel,
    hideOnTablet,
    hideTable,
    checkboxSelection,
    hideAddSelectedButton,
    disableSelectionOnClick,
    hideSearch,
    hidePagination,
    hideViews,
    keepNonExistentRowsSelected,
    selectableRow,
    showRefreshButton,
    customSelectableRow,
    initialPage,
    initialPageSize,
    initialOrder,
    initialOrderBy,
    showHeaderBelowTable,
    stackHeaderComponents,
    fileViewer,
    onGridItemClick,
    mobileViewDetailsDisplay,
    mobileViewConfig,
    onFilesRefresh,
    displayChildComponent,
    stickyTop,
    afterSearchbarComponent,
    onRowsSelected,
  } = tableComponent;

  const {
    formInitialValues,
    formFields,
    formModalWidth,
    formRowGap,
    formSchema,
    formSubmitApiFunction,
    formAfterSubmitFunction,
    updateFormFields,
    updateFormSchema,
    updateFormInitialValues,
    processFormInitialValues,
    disableSubmitButton,
    submitButtonText,
    customForm,
    useInlineForm,
    buttonPosition,
  } = tableForm ?? {};

  const {
    includeAddButton,
    includeUpdateAction,
    includeDeleteAction,
    disableAddButton,
    disableUpdateButton,
    disableDeleteButton,
    rowActions,
    rowActionsComponent,
    rowActionRequiredAuth,
    addActionRequiredAuth,
    actionFlex,
    showAddModal,
    handleAddButtonClick,
    handleRowActionsClick,
    deleteApiFunction,
    callbackAfterDelete,
    addButtonLabel,
    deleteButtonLabel,
    updateButtonLabel,
    alertCustomObjectColumn,
    customDeleteSnackBarMessageOnSuccess,
    getRow,
    getRowCount,
    hideUpdateCallback,
    hideDeleteCallback,
    hideRowActionCallback,
    doNotHideActionForInactiveCompany,
  } = tableAction ?? {};
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const tableRef: any = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState(columnVisibilityModel);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [rowActionOptions, setRowActionOptions] = useState<DropdownOptionProps[]>([]);

  const [tableColumns, setTableColumns] = useState<GridColDef[]>([]);
  const [refreshData, setRefreshData] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<any>(formInitialValues);
  const [modalHeader, setModalHeader] = useState('');

  const [isUpdate, setIsUpdate] = useState(false);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertContent, setAlertContent] = useState('');

  const [rowSelectedForAction, setRowSelectedForAction] = useState<any>();
  const deviceIsTablet = useMediaQuery('(max-width: 1224px)');
  const isMobile = useMediaQuery('(max-width:768px)');

  const refreshTable = () => {
    setRefreshData((prev: number) => prev + 1);
    tableRef.current?.refreshTable();
  };

  useEffect(() => {
    forceRefresh && setRefreshData(forceRefresh);
    tableRef.current?.refreshTable();
  }, [forceRefresh]);

  useEffect(() => {
    forceRefresh && setRefreshData(forceRefresh);
    tableRef.current?.refreshTable();
  }, [forceRefresh]);

  useEffect(() => {
    let list: any = {};
    if (hideOnTablet && deviceIsTablet) {
      hideOnTablet.forEach((col: string) => {
        list[col] = false;
      });
    }
    if (columnVisibilityModel) list = { ...columnVisibilityModel, ...list };
    setColumnVisibility(list);
  }, [hideOnTablet, deviceIsTablet, columnVisibilityModel]);

  const handleConfirmDelete = async (rowId: any) => {
    if (deleteApiFunction) {
      try {
        setLoadingDelete(true);
        await deleteApiFunction(rowId);
        if (customDeleteSnackBarMessageOnSuccess) {
          enqueueSnackbar(`${entityName} successfully ${customDeleteSnackBarMessageOnSuccess}!`, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(`${entityName} successfully deleted!`, { variant: 'success' });
        }
        refreshTable();
        callbackAfterDelete && callbackAfterDelete();
      } catch (error) {
        enqueueSnackbar(`Error`, { variant: 'error' });
      } finally {
        setLoadingDelete(false);
      }
    }
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (formSubmitApiFunction) {
      try {
        const res = await formSubmitApiFunction(data);
        successFormSubmit(!!data[rowId], res, formikHelpers);
        formAfterSubmitFunction && formAfterSubmitFunction(res.data);
      } catch (error: any) {
        if (error.response) {
          enqueueSnackbar(error.response.data.message ?? 'Error', { variant: 'error' });
          if (error.response?.data?.errors) formikHelpers.setErrors(tranformFormErrors(error.response.data.errors));
        }
      } finally {
        setButtonLoading(false);
        setOpenModal(false);
      }
    }
  };

  const successFormSubmit = (from_edit: boolean, res: any, { resetForm, setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar(`${entityName} successfully ${from_edit ? 'updated' : 'created'}!`, { variant: 'success' });
      refreshTable();
      setOpenModal(false);
      if (!from_edit) {
        resetForm();
      }
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
  };

  const handleAddClick = () => {
    handleAddButtonClick && handleAddButtonClick();

    setIsUpdate(false);
    setModalHeader('Create ' + entityName);
    setInitialValues(formInitialValues);
    setOpenModal(true);
  };

  const handleUpdateClick = (data: any) => {
    data = changeNullToBlank(updateFormInitialValues ? updateFormInitialValues : data);
    const to_update = { ...data };
    processFormInitialValues && processFormInitialValues(to_update);
    handleRowActionsClick && handleRowActionsClick(to_update, 'update');

    setIsUpdate(true);
    setModalHeader(alertCustomObjectColumn ? `Update ${data[alertCustomObjectColumn]}` : 'Update ' + entityName);
    setInitialValues(to_update);
    setOpenModal(true);
  };

  const handleDeleteClick = (data: any) => {
    handleRowActionsClick && handleRowActionsClick(data, 'delete');
    const deleteLabel = deleteButtonLabel ? deleteButtonLabel.toLocaleLowerCase() : 'delete';
    setAlertContent(
      deleteLabel && alertCustomObjectColumn
        ? `Are you sure you want to ${deleteLabel} ${data[alertCustomObjectColumn]}?`
        : `Are you sure you want to ${deleteLabel} this ${entityName.toLocaleLowerCase()}?`
    );
    setRowSelectedForAction(data[rowId]);
    setOpenAlertDialog(true);
  };

  const _actions: DropdownOptionProps[] = [
    {
      label: updateButtonLabel ? updateButtonLabel : 'Update',
      action: handleUpdateClick,
      icon: <EditOutlinedIcon />,
      hideCallback: hideUpdateCallback && hideUpdateCallback,
    },
    {
      label: deleteButtonLabel ? deleteButtonLabel : 'Delete',
      action: handleDeleteClick,
      icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
      hideCallback: hideDeleteCallback && hideDeleteCallback,
      color: 'error',
    },
  ];

  useEffect(() => {
    if (columns) {
      const cols = [...columns];
      if (rowActions || rowActionsComponent || includeUpdateAction || includeDeleteAction) {
        if (rowActionsComponent)
          cols.push({
            field: 'action',
            sortable: false,
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: actionFlex ?? 1,
            renderCell: (params: any) => <>{rowActionsComponent(params.row)}</>,
          });

        let actions: DropdownOptionProps[] = [];
        includeUpdateAction && !disableUpdateButton && actions.push(_actions[0]);

        if (rowActions) actions = [...actions, ...rowActions];

        if (actions.length >= 2 && includeDeleteAction && !disableDeleteButton) {
          actions.push({
            label: 'divider',
            hideCallback: (data) => !!(hideDeleteCallback && hideDeleteCallback(data)),
          });
        }
        includeDeleteAction && !disableDeleteButton && actions.push(_actions[1]);
        if (actions.length || !user) {
          cols.push({
            field: 'action',
            sortable: false,
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: actionFlex ?? 1,
            renderCell: (params: any) => (
              <CompanyProtectedComponent requiredAuth={rowActionRequiredAuth} showNoAccess>
                {actions.length === 1 ? (
                  <RegularButton
                    variant="outlined"
                    size="small"
                    color={actions[0].color}
                    label={actions[0].label}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (actions[0].action) {
                        getRow && getRow(params.row);
                        actions[0].action(params.row);
                      }
                    }}
                    startIcon={actions[0].icon}
                    styles={{ padding: '4px 10px' }}
                  />
                ) : (
                  <RowAction
                    actions={actions}
                    data={params.row}
                    getRow={getRow}
                    hidden={hideRowActionCallback && hideRowActionCallback(params.row)}
                  />
                )}
              </CompanyProtectedComponent>
            ),
          });
          setRowActionOptions(actions);
        }
      }

      setTableColumns(cols);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rowActions, includeUpdateAction, includeDeleteAction, disableUpdateButton, disableDeleteButton]);

  useEffect(() => {
    if (showAddModal) handleAddClick();
    else setOpenModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddModal]);

  const handleSelectRow = (selected: GridRowSelectionModel) => {
    if (useColoredTable) {
      onSelectRow && onSelectRow(selected);
      return;
    }
    if (checkboxSelection) {
      setSelectedRow(selected);
      onSelectRow && onSelectRow(selected);
      return;
    }

    if (selected.length && (!selectedRow || selectedRow[rowId] !== selected[0])) {
      const selectedID = selected[0] as number;

      const row = rows.find((row) => row[rowId] === selectedID);
      setSelectedRow(row);
      onSelectRow && onSelectRow(row);
    } else {
      setSelectedRow(undefined);
      onSelectRow && onSelectRow(undefined);
    }
  };

  const getTableData = async (query: ApiQuery) => {
    try {
      if (getData) {
        if (useColoredTable) return getData({ ...query, ...otherFilter });
        setLoading(true);
        const res = await getData({ ...query, ...otherFilter });
        if (res) {
          setRows(res.data?.data);
          setRowCount(res.data.meta.total);
          getRowCount && getRowCount(res.data.meta.total);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clearSelected) {
      setSelectedRow(undefined);
      if (checkboxSelection) tableRef.current.clearSelectedRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelected]);

  const tableHeaderComponent = (
    <Box display="flex" flexDirection={'column'} gap="10px" width="100%">
      {tableName && !mainHeaderComponent && <Header title={tableName} mb="0" />}
      {!showHeaderBelowTable && (
        <Box display="flex" gap="10px" alignItems="flex-end">
          {includeAddButton && (
            <CompanyProtectedComponent requiredAuth={addActionRequiredAuth ?? rowActionRequiredAuth}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={tableAction?.addHeaderContainerStyles}
              >
                {tableAction?.addMobileLabel && (
                  <Typography variant="body1" style={{ marginRight: '10px' }}>
                    {tableAction.addMobileLabel}
                  </Typography>
                )}
                <PrimaryButton
                  onClick={() => handleAddClick()}
                  label={addButtonLabel ?? `Add ${entityName}`}
                  id={`${entityName.replace(/\s/g, '')}_add_button`}
                  disabled={disableAddButton}
                  styles={tableAction?.addButtonStyles}
                />
              </Box>
            </CompanyProtectedComponent>
          )}
          {headerComponent}
        </Box>
      )}
      {filterComponent}
    </Box>
  );

  return (
    <Box width="100%">
      <WaitForFacility facility={facility} skip={doNotWaitForFacility}>
        {!hideTable && (
          <>
            {useColoredTable ? (
              <ColoredTable
                columns={tableColumns}
                getData={getData}
                onGetData={onGetData}
                headerComponent={tableHeaderComponent}
                otherFilter={otherFilter}
                ref={tableRef}
                rowId={rowId}
                handleSelectRow={handleSelectRow}
                exportRecords={exportRecords}
                stackHeaderComponents={stackHeaderComponents}
                fileViewer={fileViewer}
                onFilesRefresh={onFilesRefresh && onFilesRefresh}
                hideSearch={hideSearch}
                hidePagination={hidePagination}
                displayChildComponent={displayChildComponent}
                afterSearchbarComponent={afterSearchbarComponent}
                stickyTop={stickyTop}
                rowHeight={rowHeight}
                orderBy={initialOrderBy}
                order={initialOrder}
                checkboxSelection={
                  checkboxSelection && !!(doNotHideActionForInactiveCompany || user.company?.is_active)
                }
                hideAddSelectedButton={hideAddSelectedButton}
                onRowsSelected={onRowsSelected}
                rowActions={rowActionOptions}
                mobileViewDetailsDisplay={mobileViewDetailsDisplay}
                mobileViewConfig={mobileViewConfig}
                showAppointmentActionsButton={tableComponent.showAppointmentActionsButton}
                showViewInvoiceButton={tableComponent.showViewInvoiceButton}
              />
            ) : (
              <CustomTable
                rows={rows}
                columns={tableColumns}
                columnVisibilityModel={columnVisibility}
                rowId={rowId}
                rowCount={rowCount}
                exportRecords={exportRecords}
                getData={getTableData}
                handleSelectRow={handleSelectRow}
                clearSelected={clearSelected}
                hideViews={hideViews}
                forceRefresh={refreshData}
                otherFilter={otherFilter}
                rowHeight={rowHeight}
                tableHeight={tableHeight}
                checkboxSelection={
                  checkboxSelection && !!(doNotHideActionForInactiveCompany || user.company?.is_active)
                }
                disableSelectionOnClick={disableSelectionOnClick}
                hideSearch={hideSearch}
                keepNonExistentRowsSelected={keepNonExistentRowsSelected}
                loading={loading}
                selectableRow={selectableRow}
                showRefreshButton={showRefreshButton}
                initialPage={initialPage}
                initialPageSize={initialPageSize}
                initialOrder={initialOrder}
                initialOrderBy={initialOrderBy}
                customSelectableRow={customSelectableRow}
                mainHeaderComponent={mainHeaderComponent}
                updateRow={formSubmitApiFunction}
                hideActionColumn={!doNotHideActionForInactiveCompany && user?.company && !user?.company?.is_active}
                stackHeaderComponents={stackHeaderComponents}
                headerComponent={tableHeaderComponent}
                footerComponent={
                  <Box display="flex" flexDirection="column" gap="10px">
                    {tableName && !mainHeaderComponent && <Header title={tableName} mb="0" />}
                    <Box display="flex" gap="10px" alignItems="flex-end">
                      {includeAddButton &&
                        !!(doNotHideActionForInactiveCompany || !user?.company || user?.company?.is_active) && (
                          <PrimaryButton
                            onClick={() => handleAddClick()}
                            label={addButtonLabel ?? `Add ${entityName}`}
                            id={`${entityName.replace(/\s/g, '')}_add_button_footer`}
                            disabled={disableAddButton}
                          />
                        )}
                      {headerComponent}
                    </Box>
                  </Box>
                }
                showHeaderBelowTable={showHeaderBelowTable}
                onGridItemClick={onGridItemClick}
                rowActions={rowActionOptions}
                mobileViewDetailsDisplay={mobileViewDetailsDisplay}
                mobileViewConfig={mobileViewConfig}
              />
            )}
          </>
        )}
      </WaitForFacility>

      {formFields && (
        <CustomModal
          header={modalHeader}
          open={openModal}
          setOpen={setOpenModal}
          width={formModalWidth}
          id={`${entityName.replace(/\s/g, '')}_modal`}
        >
          {useInlineForm ? (
            <CustomInlineForm
              onSubmit={handleSubmit}
              fields={isUpdate && updateFormFields ? updateFormFields : formFields}
              initialValues={initialValues}
              schema={isUpdate && updateFormSchema ? updateFormSchema : formSchema}
              loading={buttonLoading}
              disabled={disableSubmitButton}
              submitButtonText={submitButtonText ? submitButtonText : 'Submit'}
            />
          ) : (
            <CustomForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              fields={isUpdate && updateFormFields ? updateFormFields : formFields}
              schema={isUpdate && updateFormSchema ? updateFormSchema : formSchema}
              loading={buttonLoading}
              rowGap={formRowGap}
              disabled={disableSubmitButton}
              submitButtonText={submitButtonText ? submitButtonText : 'Submit'}
              submitButtonId={`${entityName.replace(/\s/g, '')}_submit_button`}
              buttonPosition={buttonPosition}
            />
          )}
        </CustomModal>
      )}

      {includeDeleteAction && (
        <ConfirmationDialog
          open={openAlertDialog}
          setOpen={setOpenAlertDialog}
          content={alertContent}
          onConfirm={handleConfirmDelete}
          data={rowSelectedForAction}
          loadingConfirm={loadingDelete}
          title={'Confirmation'}
        />
      )}

      {customForm}
    </Box>
  );
};

export default CareGoTable;
