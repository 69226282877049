/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import {
  ColoredTable,
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  PrimaryButton,
  ProtectedComponent,
  RegularButton,
} from 'core/components';
import { addAppointment, cancelBooking, confirmBooking, getBookings } from 'company/api/bookings';
import { formatDate, formatDateTime } from 'core/utils';
import { useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { LoadingScreen } from 'core/screens';
import { SIZES } from 'theme/constants';
import { getPatients } from 'company/api/patient';
import { useSnackbar } from 'notistack';

type Props = {
  onUpdate: () => void;
  setShowTable: any;
};

const BookingsForConfirmation: React.FC<Props> = ({ onUpdate, setShowTable }) => {
  const { facility } = useContext(FacilityContext);
  const [alertContent, setAlertContent] = useState<string>('');
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(true);
  const [registerAsNew, setRegisterAsNew] = useState<boolean>(false);

  const [selectedBooking, setSelectedBooking] = useState<any | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [hasPendingBookings, setHasPendingBookings] = useState<boolean>(false);
  const tableRef: any = useRef();
  const patientsTableRef: any = useRef();

  const [patient, setPatient] = useState<any>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(0);
  const isMobile = useMediaQuery('(max-width:768px)');

  const refreshTable = () => {
    tableRef.current.refreshTable();
  };

  const refreshLocalTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  const getPendingBookings = (query: ApiQuery) => {
    return getBookings(facility.id, { status: 'pending', ...query });
  };

  useEffect(() => {
    if (facility) {
      getBookings(facility.id, { status: 'pending' }).then((res) => {
        if (res.data.data.length === 0) {
          setHasPendingBookings(false);
          setShowTable(false);
        } else {
          setHasPendingBookings(true);
          setShowTable(true);
        }
      });
    }
  }, [facility, refresh]);

  const successBookingUpdate = (message: string) => {
    setPendingAction(false);
    setOpenConfirmModal(false);
    setRegisterAsNew(false);
    onUpdate();
    refreshLocalTable();
    enqueueSnackbar(message, { variant: 'success' });
    if (isConfirm) {
      refreshTable();
    }
  };

  const handleProcessBooking = async () => {
    try {
      setPendingAction(true);
      let res;
      if (isConfirm) {
        res = registerAsNew
          ? await confirmBooking(facility.id, selectedBooking.id)
          : await addAppointment(facility.id, patient.patient_id, selectedBooking?.id);
      } else {
        res = await cancelBooking(facility.id, selectedBooking.id);
      }
      successBookingUpdate(res.data.message);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error occured! Please try again.', { variant: 'error' });
    }
  };

  if (!hasPendingBookings) return <></>;

  const patientColumns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1.5,
      hideable: false,
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      type: 'Date',
      flex: 0.75,
      renderCell: (params: { row: { birthday: string | Date | undefined } }) => (
        <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>
      ),
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      renderCell: (params: {
        row: { address: any; municipality: any; province_city: any; province: any; region: any };
      }) => (
        <CustomGridCell>
          {params.row.address && params.row.province_city
            ? [params.row.address, params.row.province_city].join(', ')
            : params.row.address
            ? params.row.address
            : params.row.province_city}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      renderCell: (params: { row: { full_name: string } }) => (
        <RegularButton
          variant="outlined"
          size="small"
          label="Select"
          onClick={() => {
            setOpenConfirmationDialog(true);
            setAlertContent('Are you sure you want to confirm booking for ' + params.row.full_name + '?');
            setPatient(params.row);
          }}
        />
      ),
    },
  ];

  return (
    <ProtectedComponent
      requiredAuth={['MANAGE_APPOINTMENTS']}
      requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
    >
      {' '}
      <Box width="100%" mb={SIZES.paddingL}>
        <ColoredTable
          ref={tableRef}
          forceRefresh={refresh}
          stackHeaderComponents
          containerRowAlignment={'flex-start'}
          getData={getPendingBookings}
          headerComponent={
            <Typography variant="h5" fontWeight="600" mb="10px">
              Bookings for Confirmation
            </Typography>
          }
          columns={[
            {
              field: 'schedule',
              headerName: 'Schedule',
              renderCell: (params) => (
                <CustomGridCell>
                  <Typography>{formatDateTime(params.value)}</Typography>
                </CustomGridCell>
              ),
            },
            {
              field: 'first_name',
              headerName: 'Patient Name',
              renderCell: (params) => (
                <CustomGridCell>
                  <Typography>
                    {params.row.first_name} {params.row.last_name}
                  </Typography>
                </CustomGridCell>
              ),
            },
            { field: 'mobile_number', headerName: 'Mobile Number' },
            { field: 'service_name', headerName: 'Service ' },
            {
              field: 'action',
              sortable: false,
              headerName: 'Actions',
              headerAlign: 'center',
              align: 'center',
              flex: 1.8,
              renderCell: (params) => (
                <Box mr="50px" display="flex" gap="10px">
                  <Button
                    variant="contained"
                    color="error"
                    disableElevation
                    sx={{ backgroundColor: '#e74b3c', borderRadius: '10px' }}
                    startIcon={<HighlightOffIcon color="error" sx={{ color: '#fff' }} />}
                    onClick={() => {
                      setSelectedBooking(params.row);
                      setAlertTitle('Reject booking for ' + params.row.full_name);
                      setAlertContent('Would you like to reject ' + params.row.first_name + "'s booking?");
                      setIsConfirm(false);
                      setOpenConfirmationDialog(true);
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    disableElevation
                    sx={{ backgroundColor: '#388e3c', borderRadius: '10px' }}
                    startIcon={<CheckCircleOutlineIcon sx={{ color: '#fff' }} />}
                    onClick={() => {
                      setSelectedBooking(params.row);
                      setAlertTitle('Confirm booking for ' + params.row.full_name);
                      setAlertContent('Would you like to confirm ' + params.row.first_name + "'s booking?");
                      setIsConfirm(true);
                      setOpenConfirmModal(true);
                    }}
                  >
                    Confirm
                  </Button>
                </Box>
              ),
            },
          ]}
          mobileViewConfig={(params) => ({
            title_fields: ['schedule'],
            title_link: `/company/patient-management?patient_id=${params.patient_id}`,
            subtitle_fields: ['full_name', 'service_name'],
          })}
          rowActions={[
            {
              label: 'Reject',
              color: 'error',
              icon: <HighlightOffIcon />,
              action: (row) => {
                setSelectedBooking(row);
                setAlertTitle('Reject booking for ' + row.full_name);
                setAlertContent('Would you like to reject ' + row.first_name + "'s booking?");
                setIsConfirm(false);
                setOpenConfirmationDialog(true);
              },
            },
            {
              label: 'Confirm',
              color: 'success',
              icon: <CheckCircleOutlineIcon />,
              action: (row) => {
                setSelectedBooking(row);
                setAlertTitle('Confirm booking for ' + row.full_name);
                setAlertContent('Would you like to confirm ' + row.first_name + "'s booking?");
                setIsConfirm(true);
                setOpenConfirmModal(true);
              },
            },
          ]}
        />
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        content={alertContent}
        title={alertTitle}
        onConfirm={handleProcessBooking}
      />
      <CustomModal open={openConfirmModal} setOpen={setOpenConfirmModal} header={alertTitle} width={1000}>
        <LoadingScreen loading={pendingAction}>
          <Box>
            <Box display="flex" gap="10px" alignItems={'center'}>
              <Typography fontSize={14}> [a] As a new patient:</Typography>
              <PrimaryButton
                onClick={() => {
                  setOpenConfirmationDialog(true);
                  setAlertContent(
                    'Are you sure you want to register as new patient and then confirm booking for ' +
                      selectedBooking?.full_name +
                      '?'
                  );
                  setRegisterAsNew(true);
                }}
                label="Register as a new patient"
              />
            </Box>
          </Box>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />

          <ColoredTable
            ref={patientsTableRef}
            rowId="patient_id"
            getData={(query: ApiQuery) => getPatients(facility.id, query)}
            rowHeight={20}
            headerComponent={
              <Typography paddingTop={1} fontSize={14}>
                [b] As an existing patient:
              </Typography>
            }
            columns={patientColumns}
          />
        </LoadingScreen>
      </CustomModal>
    </ProtectedComponent>
  );
};

export default BookingsForConfirmation;
