import { Box, Switch, SwitchProps, Typography, styled, useTheme } from '@mui/material';

import { tokens } from 'theme/theme';

type CustomSwitchProps = {
  label1: string;
  label2: string;
  image1: string;
  image2: string;
  value: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const CustomSwitch: React.FC<CustomSwitchProps> = ({ label1, label2, image1, image2, value, handleChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '500ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.primary,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box display="flex" alignItems="center">
      <Box padding="0px 5px">
        <Typography variant="body2">{label1}</Typography>
      </Box>
      <IOSSwitch checked={value} onChange={handleChange} />
      <Box padding="0px 5px">
        <Typography variant="body2">{label2}</Typography>
      </Box>
    </Box>
  );
};

export default CustomSwitch;
