import 'core/styles/Components.css';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomIconButton, PrimaryButton } from 'core/components';
import {
  getClientHealthPackages,
  getHealthPackagePatient,
  removePatientsInHealthPackage,
} from 'company/api/health-packages-patients';
import { useContext, useEffect, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EnrollmentForm from 'company/screens/HealthPackage/components/EnrollmentForm';
import { FacilityContext } from 'core/context/facility.context';
import HealthPlan from '../../HealthPlan/HealthPlan';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { PatientHealthPlan } from 'company/entities/modules/ClinicManagement/HealthPackage/HealthPackageModel';
import { SIZES } from 'theme/constants';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import { formatDate } from 'core/utils';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  setRefresh?: React.Dispatch<React.SetStateAction<number>>;
  patient: any;
};

const HealthPackagesEnrolled: React.FC<Props> = ({ patient, setRefresh }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);

  const [serviceSearchQuery, setServiceSearchQuery] = useState<string>('');

  const [healthPackages, setHealthPackages] = useState<PatientHealthPlan[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<number>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [expanded, setExpanded] = useState<number | false>(false);

  const [openEnrollFormModal, setOpenEnrollFormModal] = useState<boolean>(false);

  const getPackagePatient = async () => {
    if (facility && patient) {
      getHealthPackagePatient(facility.id, patient.patient_id)
        .then((res) => {
          setExpanded(false);
          fetchHealthPackages();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleAccordionChange = (healthPackage: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (!isExpanded) {
      setExpanded(false);
    } else {
      setExpanded(healthPackage.id);
    }
  };

  const handleDeleteHealthPackage = async () => {
    try {
      await removePatientsInHealthPackage(facility.id, { patients: JSON.stringify([selectedPackage]) });
      enqueueSnackbar('Health Session successfully deleted!', { variant: 'success' });

      fetchHealthPackages();
    } catch (error) {
      console.error('Error deleting health session:', error);
    }
  };

  const fetchHealthPackages = async () => {
    try {
      const healthPackagesRes = await getClientHealthPackages(facility.id, patient.patient_id, { length: 1000 });
      setHealthPackages(healthPackagesRes.data.data);
    } catch (error) {
      console.error('Error fetching health sessions and stages:', error);
      setHealthPackages([]);
    }
  };

  useEffect(() => {
    fetchHealthPackages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patient) {
      setExpanded(false);
      getPackagePatient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  return (
    <Box>
      <Box display={'flex'} mb="20px" justifyContent="right" gap="20px" alignContent="center">
        <SearchBar handleSearch={(searchKey) => setServiceSearchQuery(searchKey)} />

        <PrimaryButton
          onClick={() => {
            setOpenEnrollFormModal(true);
          }}
          label="Enroll to Health Session"
        />
      </Box>

      {!healthPackages.length && (
        <Typography variant="h6" color="textSecondary" align="center">
          No Health Sessions Enrolled
        </Typography>
      )}

      {healthPackages.map((healthPackage, index) => (
        <Box key={index} width="100%" mb="10px">
          <Accordion
            sx={{
              background: 'transparent',
              '& .MuiAccordionSummary-root': { minHeight: 'unset', margin: 0, padding: 0 },
              '& .MuiAccordionSummary-content': { margin: 0 },
              '& .Mui-expanded': { margin: '0px !important' },
            }}
            elevation={0}
            expanded={expanded === healthPackage.id}
            onChange={handleAccordionChange(healthPackage)}
          >
            <AccordionSummary
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: colors.light_blue_background_2,
                borderRadius: SIZES.borderRadiusS,
                padding: '6px 16px',
              }}
              expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '25px' }} />}
            >
              <Box padding="10px 10px" display="flex" width="100%" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="h4" paddingBottom="10px" fontWeight={500}>
                    {healthPackage.package_name}{' '}
                    {!healthPackage.is_active ? '(Cancelled)' : healthPackage.is_completed ? '(Completed)' : ''}
                  </Typography>
                  <Box display="flex" gap="20px">
                    <Box display="flex" gap="10px">
                      <Typography color={colors.accent}>Date Started</Typography>
                      <Typography>{formatDate(healthPackage.date_started)}</Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography color={colors.accent}>Current Session</Typography>
                      <Typography>{healthPackage.stage_name}</Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography color={colors.accent}>No. of Sessions</Typography>
                      <Typography>{healthPackage.number_of_stages}</Typography>
                    </Box>
                  </Box>
                </Box>
                {!!healthPackage.is_active && (
                  <CustomIconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedPackage(healthPackage.id);
                      setOpenConfirmationDialog(true);
                      setAlertContent('Are you sure you want to delete ' + healthPackage.package_name + '?');
                    }}
                    tooltip={'Delete'}
                    placement={'bottom'}
                    sx={{
                      padding: '10px 10px',
                      border: '1px solid',
                      borderColor: 'red',
                      borderRadius: '10px',
                    }}
                    icon={<DeleteOutlineIcon sx={{ color: 'red' }} />}
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <HealthPlan patientPlan={healthPackage} />
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}

      <EnrollmentForm
        selectedRow={patient}
        openEnrollFormModal={openEnrollFormModal}
        setOpenEnrollFormModal={setOpenEnrollFormModal}
        callbackAfterSubmit={(data) => {
          fetchHealthPackages();
        }}
      />

      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        content={alertContent}
        onConfirm={handleDeleteHealthPackage}
      />
    </Box>
  );
};

export default HealthPackagesEnrolled;
