import {
  AppointmentTable,
  BookingTable,
  ChartOfAccountTable,
  ClinicTable,
  CorporateClientTable,
  ExpenseTable,
  HealthPackageTable,
  MedicineTable,
  PatientBillingTable,
  PatientGroupTable,
  PatientNoteTemplatesTable,
  PatientTable,
  PrescriptionTable,
  ProductCategoryTable,
  ProductTable,
  PromoTable,
  ServiceProviderTable,
  ServiceTable,
  SupplierTable,
  UserAccessTable,
  UserGroupTable,
} from '../tables';

import { APPOINTMENT_ENTITY_NAME } from '../modules/ClinicManagement/Appointment/AppointmentModel';
import { BOOKING_ENTITY_NAME } from '../modules/ClinicManagement/Booking/BookingModel';
import { CC_ENTITY_NAME } from '../modules/ClinicManagement/CorporateClient/CorporateClientModel';
import { CHART_OF_ACCOUNT_ENTITY_NAME } from '../modules/InventorySystem/ChartOfAccount/ChartOfAccountModel';
import { CLINIC_ENTITY_NAME } from '../modules/ClinicManagement/Clinic/ClinicModel';
import { EXPENSE_ENTITY_NAME } from '../modules/AccountingSystem/Expense/ExpenseModel';
import { HP_ENTITY_NAME } from '../modules/ClinicManagement/HealthPackage/HealthPackageModel';
import { INVOICE_ENTITY_NAME } from '../modules/ClinicManagement/PatientBilling/PatientBillingModel';
import { MEDICINE_ENTITY_NAME } from '../modules/ClinicManagement/Medicine/MedicineModel';
import { PATIENT_ENTITY_NAME } from '../modules/ClinicManagement/Patient/PatientModel';
import { PC_ENTITY_NAME } from '../modules/InventorySystem/ProductCategory/ProductCategoryModel';
import { PG_ENTITY_NAME } from '../modules/ClinicManagement/PatientGroup/PatientGroupModel';
import { PNT_ENTITY_NAME } from '../modules/ClinicManagement/PatientNoteTemplate/PatientNoteTemplateModel';
import { PRESCRIPTION_ENTITY_NAME } from '../modules/ClinicManagement/Prescription/PrescriptionModel';
import { PRODUCT_ENTITY_NAME } from '../modules/InventorySystem/Product/ProductModel';
import { PROMO_ENTITY_NAME } from '../modules/ClinicManagement/Promo/PromoModel';
import { SERVICE_ENTITY_NAME } from '../modules/ClinicManagement/Service/ServiceModel';
import { SERVICE_PROVIDER_ENTITY_NAME } from '../modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { SUPPLIER_ENTITY_NAME } from '../modules/InventorySystem/Supplier/SupplierModel';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import { USER_ACCESS_ENTITY_NAME } from '../modules/ClinicManagement/UserAccess/UserAccessModel';
import { USER_GROUP_ENTITY_NAME } from '../modules/UserManagement/UserGroup/UserGroupModel';

type Props = {
  entityName: string;
  tableRef: any;
  setSelectedRow: any;
  selectedRow: any;
  tableExtraParams?: any;
  tableAction?: TableActionProps;
};

const TemplateTableGetter = (props: Props) => {
  const { entityName, tableRef, setSelectedRow, selectedRow, tableExtraParams, tableAction } = props;

  switch (entityName) {
    case CLINIC_ENTITY_NAME:
      return <ClinicTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />;

    case USER_ACCESS_ENTITY_NAME:
      return (
        <UserAccessTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );

    case SERVICE_PROVIDER_ENTITY_NAME:
      return (
        <ServiceProviderTable
          tableRef={tableRef}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );
    case APPOINTMENT_ENTITY_NAME:
      return (
        <AppointmentTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );
    case SERVICE_ENTITY_NAME:
      return (
        <ServiceTable
          tableRef={tableRef}
          tableAction={tableAction}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case PROMO_ENTITY_NAME:
      return <PromoTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />;

    case SUPPLIER_ENTITY_NAME:
      return (
        <SupplierTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );

    case PC_ENTITY_NAME:
      return (
        <ProductCategoryTable
          tableRef={tableRef}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case PRODUCT_ENTITY_NAME:
      return <ProductTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />;

    case INVOICE_ENTITY_NAME:
      return (
        <PatientBillingTable
          tableRef={tableRef}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case PATIENT_ENTITY_NAME:
      return (
        <PatientTable
          tableRef={tableRef}
          tableAction={tableAction}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case BOOKING_ENTITY_NAME:
      return <BookingTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />;

    case MEDICINE_ENTITY_NAME:
      return (
        <MedicineTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );

    case CC_ENTITY_NAME:
      return (
        <CorporateClientTable
          tableRef={tableRef}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case HP_ENTITY_NAME:
      return (
        <HealthPackageTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );

    case USER_GROUP_ENTITY_NAME:
      return (
        <UserGroupTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );

    case PG_ENTITY_NAME:
      return (
        <PatientGroupTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />
      );

    case PRESCRIPTION_ENTITY_NAME:
      return (
        <PrescriptionTable
          tableRef={tableRef}
          patientId={tableExtraParams.patientId}
          patient={tableExtraParams.patient}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case PNT_ENTITY_NAME:
      return (
        <PatientNoteTemplatesTable
          tableRef={tableRef}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case CHART_OF_ACCOUNT_ENTITY_NAME:
      return (
        <ChartOfAccountTable
          tableRef={tableRef}
          setDrawerSelectedRow={setSelectedRow}
          drawerSelectedRow={selectedRow}
        />
      );

    case EXPENSE_ENTITY_NAME:
      return <ExpenseTable tableRef={tableRef} setDrawerSelectedRow={setSelectedRow} drawerSelectedRow={selectedRow} />;

    default:
      return <></>;
  }
};

export default TemplateTableGetter;
