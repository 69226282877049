/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { Box, Button, Chip, Divider, Typography, useTheme } from '@mui/material';
import {
  CustomGridCell,
  CustomModal,
  CustomSwitch,
  CustomTable,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { addAppointment, cancelBooking, confirmBooking, createBooking, getBookings } from 'company/api/bookings';
import { all, upcoming } from 'core/icons';
import { capitalizeWord, formatDate, formatDateTimeWithDay } from 'core/utils';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { ConfirmationDialog } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { LoadingScreen } from 'core/screens';
import QRCode from 'react-qr-code';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { UserContext } from 'core/context/user.context';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { WaitForFacility } from 'company/components';
import { create_booking_schema } from 'company/model/schema';
import { getColorByStatus } from 'company/components/util';
import { getPatients } from 'company/api/patient';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type BookingInput = {
  first_name: string;
  middle_name: string;
  last_name: string;
  schedule: string;
  mobile_number: string;
  email: string;
  birthday: string;
  sex: string;
  service_id: number | string;
};

interface BookingFieldInput extends FieldInput {
  field_name: keyof BookingInput;
}

const initialValues: BookingInput = {
  first_name: '',
  middle_name: '',
  last_name: '',
  schedule: '',
  mobile_number: '',
  email: '',
  birthday: '',
  sex: '',
  service_id: '',
};

const Bookings = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { facility } = useContext(FacilityContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const [formFields, setFormFields] = useState<FieldInput[]>([]);
  const [patientRows, setPatientRows] = useState<any[]>([]);
  const [patient, setPatient] = useState<any>();
  const [patientRowCount, setPatientRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [registerConfirmAlertContent, setRegisterConfirmAlertContent] = useState<string>('');
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const [booking, setBooking] = useState<any>();
  const [refreshTable, setRefreshTable] = useState<number>(0);
  const [viewUpcoming, setViewUpcoming] = useState<boolean>(false);
  const [openQRModal, setOpenQRModal] = useState<boolean>(false);
  const [otherFilter, setOtherFilter] = useState<any>({});

  const STATUS = {
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    CANCELLED: 'cancelled',
  };

  const all_columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.3,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => <CustomGridCell>{params.row.id}</CustomGridCell>,
    },
    {
      field: 'full_name',
      headerName: 'Patient Name',
      flex: 1,
      hideable: false,
    },
    {
      field: 'mobile_number',
      headerName: 'Mobile Number',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.mobile_number}</CustomGridCell>,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      flex: 1.2,
      minWidth: 170,
      renderCell: (params) => <CustomGridCell>{formatDateTimeWithDay(params.row.schedule)}</CustomGridCell>,
    },
    {
      field: 'service_name',
      headerName: 'Service',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      renderCell: (params) => (
        <Chip
          size="small"
          variant="outlined"
          label={
            params.row.status === 'cancelled'
              ? 'Cancelled'
              : capitalizeWord(params.row.status) &&
                (params.row.status === 'pending' ? 'Pending' : capitalizeWord(params.row.status))
          }
          sx={{
            borderColor: getColorByStatus(params.row.status, theme.palette.mode),
            color: getColorByStatus(params.row.status, theme.palette.mode),
            fontSize: 9,
            paddingBlock: 0,
          }}
        />
      ),
    },
    {
      field: 'action',
      flex: 0.5,
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div>
          <RowAction actions={actions} data={params.row} hidden={params.row.status === 'confirmed'} />
        </div>
      ),
    },
  ];

  const actions = [
    {
      label: 'Confirm',
      hideCallback: (data: any) => [STATUS.CONFIRMED].includes(data.status),
      icon: <CheckIcon />,
      action: (data: any) => {
        setOpenConfirmModal(true);
        setAlertContent('Confirm booking for ' + data.full_name);
        setBooking(data);
      },
    },
    {
      label: 'Cancel',
      hideCallback: (data: any) => [STATUS.CANCELLED].includes(data.status),
      icon: <CancelIcon />,
      action: (data: any) => {
        setOpenConfirmationDialog(true);
        setAlertContent('Are you sure you want to cancel this booking?');
        setBooking(data);
        setIsConfirm(false);
      },
    },
  ];

  const patientColumns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      hideable: false,
    },
    { field: 'mobile_number', headerName: 'Mobile Number', flex: 1 },
    {
      field: 'birthday',
      headerName: 'Birthday',
      type: 'Date',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.birthday)}</CustomGridCell>,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {[params.row.address, params.row.municipality_name, params.row.province_name, params.row.region_name].join(
            ', '
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={() => {
            setOpenRegisterModal(true);
            setRegisterConfirmAlertContent(
              'Are you sure you want to confirm booking for ' + params.row.full_name + '?'
            );
            setPatient(params.row);
            setIsConfirm(true);
          }}
        >
          Select
        </Button>
      ),
    },
  ];

  const bookingFields: BookingFieldInput[] = [
    { field_name: 'first_name', display_name: 'First Name', type: 'text' },
    { field_name: 'middle_name', display_name: 'Middle Name (optional)', type: 'text' },
    { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
    {
      field_name: 'schedule',
      display_name: 'Appointment Schedule',
      type: 'datetime',
      minutesStep: 5,
      disablePast: true,
    },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'email', display_name: 'Email', type: 'text' },
    { field_name: 'birthday', display_name: 'Birthday (optional)', type: 'date', disableFuture: true },
    {
      field_name: 'sex',
      display_name: 'Sex Assigned at Birth',
      type: 'select',
      options: [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
      ],
    },
    {
      field_name: 'service_id',
      display_name: 'Service',
      type: DROPDOWN_FIELD.SERVICE,
      options: [],
      id: 'booking_service_dropdown',
    },
  ];

  const getAllBookings = async (query: ApiQuery) => {
    if (viewUpcoming) query.get_upcoming = 1;

    return getBookings(facility.id, query);
  };

  const getPatientsList = async (query: ApiQuery) => {
    if (facility) {
      setLoading(true);
      try {
        let res = await getPatients(facility.id, query);
        setPatientRows(res.data.data);
        setPatientRowCount(res.data.meta.total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleBookingStatus = async () => {
    if (isConfirm) {
      confirmBooking(facility.id, booking.id)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          refresh();
        })
        .catch((error) => console.error(error));
    } else {
      cancelBooking(facility.id, booking.id)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          refresh();
        })
        .catch((error) => console.error(error));
    }
  };

  const handleRegisterConfirm = async () => {
    if (isConfirm) {
      addAppointment(facility.id, patient.patient_id, booking.id)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          setOpenConfirmModal(false);
          refresh();
        })
        .catch((error) => console.error(error));
    } else {
      confirmBooking(facility.id, booking.id)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          setOpenConfirmModal(false);
          refresh();
        })
        .catch((error) => console.error(error));
    }
  };

  const handleSubmit = async (data: any) => {
    return createBooking(facility.id, data);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewUpcoming(event.target.checked);
    setOtherFilter({ ...otherFilter, get_past: event.target.checked ? 1 : undefined });
  };

  const refresh = () => {
    setRefreshTable((prev: number) => prev + 1);
  };

  useEffect(() => {
    setFormFields(bookingFields);
    setBreadcrumb([{ label: 'Bookings' }]);
  }, [facility]);

  useEffect(() => {
    if (user) {
      if (!user?.company?.with_booking_platform) {
        navigate('/forbidden');
      }
      setIsLoading(false);
    }
  }, [user]);

  const content = {
    type: BLOCK_TYPE.PAGE,
    header: 'Bookings',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Booking',
        tableComponent: {
          columns: all_columns,
          getData: getAllBookings,
          forceRefresh: refreshTable,
          otherFilter: otherFilter,
          initialOrder: 'desc',
          initialOrderBy: 'schedule',
          headerComponent: (
            <Box display="flex" gap={5}>
              <RegularButton
                startIcon={<QrCodeIcon />}
                onClick={() => setOpenQRModal(true)}
                label="View Booking Page"
              />
            </Box>
          ),
          filterComponent: (
            <CustomSwitch
              label1="All"
              label2="Upcoming"
              image1={all}
              image2={upcoming}
              value={viewUpcoming}
              handleChange={handleChange}
            />
          ),
        },
        tableAction: {
          includeAddButton: true,
          includeUpdateAction: false,
          includeDeleteAction: false,
        },
        tableForm: {
          formFields: formFields,
          formSchema: create_booking_schema,
          formInitialValues: initialValues,
          formSubmitApiFunction: handleSubmit,
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <WaitForFacility facility={facility}>
      <LoadingScreen loading={isLoading}>
        <PageBuilder content={content} />
        <ConfirmationDialog
          open={openConfirmationDialog}
          setOpen={setOpenConfirmationDialog}
          content={alertContent}
          onConfirm={handleBookingStatus}
        />
        <CustomModal open={openQRModal} setOpen={setOpenQRModal} header="View Booking Page" width={400}>
          <Box paddingY="10px" textAlign="center">
            <RegularButton
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => navigate(`/book/appointment/${facility?.facility_code}`)}
              label="Go to booking page"
            />
            <Typography variant="h5" marginTop="20px">
              or scan this QR Code
            </Typography>
          </Box>
          <QRCode
            value={`${process.env.REACT_APP_BASE_URL}/book/appointment/${facility?.facility_code}`}
            size={300}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            viewBox={`0 0 256 256`}
            // level={"H"}
          />
        </CustomModal>
        <CustomModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          header={alertContent}
          width={1000}
          headerComponent={
            <Box>
              <Box display="flex" gap="10px" alignItems={'center'}>
                <Typography fontSize={14}> [a] As a new patient:</Typography>
                <PrimaryButton
                  onClick={() => {
                    setOpenRegisterModal(true);
                    setRegisterConfirmAlertContent(
                      'Are you sure you want to register as new patient and then confirm booking for ' +
                        booking.full_name +
                        '?'
                    );
                    setIsConfirm(false);
                  }}
                  label="Register as a new patient"
                />
              </Box>
            </Box>
          }
        >
          <Divider style={{ marginBottom: 15 }} />

          <CustomTable
            // hideSearch={true}
            rowId={'patient_id'}
            rows={patientRows}
            rowCount={patientRowCount}
            columns={patientColumns}
            loading={loading}
            getData={getPatientsList}
            headerComponent={
              <Typography paddingTop={1} fontSize={14}>
                [b] As an existing patient:
              </Typography>
            }
          />
        </CustomModal>

        <ConfirmationDialog
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          content={registerConfirmAlertContent}
          onConfirm={handleRegisterConfirm}
        />
      </LoadingScreen>
    </WaitForFacility>
  );
};

export default Bookings;
