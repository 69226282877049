import { Box, Step, StepIcon, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import {
  HealthPlanSessionModel,
  PatientHealthPlan,
} from 'company/entities/modules/ClinicManagement/HealthPackage/HealthPackageModel';
import ScheduleAppointment, { ACTION } from 'company/screens/HealthPackage/components/ScheduleAppointmentForm';
import { proceedToNextStage, setHealthPackageAsComplete } from 'company/api/health-packages-patients';
import { useEffect, useRef, useState } from 'react';

import { AppointmentTable } from 'company/entities/tables';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { SIZES } from 'theme/constants';
import { formatDate } from 'core/utils';
import { getStages } from 'company/api/health-packages';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  //   plan: HealthPackageModel;
  patientPlan: PatientHealthPlan;
};

const HealthPlan: React.FC<Props> = ({ patientPlan }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const stagesContainerRef = useRef<HTMLDivElement>(null);
  const tableRef: any = useRef();

  const [activeStep, setActiveStep] = useState<number>(0);

  const [stages, setStages] = useState<HealthPlanSessionModel[]>([]);
  const [currentStageIndex, setCurrentStageIndex] = useState<number>(0);
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const [healthPackageComplete, setHealthPackageComplete] = useState<boolean>(patientPlan.is_completed);
  const [healthPackageCompleteModal, setHealthPackageCompleteModal] = useState<boolean>(false);
  const [openNextStageModal, setOpenNextStageModal] = useState<boolean>(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState<boolean>(false);
  const [loadingNextSession, setLoadingNextSession] = useState<boolean>(false);

  const handleStepClick = (stage: any, index: number) => {
    setActiveStep(index);
    setSelectedStage(stage);
  };

  const getPackageStages = async () => {
    try {
      const res = await getStages(patientPlan.package_id);
      const stages: HealthPlanSessionModel[] = res.data.data;
      updateVisitDate(stages);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const updateVisitDate = (stages: HealthPlanSessionModel[]) => {
    let totalDays = 0;
    let currentStageFound = false;
    stages.forEach((session, index) => {
      if (session.id === patientPlan.stage_id) {
        setCurrentStageIndex(index);
        setActiveStep(index);
        setSelectedStage(session);
        currentStageFound = true;
      }

      if (currentStageFound) {
        if (session.id !== patientPlan.stage_id) {
          totalDays += session.days_after_prev_stage;
        }
        const calculatedDate = moment(patientPlan.expected_next_stage).add(totalDays, 'days').toString();
        session.visit_date = calculatedDate;
      } else {
        session.visit_date = '';
      }
    });

    setStages([...stages]);
  };

  const setAsComplete = () => {
    setHealthPackageAsComplete(patientPlan.id).then(() => {
      enqueueSnackbar('Health Plan successfully completed!', { variant: 'success' });
      setHealthPackageComplete(true);
    });
  };

  const handleSubmit = async () => {
    try {
      setLoadingNextSession(true);
      await proceedToNextStage(patientPlan.id);
      const nextStage = stages[currentStageIndex + 1];
      setActiveStep(currentStageIndex + 1);
      patientPlan.stage_id = nextStage.id;
      patientPlan.is_current_stage_confirmed = false;
      setCurrentStageIndex(currentStageIndex + 1);
      updateVisitDate(stages);
      setLoadingNextSession(false);

      enqueueSnackbar('Patient proceeded to next stage!', { variant: 'success' });
      setOpenNextStageModal(false);
    } catch (error) {
      enqueueSnackbar('Error in proceeding to next stage!', { variant: 'error' });
    }

    // successFormSubmit(res);
  };

  useEffect(() => {
    if (stagesContainerRef.current && stages) {
      const currentStageElement = stagesContainerRef.current.querySelector('.client-current-stage');
      if (currentStageElement) {
        currentStageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
          const isLastStage = activeStep === stages.length - 1;
          if (isLastStage && stagesContainerRef.current) {
            stagesContainerRef.current.scrollTo({ top: stagesContainerRef.current.scrollHeight, behavior: 'smooth' });
          }
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stages]);

  useEffect(() => {
    tableRef.current?.refreshTable();
  }, [selectedStage]);

  useEffect(() => {
    if (patientPlan) getPackageStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPlan]);

  return (
    <Box display="grid" gridTemplateColumns="220px 1fr" columnGap={SIZES.padding}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          padding: '20px',
          borderRadius: '10px',
          backgroundColor: colors.light_blue_background,
          overflowY: 'auto',
          alignItems: 'left',
          justifyContent: 'left',
          position: 'relative',
        }}
      >
        <Box ref={stagesContainerRef} sx={{ marginTop: '10px' }}>
          <Stepper
            activeStep={activeStep}
            nonLinear
            orientation="vertical"
            sx={{
              '& .MuiStepConnector-vertical': {
                marginLeft: '17px',
              },
              '& .MuiStepConnector-line': {
                borderLeftColor: colors.blueAccent,
                borderLeftWidth: '2px',
                margin: 0,
                padding: 0,
                minHeight: '25px',
              },
            }}
          >
            {stages &&
              stages.map((stage_data, index) => {
                const isStepCompleted = patientPlan.is_completed || index < currentStageIndex || healthPackageComplete;

                return (
                  <Step
                    key={stage_data.id}
                    onClick={() => handleStepClick(stage_data, index)}
                    completed={isStepCompleted}
                    className={stage_data.id === patientPlan.stage_id ? 'client-current-stage' : ''}
                    sx={{
                      '& .MuiStepLabel-root .Mui-active': { color: colors.accent },
                      '& .MuiStepLabel-root': { color: colors.accent, cursor: 'pointer', padding: 0 },
                      '& .MuiStepLabel-root:hover': {
                        '& .completedStep': {
                          bgcolor: colors.accent,
                          color: 'white',
                        },
                      },
                      '& .MuiStepLabel-root .Mui-active .Mui-completed': {
                        color: colors.accent,
                        cursor: 'pointer',
                      },
                      '& .MuiStepIcon-root.Mui-active': {
                        backgroundColor: colors.blueAccent,
                      },
                      '& .MuiStepLabel-root .Mui-completed': { cursor: 'pointer' },
                      '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: 'white' },
                      '& .MuiStepLabel-root .MuiStepIcon-text': {
                        fill: colors.accent,
                        fontSize: '14px',
                      },
                      '& .MuiStepIcon-root': {
                        height: '35px',
                        width: '35px',
                        border: '2px solid ' + colors.blueAccent,
                        color: 'transparent',
                        borderRadius: '50px',
                      },
                      '&:hover': {
                        '& .MuiStepIcon-root': {
                          color: colors.accent,
                          backgroundColor: colors.blueAccent,
                        },
                        '& .MuiStepIcon-text': {
                          fill: 'white',
                        },
                        '& > .completedStep': {
                          bgcolor: colors.accent,
                        },
                      },
                      '& .client-current-stage.MuiStepLabel': {
                        fontWeight: 'bold',
                      },
                      '& .selected-step': {
                        bgcolor: colors.accent,
                        color: 'white',
                      },
                    }}
                  >
                    <StepLabel
                      color="inherit"
                      StepIconComponent={(props) => (
                        <StepIcon
                          icon={
                            isStepCompleted ? (
                              <Box
                                className={`completedStep ${activeStep === index ? 'selected-step' : ''}`}
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  borderRadius: '50px',
                                  border: '2px solid ' + colors.blueAccent,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  '&:hover': {
                                    bgcolor: colors.accent,
                                    cursor: 'pointer',
                                    '& > svg': {
                                      color: 'white',
                                    },
                                  },
                                  '&:active': {
                                    bgcolor: colors.accent,
                                    cursor: 'pointer',
                                    '& > svg': {
                                      color: 'white',
                                    },
                                  },
                                }}
                                onClick={() => {
                                  handleStepClick(stage_data, index);
                                }}
                              >
                                <DoneRoundedIcon
                                  className={`completedStep ${activeStep === index ? 'selected-step' : ''}`}
                                  sx={{ color: colors.accent }}
                                />
                              </Box>
                            ) : (
                              props.icon
                            )
                          }
                          active={props.active}
                          completed={isStepCompleted}
                        />
                      )}
                    >
                      <Typography
                        sx={{
                          color: 'black',
                          fontWeight: stage_data.id === patientPlan.stage_id ? '600' : 'regular',
                        }}
                      >
                        {stage_data.stage_name}
                      </Typography>
                      {index >= currentStageIndex && (
                        <Typography
                          sx={{
                            color: 'black',
                            fontWeight: stage_data.id === patientPlan.stage_id ? '600' : 'regular',
                          }}
                        >
                          {formatDate(stage_data.visit_date)}
                        </Typography>
                      )}
                    </StepLabel>
                  </Step>
                );
              })}
          </Stepper>

          {/* Buttons */}
          {!!patientPlan.is_active && (
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                right: 0,
                backgroundColor: colors.light_blue_background,
                // padding: '10px',
                mt: SIZES.padding,
                // marginBlock: '-20px',
                // marginBottom: '-20px',
              }}
            >
              {activeStep ===
                stages.findIndex((stage) => stage.id === patientPlan.stage_id && stages.length - 1 !== activeStep) && (
                <RegularButton
                  // startIcon={<ArrowForwardSharpIcon />}
                  variant="outlined"
                  onClick={() => {
                    // setNextStageName(stages[activeStep + 1].stage_name);
                    setOpenNextStageModal(true);
                  }}
                  label="Proceed to Next Session"
                  size="small"
                  fullWidth
                  styles={{ padding: SIZES.paddingS }}
                />
              )}
              {!healthPackageComplete &&
                currentStageIndex === activeStep &&
                currentStageIndex === stages.length - 1 && (
                  <RegularButton
                    label={'Complete'}
                    onClick={() => setHealthPackageCompleteModal(true)}
                    startIcon={<DoneAllOutlinedIcon />}
                  />
                )}
            </Box>
          )}

          {/* Modal */}
          <ConfirmationDialog
            open={healthPackageCompleteModal}
            setOpen={setHealthPackageCompleteModal}
            content={'Mark "' + patientPlan.package_name + '" as complete?'}
            onConfirm={setAsComplete}
          />

          <CustomModal
            open={openNextStageModal}
            setOpen={setOpenNextStageModal}
            header={`Proceed to Next Session`}
            width={500}
          >
            <Box display="flex" flexDirection="column" gap="30px">
              <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center">
                <Box display="flex" gap="5px">
                  <Typography>The next session is scheduled for</Typography>
                  <Typography sx={{ color: colors.blueAccent, fontWeight: 'bold' }}>
                    {formatDate(stages[currentStageIndex + 1]?.visit_date)}.
                  </Typography>
                </Box>
                <Typography>Would you like to proceed to next session?</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" gap={SIZES.paddingS}>
                <RegularButton label="Cancel" onClick={() => setOpenNextStageModal(false)} variant="outlined" />
                <RegularButton label="Proceed" onClick={handleSubmit} loading={loadingNextSession} />
              </Box>
            </Box>
          </CustomModal>
        </Box>
      </Box>

      <Box width="100%" display="flex" flexDirection="column" gap="20px" paddingBlock={SIZES.padding}>
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          {stages && stages.length > activeStep ? stages[activeStep].stage_name : ''}
        </Typography>

        <AppointmentTable
          ref={tableRef}
          packageStageId={selectedStage?.id}
          packagePatientId={patientPlan.id}
          visibleColumns={['schedule', 'service_names', 'status', 'invoice_number']}
        />
        {/* {(patientPlan.is_current_stage_confirmed && currentStageIndex === activeStep) ||
        currentStageIndex > activeStep ? (
          <AppointmentTable
            ref={tableRef}
            packageStageId={selectedStage?.id}
            packagePatientId={patientPlan.id}
            visibleColumns={['schedule', 'service_names', 'status', 'invoice_number']}
          />
        ) : (
          <>
            {selectedStage && (
              <Box width="100%" mb={SIZES.paddingL}>
                <ColoredTable
                  ref={tableRef}
                  //   dataRows={services}
                  getData={(query) => getServicesByStage(selectedStage.id, query)}
                  columns={[
                    {
                      field: 'service_name',
                      headerName: 'Service',
                      flex: 1,
                    },
                    {
                      field: 'price',
                      headerName: 'Price',
                      flex: 1,
                      headerAlign: 'right',
                      align: 'right',
                      renderCell: (params) => (
                        <Typography sx={{ fontWeight: '700', color: colors.blueAccent }}>
                          {formatCurrency(params.row.price)}
                        </Typography>
                      ),
                    },
                  ]}
                />
              </Box>
            )}
          </>
        )} */}
      </Box>
      <CustomModal
        open={openAppointmentModal}
        setOpen={setOpenAppointmentModal}
        header={'Schedule Appointment'}
        width={500}
      >
        <ScheduleAppointment
          selectedService={{}}
          stageID={selectedStage?.id}
          patient={patientPlan}
          packagePatientID={patientPlan.id}
          setOpen={setOpenAppointmentModal}
          refreshTable={() => {
            tableRef.current?.refreshTable();
            patientPlan.is_current_stage_confirmed = true;
          }}
          action={ACTION.APPOINTMENT}
        />
      </CustomModal>
    </Box>
  );
};

export default HealthPlan;
