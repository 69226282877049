import { createContext, useMemo, useState } from 'react';

import { createTheme } from '@mui/material/styles';

export interface ThemeTokens {
  secondary: string;
  accent: string;
  blueAccent: string;
  redAccent: string;
  greenAccent: string;
  deleteColor: string;

  secondary_background: string;
  text: string;
  white_text: string;
  black_text: string;

  dark: string;
  main: string;
  light: string;
  border: string;
  hoverBackground: string;
  hoverTableRow: string;
  documentBackground: string;

  tableHeader: string;
  tableRow: string;
  textBackground: string;
  error: string;
  lightBackground: string;

  primary: string;
  light_blue_background: string;
  light_blue_background_2: string;
  light_red_background: string;
  light_red_background_2: string;
  dark_grey_text: string;
  grey_text: string;
  background: string;
  input_background: string;
  primary_hovered: string;
  green_hovered: string;
  red_hovered: string;
}

export const tokens = (mode: string): ThemeTokens => ({
  ...(mode === 'dark'
    ? {
        secondary: '#0380BC',
        // accent: '#239BD7',
        accent: '#0192D5',
        blueAccent: '#0192D5',
        redAccent: '#db4f4a',
        greenAccent: '#388E3C',
        deleteColor: '#E74C3C',

        secondary_background: '#232426',
        text: '#e0e0e0',
        white_text: '#e0e0e0',
        black_text: '#45464E',

        dark: '#3d3d3d',
        main: '#666666',
        light: '#e0e0e0',
        border: '#515151',
        hoverBackground: 'rgba(255, 255, 255, 0.08)',
        // hoverTableRow: '#5f6569',
        hoverTableRow: 'rgba(255, 255, 255, 0.08)',
        documentBackground: '#343537',

        tableHeader: '#6BAED6',
        tableRow: '#F8FCFE',
        textBackground: '#BBD7F0',
        error: '#c62828',
        lightBackground: '#E4F3FA',

        // primary: '#239BD7',
        primary: '#0192D5',
        light_blue_background: '#232426',
        light_blue_background_2: '#343537',
        light_red_background: 'rgba(244, 67, 54, 0.08)',
        light_red_background_2: 'rgba(244, 67, 54, 0.18)',
        dark_grey_text: '#FFF',
        grey_text: '#FFF',
        background: '#161616',
        input_background: '#45464E',
        primary_hovered: 'rgba(35, 155, 215, 0.9)',
        green_hovered: '#2f7530',
        red_hovered: '#b53636',
      }
    : {
        secondary: '#239BD7',
        // accent: '#1581c4',
        accent: '#0192D5',
        blueAccent: '#0192D5',
        redAccent: '#db4f4a',
        greenAccent: '#388E3C',
        deleteColor: '#E74C3C',

        secondary_background: '#fff',
        text: '#141414',
        white_text: '#e0e0e0',
        black_text: '#45464E',

        dark: '#bdbdbd',
        main: '#a3a3a3',
        light: '#45464E',
        border: '#e0e0e0',
        hoverBackground: 'rgba(248, 248, 248, 0.8)',
        hoverTableRow: '#e4f3fa',
        documentBackground: 'white',

        tableHeader: '#E4F3FA',
        tableRow: '#F8FCFE',
        textBackground: '#BBD7F0',
        error: '#c62828',
        lightBackground: '#E4F3FA',

        // primary: '#0092d5',
        primary: '#0192D5',
        light_blue_background: '#f3f9ff',
        light_blue_background_2: '#dff1fa',
        light_red_background: '#fef7f4',
        light_red_background_2: 'rgba(244, 67, 54, 0.08)',
        grey_text: '#888888',
        dark_grey_text: '#45464E',
        background: '#fff',
        input_background: '#f0f0f0',
        primary_hovered: 'rgba(0, 146, 213, 0.85)',
        green_hovered: '#2f7530',
        red_hovered: '#b53636',
      }),
});

export const themeSettings = (mode: string) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            primary: {
              main: colors.primary,
            },
            secondary: {
              main: colors.accent,
            },
            neutral: {
              dark: colors.dark,
              main: colors.main,
              light: colors.light,
            },
            background: {
              default: colors.background,
            },
          }
        : {
            primary: {
              main: colors.primary,
            },
            secondary: {
              main: colors.accent,
            },
            neutral: {
              dark: colors.dark,
              main: colors.main,
              light: colors.light,
            },
            background: {
              default: colors.background,
            },
          }),
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 14,
      h1: {
        fontSize: 30,
      },
      h2: {
        fontSize: 24,
      },
      h3: {
        fontSize: 20,
      },
      h4: {
        fontSize: 16,
      },
      h5: {
        fontSize: 14,
      },
      h6: {
        fontSize: 12,
      },
      body1: {
        fontSize: 14,
      },
      subtitle1: {
        fontSize: 13,
      },
    },
    // components: {
    //   MuiTextField: {
    //     styleOverrides: {
    //       root: {
    //         backgroundColor: '#fff',
    //         borderRadius: '10px',
    //         '& .MuiInputBase-root': {
    //           backgroundColor: '#fff', // Set input background to white
    //           zIndex: 1,
    //         },
    //         '& .MuiInputLabel-root': {
    //           backgroundColor: '#fff', // Set label background to white
    //           zIndex: 2,
    //           margin: '0 10px'
    //         },
    //         '& .MuiInput-underline': {
    //           padding: '0 10px',
    //           zIndex: 0, // Make sure underline is below the input
    //         },
    //         '& .MuiInput-underline:before': {
    //           borderBottomColor: colors.primary,
    //         },
    //         '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    //           borderBottomColor: colors.primary_hovered,
    //         },
    //         '& .MuiInput-underline:after': {
    //           borderBottomColor: colors.primary,
    //         },
    //       },
    //     },
    //   },
    // },
  };
};

// Context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const theme_mode = localStorage.getItem('theme_mode');
  const [mode, setMode] = useState<string>(theme_mode ?? 'light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('theme_mode', mode === 'light' ? 'dark' : 'light');
        setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode) as any), [mode]);
  return [theme, colorMode];
};
